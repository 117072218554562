import * as React from "react";
import { MUIDataTableOptions, MUIDataTableColumn } from "mui-datatables";
import { IOrderRewardRangeGetListResult } from "Services/OrderRewardRangeApi/interfaces-results";
import { IResultBase, IGridModel } from "Services/interfaces";
import { useOrderRewardRangeApi } from "Services/OrderRewardRangeApi/order-reward-range-api";
import { IOrderRewardRangeData } from "Services/OrderRewardRangeApi/interfaces";
import { ApiResultStatus } from "Utils/enums";
import { Divider, Grid } from "@material-ui/core";
import OrderRewardRangeEditorAdmin from "../OrderRewardRangeEditors/order-reward-range-editor-admin";
import useModalQuestion from "Hooks/use-modal-question";
import useModalSuccess from "Hooks/use-modal-success";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import GridContainer from "Components/Grids/grid-container";
import GridTable from "Components/Grids/grid-table";
import { useTranslation } from "react-i18next";

const OrderRewardRangeList: () => JSX.Element = (): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (reloadTable: number): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [reloadTable, dispatchReloadTable] = React.useReducer(reloadTableReducer, 0);
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { deleteById, getList } = useOrderRewardRangeApi();
  const { t } = useTranslation(["commonResources"]);

  const deleteRange: (id: number) => Promise<void> = async (id: number): Promise<void> => {
    await deleteById(id).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("rewardForm.deleteSuccess"));
        dispatchReloadTable();
      }
    });
  };

  const handleClickDelete: (id: number) => Promise<void> = async (id: number): Promise<void> => {
    showModalQuestion(t("rewardInfo.deleteInfo"), id);
  };

  React.useEffect(() => {
    setYesFunction(() => deleteRange);
  }, []);

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "minOrderTotalPrice",
      label: t("totalPriceMin"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ textAlign: "right" }}>{value} zł</p>;
        },
      },
    },
    {
      name: "maxOrderTotalPrice",
      label: t("totalPriceMax"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ textAlign: "right" }}>{value} zł</p>;
        },
      },
    },
    {
      name: "rewardValue",
      label: t("reward"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ textAlign: "right" }}>{value} zł</p>;
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <OrderRewardRangeEditorAdmin
              orderRewardRangeId={tableMeta.rowData[0]}
              handleSaveSuccess={dispatchReloadTable}
            />
          );
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGridIcon
              icon="fa-trash-alt"
              color="primary"
              onClick={() => {
                handleClickDelete(tableMeta.rowData[0]);
              }}
            />
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    search: false,
    pagination: false,
    sort: false,
  };

  return (
    <React.Fragment>
      <GridContainer title={t("rewards")}>
        <Grid container item justify="flex-start" alignItems="flex-start">
          <OrderRewardRangeEditorAdmin handleSaveSuccess={dispatchReloadTable} />
        </Grid>
        <Divider />
        <GridTable<IGridModel, IOrderRewardRangeData, IOrderRewardRangeGetListResult>
          reloadCount={reloadTable}
          columns={columns}
          customTableOptions={options}
          getList={getList}
        />
      </GridContainer>
    </React.Fragment>
  );
};

export default OrderRewardRangeList;
