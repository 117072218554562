import { IOrderProductGetListResult, IOrderProductGetDetailsResult } from "./interfaces-result";
import { IOrderProductGetListModel, IOrderProductSetPlaygroundModel } from "./interfaces-model";
import { IOrderProductApiInterface } from "./interfaces";
import { useBaseApi } from "Services/base-api";
import { IResultBase } from "Services/interfaces";
import useBaseDataApi from "Services/base-data-api";

export const useOrderProductApi: () => IOrderProductApiInterface
    = (): IOrderProductApiInterface => {
        const { baseGetDetails, baseGetList } = useBaseDataApi({ apiControllerName: "OrderProduct" });
        const { basePostRequest } = useBaseApi();

        const getDetails: (id: number) => Promise<IOrderProductGetDetailsResult>
            = async (id: number): Promise<IOrderProductGetDetailsResult> => {
                return await baseGetDetails<IOrderProductGetDetailsResult>(id);
            };

        const getList: (model: IOrderProductGetListModel) => Promise<IOrderProductGetListResult>
            = async (model: IOrderProductGetListModel): Promise<IOrderProductGetListResult> => {
                return await baseGetList<IOrderProductGetListModel, IOrderProductGetListResult>(model);
            };

        const setPlayground: (model: IOrderProductSetPlaygroundModel) => Promise<IResultBase>
            = async (model: IOrderProductSetPlaygroundModel): Promise<IResultBase> => {
                let url: string = "/OrderProduct/SetPlayground";
                return await basePostRequest<IOrderProductSetPlaygroundModel, IResultBase>(url, model);
            };

        return { getDetails, getList, setPlayground };
    };