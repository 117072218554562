import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const stylesTextHeader: () => Record<string, string> = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            flexGrow: 1
        }
    }
));

export const stylesTextTitle: () => Record<string, string> = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            textAlign: "left",
            marginBottom: "10px"
        }
    }
));

export const stylesTextSubtitle: () => Record<string, string> = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            marginBottom: "0"
        }
    }
));

export const stylesTextOverflow: () => Record<string, string> = makeStyles((theme: Theme) => 
    createStyles({
        overflowText: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "150px"
        }
    }
));