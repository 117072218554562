import * as React from "react";
import { Grid, Hidden, Link, Typography } from "@material-ui/core";
import { stylesInfoPortal } from "./styles";
import { useTranslation } from "react-i18next";
import slider from "Images/slider-2.png";
import TextSubheader from "Components/Texts/text-subheader";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";

const InfoPortal: () => JSX.Element = (): JSX.Element => {
  const customStyles: Record<string, string> = stylesInfoPortal();
  const { t } = useTranslation(["commonResources"]);

  const handleClickDownload: () => void = (): void => {};

  return (
    <React.Fragment>
      <Grid container className={customStyles.root}>
        <Grid item xs={12} lg={7} className={customStyles.infoContainer}>
          <Typography variant="h4" className={customStyles.titleText}>
            {t("infoPortal.title")}
          </Typography>
          <TextSubheader className={customStyles.infoText}>
            {t("infoPortal.info")}
          </TextSubheader>
          <Link
            href="https://drive.google.com/file/d/105pHOzdgJcwwvN6bo7qjs7pD_bl_gjtf/view?usp=sharing"
            target="_blank"
          >
            <ButtonPrimary size="sm" onClick={handleClickDownload}>
              <ButtonGridIcon icon={"fa-download"} color="secondary" />
              {t("infoPortal.download").toUpperCase()}
            </ButtonPrimary>
          </Link>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={5}>
            <img src={slider} className={customStyles.sliderImage} />
          </Grid>
        </Hidden>
      </Grid>
    </React.Fragment>
  );
};

export default InfoPortal;
