import * as React from "react";
import * as Yup from "yup";
import { IOrderRewardRangeEditorAdminProps } from "../interfaces";
import { IOrderRewardRangeGetDetailsResult } from "Services/OrderRewardRangeApi/interfaces-results";
import { useOrderRewardRangeApi } from "Services/OrderRewardRangeApi/order-reward-range-api";
import { IOrderRewardRangeData } from "Services/OrderRewardRangeApi/interfaces";
import { ApiResultStatus } from "Utils/enums";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import useModalSuccess from "Hooks/use-modal-success";
import ButtonSecondary from "Components/Buttons/button-secondary";

const OrderRewardRangeEditorAdmin: (
  props: IOrderRewardRangeEditorAdminProps
) => JSX.Element = (props: IOrderRewardRangeEditorAdminProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { save, getDetails } = useOrderRewardRangeApi();
  const showModalSuccess: (successResult: string) => void = useModalSuccess(
    undefined
  );
  const [values, setValues] = React.useState<IOrderRewardRangeData>({
    minOrderTotalPrice: null,
    maxOrderTotalPrice: null,
    rewardValue: null,
  });
  const { t } = useTranslation(["commonResources", "validationResources"]);
  
  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    if (props.orderRewardRangeId) {
      return await getDetails(props.orderRewardRangeId).then(
        (response: IOrderRewardRangeGetDetailsResult) => {
          if (response && response.data) {
            setValues(response.data);
          }
        }
      );
    }
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    if (props.orderRewardRangeId) {
      handleLoadData();
    }
    setIsOpen(true);
  };

  const handleSave: (
    orderRewardRange: IOrderRewardRangeData
  ) => Promise<void> = async (
    orderRewardRange: IOrderRewardRangeData
  ): Promise<void> => {
    orderRewardRange.id = props.orderRewardRangeId;
    await save(orderRewardRange).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(
          orderRewardRange.id
            ? t("saveChangesSuccess")
            : t("rewardForm.addSuccess")
        );
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
        handleClose();
      }
    });
  };

  return (
    <React.Fragment>
      {props.orderRewardRangeId && (
        <ButtonGridIcon
          icon={"fa-edit"}
          onClick={handleOpen}
          color="secondary"
        />
      )}
      {!props.orderRewardRangeId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-award"} color="secondary" />
          {t("rewardForm.add")}
        </ButtonPrimary>
      )}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          {props.orderRewardRangeId
            ? t("rewardForm.edit")
            : t("rewardForm.addInfo")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<IOrderRewardRangeData>({
              maxOrderTotalPrice: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .integer(t("validationResources:numericInteger"))
                .min(0, t("validationResources:numericMin0"))
                .max(1000000, t("validationResources:numericMax1000000"))
                .required(t("validationResources:required")),
              minOrderTotalPrice: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .integer(t("validationResources:numericInteger"))
                .min(0, t("validationResources:numericMin0"))
                .max(1000000, t("validationResources:numericMax1000000"))
                .required(t("validationResources:required")),
              rewardValue: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(0, t("validationResources:numericMin0"))
                .max(9999, t("validationResources:numericMax9999"))
                .required(t("validationResources:required")),
            })}
            onSubmit={(values: IOrderRewardRangeData) => {
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={formik.values.minOrderTotalPrice ?? ""}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          formik.errors.minOrderTotalPrice &&
                          formik.touched.minOrderTotalPrice
                        )
                      }
                      helperText={
                        formik.errors.minOrderTotalPrice &&
                        formik.touched.minOrderTotalPrice &&
                        formik.errors.minOrderTotalPrice
                      }
                      label={`${t("totalPriceMin")} (zł)`}
                      variant="outlined"
                      size="small"
                      name="minOrderTotalPrice"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={formik.values.maxOrderTotalPrice ?? ""}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          formik.errors.maxOrderTotalPrice &&
                          formik.touched.maxOrderTotalPrice
                        )
                      }
                      helperText={
                        formik.errors.maxOrderTotalPrice &&
                        formik.touched.maxOrderTotalPrice &&
                        formik.errors.maxOrderTotalPrice
                      }
                      label={`${t("totalPriceMax")} (zł)`}
                      variant="outlined"
                      size="small"
                      name="maxOrderTotalPrice"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={formik.values.rewardValue ?? ""}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          formik.errors.rewardValue &&
                          formik.touched.rewardValue
                        )
                      }
                      helperText={
                        formik.errors.rewardValue &&
                        formik.touched.rewardValue &&
                        formik.errors.rewardValue
                      }
                      label={`${t("rewardForm.value")} (zł)`}
                      variant="outlined"
                      size="small"
                      name="rewardValue"
                    />
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default OrderRewardRangeEditorAdmin;
