import { IConfigurationApiInterface, IConfigurationData } from "./interfaces";
import useBaseDataApi from "Services/base-data-api";
import { IResultBase } from "Services/interfaces";
import { IConfigurationGetDetailsResult } from "./interfaces-result";

export const useConfigurationApi: () => IConfigurationApiInterface
    = (): IConfigurationApiInterface => {
        const { baseGetDetails, baseSave } = useBaseDataApi({ apiControllerName: "Configuration" });

        const save: (model: IConfigurationData) => Promise<IResultBase>
            = async (model: IConfigurationData): Promise<IResultBase> => {
                return await baseSave<IConfigurationData, IResultBase>(model);
            };

        const getDetails: () => Promise<IConfigurationGetDetailsResult>
            = async (): Promise<IConfigurationGetDetailsResult> => {
                return await baseGetDetails<IConfigurationGetDetailsResult>();
            };

        return { save, getDetails };
    }