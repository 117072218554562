import * as React from "react";
import { Grid, Container, Paper, Divider } from "@material-ui/core";
import { IGridContainerlProps } from "./interfaces";
import { stylesGridContainer } from "./styles";
import TextTitle from "Components/Texts/text-title";
import TextSubtitle from "Components/Texts/text-subtitle";

const GridContainer: (props: IGridContainerlProps) => JSX.Element = (
  props: IGridContainerlProps
): JSX.Element => {
  const customStyles: Record<string, string> = stylesGridContainer();

  return (
    <Container
      hidden={props.value !== props.index}
      id={`vertical-tabpanel-${props.index}`}
      aria-labelledby={`vertical-tab-${props.index}`}
      className={customStyles.root}
      maxWidth={false}
    >
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Paper className={customStyles.paper}>
            {props.titleSize === "sm" && (
              <TextSubtitle>{props.title}</TextSubtitle>
            )}
            {!props.titleSize && (
              <TextTitle>
                {props.titleChildren}
                {props.title}
              </TextTitle>
            )}
            <Divider />
            {props.children}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GridContainer;
