import * as React from "react";
import { ObjectType } from "Utils/enums";
import { ISBSSceneObjectTimelineEditorAdminProps } from "../interfaces";
import SBSSceneObjectTimelineGraphicVideoEditorAdmin from "./sbs-scene-object-timeline-graphic-video-editor-admin";
import SBSSceneObjectTimelineModel3dEditorAdmin from "./sbs-scene-object-timeline-model3d-editor-admin";
import SBSSceneObjectTimelineSoundEditorAdmin from "./sbs-scene-object-timeline-sound-editor-admin";

const SBSSceneObjectTimelineEditorAdmin: (
  props: ISBSSceneObjectTimelineEditorAdminProps
) => JSX.Element = (
  props: ISBSSceneObjectTimelineEditorAdminProps
): JSX.Element => {
  return (
    <React.Fragment>
      {props.sbsSceneObjectType === ObjectType.Model3D && (
        <SBSSceneObjectTimelineModel3dEditorAdmin {...props} />
      )}
      {(props.sbsSceneObjectType === ObjectType.Graphic ||
        props.sbsSceneObjectType === ObjectType.Video) && (
        <SBSSceneObjectTimelineGraphicVideoEditorAdmin {...props} />
      )}
      {props.sbsSceneObjectType === ObjectType.Sound && (
        <SBSSceneObjectTimelineSoundEditorAdmin {...props} />
      )}
    </React.Fragment>
  );
};

export default SBSSceneObjectTimelineEditorAdmin;
