import * as React from "react";
import * as _ from "lodash";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { EventLogGridGetListColumn, EventLogType } from "Utils/enums";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import { getStoredUserAuthToken } from "Utils/helpers";
import { IEventLogGetListModel } from "Services/EventLogApi/interfaces-model";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useEventLogApi } from "Services/EventLogApi/event-log-api";
import { IGridModel } from "Services/interfaces";
import {
  IEventLogGetListGridData,
  IEventLogGetListResult,
} from "Services/EventLogApi/interfaces-result";
import {
  Grid,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GridTable from "Components/Grids/grid-table";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import axios from "axios";
import GridContainer from "Components/Grids/grid-container";

const envBaseURL: string =
  process.env.REACT_APP_API_BASE_URL ?? "https://webapi.gsot.pl/api";
const envApiVersion: string = process.env.REACT_APP_API_VERSION ?? "v1";

const EventLogList: () => JSX.Element = (): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const { getList } = useEventLogApi();
  const [startDate, setStartDate] = React.useState<string | null>(
    moment().toString()
  );
  const [endDate, setEndDate] = React.useState<string | null>(
    moment().toString()
  );
  const [logType, setLogType] = React.useState<EventLogType>(0);
  const { t } = useTranslation(["commonResources", "enumResources"]);
  const columnSortables: string[] = [
    "startDate",
    "endDate",
    "durationInSeconds",
    "scene.name",
    "sceneUsingModeText",
    "requestApiResultStatusText",
    "device.uid",
  ];

  const handleChangeLogType: (c: any) => void = (c: any): void => {
    setLogType(c.target.value);
  };

  const handleChangeStartDate: (date: MaterialUiPickersDate) => void = (
    date: MaterialUiPickersDate
  ): void => {
    setStartDate(date ? moment(date).toString() : null);
  };

  const handleChangeEndDate: (date: MaterialUiPickersDate) => void = (
    date: MaterialUiPickersDate
  ): void => {
    setEndDate(date ? moment(date).toString() : null);
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "typeText",
      label: t("eventLogForm.eventType"),
      options: {
        sort: false,
      },
    },
    {
      name: "startDate",
      label: t("startDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let dateText: string = value
            ? moment(value).format("DD.MM.YYYY HH:mm")
            : "";
          return dateText;
        },
      },
    },
    {
      name: "endDate",
      label: t("endDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let dateText: string = value
            ? moment(value).format("DD.MM.YYYY HH:mm")
            : "";
          return dateText;
        },
      },
    },
    {
      name: "durationInSeconds",
      label: t("eventLogForm.durationInSeconds"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ?? "";
        },
      },
    },
    {
      name: "scene",
      label: t("scene"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <p
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100px",
              }}
              title={value?.name}
            >
              {value?.name}
            </p>
          );
        },
      },
    },
    {
      name: "sceneUsingModeText",
      label: t("eventLogForm.sceneMode"),
    },
    {
      name: "place",
      label: t("place"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <p
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100px",
              }}
              title={value?.name}
            >
              {value?.name}
            </p>
          );
        },
      },
    },
    {
      name: "requestApiResultStatusText",
      label: t("eventLogForm.requestApiResultStatus"),
    },
    {
      name: "device",
      label: t("eventLogForm.deviceUid"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <p
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100px",
              }}
              title={value.uid}
            >
              {value.uid}
            </p>
          );
        },
      },
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "startDate":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = EventLogGridGetListColumn.StartDate;
        break;
      case "endDate":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn = EventLogGridGetListColumn.EndDate;
        break;
      case "durationInSeconds":
        newSortParams.columnIndex = 2;
        newSortParams.orderColumn = EventLogGridGetListColumn.Duration;
        break;
      case "scene.name":
        newSortParams.columnIndex = 3;
        newSortParams.orderColumn = EventLogGridGetListColumn.Scene;
        break;
      case "sceneUsingModeText":
        newSortParams.columnIndex = 4;
        newSortParams.orderColumn = EventLogGridGetListColumn.SceneUsingMode;
        break;
      case "requestApiResultStatusText":
        newSortParams.columnIndex = 5;
        newSortParams.orderColumn = EventLogGridGetListColumn.RequestApiStatus;
        break;
      case "device.uid":
        newSortParams.columnIndex = 6;
        newSortParams.orderColumn = EventLogGridGetListColumn.Device;
        break;
    }

    return newSortParams;
  };

  const generateParams: (tableState: IGridModel) => IEventLogGetListModel = (
    tableState: IGridModel
  ): IEventLogGetListModel => {
    let params: IEventLogGetListModel = { ...tableState };
    if (endDate) {
      params.endDate = moment(endDate).format("YYYY-MM-DD");
    }
    if (startDate) {
      params.startDate = moment(startDate).format("YYYY-MM-DD");
    }
    if (logType && logType > 0) {
      params.type = logType;
    }
    return params;
  };

  const tableOptions: MUIDataTableOptions = {
    customToolbar: () => {
      return (
        <ButtonGridIcon
          icon="fa-file-csv"
          color="primary"
          title={t("exportToCsv")}
          onClick={() => {
            axios({
              url: `${envBaseURL}/${envApiVersion}/EventLog/ExportToCsv`,
              method: "GET",
              responseType: "blob", // important
              headers: {
                Authorization: getStoredUserAuthToken(),
              },
              params: {
                startDate: startDate
                  ? moment(startDate).format("YYYY-MM-DD")
                  : null,
                endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
                type: logType && logType > 0 ? logType : null,
              },
            }).then((response) => {
              let fileDownload = require("js-file-download");
              fileDownload(response.data, `${t("events")}.csv`);
            });
          }}
        />
      );
    },
  };

  React.useEffect(() => {
    dispatchReloadTable();
  }, [startDate, endDate, logType]);

  return (
    <React.Fragment>
      <GridContainer title={t("eventLog")}>
        <Grid
          container
          spacing={2}
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={3} style={{ textAlign: "left" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                <DatePicker
                  autoOk={true}
                  format={"dd.MM.yyy"}
                  value={startDate}
                  onChange={handleChangeStartDate}
                  label={t("dateFrom")}
                  size="small"
                  inputVariant="outlined"
                  clearable
                  cancelLabel={t("cancel")}
                  clearLabel={t("clear")}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "left" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
                <DatePicker
                  autoOk={true}
                  format={"dd.MM.yyy"}
                  value={endDate}
                  onChange={handleChangeEndDate}
                  label={t("dateTo")}
                  size="small"
                  inputVariant="outlined"
                  clearable={true}
                  cancelLabel={t("cancel")}
                  clearLabel={t("clear")}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth size="small" variant="outlined">
                <InputLabel id="gsEventLogType">
                  {t("eventLogForm.eventType")}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="gsEventLogType"
                  value={logType}
                  onChange={handleChangeLogType}
                >
                  <MenuItem key={`eventLog_0`} value={0}>
                    {t("all")}
                  </MenuItem>
                  <MenuItem
                    key={`eventLog_${EventLogType.MobileAppLogin}`}
                    value={EventLogType.MobileAppLogin}
                  >
                    {t("enumResources:eventLogType.mobileAppLogin")}
                  </MenuItem>
                  <MenuItem
                    key={`eventLog_${EventLogType.MobileAppShopping}`}
                    value={EventLogType.MobileAppShopping}
                  >
                    {t("enumResources:eventLogType.mobileAppShopping")}
                  </MenuItem>
                  <MenuItem
                    key={`eventLog_${EventLogType.MobileAppRegisterLicenseCode}`}
                    value={EventLogType.MobileAppRegisterLicenseCode}
                  >
                    {t(
                      "enumResources:eventLogType.mobileAppRegisterLicenseCode"
                    )}
                  </MenuItem>
                  <MenuItem
                    key={`eventLog_${EventLogType.MobileAppUsing}`}
                    value={EventLogType.MobileAppUsing}
                  >
                    {t("enumResources:eventLogType.mobileAppUsing")}
                  </MenuItem>
                  <MenuItem
                    key={`eventLog_${EventLogType.MobileAppSceneUsing}`}
                    value={EventLogType.MobileAppSceneUsing}
                  >
                    {t("enumResources:eventLogType.mobileAppSceneUsing")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <GridTable<
          IEventLogGetListModel,
          IEventLogGetListGridData,
          IEventLogGetListResult
        >
          columns={columns}
          columnsSortableNames={columnSortables}
          generateParams={generateParams}
          generateSortParams={generateSortParams}
          getList={getList}
          customTableOptions={tableOptions}
          reloadCount={reloadTable}
        />
      </GridContainer>
    </React.Fragment>
  );
};

export default EventLogList;
