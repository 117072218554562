import { IOrderProductLicenseApiInterface } from "./interfaces";
import { IOrderProductLicenseGetListModel } from "./interfaces-model";
import { IOrderProductLicenseGetListResult } from "./interfaces-result";
import useBaseDataApi from "Services/base-data-api";

export const useOrderProductLicenseApi: () => IOrderProductLicenseApiInterface
    = (): IOrderProductLicenseApiInterface => {
        const { baseGetList } = useBaseDataApi({ apiControllerName: "OrderProductLicense" });

        const getList: (model: IOrderProductLicenseGetListModel) => Promise<IOrderProductLicenseGetListResult>
            = async (model: IOrderProductLicenseGetListModel): Promise<IOrderProductLicenseGetListResult> => {
                return await baseGetList<IOrderProductLicenseGetListModel, IOrderProductLicenseGetListResult>(model);
            };

        return { getList };
    };