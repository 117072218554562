import * as React from "react";
import * as _ from "lodash";
import useModalSuccess from "Hooks/use-modal-success";
import useModalQuestion from "Hooks/use-modal-question";
import { useSBSObjectApi } from "Services/SBSObjectApi/sbs-object-api";
import { ISBSObjectData } from "Services/SBSObjectApi/interfaces";
import {
  ApiResultStatus,
  SBSObjectGridGetListColumn,
  UserRole,
} from "Utils/enums";
import { Grid, Divider } from "@material-ui/core";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import { ISBSObjectListProps } from "../interfaces";
import { IGridModel, IResultBase } from "Services/interfaces";
import { MUIDataTableColumn } from "mui-datatables";
import { useTranslation } from "react-i18next";
import { useLanguageApi } from "Services/LanguageApi/language-api";
import { ILanguageData } from "Services/LanguageApi/interfaces";
import { ILanguageGetListResult } from "Services/LanguageApi/interfaces-result";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import GridTable from "Components/Grids/grid-table";
import { ISBSObjectGetListModel } from "Services/SBSObjectApi/interfaces-model";
import { ISBSObjectGetListResult } from "Services/SBSObjectApi/interfaces-result";
import SBSObjectEditorAdmin from "../ObjectEditors/sbs-object-editor-admin";
import ButtonPrimary from "Components/Buttons/button-primary";
import { getStoredUserRole } from "Utils/helpers";

const SBSObjectList: (props: ISBSObjectListProps) => JSX.Element = (
  props: ISBSObjectListProps
): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { deleteById, getList } = useSBSObjectApi();
  const { getList: getLanguageList } = useLanguageApi();
  const [languages, setLanguages] = React.useState<ILanguageData[]>([]);
  const { t } = useTranslation(["commonResources"]);
  const userRole: UserRole = getStoredUserRole();

  const loadLanguages: () => Promise<void> = async () => {
    return await getLanguageList().then((response: ILanguageGetListResult) => {
      if (response?.data) {
        setLanguages(response.data);
      }
    });
  };

  const handleClickDelete: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    showModalQuestion(t("sbsObjectForm.deleteInfo"), id);
  };

  const deleteSBSObject: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await deleteById(id).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("sbsObjectForm.deleteSuccess"));
        dispatchReloadTable();
      }
    });
  };

  React.useEffect(() => {
    setYesFunction(() => deleteSBSObject);
    loadLanguages();
  }, []);

  const columnSortables: string[] = ["name", "description", "typeText"];

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "name",
      label: t("name"),
    },
    {
      name: "description",
      label: t("description"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{ width: "200px" }}>{value}</div>;
        },
      },
    },
    {
      name: "gsLanguageId",
      label: t("language"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            _.find(languages, (language) => {
              return language.id === (value as number);
            })?.name ?? ""
          );
        },
      },
    },
    {
      name: "file",
      label: t("file"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.originalFileName;
        },
      },
    },
    {
      name: "typeText",
      label: t("sbsObjectForm.type"),
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (props.enableEdit && userRole === UserRole.Admin) {
            return (
              <SBSObjectEditorAdmin
                sbsObjectId={tableMeta.rowData[0]}
                handleSaveSuccess={dispatchReloadTable}
              />
            );
          }
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (props.enableEdit && userRole === UserRole.Admin) {
            return (
              <ButtonGridIcon
                icon="fa-trash-alt"
                color="primary"
                onClick={() => {
                  handleClickDelete(tableMeta.rowData[0]);
                }}
              />
            );
          } else if (props.enableSelect) {
            return (
              <ButtonPrimary
                size="sm"
                onClick={() => {
                  if (props.handleSelect) {
                    props.handleSelect(false, tableMeta.rowData[0]);
                  }
                }}
              >
                {t("add")}
              </ButtonPrimary>
            );
          }
        },
      },
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "name":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = SBSObjectGridGetListColumn.Name;
        break;
      case "description":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn = SBSObjectGridGetListColumn.Description;
        break;
      case "typeText":
        newSortParams.columnIndex = 2;
        newSortParams.orderColumn = SBSObjectGridGetListColumn.Type;
        break;
    }

    return newSortParams;
  };

  const generateParams: (tableState: IGridModel) => ISBSObjectGetListModel = (
    tableState: IGridModel
  ): ISBSObjectGetListModel => {
    let params: ISBSObjectGetListModel = { ...tableState };
    if (props.sbsSceneId) {
      params = {
        GSSBSSceneId: props.sbsSceneId,
        ...tableState,
      };
    }
    return params;
  };

  return (
    <React.Fragment>
      {props.enableEdit && userRole === UserRole.Admin && (
        <React.Fragment>
          <Grid container justify="flex-start" alignItems="flex-start">
            <Grid container item sm={12}>
              <SBSObjectEditorAdmin handleSaveSuccess={dispatchReloadTable} />
            </Grid>
          </Grid>
          <Divider />
        </React.Fragment>
      )}
      <GridTable<
        ISBSObjectGetListModel,
        ISBSObjectData,
        ISBSObjectGetListResult
      >
        reloadCount={reloadTable}
        columns={columns}
        columnsSortableNames={columnSortables}
        generateSortParams={generateSortParams}
        generateParams={generateParams}
        getList={getList}
      />
    </React.Fragment>
  );
};

export default SBSObjectList;
