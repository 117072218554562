import { axiosInstance } from "Services/base-api";
import { getStoredUserAuthToken } from "Utils/helpers";
import { IFileApiInterface } from "./interfaces";
import { IFileUploadModel, IFileDownloadModel } from "./interfaces-models";
import { IFileUploadResult } from "./interfaces-results";
import { SweetAlertResult } from "sweetalert2";
import { useHistory } from "react-router-dom";
import useModalError from "Hooks/use-modal-error";
import useAuthorization from "Hooks/use-auth-token";

export const useFileApi: () => IFileApiInterface = (): IFileApiInterface => {
  const showModalError: (
    errorResult: string
  ) => Promise<SweetAlertResult> = useModalError();
  const history = useHistory();
  const { setUnauthorize } = useAuthorization(getStoredUserAuthToken());

  const upload: (
    model: IFileUploadModel
  ) => Promise<IFileUploadResult> = async (
    model: IFileUploadModel
  ): Promise<IFileUploadResult> => {
    let formData: FormData = new FormData();
    formData.append("Type", model.type.toString());
    formData.append("File", model.file!);

    return await axiosInstance
      .post("/File/Upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: getStoredUserAuthToken(),
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 401) {
          showModalError("Twoja sesja wygasła, zaloguj się ponownie!").then(
            () => {
              setUnauthorize();
              history.push("/login");
            }
          );
        } else if (error.response) {
          showModalError(error.response.data.statusMessage);
        } else {
          showModalError("Coś poszło nie tak, spróbuj ponownie później");
        }
      });
  };

  const download: (
    model: IFileDownloadModel
  ) => Promise<Blob | undefined> = async (
    model: IFileDownloadModel
  ): Promise<Blob | undefined> => {
    return await axiosInstance.get(`/File/Download/${model.discFileName}`, {
      responseType: "arraybuffer",
    });
    // .then(response => new Blob([response.data]))
    // .catch(error => {
    //     console.error(error);
    //     return undefined;
    // });
  };

  return { upload, download };
};

export default useFileApi;
