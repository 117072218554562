import * as React from "react";
import { ITextProps } from "./interfaces";
import { Typography } from "@material-ui/core";
import { stylesTextHeader } from "./styles";

const TextHeader: (props: ITextProps) => JSX.Element = (props: ITextProps): JSX.Element => {
    const customStyles: Record<string, string> = stylesTextHeader();
    const { children, ...other } = props;

    return (
        <Typography variant="h4" color="primary" className={customStyles.main} {...other}>
            {children}
        </Typography>
    );
};

export default TextHeader;

