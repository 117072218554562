import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import cogoToast from "cogo-toast";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { ISBSSceneObjectTimelineAddPointByTimeModel } from "Services/SBSSceneObjectTimelineApi/interfaces-model";
import useSBSSceneObjectTimelineApi from "Services/SBSSceneObjectTimelineApi/sbs-scene-object-timeline-api";
import { ApiResultStatus } from "Utils/enums";
import { ISBSSceneObjectAddPointProps } from "../interfaces";
import TextInfo from "Components/Texts/text-info";

const SBSSceneObjectAddPoint: (
  props: ISBSSceneObjectAddPointProps
) => JSX.Element = (props: ISBSSceneObjectAddPointProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const { addPointByTime } = useSBSSceneObjectTimelineApi();
  const values: ISBSSceneObjectTimelineAddPointByTimeModel = {
    GSSBSSceneObjectId: props.sbsSceneObjectId,
    time: 0,
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    setIsOpen(true);
  };

  const handleSave: (
    model: ISBSSceneObjectTimelineAddPointByTimeModel
  ) => Promise<void> = async (
    model: ISBSSceneObjectTimelineAddPointByTimeModel
  ): Promise<void> => {
    await addPointByTime(model).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        cogoToast.success(t("sbsSceneObjectTimelineForm.savePointsSuccess"));
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
        handleClose();
      }
    });
  };

  return (
    <React.Fragment>
      <ButtonGridIcon
        icon={"fa-stopwatch"}
        onClick={handleOpen}
        color="primary"
        title={t("sbsSceneObjectTimelineForm.addNewPointByTime")}
        style={{ padding: 0 }}
      />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>
          {t("sbsSceneObjectTimelineForm.addNewPointByTime")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<ISBSSceneObjectTimelineAddPointByTimeModel>(
              {
                GSSBSSceneObjectId: Yup.number().required(),
                time: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .min(
                    0,
                    t("validationResources:numericMinValue", {
                      value: 0,
                    })
                  )
                  .required(t("validationResources:required")),
              }
            )}
            onSubmit={(values: ISBSSceneObjectTimelineAddPointByTimeModel) => {
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid
                  container
                  justify="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <TextInfo>
                        {t("sbsSceneObjectTimelineForm.addNewPointByTimeInfo")}
                      </TextInfo>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.time ?? ""}
                        onChange={formik.handleChange}
                        error={!!(formik.errors.time && formik.touched.time)}
                        helperText={
                          formik.errors.time &&
                          formik.touched.time &&
                          formik.errors.time
                        }
                        label={t("second")}
                        variant="outlined"
                        size="small"
                        name="time"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("add")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SBSSceneObjectAddPoint;
