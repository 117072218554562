import * as React from "react";
import { AppBar, Toolbar, Hidden, Grid } from "@material-ui/core";
import { IBarTopProps } from "./interfaces";
import { stylesBarTop } from "./styles";
import { getStoredUserAuthToken, getStoredUserEmail, getStoredUserVirtualWalletBalance, getStoredUserRole } from "Utils/helpers";
import { useHistory } from "react-router-dom";
import { UserRole } from "Utils/enums";
import { useTranslation } from "react-i18next";
import ButtonMenu from "Components/Buttons/button-menu";
import ButtonPrimary from "Components/Buttons/button-primary";
import useAuthorization from "Hooks/use-auth-token";
import TextHeader from "Components/Texts/text-header";
import TextInfo from "Components/Texts/text-info";
import ButtonLanguage from "Components/Buttons/button-language";

const BarTop: (props: IBarTopProps) => JSX.Element = (props: IBarTopProps): JSX.Element => {
    const customStyles: Record<string, string> = stylesBarTop();
    const { setUnauthorize } = useAuthorization(getStoredUserAuthToken());
    const { t } = useTranslation(["commonResources"]);
    const userEmail: string = getStoredUserEmail();
    const userVirtualWalletBalance: string = getStoredUserVirtualWalletBalance();
    const history = useHistory();

    const handleLogout: () => void = (): void => {
        setUnauthorize();
        history.push("/login");
    };

    return (
        <div className={customStyles.root}>
            <AppBar position="static" className={customStyles.appBar}>
                <Toolbar>
                    <Hidden lgUp>
                        <ButtonMenu handlerOnClick={props.handleOnMenuClick} />
                    </Hidden>
                    <Hidden mdDown>
                        <Grid container justify="flex-end" alignItems="flex-end" >
                            {getStoredUserRole() === UserRole.Standard &&
                                <Grid container item sm={2} direction="column">
                                    <Grid item sm={12}>
                                        <TextInfo color="textPrimary">
                                            {t("virtualWalletStatus")}
                                </TextInfo>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <TextInfo color="primary">
                                            {userVirtualWalletBalance} zł
                                    </TextInfo>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container item sm={2} direction="column" >
                                <Grid item sm={12}>
                                    <TextInfo color="textPrimary">
                                        {t("loggedAs")}
                                </TextInfo>
                                </Grid>
                                <Grid item sm={12}>
                                    <TextInfo color="primary" overflowText={true}>
                                        {userEmail}
                                    </TextInfo>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <ButtonLanguage />
                    <ButtonPrimary onClick={handleLogout}>
                        {t("logOut")}
                    </ButtonPrimary>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default BarTop;