import * as React from "react";
import { IUserExternalLoginModel } from "Services/UserApi/interfaces-model";
import { getStoredUserAuthToken } from "Utils/helpers";
import { useHistory, useLocation } from "react-router-dom";
import { ApiResultStatus, ExternalLoginType } from "Utils/enums";
import { IButtonLoadProps } from "./interfaces";
import { stylesButtonGoogle } from "./styles";
import { useUserApi } from "Services/UserApi/user-api";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import useAuthorization from "Hooks/use-auth-token";
import useModalError from "Hooks/use-modal-error";
import { useTranslation } from "react-i18next";

const ButtonGoogle: (props: IButtonLoadProps) => JSX.Element = (
  props: IButtonLoadProps
): JSX.Element => {
  const showModalError: (errorResult: string) => void = useModalError();
  const customStyles: Record<string, string> = stylesButtonGoogle();
  const { setUser, setAuthorize } = useAuthorization(getStoredUserAuthToken());
  const { externalLogin } = useUserApi();
  const { t } = useTranslation(["commonResources"]);
  const history = useHistory();
  const query: URLSearchParams = new URLSearchParams(useLocation().search);

  const handleSuccessResponseGoogle: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => Promise<void> = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ((response as GoogleLoginResponseOffline).code) {
      showModalError(t("googleLoginFailed"));
      props.setLoading(false);
    } else {
      props.setLoading(true);
      let model: IUserExternalLoginModel = {
        accessToken: (response as GoogleLoginResponse).accessToken,
        type: ExternalLoginType.Google,
      };
      await externalLogin(model)
        .then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            setAuthorize(response.data.token);
            setUser(response.data);
            const redirect = query.get("redirectTo");
            if (redirect) {
              history.push(`/${redirect}`);
            } else {
              history.push("/");
            }
          }
        })
        .finally(() => {
          props.setLoading(false);
        });
    }
  };

  const handleFailureResponseFromGoogle: (response: any) => void = (
    response: any
  ): void => {
    console.error(response);
    showModalError(t("googleLoginFailed"));
    props.setLoading(false);
  };

  return (
    <GoogleLogin
      clientId="101281102168-jsqfsaqibj3slmi19kpbfncm5d3omg3r.apps.googleusercontent.com"
      buttonText={t("googleLogin")}
      className={customStyles.googleButton}
      onSuccess={handleSuccessResponseGoogle}
      onFailure={handleFailureResponseFromGoogle}
    />
  );
};

export default ButtonGoogle;
