import * as React from "react";
import { ObjectType } from "Utils/enums";
import { ISceneObjectTimelineEditorAdminProps } from "../interfaces";
import SceneObjectTimelineSoundEditorAdmin from "./scene-object-timeline-sound-editor-admin";
import SceneObjectTimelineModel3dEditorAdmin from "./scene-object-timeline-model3d-editor-admin";
import SceneObjectTimelineGraphicVideoEditorAdmin from "./scene-object-timeline-graphic-video-editor-admin";

const SceneObjectTimelineEditorAdmin: (
  props: ISceneObjectTimelineEditorAdminProps
) => JSX.Element = (
  props: ISceneObjectTimelineEditorAdminProps
): JSX.Element => {
  return (
    <React.Fragment>
      {props.sceneObjectType === ObjectType.Model3D && (
        <SceneObjectTimelineModel3dEditorAdmin {...props} />
      )}
      {props.sceneObjectType === ObjectType.Sound && (
        <SceneObjectTimelineSoundEditorAdmin {...props} />
      )}
      {(props.sceneObjectType === ObjectType.Graphic ||
        props.sceneObjectType === ObjectType.Video) && (
        <SceneObjectTimelineGraphicVideoEditorAdmin {...props} />
      )}
    </React.Fragment>
  );
};

export default SceneObjectTimelineEditorAdmin;
