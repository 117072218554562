import * as React from "react";
import * as _ from "lodash";
import { useProductApi } from "Services/ProductApi/product-api";
import { useLanguageApi } from "Services/LanguageApi/language-api";
import { IResultBase } from "Services/interfaces";
import { ApiResultStatus, ProductGridGetListColumn } from "Utils/enums";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { Grid, Divider, TableRow, TableCell } from "@material-ui/core";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import { IProductGetListResult } from "Services/ProductApi/interfaces-result";
import { IProductGetListModel } from "Services/ProductApi/interfaces-model";
import { IProductListProps } from "../interfaces";
import { IProductData } from "Services/ProductApi/interfaces";
import { useTranslation } from "react-i18next";
import { ILanguageGetListResult } from "Services/LanguageApi/interfaces-result";
import { ILanguageData } from "Services/LanguageApi/interfaces";
import useModalSuccess from "Hooks/use-modal-success";
import useModalQuestion from "Hooks/use-modal-question";
import ProductEditorAdmin from "../ProductEditor/product-editor-admin";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import GridTable from "Components/Grids/grid-table";
import moment from "moment";
import TextInfo from "Components/Texts/text-info";

const ProductList: (props: IProductListProps) => JSX.Element = (
  props: IProductListProps
): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };

  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { copy, deleteById, getList } = useProductApi();
  const { getList: getLanguageList } = useLanguageApi();
  const [languages, setLanguages] = React.useState<ILanguageData[]>([]);
  const { t } = useTranslation(["commonResources"]);
  const adminMode: boolean = props.adminMode;

  const loadLanguages: () => Promise<void> = async () => {
    return await getLanguageList().then((response: ILanguageGetListResult) => {
      if (response?.data) {
        setLanguages(response.data);
      }
    });
  };

  const deleteProduct: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await deleteById(id).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("productForm.deleteSuccess"));
        dispatchReloadTable();
      }
    });
  };

  const handleClickCopy: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await copy({ id: id }).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("productForm.copySuccess"));
        dispatchReloadTable();
      }
    });
  };

  const handleClickDelete: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    showModalQuestion(t("productForm.deleteInfo"), id);
  };

  React.useEffect(() => {
    setYesFunction(() => deleteProduct);
    loadLanguages();
  }, []);

  const columnSortables: string[] = [
    "name",
    "description",
    "placeName",
    "price",
  ];

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "name",
      label: t("name"),
    },
    {
      name: "description",
      label: t("description"),
      options: {
        display: adminMode ? "true" : "false",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{ width: "200px" }}>{value}</div>;
        },
      },
    },
    {
      name: "placeName",
      label: t("placeForm.name"),
    },
    {
      name: "price",
      label: t("price"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ textAlign: "right" }}>{value} zł</p>;
        },
      },
    },
    {
      name: "quantity",
      label: " ",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (adminMode) {
            return (
              <ProductEditorAdmin
                productId={tableMeta.rowData[0]}
                handleSaveSuccess={dispatchReloadTable}
              />
            );
          } else {
            return (
              <React.Fragment>
                <ButtonGridIcon
                  icon="fa-minus-square"
                  color="secondary"
                  onClick={() => {
                    let newValue: number = (value ? value : 0) - 1;
                    if (newValue >= 0) {
                      updateValue(newValue.toString());
                    }
                  }}
                />
                <TextInfo
                  style={{
                    display: "inline-block",
                    marginRight: "5px",
                    width: "20px",
                    textAlign: "center",
                  }}
                >
                  {value ?? 0}
                </TextInfo>
                <ButtonGridIcon
                  icon="fa-plus-square"
                  color="secondary"
                  onClick={() => {
                    let newValue: number = (value ? value : 0) + 1;
                    updateValue(newValue.toString());
                  }}
                />
              </React.Fragment>
            );
          }
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (adminMode) {
            return (
              <ButtonGridIcon
                icon="fa-copy"
                color="primary"
                onClick={() => {
                  handleClickCopy(tableMeta.rowData[0]);
                }}
              />
            );
          } else {
            return (
              <ButtonGridIcon
                icon="fa-cart-plus"
                color="primary"
                onClick={() => {
                  if (tableMeta.rowData[5] > 0 && props.handleAddToCart) {
                    props.handleAddToCart({
                      id: tableMeta.rowData[0],
                      name: tableMeta.rowData[1],
                      price: tableMeta.rowData[4],
                      quantity: tableMeta.rowData[5],
                    });
                  }
                }}
              />
            );
          }
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (adminMode) {
            return (
              <ButtonGridIcon
                icon="fa-trash-alt"
                color="primary"
                onClick={() => {
                  handleClickDelete(tableMeta.rowData[0]);
                }}
              />
            );
          }
        },
      },
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "name":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = ProductGridGetListColumn.Name;
        break;
      case "description":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn = ProductGridGetListColumn.Description;
        break;
      case "scenesCount":
        newSortParams.columnIndex = 2;
        newSortParams.orderColumn = ProductGridGetListColumn.Place;
        break;
      case "isAvailableInDemoVersion":
        newSortParams.columnIndex = 3;
        newSortParams.orderColumn = ProductGridGetListColumn.Price;
        break;
    }

    return newSortParams;
  };

  const generateExpandedRowContent: (
    data: IProductData,
    colSpan: number
  ) => React.ReactNode = (
    data: IProductData,
    colSpan: number
  ): React.ReactNode => {
    return (
      <TableRow>
        <TableCell colSpan={colSpan}>
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <TextInfo color="textSecondary">
                {t("description")}: {data.description ?? "-"}
              </TextInfo>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <TextInfo color="textSecondary">
                {t("licenseForm.activeDays")} {data.licenseActiveDays}{" "}
                {t("days").toLowerCase()}
              </TextInfo>
            </Grid>
            <Grid item xs={6}>
              <TextInfo color="textSecondary">
                {t("expirationDate")}:{" "}
                {data.expireDate
                  ? moment(data.expireDate).format("DD.MM.YYYY")
                  : " - "}
              </TextInfo>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <TextInfo color="textSecondary">
                {t("licenseForm.codeActiveDays")} {data.licenseCodeActiveDays}{" "}
                {t("days").toLowerCase()}
              </TextInfo>
            </Grid>
            <Grid item xs={6}>
              <TextInfo color="textSecondary">
                {t("language")}:{" "}
                {_.find(languages, (language) => {
                  return language.id === data.gsLanguageId;
                })?.name ?? ""}
              </TextInfo>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  const tableOptions: MUIDataTableOptions = {
    expandableRows: true,
  };

  return (
    <React.Fragment>
      {adminMode && (
        <React.Fragment>
          <Grid container justify="flex-start" alignItems="flex-start">
            <Divider />
            <Grid container item sm={12}>
              <ProductEditorAdmin handleSaveSuccess={dispatchReloadTable} />
            </Grid>
          </Grid>
          <Divider />
        </React.Fragment>
      )}
      <GridTable<IProductGetListModel, IProductData, IProductGetListResult>
        reloadCount={reloadTable}
        columns={columns}
        columnsSortableNames={columnSortables}
        customTableOptions={tableOptions}
        generateExpandedRowContent={generateExpandedRowContent}
        generateSortParams={generateSortParams}
        getList={getList}
      />
    </React.Fragment>
  );
};

export default ProductList;
