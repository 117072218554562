import { IPrivateRoutes, IPublicRoutes } from "./interfaces";

const PrivateRoutes: IPrivateRoutes = {
  default: "/",
  info: "/info",
  adverts: "/adverts",
  configurations: "/configurations",
  eventLog: "/event-log",
  places: "/places",
  products: "/products",
  objects: "/objects",
  orders: "/orders",
  ordersLicense: "/orders-license",
  orderCreator: "/order-creator",
  orderRewards: "/order-rewards",
  users: "/users",
  yourAccount: "/your-account",
  applications: "/appliactions",
  sbsObjects: "/sbs-objects",
  sbsScenes: "/sbs-scenes",
  sbsSceneEditor: "/sbs-scene/:sbsSceneId",
  sbsSceneObjects: "/sbs-scene-objects/:sbsSceneId",
  notificationList: "/notification-list",
};

const PublicRoutes: IPublicRoutes = {
  login: "/login",
};

export { PrivateRoutes, PublicRoutes };
