import * as React from "react";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { ISceneObjectAddAdminProps } from "../interfaces";
import { ISceneObjectData } from "Services/SceneObjectApi/interfaces";
import { ApiResultStatus } from "Utils/enums";
import { useTranslation } from "react-i18next";
import useSceneObjectApi from "Services/SceneObjectApi/scene-object-api";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import ObjectList from "Components/Object/ObjectList/object-list";
import ButtonSecondary from "Components/Buttons/button-secondary";
import cogoToast from "cogo-toast";

const SceneObjectAddAdmin: (props: ISceneObjectAddAdminProps) => JSX.Element = (
  props: ISceneObjectAddAdminProps
): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation(["commonResources"]);
  const { save } = useSceneObjectApi();

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    setIsOpen(true);
  };

  const handleSave: (sceneObject: ISceneObjectData) => Promise<void> = async (
    sceneObject: ISceneObjectData
  ): Promise<void> => {
    await save(sceneObject).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        cogoToast.success(t("sceneObjectForm.addSuccess"));
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
      }
    });
  };

  const handleAddClick: (
    isAvailableInDemoVersion: boolean,
    id: number
  ) => void = (isAvailableInDemoVersion: boolean, id: number): void => {
    let newSceneObject: ISceneObjectData = {
      gsSceneId: props.sceneId,
      isAvailableInDemoVersion: isAvailableInDemoVersion,
      isFacingSpectator: false,
      isAvailableInTableSceneUsingMode: false,
      gsObjectId: id,
    };
    handleSave(newSceneObject);
  };

  return (
    <React.Fragment>
      {!props.sceneObjectId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-cube"} color="secondary" />
          {t("sceneObjectForm.add")}
        </ButtonPrimary>
      )}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>{t("sceneObjectForm.addInfo")}</DialogTitle>
        <Divider />
        <DialogContent>
          <ObjectList
            sceneId={props.sceneId}
            enableEdit={false}
            enableSelect={true}
            handleSelect={handleAddClick}
          />
          <Divider />
          <DialogActions>
            <ButtonSecondary onClick={handleClose}>
              {t("close")}
            </ButtonSecondary>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SceneObjectAddAdmin;
