import * as React from "react";
import * as Yup from "yup";
import * as _ from "lodash";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { IPlaceData } from "Services/PlaceApi/interfaces";
import { usePlaceApi } from "Services/PlaceApi/place-api";
import { ApiResultStatus } from "Utils/enums";
import { IPlaceGetDetailsResult } from "Services/PlaceApi/interfaces-result";
import { IPlaceEditorAdminProps } from "../interfaces";
import { Formik, Form, getIn } from "formik";
import { ILanguageGetListResult } from "Services/LanguageApi/interfaces-result";
import { ILanguageData } from "Services/LanguageApi/interfaces";
import { ILocalization } from "Services/interfaces";
import { useTranslation } from "react-i18next";
import { useLanguageApi } from "Services/LanguageApi/language-api";
import { useApplicationApi } from "Services/ApplicationApi/application-api";
import { IApplicationGetListResult } from "Services/ApplicationApi/interfaces-result";
import { IApplicationData } from "Services/ApplicationApi/interfaces";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonSecondary from "Components/Buttons/button-secondary";
import ButtonPrimary from "Components/Buttons/button-primary";
import useModalSuccess from "Hooks/use-modal-success";
import TextInfo from "Components/Texts/text-info";

const PlaceEditorAdmin: (props: IPlaceEditorAdminProps) => JSX.Element = (
  props: IPlaceEditorAdminProps
): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { save, getDetails } = usePlaceApi();
  const { getList } = useLanguageApi();
  const { getList: getApplicationList } = useApplicationApi();
  const showModalSuccess: (successResult: string) => void =
    useModalSuccess(undefined);
  const [languages, setLanguages] = React.useState<ILanguageData[]>([]);
  const [appliactionList, setApplicationList] = React.useState<
    IApplicationData[]
  >([]);
  const [values, setValues] = React.useState<IPlaceData>({
    name: "",
    description: "",
    isAvailableInDemoVersion: false,
    rectangleSecondSideLengthInMeters: null,
    orderNo: null,
    gsLanguageId: 1,
    gsApplicationId: 1,
    sceneGroup1Name: "",
    sceneGroup2Name: "",
  });
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const placeId: number = props.placeId ?? 0;

  const loadApplications: () => Promise<void> = async () => {
    return await getApplicationList({}).then(
      (response: IApplicationGetListResult) => {
        if (response?.data) {
          setApplicationList(response.data?.gridData ?? []);
        }
      }
    );
  };

  const loadLanguages: () => Promise<void> = async () => {
    return await getList().then((response: ILanguageGetListResult) => {
      if (response?.data) {
        setLanguages(response.data);
      }
    });
  };

  const handleLoadData: () => Promise<void> = React.useCallback(async () => {
    if (placeId) {
      return await getDetails(placeId).then(
        (response: IPlaceGetDetailsResult) => {
          if (response && response.data) {
            setValues(response.data);
          }
        }
      );
    }
  }, [placeId, getDetails]);

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    if (placeId) {
      handleLoadData();
    }
    setIsOpen(true);
  };

  const handleSave: (place: IPlaceData) => Promise<void> = async (
    place: IPlaceData
  ): Promise<void> => {
    await save(place).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(
          place.id ? t("saveChangesSuccess") : t("placeForm.addSuccess")
        );
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
        handleClose();
      }
    });
  };

  React.useEffect(() => {
    loadLanguages();
    loadApplications();
  }, []);

  return (
    <React.Fragment>
      {props.placeId && (
        <ButtonGridIcon
          icon={"fa-edit"}
          onClick={handleOpen}
          color="secondary"
        />
      )}
      {!props.placeId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-map-marker-alt"} color="secondary" />
          {t("placeForm.add")}
        </ButtonPrimary>
      )}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          {props.placeId ? t("placeForm.edit") : t("placeForm.addInfo")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<IPlaceData>({
              name: Yup.string()
                .max(100, t("validationResources:nameMaxLength"))
                .required(t("validationResources:required")),
              description: Yup.string()
                .max(1000, t("validationResources:descriptionMaxLength"))
                .nullable(),
              isAvailableInDemoVersion: Yup.boolean(),
              rectanglePoint1EPSG4326: Yup.object<ILocalization>({
                latitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .nullable(),
                longitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .nullable(),
              }).nullable(),
              rectanglePoint2EPSG4326: Yup.object<ILocalization>({
                latitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .nullable(),
                longitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .nullable(),
              }).nullable(),
              rectangleSecondSideLengthInMeters: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .nullable(),
              orderNo: Yup.number()
                .min(1, t("validationResources:numericMin0"))
                .max(255, t("validationResources:numericMax255"))
                .typeError(t("validationResources:requiredNumeric"))
                .required(t("validationResources:required")),
              gsLanguageId: Yup.number().required(
                t("validationResources:required")
              ),
              gsApplicationId: Yup.number().required(
                t("validationResources:required")
              ),
              sceneGroup1Name: Yup.string()
                .max(100, t("validationResources:nameMaxLength"))
                .required(t("validationResources:required")),
              sceneGroup2Name: Yup.string()
                .max(100, t("validationResources:nameMaxLength"))
                .required(t("validationResources:required")),
            })}
            onSubmit={(values: IPlaceData) => {
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid
                  container
                  justify="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid container item md={6} sm={12} spacing={1}>
                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        size="small"
                        onChange={formik.handleChange}
                        variant="outlined"
                      >
                        <InputLabel id="language-select-id">
                          {t("language")}
                        </InputLabel>
                        <Select
                          name="gsLanguageId"
                          fullWidth
                          labelId="language-select-id"
                          value={formik.values.gsLanguageId ?? languages[0].id}
                          onChange={formik.handleChange}
                        >
                          {_.map(languages, (value: ILanguageData) => {
                            return (
                              <MenuItem
                                value={value.id ?? 0}
                                key={`language-id-${value.id}`}
                              >
                                {value.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        size="small"
                        onChange={formik.handleChange}
                        variant="outlined"
                      >
                        <InputLabel id="application-select-id">
                          {t("application")}
                        </InputLabel>
                        <Select
                          name="gsApplicationId"
                          fullWidth
                          labelId="application-select-id"
                          value={
                            formik.values.gsApplicationId ??
                            appliactionList[0].id
                          }
                          disabled={!!values.id}
                          onChange={formik.handleChange}
                        >
                          {_.map(appliactionList, (value: IApplicationData) => {
                            return (
                              <MenuItem
                                value={value.id ?? 0}
                                key={`application-id-${value.id}`}
                              >
                                {value.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.name ?? ""}
                        onChange={formik.handleChange}
                        error={!!(formik.errors.name && formik.touched.name)}
                        helperText={
                          formik.errors.name &&
                          formik.touched.name &&
                          formik.errors.name
                        }
                        label={t("name")}
                        variant="outlined"
                        size="small"
                        name="name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.description ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.description &&
                            formik.touched.description
                          )
                        }
                        helperText={
                          formik.errors.description &&
                          formik.touched.description &&
                          formik.errors.description
                        }
                        label={t("description")}
                        variant="outlined"
                        size="small"
                        name="description"
                        multiline
                        rowsMax="5"
                        rows="5"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                formik.values.isAvailableInDemoVersion || false
                              }
                              onChange={formik.handleChange}
                              value={
                                formik.values.isAvailableInDemoVersion || false
                              }
                              name="isAvailableInDemoVersion"
                            />
                          }
                          label={t("availableInDemoVersion")}
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        value={formik.values.orderNo ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(formik.errors.orderNo && formik.touched.orderNo)
                        }
                        helperText={
                          formik.errors.orderNo &&
                          formik.touched.orderNo &&
                          formik.errors.orderNo
                        }
                        label={t("possition")}
                        variant="outlined"
                        size="small"
                        name="orderNo"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.sceneGroup1Name ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.sceneGroup1Name &&
                            formik.touched.sceneGroup1Name
                          )
                        }
                        helperText={
                          formik.errors.sceneGroup1Name &&
                          formik.touched.sceneGroup1Name &&
                          formik.errors.sceneGroup1Name
                        }
                        label={t("placeForm.sceneGroup", { number: 1 })}
                        variant="outlined"
                        size="small"
                        name="sceneGroup1Name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.sceneGroup2Name ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.sceneGroup2Name &&
                            formik.touched.sceneGroup2Name
                          )
                        }
                        helperText={
                          formik.errors.sceneGroup2Name &&
                          formik.touched.sceneGroup2Name &&
                          formik.errors.sceneGroup2Name
                        }
                        label={t("placeForm.sceneGroup", { number: 2 })}
                        variant="outlined"
                        size="small"
                        name="sceneGroup2Name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={t("placeForm.guide")}
                        label={t("placeForm.sceneGroup", { number: 3 })}
                        variant="outlined"
                        size="small"
                        name="sceneGroup3Name"
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item md={6} sm={12} spacing={1}>
                    <Grid item xs={12}>
                      <TextInfo color={"secondary"}>
                        {t("placeForm.possitionInfo")}
                      </TextInfo>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.rectanglePoint1EPSG4326?.longitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "rectanglePoint1EPSG4326.longitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "rectanglePoint1EPSG4326.longitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.errors,
                            "rectanglePoint1EPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "rectanglePoint1EPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "rectanglePoint1EPSG4326.longitude"
                          )
                        }
                        label={t("placeForm.rectangleALongitude")}
                        variant="outlined"
                        size="small"
                        name="rectanglePoint1EPSG4326.longitude"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.rectanglePoint1EPSG4326?.latitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "rectanglePoint1EPSG4326.latitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "rectanglePoint1EPSG4326.latitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.errors,
                            "rectanglePoint1EPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "rectanglePoint1EPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "rectanglePoint1EPSG4326.latitude"
                          )
                        }
                        label={t("placeForm.rectangleALatitude")}
                        variant="outlined"
                        size="small"
                        name="rectanglePoint1EPSG4326.latitude"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.rectanglePoint2EPSG4326?.longitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "rectanglePoint2EPSG4326.longitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "rectanglePoint2EPSG4326.longitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.errors,
                            "rectanglePoint2EPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "rectanglePoint2EPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "rectanglePoint2EPSG4326.longitude"
                          )
                        }
                        label={t("placeForm.rectangleBLongitude")}
                        variant="outlined"
                        size="small"
                        name="rectanglePoint2EPSG4326.longitude"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.rectanglePoint2EPSG4326?.latitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "rectanglePoint2EPSG4326.latitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "rectanglePoint2EPSG4326.latitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.errors,
                            "rectanglePoint2EPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "rectanglePoint2EPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "rectanglePoint2EPSG4326.latitude"
                          )
                        }
                        label={t("placeForm.rectangleBLatitude")}
                        variant="outlined"
                        size="small"
                        name="rectanglePoint2EPSG4326.latitude"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                      <TextInfo color={"secondary"}>
                        {t("placeForm.rectangleInfo")}
                      </TextInfo>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.rectangleSecondSideLengthInMeters ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.rectangleSecondSideLengthInMeters &&
                            formik.touched.rectangleSecondSideLengthInMeters
                          )
                        }
                        helperText={
                          formik.errors.rectangleSecondSideLengthInMeters &&
                          formik.touched.rectangleSecondSideLengthInMeters &&
                          formik.errors.rectangleSecondSideLengthInMeters
                        }
                        label={t("length")}
                        variant="outlined"
                        size="small"
                        name="rectangleSecondSideLengthInMeters"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default PlaceEditorAdmin;
