import * as React from "react";
import { ApiResultStatus, AdvertGridGetListColumn } from "Utils/enums";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import { IAdvertGetListResult } from "Services/AdvertApi/interfaces-result";
import { IAdvertGetListModel } from "Services/AdvertApi/interfaces-model";
import { MUIDataTableColumn } from "mui-datatables";
import { Grid, Divider } from "@material-ui/core";
import { useAdvertApi } from "Services/AdvertApi/advert-api";
import { IAdvertData } from "Services/AdvertApi/interfaces";
import { IResultBase } from "Services/interfaces";
import { useTranslation } from "react-i18next";
import AdvertEditorAdmin from "../AdvertEditor/advert-editor-admin";
import useModalQuestion from "Hooks/use-modal-question";
import useModalSuccess from "Hooks/use-modal-success";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import GridContainer from "Components/Grids/grid-container";
import GridTable from "Components/Grids/grid-table";
import moment from "moment";

const AdvertList: () => JSX.Element = (): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (reloadTable: number): number => {
    reloadTable += 1;
    return reloadTable;
  };

  const [reloadTable, dispatchReloadTable] = React.useReducer(reloadTableReducer, 0);
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { deleteById, getList } = useAdvertApi();
  const { t } = useTranslation(["commonResources"]);

  const deleteAdvert: (id: number) => Promise<void> = async (id: number): Promise<void> => {
    await deleteById(id).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("advertForm.deleteSuccess"));
        dispatchReloadTable();
      }
    });
  };

  const handleClickDelete: (id: number) => Promise<void> = async (id: number): Promise<void> => {
    showModalQuestion(t("advertForm.deleteInfo"), id);
  };

  React.useEffect(() => {
    setYesFunction(() => deleteAdvert);
  }, []);

  const columnSortables: string[] = [
    "name",
    "description",
    "typeText",
    "startDate",
    "endDate",
    "durationInSeconds",
  ];

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "name",
      label: t("name"),
    },
    {
      name: "description",
      label: t("description"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{ width: "200px" }}>{value}</div>;
        },
      },
    },
    {
      name: "typeText",
      label: t("type"),
    },
    {
      name: "startDate",
      label: t("startDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("DD.MM.YYYY") : "-";
        },
      },
    },
    {
      name: "endDate",
      label: t("endDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("DD.MM.YYYY") : "-";
        },
      },
    },
    {
      name: "durationInSeconds",
      label: t("durationInSeconds"),
      options: {
        setCellProps: () => {
          return {
            style: {
              textAlign: "right",
            },
          };
        },
        setCellHeaderProps: () => {
          return {
            style: {
              textAlign: "right",
            },
          };
        },
      },
    },
    {
      name: "",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <AdvertEditorAdmin
              advertId={tableMeta.rowData[0]}
              handleSaveSuccess={dispatchReloadTable}
            />
          );
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGridIcon
              icon="fa-trash-alt"
              color="primary"
              onClick={() => {
                handleClickDelete(tableMeta.rowData[0]);
              }}
            />
          );
        },
      },
    },
  ];

  const generateSortParams: (changedColumn: string) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "name":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = AdvertGridGetListColumn.Name;
        break;
      case "description":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn = AdvertGridGetListColumn.Description;
        break;
      case "typeText":
        newSortParams.columnIndex = 2;
        newSortParams.orderColumn = AdvertGridGetListColumn.Type;
        break;
      case "startDate":
        newSortParams.columnIndex = 3;
        newSortParams.orderColumn = AdvertGridGetListColumn.StartDate;
        break;
      case "endDate":
        newSortParams.columnIndex = 4;
        newSortParams.orderColumn = AdvertGridGetListColumn.EndDate;
        break;
      case "durationInSeconds":
        newSortParams.columnIndex = 5;
        newSortParams.orderColumn = AdvertGridGetListColumn.Duration;
        break;
    }

    return newSortParams;
  };

  return (
    <React.Fragment>
      <GridContainer title={t("adverts")}>
        <Grid container justify="flex-start" alignItems="flex-start">
          <Divider />
          <Grid container item sm={12}>
            <AdvertEditorAdmin handleSaveSuccess={dispatchReloadTable} />
          </Grid>
        </Grid>
        <Divider />
        <GridTable<IAdvertGetListModel, IAdvertData, IAdvertGetListResult>
          reloadCount={reloadTable}
          columns={columns}
          columnsSortableNames={columnSortables}
          generateSortParams={generateSortParams}
          getList={getList}
        />
      </GridContainer>
    </React.Fragment>
  );
};

export default AdvertList;
