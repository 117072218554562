import * as React from "react";
import * as _ from "lodash";
import { useProductApi } from "Services/ProductApi/product-api";
import { IProductData } from "Services/ProductApi/interfaces";
import { ApiResultStatus } from "Utils/enums";
import { IProductGetListResult } from "Services/ProductApi/interfaces-result";
import { IProductCardListProps } from "../interfaces";
import ProductCard from "./product-card";

const ProductCardList: (props: IProductCardListProps) => JSX.Element
= (props: IProductCardListProps): JSX.Element => {
    const [productList, setProductList] = React.useState<IProductData[]>([]);
    const { getList } = useProductApi();

    const handleLoadData: () => Promise<void>
        = async (): Promise<void> => {
            await getList({})
                .then((response: IProductGetListResult) => {
                    if (response && response.status === ApiResultStatus.Ok) {
                        setProductList(response.data.gridData);
                    }
                })
        };

    React.useEffect(() => {
        handleLoadData();
    }, []);

    return (
        <React.Fragment>
            {
                _.map(productList, (value) => {
                    return (<ProductCard product={value} handleAddToCart={props.handleAddToCart}/>)
                })
            }
        </React.Fragment>
    );
};

export default ProductCardList;