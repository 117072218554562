import * as React from "react";
import { IFileUploaderProps } from "./interfaces";
import { stylesFileUploader } from "./styles";
import { useDropzone } from "react-dropzone";
import { Avatar, Grid, Icon, IconButton, Link } from "@material-ui/core";
import TextInfo from "Components/Texts/text-info";
import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";

const envBaseURL: string =
  process.env.REACT_APP_API_BASE_URL ?? "https://webapi.gsot.pl/api";
const envApiVersion: string = process.env.REACT_APP_API_VERSION ?? "v1";

const FileUploader: (props: IFileUploaderProps) => JSX.Element = (
  props: IFileUploaderProps
): JSX.Element => {
  const customStyles: Record<string, string> = stylesFileUploader();
  const { t } = useTranslation(["commonResources"]);

  const handleDrop: (files: File[]) => void = (files: File[]): void => {
    if (files.length > 0) {
      props.setFile(files[0]);
    }
  };

  const handleDropRejected: () => void = (): void => {
    cogoToast.error(t("fileForm.wrongFormat"));
  };

  const handleClick: (url: string) => void = (url: string): void => {
    window.location.href = url;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.acceptedFormats,
    multiple: false,
    onDrop: handleDrop,
    onDropRejected: handleDropRejected,
  });

  const handleDeleteClick: () => void = (): void => {
    props.setFile(undefined);
  };

  return (
    <React.Fragment>
      <div className={customStyles.container} {...getRootProps()}>
        {!!!props.disabled && <input {...getInputProps()} />}
        <div className={customStyles.centerItem}>
          {props.fileName ? (
            <div className={customStyles.fileInfo}>
              {props.addFilePreview ? (
                props.file ? (
                  <Avatar
                    alt={props.fileName}
                    className={customStyles.imageContainer}
                    src={URL.createObjectURL(props.file)}
                    variant="square"
                  />
                ) : (
                  <Avatar
                    alt={props.fileName}
                    className={customStyles.imageContainer}
                    src={`${envBaseURL}/${envApiVersion}/File/Download/${props.discFileName}`}
                    variant="square"
                  />
                )
              ) : null}
              <TextInfo
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "200px",
                  height: "1.4em",
                  whiteSpace: "nowrap",
                }}
              >
                {props.fileName}
              </TextInfo>
            </div>
          ) : (
            <div className={customStyles.fileInfo}>
              <TextInfo>{t("fileForm.fillForm")}</TextInfo>
              <br />
              <TextInfo>
                {t("fileForm.format")} {props.acceptedFormats.join(", ")}
                <br />
                {props.size ? `${t("fileForm.size")} ${props.size}` : ""}
              </TextInfo>
            </div>
          )}
        </div>
        {props.discFileName && (
          <TextInfo style={{ marginTop: "5px" }}>
            <Link
              onClick={(
                event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
              ) => {
                event.stopPropagation();
                handleClick(
                  `${envBaseURL}/${envApiVersion}/File/Download/${props.discFileName}`
                );
              }}
              style={{ cursor: "pointer" }}
            >
              {t("fileForm.downloadFile")}
            </Link>
            {props.showDeleteButton && (
              <IconButton
                color="primary"
                title={t("delete")}
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  event.stopPropagation();
                  handleDeleteClick();
                }}
                style={{ padding: 0 }}
              >
                <Icon
                  className={`fa fa-trash-alt`}
                  style={{ fontSize: "0.6em", paddingLeft: "10px" }}
                />
              </IconButton>
            )}
          </TextInfo>
        )}
      </div>
    </React.Fragment>
  );
};

export default FileUploader;
