import "Styles/custom-styles-sweetalert2.scss";
import { useState } from "react";
import Swal from "sweetalert2";

const useModalSuccess: (initialFunction?: () => void) => (successResult: string) => void
= (initialFunction?: () => void): (successResult: string) => void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [additionalFunction, setAdditionalFunction] = useState(() => initialFunction);

    const showModalSuccess: (successResult: string) => void = (successResult: string): void => {
        Swal.fire({
            icon: "success",
            text: successResult,
            customClass: {
                title: "swal-title",
                container: "swal-container",
                content: "swal-content",
                confirmButton: "swal-confirm-button"
            },
            onClose: () => {
                if(additionalFunction) {
                    additionalFunction();
                }
            }
        });
      };

    return showModalSuccess;
};

export default useModalSuccess;