import * as React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import { stylesPanelExpansion } from "./styles";
import { IPanelExpansionProps } from "./interfaces";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextTitle from "Components/Texts/text-title";

const PanelExpansion: (props: IPanelExpansionProps) => JSX.Element = (
  props: IPanelExpansionProps
): JSX.Element => {
  const customStyles: Record<string, string> = stylesPanelExpansion();
  const { children, title, handleChangeExpand } = { ...props };

  return (
    <React.Fragment>
      <Accordion
        classes={{
          root: customStyles.root,
          expanded: customStyles.rootExtended,
        }}
        expanded={props.isExpanded}
        disabled={props.isDisabled ?? false}
        onChange={(event: React.ChangeEvent<{}>, expanded: boolean) => {
          if (handleChangeExpand) {
            handleChangeExpand(expanded);
          }
        }}
      >
        <AccordionSummary
          classes={{
            content: customStyles.extended,
            expanded: customStyles.extended,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <TextTitle>{title}</TextTitle>
        </AccordionSummary>
        <AccordionDetails className={customStyles.details}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default PanelExpansion;
