import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import useModalError from "Hooks/use-modal-error";
import useModalSuccess from "Hooks/use-modal-success";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import useSBSSceneObjectTimelineApi from "Services/SBSSceneObjectTimelineApi/sbs-scene-object-timeline-api";
import { ApiResultStatus } from "Utils/enums";
import { ISBSSceneObjectValidateProps } from "../interfaces";

const SBSSceneObjectValidator: (
  props: ISBSSceneObjectValidateProps
) => JSX.Element = (props: ISBSSceneObjectValidateProps): JSX.Element => {
  const showModalError: (errorResult: string) => void = useModalError();
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { validate } = useSBSSceneObjectTimelineApi();
  const { t } = useTranslation(["commonResources", "validationResources"]);

  const handleValidate: (id: number) => Promise<ApiResultStatus> = async (
    id: number
  ): Promise<ApiResultStatus> => {
    return await validate({ id: id, withoutDefaultErrorCatching: true })
      .then((r) => {
        return r.status;
      })
      .catch((e) => {
        return e.response.status;
      });
  };

  const handleClick: () => Promise<void> = async (): Promise<void> => {
    Promise.all(
      _.map(props.sbsSceneObjectTimelineIdList, (v, i) => {
        return handleValidate(v);
      })
    ).then((results) => {
      const errorCounts = _.countBy(results, (r) => {
        return r !== ApiResultStatus.Ok;
      });
      if (errorCounts.true > 0) {
        showModalError(
          t("validationResources:incorrectTrajectoryCount", {
            count: errorCounts.true,
          })
        );
      } else {
        showModalSuccess(t("sbsSceneForm.validateSuccess"));
      }
    });
  };

  return (
    <React.Fragment>
      <ButtonPrimary size="sm" onClick={handleClick}>
        <ButtonGridIcon icon={"fa-spell-check"} color="secondary" />
        {t("validate")}
      </ButtonPrimary>
    </React.Fragment>
  );
};

export default SBSSceneObjectValidator;
