import { INotificationApiInterface } from "./interfaces";
import { INotificationGetListModel } from "./interfaces-model";
import { INotificationGetListResult } from "./interfaces-result";
import useBaseDataApi from "Services/base-data-api";

export const useNotificationApi: () => INotificationApiInterface =
  (): INotificationApiInterface => {
    const { baseGetList } = useBaseDataApi({
      apiControllerName: "Notification",
    });

    const getList: (
      model: INotificationGetListModel
    ) => Promise<INotificationGetListResult> = async (
      model: INotificationGetListModel
    ): Promise<INotificationGetListResult> => {
      return await baseGetList<
        INotificationGetListModel,
        INotificationGetListResult
      >(model);
    };

    return { getList };
  };
