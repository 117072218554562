import * as React from "react";
import * as Yup from "yup";
import { ISceneObjectTimelineEditorAdminProps } from "../interfaces";
import { ISceneObjectTimelineData } from "Services/SceneObjectTimelineApi/interfaces";
import {
  ISceneObjectTimelineGetDetailsResult,
  ISceneObjectTimelineGetLastItemDetailsResult,
} from "Services/SceneObjectTimelineApi/interfaces-result";
import { ApiResultStatus } from "Utils/enums";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
} from "@material-ui/core";
import { Formik, Form, getIn } from "formik";
import { ILocalization } from "Services/interfaces";
import { useTranslation } from "react-i18next";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";
import TextInfo from "Components/Texts/text-info";
import useSceneObjectTimelineApi from "Services/SceneObjectTimelineApi/scene-object-timeline-api";
import useModalSuccess from "Hooks/use-modal-success";

const SceneObjectTimelineModel3dEditorAdmin: (
  props: ISceneObjectTimelineEditorAdminProps
) => JSX.Element = (
  props: ISceneObjectTimelineEditorAdminProps
): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { save, getDetails, getLastItemDetails } = useSceneObjectTimelineApi();
  const showModalSuccess: (successResult: string) => void =
    useModalSuccess(undefined);
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const [valuesLastItem, setValuesLastItem] =
    React.useState<ISceneObjectTimelineData>();
  const [values, setValues] = React.useState<ISceneObjectTimelineData>({
    animationIndex: null,
    startTimeInSeconds: 0,
    caption: "",
    startLocalizationEPSG4326: {
      latitude: null,
      longitude: null,
      altitude: null,
    },
    endLocalizationEPSG4326: null,
    endTimeInSeconds: null,
    captionLocalizationAltitude: null,
  });

  const handleLoadLastItemData: () => Promise<void> =
    async (): Promise<void> => {
      if (props.sceneObjectId) {
        return await getLastItemDetails(props.sceneObjectId).then(
          (response: ISceneObjectTimelineGetLastItemDetailsResult) => {
            if (response && response.data) {
              let newValues: ISceneObjectTimelineData = { ...values };
              newValues.startTimeInSeconds =
                (response.data.endTimeInSeconds ?? 0) + 1;
              newValues.startLocalizationEPSG4326 = response.data
                ?.endLocalizationEPSG4326 ?? {
                latitude: null,
                longitude: null,
                altitude: null,
              };
              setValues(newValues);
              setValuesLastItem(response.data);
            } else {
              setValues({
                animationIndex: null,
                startTimeInSeconds: 0,
                caption: "",
                startLocalizationEPSG4326: {
                  latitude: null,
                  longitude: null,
                  altitude: null,
                },
                endLocalizationEPSG4326: null,
                endTimeInSeconds: null,
                captionLocalizationAltitude: null,
              });
              setValuesLastItem(undefined);
            }
          }
        );
      }
    };

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    if (props.sceneObjectTimelineId) {
      return await getDetails(props.sceneObjectTimelineId).then(
        (response: ISceneObjectTimelineGetDetailsResult) => {
          if (response && response.data) {
            setValues(response.data);
          }
        }
      );
    }
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    if (props.sceneObjectTimelineId) {
      handleLoadData();
    } else {
      handleLoadLastItemData();
    }
    setIsOpen(true);
  };

  const handleSave: (
    sceneObjectTimeline: ISceneObjectTimelineData
  ) => Promise<void> = async (
    sceneObjectTimeline: ISceneObjectTimelineData
  ): Promise<void> => {
    sceneObjectTimeline.gsSceneObjectId = props.sceneObjectId;
    await save(sceneObjectTimeline).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(
          sceneObjectTimeline.id
            ? t("saveChangesSuccess")
            : t("animationForm.addSuccess")
        );
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
        handleClose();
      }
    });
  };

  return (
    <React.Fragment>
      {props.sceneObjectTimelineId && (
        <ButtonGridIcon
          icon={"fa-edit"}
          onClick={handleOpen}
          color="secondary"
        />
      )}
      {!props.sceneObjectTimelineId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-route"} color="secondary" />
          {t("animationForm.add")}
        </ButtonPrimary>
      )}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          {props.sceneObjectTimelineId
            ? t("animationForm.edit")
            : t("animationForm.addInfo")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<ISceneObjectTimelineData>({
              animationIndex: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(0, t("validationResources:numericMin0"))
                .max(255, t("validationResources:numericMax255"))
                .nullable(),
              endTimeInSeconds: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .max(1000000, t("validationResources:numericMax1000000"))
                .required(t("validationResources:required")),
              startTimeInSeconds: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .max(1000000, t("validationResources:numericMax1000000"))
                .required(t("validationResources:required")),
              endLocalizationEPSG4326: Yup.object<ILocalization>({
                altitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
                latitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
                longitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
              }).nullable(),
              startLocalizationEPSG4326: Yup.object<ILocalization>({
                altitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .required(t("validationResources:required")),
                latitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .required(t("validationResources:required")),
                longitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .required(t("validationResources:required")),
              }).required(t("validationResources:required")),
              caption: Yup.string()
                .max(
                  100,
                  t("validationResources:stringMaxLength", { length: 100 })
                )
                .nullable(),
              captionLocalizationAltitude: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .max(
                  9999,
                  t("validationResources:numericMaxValue", { value: 9999 })
                )
                .min(
                  -9999,
                  t("validationResources:numericMinValue", { value: -9999 })
                )
                .nullable(),
            })}
            onSubmit={(values: ISceneObjectTimelineData) => {
              if (
                (!values.endLocalizationEPSG4326?.altitude &&
                  values.endLocalizationEPSG4326?.altitude?.toString() !==
                    "0") ||
                !values.endLocalizationEPSG4326.latitude ||
                !values.endLocalizationEPSG4326.longitude
              ) {
                values.endLocalizationEPSG4326 = null;
              }
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid container justify="center" alignItems="flex-start">
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        value={formik.values.caption ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(formik.errors.caption && formik.touched.caption)
                        }
                        helperText={
                          formik.errors.caption &&
                          formik.touched.caption &&
                          formik.errors.caption
                        }
                        label={t("caption")}
                        variant="outlined"
                        size="small"
                        name="caption"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={formik.values.captionLocalizationAltitude ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.captionLocalizationAltitude &&
                            formik.touched.captionLocalizationAltitude
                          )
                        }
                        helperText={
                          formik.touched.captionLocalizationAltitude &&
                          formik.errors.captionLocalizationAltitude
                        }
                        label={t("captionAltitude")}
                        variant="outlined"
                        size="small"
                        name="captionLocalizationAltitude"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={formik.values.startTimeInSeconds ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.startTimeInSeconds &&
                            formik.touched.startTimeInSeconds
                          )
                        }
                        helperText={
                          formik.touched.startTimeInSeconds &&
                          formik.errors.startTimeInSeconds
                        }
                        label={t("animationForm.startTime")}
                        variant="outlined"
                        size="small"
                        name="startTimeInSeconds"
                        disabled={
                          !props.index ? !!valuesLastItem : props.index !== 0
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={formik.values.endTimeInSeconds ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.endTimeInSeconds &&
                            formik.touched.endTimeInSeconds
                          )
                        }
                        helperText={
                          formik.touched.endTimeInSeconds &&
                          formik.errors.endTimeInSeconds
                        }
                        label={t("animationForm.endTime")}
                        variant="outlined"
                        size="small"
                        name="endTimeInSeconds"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={formik.values.animationIndex ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.animationIndex &&
                            formik.touched.animationIndex
                          )
                        }
                        helperText={
                          formik.touched.animationIndex &&
                          formik.errors.animationIndex
                        }
                        label={t("animationForm.index")}
                        variant="outlined"
                        size="small"
                        name="animationIndex"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={12}>
                      <Divider />
                      <TextInfo color={"secondary"}>
                        {t("animationForm.startPosition")}
                      </TextInfo>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.startLocalizationEPSG4326?.longitude ??
                          ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "startLocalizationEPSG4326.longitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "startLocalizationEPSG4326.longitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.touched,
                            "startLocalizationEPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "startLocalizationEPSG4326.longitude"
                          )
                        }
                        label="L"
                        variant="outlined"
                        size="small"
                        name="startLocalizationEPSG4326.longitude"
                        disabled={
                          !props.index ? !!valuesLastItem : props.index !== 0
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.startLocalizationEPSG4326?.latitude ??
                          ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "startLocalizationEPSG4326.latitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "startLocalizationEPSG4326.latitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.touched,
                            "startLocalizationEPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "startLocalizationEPSG4326.latitude"
                          )
                        }
                        label="B"
                        variant="outlined"
                        size="small"
                        name="startLocalizationEPSG4326.latitude"
                        disabled={
                          !props.index ? !!valuesLastItem : props.index !== 0
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.startLocalizationEPSG4326?.altitude ??
                          ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "startLocalizationEPSG4326.altitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "startLocalizationEPSG4326.altitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.touched,
                            "startLocalizationEPSG4326.altitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "startLocalizationEPSG4326.altitude"
                          )
                        }
                        label={t("height")}
                        variant="outlined"
                        size="small"
                        name="startLocalizationEPSG4326.altitude"
                        disabled={
                          !props.index ? !!valuesLastItem : props.index !== 0
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={12}>
                      <Divider />
                      <TextInfo color={"secondary"}>
                        {t("animationForm.endPosition")}
                      </TextInfo>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.endLocalizationEPSG4326?.longitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "endLocalizationEPSG4326.longitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "endLocalizationEPSG4326.longitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.errors,
                            "endLocalizationEPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "endLocalizationEPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "endLocalizationEPSG4326.longitude"
                          )
                        }
                        label="L"
                        variant="outlined"
                        size="small"
                        name="endLocalizationEPSG4326.longitude"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.endLocalizationEPSG4326?.latitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "endLocalizationEPSG4326.latitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "endLocalizationEPSG4326.latitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.errors,
                            "endLocalizationEPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "endLocalizationEPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "endLocalizationEPSG4326.latitude"
                          )
                        }
                        label="B"
                        variant="outlined"
                        size="small"
                        name="endLocalizationEPSG4326.latitude"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.endLocalizationEPSG4326?.altitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "endLocalizationEPSG4326.altitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "endLocalizationEPSG4326.altitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.errors,
                            "endLocalizationEPSG4326.altitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "endLocalizationEPSG4326.altitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "endLocalizationEPSG4326.altitude"
                          )
                        }
                        label={t("height")}
                        variant="outlined"
                        size="small"
                        name="endLocalizationEPSG4326.altitude"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SceneObjectTimelineModel3dEditorAdmin;
