import { IResultBase } from "Services/interfaces";
import {
  ISceneObjectTimelineApiInterface,
  ISceneObjectTimelineData,
} from "./interfaces";
import {
  ISceneObjectTimelineGetDetailsResult,
  ISceneObjectTimelineGetLastItemDetailsResult,
  ISceneObjectTimelineGetListResult,
} from "./interfaces-result";
import {
  ISceneObjectTimelineGetListModel,
  ISceneObjectTimelineImportCsvFileModel,
} from "./interfaces-model";
import { axiosInstance, useBaseApi } from "Services/base-api";
import { getStoredUserAuthToken } from "Utils/helpers";
import { SweetAlertResult } from "sweetalert2";
import { useHistory } from "react-router-dom";
import useBaseDataApi from "Services/base-data-api";
import useModalError from "Hooks/use-modal-error";
import useAuthorization from "Hooks/use-auth-token";

const useSceneObjectTimelineApi: () => ISceneObjectTimelineApiInterface = (): ISceneObjectTimelineApiInterface => {
  const showModalError: (
    errorResult: string,
    isHtml?: boolean
  ) => Promise<SweetAlertResult> = useModalError();
  const history = useHistory();
  const { setUnauthorize } = useAuthorization(getStoredUserAuthToken());
  const {
    baseDeleteById,
    baseGetDetails,
    baseGetList,
    baseSave,
  } = useBaseDataApi({ apiControllerName: "SceneObjectTimeline" });
  const { baseGetRequest } = useBaseApi();

  const importCSVFile: (
    model: ISceneObjectTimelineImportCsvFileModel
  ) => Promise<IResultBase> = async (
    model: ISceneObjectTimelineImportCsvFileModel
  ): Promise<IResultBase> => {
    let url: string = "/SceneObjectTimeline/Import";
    let formData: FormData = new FormData();
    formData.append("GSSceneObjectId", model.GSSceneObjectId.toString());
    formData.append("File", model.File!);

    return await axiosInstance
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: getStoredUserAuthToken(),
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 401) {
          showModalError("Twoja sesja wygasła, zaloguj się ponownie!").then(
            () => {
              setUnauthorize();
              history.push("/login");
            }
          );
        } else if (error.response && error.response.status === 500) {
          showModalError("Coś poszło nie tak, spróbuj ponownie później");
        } else {
          showModalError(error.response.data.statusMessage, true);
        }
      });
  };

  const deleteById: (id: number) => Promise<IResultBase> = async (
    id: number
  ): Promise<IResultBase> => {
    return await baseDeleteById<IResultBase>(id);
  };

  const save: (
    model: ISceneObjectTimelineData
  ) => Promise<IResultBase> = async (
    model: ISceneObjectTimelineData
  ): Promise<IResultBase> => {
    return await baseSave<ISceneObjectTimelineData, IResultBase>(model);
  };

  const getDetails: (
    id: number
  ) => Promise<ISceneObjectTimelineGetDetailsResult> = async (
    id: number
  ): Promise<ISceneObjectTimelineGetDetailsResult> => {
    return await baseGetDetails<ISceneObjectTimelineGetDetailsResult>(id);
  };

  const getLastItemDetails: (
    sceneObjectid: number
  ) => Promise<ISceneObjectTimelineGetLastItemDetailsResult> = async (
    sceneObjectid: number
  ): Promise<ISceneObjectTimelineGetLastItemDetailsResult> => {
    let url: string = `/SceneObjectTimeline/GetLastItemDetails/${sceneObjectid}`;
    return await baseGetRequest<ISceneObjectTimelineGetLastItemDetailsResult>(
      url,
      true
    );
  };

  const getList: (
    model: ISceneObjectTimelineGetListModel
  ) => Promise<ISceneObjectTimelineGetListResult> = async (
    model: ISceneObjectTimelineGetListModel
  ): Promise<ISceneObjectTimelineGetListResult> => {
    return await baseGetList<
      ISceneObjectTimelineGetListModel,
      ISceneObjectTimelineGetListResult
    >(model);
  };

  return {
    deleteById,
    save,
    getDetails,
    getLastItemDetails,
    getList,
    importCSVFile,
  };
};

export default useSceneObjectTimelineApi;
