import * as React from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Form, Formik, getIn } from "formik";
import { ISceneObjectData } from "Services/SceneObjectApi/interfaces";
import { ILocalization } from "Services/interfaces";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";
import useSceneObjectApi from "Services/SceneObjectApi/scene-object-api";
import { ISceneObjectGetDetailsResult } from "Services/SceneObjectApi/interfaces-result";
import { ISceneObjectTimelineOrientationPointEditorAdminProps } from "../interfaces";
import { ApiResultStatus } from "Utils/enums";
import useModalSuccess from "Hooks/use-modal-success";
import TextInfo from "Components/Texts/text-info";

const SceneObjectTimelineOrientationPointEditorAdmin: (
  props: ISceneObjectTimelineOrientationPointEditorAdminProps
) => JSX.Element = (
  props: ISceneObjectTimelineOrientationPointEditorAdminProps
): JSX.Element => {
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const { save, getDetails } = useSceneObjectApi();
  const showModalSuccess: (successResult: string) => void = useModalSuccess(
    undefined
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [values, setValues] = React.useState<ISceneObjectData>({
    gsSceneId: 0,
    gsObjectId: 0,
    isAvailableInDemoVersion: true,
    isFacingSpectator: false,
    orientationPointEPSG4326: {
      altitude: 1,
      longitude: null,
      latitude: null,
    },
  });

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    if (props.sceneObjectId) {
      return await getDetails(props.sceneObjectId).then(
        (response: ISceneObjectGetDetailsResult) => {
          if (response && response.data) {
            setValues(response.data);
          }
        }
      );
    }
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    setIsOpen(true);
  };

  const handleSave: (sceneObject: ISceneObjectData) => Promise<void> = async (
    sceneObject: ISceneObjectData
  ): Promise<void> => {
    sceneObject.id = props.sceneObjectId;
    await save(sceneObject).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("saveChangesSuccess"));
        handleClose();
        handleLoadData();
      }
    });
  };

  React.useEffect(() => {
    if (props.sceneObjectId) {
      handleLoadData();
    }
  }, [props.sceneObjectId]);

  return (
    <React.Fragment>
      <Grid container item spacing={2} alignItems={"center"}>
        <Grid item>
          <ButtonPrimary size="sm" onClick={handleOpen}>
            <ButtonGridIcon icon={"fa-compass"} color="secondary" />
            {t("orientationPoint")}
          </ButtonPrimary>
        </Grid>
        <Grid item>
          <TextInfo>
            L: {values.orientationPointEPSG4326?.longitude ?? "-"} B:{" "}
            {values.orientationPointEPSG4326?.latitude ?? "-"}
          </TextInfo>
        </Grid>
      </Grid>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>{t("orientationForm.title")}</DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<ISceneObjectData>({
              orientationPointEPSG4326: Yup.object<ILocalization>({
                altitude: Yup.number().nullable(),
                latitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
                longitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
              }).nullable(),
            })}
            onSubmit={(values: ISceneObjectData) => {
              if (
                !values.orientationPointEPSG4326?.latitude ||
                !values.orientationPointEPSG4326?.longitude
              ) {
                values.orientationPointEPSG4326 = null;
              }
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid container justify="center" alignItems="flex-start">
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.orientationPointEPSG4326?.longitude ??
                          ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "orientationPointEPSG4326.longitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "orientationPointEPSG4326.longitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.errors,
                            "orientationPointEPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "orientationPointEPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "orientationPointEPSG4326.longitude"
                          )
                        }
                        label="L"
                        variant="outlined"
                        size="small"
                        name="orientationPointEPSG4326.longitude"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.orientationPointEPSG4326?.latitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "orientationPointEPSG4326.latitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "orientationPointEPSG4326.latitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.errors,
                            "orientationPointEPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "orientationPointEPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "orientationPointEPSG4326.latitude"
                          )
                        }
                        label="B"
                        variant="outlined"
                        size="small"
                        name="orientationPointEPSG4326.latitude"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SceneObjectTimelineOrientationPointEditorAdmin;
