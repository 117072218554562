import * as React from "react";
import * as Yup from "yup";
import { Grid, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { ButtonPrimary } from "Components/Buttons/button-primary";
import { ApiResultStatus } from "Utils/enums";
import { IUserDetailsData } from "Services/UserApi/interfaces";
import { useUserApi } from "Services/UserApi/user-api";
import PanelBasic from "Components/Panels/panel-basic";
import useModalSuccess from "Hooks/use-modal-success";
import TextSubtitle from "Components/Texts/text-subtitle";
import BlockUi from "react-block-ui";
import { useTranslation } from "react-i18next";

const UserDetailsPassword: () => JSX.Element = (): JSX.Element => {
  const showModalSuccess: (successResult: string) => void = useModalSuccess(
    undefined
  );
  const { update } = useUserApi();
  const [isVisible, setIsVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const [initialValues, setInitialValues] = React.useState({
    password: "",
    confirmPassword: "",
  });

  const handleChangeVisibility: () => void = (): void => {
    setIsVisible(!isVisible);
  };

  const handleChangePassword: (
    user: IUserDetailsData
  ) => Promise<void> = async (user: IUserDetailsData): Promise<void> => {
    setLoading(true);
    await update(user)
      .then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          setInitialValues({
            password: "",
            confirmPassword: "",
          });
          showModalSuccess(t("passwordChanged"));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ButtonPrimary onClick={handleChangeVisibility}>
        {t("passwordChange")}
      </ButtonPrimary>
      <PanelBasic hidden={!isVisible}>
        <BlockUi
          blocking={loading}
          loader={<CircularProgress color="secondary" />}
        >
          <TextSubtitle>{t("passwordChangeInfo")}</TextSubtitle>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
              password: Yup.string().min(
                6,
                t("validationResources:passwordMinLength")
              ),
              confirmPassword: Yup.string().oneOf(
                [Yup.ref("password"), null],
                t("validationResources:passwordEqual")
              ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              let userPassword: IUserDetailsData = {
                password: values.password,
                confirmPassword: values.confirmPassword,
              };
              handleChangePassword(userPassword);
              setSubmitting(false);
            }}
          >
            {(formik) => (
              <Form>
                <Grid
                  container
                  item
                  xs={12}
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid container item xs={12}>
                    <TextField
                      fullWidth
                      label={t("password")}
                      variant="outlined"
                      size="small"
                      type="password"
                      id="password"
                      name="password"
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <TextField
                      fullWidth
                      label={t("passwordRetype")}
                      variant="outlined"
                      size="small"
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                    />
                  </Grid>
                  <Grid container item xs={12} justify="center">
                    <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
                      {t("saveChanges")}
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </BlockUi>
      </PanelBasic>
    </React.Fragment>
  );
};

export default UserDetailsPassword;
