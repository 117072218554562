import React from "react";
import GridContainer from "Components/Grids/grid-container";
import ObjectList from "./object-list";
import { useTranslation } from "react-i18next";

const ObjectListContainer: () => JSX.Element
    = (): JSX.Element => {
        const { t } = useTranslation(["commonResources"]);

        return (
            <React.Fragment>
                <GridContainer title={t("objectForm.title")} >
                    <ObjectList enableEdit={true} />
                </GridContainer>
            </React.Fragment>
        );
    };

export default ObjectListContainer;