import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { IButtonPrimaryProps, IButtonGridIconProps } from "./interfaces";

export const stylesButtonPrimary: (
  props: IButtonPrimaryProps
) => Record<string, string> = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      background: `linear-gradient(45deg, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark} 90%)`,
      borderRadius: 3,
      border: 0,
      color: `${theme.palette.text.secondary}`,
      whiteSpace: "nowrap",
      height: (props: IButtonPrimaryProps) =>
        props.size && props.size === "sm" ? 30 : 40,
      padding: (props: IButtonPrimaryProps) => {
        switch (props.size) {
          case "sm":
            return "0 20px !important";
          default:
            return "0 40px !important";
        }
      },
      fontWeight: (props: IButtonPrimaryProps) => {
        switch (props.size) {
          case "sm":
            return "normal";
          default:
            return "bold";
        }
      },
      textTransform: (props: IButtonPrimaryProps) => {
        switch (props.size) {
          case "sm":
            return "none";
          default:
            return "uppercase";
        }
      },
      margin: (props: IButtonPrimaryProps) => {
        switch (props.size) {
          case "sm":
            return "2px 0";
          default:
            return "10px 0";
        }
      },
    },
    disabled: {
      background: theme.palette.action.disabledBackground,
      color: theme.palette.text.secondary,
    },
    focused: {
      background: `linear-gradient(45deg, ${theme.palette.primary.light} 40%, ${theme.palette.secondary.light} 90%)`,
      boxShadow: `0px 0px 10px -1px ${theme.palette.secondary.main}`,
    },
  })
);

export const stylesButtonFacebook: () => Record<string, string> = makeStyles(
  (theme: Theme) =>
    createStyles({
      facebookButton: {
        backgroundColor: "#4c69ba",
        border: "calc(.06887vw + .67769px) solid #4c69ba",
        borderRadius: "2px",
        color: "#fff",
        fontFamily: "Helvetica",
        fontSize: "12px",
        fontStyle: "normal",
        margin: "10px 0",
        padding: "10px",
        transition: "background-color .3s,border-color .3s",
        width: "215px",
        "& i": {
          marginRight: "10px",
        },
      },
    })
);

export const stylesButtonMenu: () => Record<string, string> = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        "& svg": {
          height: "2em",
          width: "2em",
        },
      },
    })
);

export const stylesButtonLanguage: () => Record<string, string> = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        fontSize: "1em",
        width: "60px !important",
      },
    })
);

export const stylesButtonGoogle: () => Record<string, string> = makeStyles(
  (theme: Theme) =>
    createStyles({
      googleButton: {
        fontFamily: "Helvetica !important",
        fontSize: "12px !important",
        fontStyle: "normal !important",
        textAlign: "center",
        marginTop: "0",
        marginBottom: "10px",
        transition: "background-color .3s,border-color .3s",
        width: "215px",
        "& div": {
          padding: "5px !important",
          marginLeft: "16px",
          marginRight: "0px !important",
        },
      },
    })
);

export const stylesButtonSecondary: () => Record<string, string> = makeStyles(
  (theme: Theme) =>
    createStyles({
      main: {
        background: theme.palette.background.paper,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 3,
        color: `${theme.palette.primary.main}`,
        height: 40,
        padding: `0 40px !important`,
        fontWeight: "bold",
        margin: `10px 0`,
      },
      disabled: {
        background: theme.palette.action.disabledBackground,
        color: theme.palette.text.secondary,
      },
    })
);

export const stylesButtonGridIcon: (
  props: IButtonGridIconProps
) => Record<string, string> = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      cursor: "pointer !important",
      fontSize: (props: IButtonGridIconProps) =>
        props.iconSize ?? "15px !important",
      overflow: "visible !important",
      width: "auto !important",
      paddingRight: "5px",
    },
  })
);
