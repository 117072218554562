import * as React from "react";
import * as Yup from "yup";
import "react-block-ui/style.css";
import { Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { Link, useHistory } from "react-router-dom";
import { Grid, Divider, CircularProgress } from "@material-ui/core";
import { ButtonPrimary } from "Components/Buttons/button-primary";
import { IUserRegisterModel } from "Services/UserApi/interfaces-model";
import { useTranslation } from "react-i18next";
import { useUserApi } from "Services/UserApi/user-api";
import useModalSuccess from "Hooks/use-modal-success";
import BlockUi from "react-block-ui";
import TextInfo from "Components/Texts/text-info";
import TextHeader from "Components/Texts/text-header";
import TextSubheader from "Components/Texts/text-subheader";
import ReCAPTCHA from "react-google-recaptcha";
import ReCaptcha from "Components/ReCaptcha/recaptcha";
import LayoutNotLogged from "Layouts/layout-not-logged";
import ThemeLink from "Components/Links/theme-link";

const Register: () => JSX.Element = (): JSX.Element => {
  const { register } = useUserApi();
  const [loading, setLoading] = React.useState(false);
  const [recaptchaRef, setRecaptchaRef] = React.useState<ReCAPTCHA | null>(
    null
  );
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const history = useHistory();

  const handlerAfterRegister: () => void = (): void => {
    history.push("/");
  };

  const showModalSuccess: (successResult: string) => void =
    useModalSuccess(handlerAfterRegister);

  const initialValues: IUserRegisterModel = {
    email: "",
    password: "",
    confirmPassword: "",
    recaptchaToken: "",
  };

  const handleSave: (user: IUserRegisterModel) => Promise<void> = async (
    user: IUserRegisterModel
  ): Promise<void> => {
    setLoading(true);
    await register(user)
      .then((response) => {
        if (response) {
          showModalSuccess(t("registrationForm.successInfo"));
        }
      })
      .finally(() => {
        recaptchaRef?.reset();
        setLoading(false);
      });
  };

  return (
    <BlockUi blocking={loading} loader={<CircularProgress color="secondary" />}>
      <LayoutNotLogged>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <TextSubheader>{t("enterTo")}</TextSubheader>
            <TextHeader>GSOT</TextHeader>
            <Divider />
            <TextInfo>{t("registrationForm.fillForm")}</TextInfo>
            <Divider />
          </Grid>
        </Grid>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t("validationResources:emailInvalid"))
              .max(255, t("validationResources:emailMaxLength"))
              .required(t("validationResources:required")),
            password: Yup.string()
              .min(6, t("validationResources:passwordMinLength"))
              .required(t("validationResources:required")),
            confirmPassword: Yup.string()
              .oneOf(
                [Yup.ref("password"), null],
                t("validationResources:passwordEqual")
              )
              .required(t("validationResources:required")),
            recaptchaToken: Yup.string(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            let model: IUserRegisterModel = {
              email: values.email ?? "",
              password: values.password ?? "",
              confirmPassword: values.confirmPassword ?? "",
              recaptchaToken: values.recaptchaToken ?? "",
            };
            handleSave(model);
            setSubmitting(false);
          }}
        >
          {(formik) => (
            <Form>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid container item xs={12}>
                  <TextField
                    fullWidth
                    label={t("email")}
                    variant="outlined"
                    size="small"
                    id="email"
                    name="email"
                  />
                </Grid>
                <Grid container item xs={12}>
                  <TextField
                    fullWidth
                    label={t("password")}
                    variant="outlined"
                    size="small"
                    type="password"
                    id="password"
                    name="password"
                  />
                </Grid>
                <Grid container item xs={12}>
                  <TextField
                    fullWidth
                    label={t("passwordRetype")}
                    variant="outlined"
                    size="small"
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                  />
                </Grid>
                <Grid container item xs={12} alignItems="center">
                  <ReCaptcha
                    setRecaptchaRef={setRecaptchaRef}
                    onChangeFunction={formik.setFieldValue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
                    {t("register")}
                  </ButtonPrimary>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Divider />
        <Grid container justify="flex-end" spacing={1}>
          <Grid item xs={12}>
            <TextInfo>
              {t("accountExists")} <ThemeLink to="/" text={t("logInMe")} />
            </TextInfo>
          </Grid>
          <Grid item xs={12}>
            <TextInfo>
              {t("noActivationEmail")}{" "}
              <ThemeLink to="/resend-user-confirmation" text={t("resend")} />
            </TextInfo>
          </Grid>
        </Grid>
      </LayoutNotLogged>
    </BlockUi>
  );
};

export default Register;
