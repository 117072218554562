import * as Yup from "yup";
import * as _ from "lodash";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import { Form, Formik, FormikProps } from "formik";
import useModalSuccess from "Hooks/use-modal-success";
import React from "react";
import BlockUi from "react-block-ui";
import { useTranslation } from "react-i18next";
import useFileApi from "Services/FileApi/file-api";
import { IFileData } from "Services/FileApi/interfaces";
import { IFileUploadModel } from "Services/FileApi/interfaces-models";
import { IFileUploadResult } from "Services/FileApi/interfaces-results";
import { ILanguageData } from "Services/LanguageApi/interfaces";
import { ILanguageGetListResult } from "Services/LanguageApi/interfaces-result";
import { useLanguageApi } from "Services/LanguageApi/language-api";
import { ISBSObjectData } from "Services/SBSObjectApi/interfaces";
import { ISBSObjectGetDetailsResult } from "Services/SBSObjectApi/interfaces-result";
import { useSBSObjectApi } from "Services/SBSObjectApi/sbs-object-api";
import { DEFAULT_LANGUAGE_ID } from "Utils/consts";
import { ApiResultStatus, FileType, ObjectType } from "Utils/enums";
import { ISBSObjectEditorAdminProps } from "../interfaces";
import cogoToast from "cogo-toast";
import FileUploader from "Components/File/file-uploader";
import ButtonSecondary from "Components/Buttons/button-secondary";
import { ColorResult, SketchPicker } from "react-color";
import { hexToRgb } from "Utils/helper-color";
import { Close } from "@material-ui/icons";

const SBSObjectEditorAdmin: (props: ISBSObjectEditorAdminProps) => JSX.Element =
  (props: ISBSObjectEditorAdminProps): JSX.Element => {
    const [loading, setLoading] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isColorPickerOpen, setIsColorPickerOpen] = React.useState(false);
    const [languages, setLanguages] = React.useState<ILanguageData[]>([]);
    const [objectFile, setObjectFile] = React.useState<File>();
    const [quicklookFile, setQuicklookFile] = React.useState<File>();
    const { upload } = useFileApi();
    const { save, getDetails } = useSBSObjectApi();
    const { getList } = useLanguageApi();

    const showModalSuccess: (successResult: string) => void =
      useModalSuccess(undefined);
    const [values, setValues] = React.useState<ISBSObjectData>({
      name: "",
      description: "",
      type: ObjectType.Model3D,
      gsLanguageId: DEFAULT_LANGUAGE_ID,
    });
    const [valuesFile, setValuesFile] = React.useState<IFileData>({});
    const [quicklookValuesFile, setQuicklookValuesFile] = React.useState<IFileData>({});
    const { t } = useTranslation([
      "commonResources",
      "enumResources",
      "validationResources",
    ]);
    const model3DFileFormats: string[] = [".fbx", ".zip"];

    const formatReducer: (
      formatState: string[],
      formatType: ObjectType
    ) => string[] = (
      formatState: string[],
      formatType: ObjectType
    ): string[] => {
      switch (formatType) {
        case ObjectType.Sound:
          return [".mp3"];
        case ObjectType.Graphic:
          return [".jpg"];
        case ObjectType.Video:
          return [".webm"];
        case ObjectType.Model3D:
        default:
          return model3DFileFormats;
      }
    };
    const [formatsArray, setFormatsArray] = React.useReducer(
      formatReducer,
      model3DFileFormats
    );

    const quicklookFormatsArray: string[] = [".jpg"];

    const setNewFile: (file: File | undefined) => void = (
      file: File | undefined
    ): void => {
      if (file) {
        let newValuesFile: IFileData = { ...valuesFile };
        newValuesFile = {
          originalFileName: file.name,
        };
        setValuesFile(newValuesFile);
      }
      setObjectFile(file);
    };

    const setNewQuicklookFile: (file: File | undefined) => void = (file: File | undefined): void => {
      if (file) {
        let newQuicklookFile: IFileData = { ...quicklookValuesFile };
        newQuicklookFile = {
          originalFileName: file.name,
        };
        setQuicklookValuesFile(newQuicklookFile);
      }
      setQuicklookFile(file);
    }

    const uploadFile: (file: IFileUploadModel) => Promise<IFileUploadResult> =
      async (file: IFileUploadModel): Promise<IFileUploadResult> => {
        return await upload(file);
      };

    const loadLanguages: () => Promise<void> = async () => {
      return await getList().then((response: ILanguageGetListResult) => {
        if (response?.data) {
          setLanguages(response.data);
        }
      });
    };

    const handleLoadData: () => Promise<void> = async (): Promise<void> => {
      if (props.sbsObjectId) {
        setLoading(true);
        return await getDetails(props.sbsObjectId)
          .then((response: ISBSObjectGetDetailsResult) => {
            if (response && response.data) {
              setValues(response.data);
              setValuesFile(response.data.file ?? {});
              setQuicklookValuesFile(response.data.quicklookFile ?? {});
              setFormatsArray(response.data?.type ?? ObjectType.Model3D);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };

    const handleColorChange: (
      color: ColorResult,
      event: React.ChangeEvent<HTMLInputElement>,
      formik: FormikProps<ISBSObjectData>
    ) => void = (
      color: ColorResult,
      event: React.ChangeEvent<HTMLInputElement>,
      formik: FormikProps<ISBSObjectData>
    ): void => {
      let newColor: string = color.hex;
      let newValues: ISBSObjectData = { ...formik.values };
      newValues.markerColor = newColor;
      formik.setValues(newValues);
    };

    const handleColorClear: (formik: FormikProps<ISBSObjectData>) => void = (
      formik: FormikProps<ISBSObjectData>
    ): void => {
      let newValues: ISBSObjectData = { ...formik.values };
      newValues.markerColor = undefined;
      formik.setValues(newValues);
    };

    const handleColorPickerClose: () => void = (): void => {
      setIsColorPickerOpen(false);
    };

    const handleColorPickerOpen: () => void = (): void => {
      setIsColorPickerOpen(true);
    };

    const handleClose: () => void = (): void => {
      setIsOpen(false);
    };

    const handleOpen: () => void = (): void => {
      if (props.sbsObjectId) {
        handleLoadData();
      }
      setIsOpen(true);
    };

    const handleSaveObjectFile: (object: ISBSObjectData) => Promise<ISBSObjectData> = async (object: ISBSObjectData): Promise<ISBSObjectData> => {
      if (objectFile) {
        let fileToUpload: IFileUploadModel = {
          type: FileType.SBSObjectFile,
          file: objectFile,
        };
        await uploadFile(fileToUpload)
          .then((response) => {
            if (response?.status === ApiResultStatus.Ok) {
              object.file = response.data;
            }
          });
        setValuesFile({});
      } else if (valuesFile) {
        object.file = valuesFile;
      }
      return object;
    }

    const handleSaveQuicklookFile: (object: ISBSObjectData) => Promise<ISBSObjectData> = async (object: ISBSObjectData): Promise<ISBSObjectData> => {
      if (quicklookFile) {
        let fileToUpload: IFileUploadModel = {
          type: FileType.SBSObjectQuicklookFile,
          file: quicklookFile,
        };
        await uploadFile(fileToUpload)
          .then((response) => {
            if (response?.status === ApiResultStatus.Ok) {
              object.quicklookFile = response.data;
            }
          });
        setQuicklookValuesFile({});
      } else if (quicklookValuesFile?.id) {
        object.quicklookFile = quicklookValuesFile;
      } else {
        object.quicklookFile = null;
      }
      return object;
    }

    const handleSave: (object: ISBSObjectData) => Promise<void> = async (
      object: ISBSObjectData
    ): Promise<void> => {
      setLoading(true);
      await handleSaveObjectFile(object)
        .then((newObject) => {
          handleSaveQuicklookFile(newObject)
          .then((newObject) => {
            save(newObject).then((response) => {
              if (response?.status === ApiResultStatus.Ok) {
                showModalSuccess(
                  newObject.id
                    ? t("saveChangesSuccess")
                    : t("objectForm.addSuccess")
                );
                if (props.handleSaveSuccess) {
                  props.handleSaveSuccess();
                }
                handleClose();
              }
            });
          })
        })
        .finally(() => {
          setLoading(false);
        });
    };

    React.useEffect(() => {
      loadLanguages();
    }, []);

    return (
      <React.Fragment>
        {props.sbsObjectId && (
          <ButtonGridIcon
            icon={"fa-edit"}
            onClick={handleOpen}
            color="secondary"
          />
        )}
        {!props.sbsObjectId && (
          <ButtonPrimary size="sm" onClick={handleOpen}>
            <ButtonGridIcon icon={"fa-cube"} color="secondary" />
            {t("objectForm.add")}
          </ButtonPrimary>
        )}
        <Dialog
          open={isOpen}
          onClose={handleClose}
          scroll={"paper"}
          fullWidth={true}
          maxWidth={"md"}
        >
          <BlockUi
            blocking={loading}
            loader={<CircularProgress color="secondary" />}
          >
            <DialogTitle>
              {props.sbsObjectId
                ? t("objectForm.edit")
                : t("objectForm.addInfo")}
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Formik
                initialValues={values}
                enableReinitialize={true}
                validationSchema={Yup.object<ISBSObjectData>({
                  name: Yup.string()
                    .max(100, t("validationResources:nameMaxLength"))
                    .required(t("validationResources:required")),
                  description: Yup.string()
                    .max(1000, t("validationResources:descriptionMaxLength"))
                    .nullable(),
                  type: Yup.number()
                    .typeError(t("validationResources:requiredNumeric"))
                    .required(t("validationResources:required")),
                  file: Yup.object(),
                  gsLanguageId: Yup.number().required(
                    t("validationResources:required")
                  ),
                  markerSize: Yup.number()
                    .typeError(t("validationResources:requiredNumeric"))
                    .min(
                      1,
                      t("validationResources:numericMinValue", { value: 1 })
                    )
                    .max(
                      255,
                      t("validationResources:numericMaxValue", { value: 255 })
                    )
                    .nullable(),
                  markerComment: Yup.string()
                    .max(
                      100,
                      t("validationResources:stringMaxLength", { length: 100 })
                    )
                    .nullable(),
                })}
                onSubmit={(values: ISBSObjectData) => {
                  if (valuesFile.originalFileName) {
                    handleSave(values);
                  } else {
                    cogoToast.error(t("fileForm.noChoose"));
                  }
                }}
              >
                {(formik) => (
                  <Form>
                    <Grid container justify="center" alignItems="flex-start">
                      <Grid container item md={6} sm={12}>
                        <Grid item xs={12}>
                          <Grid
                            container
                            item
                            xs={12}
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                          >
                            <Grid container item xs={12}>
                              <TextField
                                fullWidth
                                value={formik.values.name ?? ""}
                                onChange={formik.handleChange}
                                error={
                                  !!(formik.errors.name && formik.touched.name)
                                }
                                helperText={
                                  formik.errors.name &&
                                  formik.touched.name &&
                                  formik.errors.name
                                }
                                label={t("name")}
                                variant="outlined"
                                size="small"
                                name="name"
                              />
                            </Grid>
                            <Grid container item xs={12}>
                              <TextField
                                fullWidth
                                value={formik.values.description ?? ""}
                                onChange={formik.handleChange}
                                error={
                                  !!(
                                    formik.errors.description &&
                                    formik.touched.description
                                  )
                                }
                                helperText={
                                  formik.errors.description &&
                                  formik.touched.description &&
                                  formik.errors.description
                                }
                                label={t("description")}
                                variant="outlined"
                                size="small"
                                name="description"
                                multiline
                                rowsMax="5"
                                rows="5"
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs={12} spacing={2}>
                            <Grid container item xs={6} spacing={1}>
                              <Grid item xs={10}>
                                <FormControl variant="outlined" size={"small"}>
                                  <InputLabel htmlFor="outlined-adornment-marker-color">
                                    {t("sbsObjectForm.markerColor")}
                                  </InputLabel>
                                  <OutlinedInput
                                    id="outlined-adornment-marker-color"
                                    name="markerColor"
                                    type={"text"}
                                    value={formik.values.markerColor ?? ""}
                                    onChange={formik.handleChange}
                                    disabled={true}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => {
                                            handleColorClear(formik);
                                          }}
                                          onMouseDown={() => {
                                            handleColorClear(formik);
                                          }}
                                          edge="end"
                                        >
                                          <Close />
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    labelWidth={70}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={2}>
                                <ButtonGridIcon
                                  icon={"fa-palette"}
                                  onClick={handleColorPickerOpen}
                                  iconSize={"20px"}
                                  style={{
                                    color:
                                      formik.values.markerColor ?? "#f7e13e",
                                    paddingTop: "10px",
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={6}
                              style={{ paddingRight: 0 }}
                            >
                              <TextField
                                fullWidth
                                value={formik.values.markerSize ?? ""}
                                onChange={formik.handleChange}
                                error={
                                  !!(
                                    formik.errors.markerSize &&
                                    formik.touched.markerSize
                                  )
                                }
                                helperText={
                                  formik.errors.markerSize &&
                                  formik.touched.markerSize &&
                                  formik.errors.markerSize
                                }
                                label={t("sbsObjectForm.markerSize")}
                                variant="outlined"
                                size="small"
                                name="markerSize"
                              />
                            </Grid>
                            <Grid container item xs={12}>
                              <TextField
                                fullWidth
                                value={formik.values.markerComment ?? ""}
                                onChange={formik.handleChange}
                                error={
                                  !!(
                                    formik.errors.markerComment &&
                                    formik.touched.markerComment
                                  )
                                }
                                helperText={
                                  formik.errors.markerComment &&
                                  formik.touched.markerComment &&
                                  formik.errors.markerComment
                                }
                                label={t("sbsObjectForm.markerComment")}
                                variant="outlined"
                                size="small"
                                name="markerComment"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container item md={6} sm={12} spacing={2}>
                        <Grid item xs={12} md={6}>
                          <FormControl
                            fullWidth
                            size="small"
                            onChange={formik.handleChange}
                            variant="outlined"
                          >
                            <InputLabel id="language-select-id">
                              {t("language")}
                            </InputLabel>
                            <Select
                              name="gsLanguageId"
                              fullWidth
                              labelId="language-select-id"
                              value={
                                formik.values.gsLanguageId ?? languages[0].id
                              }
                              disabled={!!values.id}
                              onChange={formik.handleChange}
                            >
                              {_.map(languages, (value: ILanguageData) => {
                                return (
                                  <MenuItem
                                    value={value.id ?? 0}
                                    key={`language-id-${value.id}`}
                                  >
                                    {value.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl
                            fullWidth
                            size="small"
                            variant="outlined"
                          >
                            <InputLabel id="object-type">
                              {t("objectForm.type")}
                            </InputLabel>
                            <Select
                              name="type"
                              fullWidth
                              labelId="object-type"
                              value={formik.values.type || ObjectType.Model3D}
                              onChange={(event) => {
                                formik.handleChange(event);
                                setFormatsArray(
                                  event.target.value as ObjectType
                                );
                              }}
                            >
                              <MenuItem value={ObjectType.Graphic}>
                                {t("enumResources:objectType.graphic")}
                              </MenuItem>
                              <MenuItem value={ObjectType.Model3D}>
                                {t("enumResources:objectType.model3D")}
                              </MenuItem>
                              <MenuItem value={ObjectType.Sound}>
                                {t("enumResources:objectType.sound")}
                              </MenuItem>
                              <MenuItem value={ObjectType.Video}>
                                {t("enumResources:objectType.video")}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FileUploader
                            fileName={valuesFile?.originalFileName || undefined}
                            discFileName={valuesFile?.discFileName}
                            file={objectFile}
                            setFile={setNewFile}
                            acceptedFormats={formatsArray}
                          />
                        </Grid>
                        {formik.values.type === ObjectType.Model3D && valuesFile?.originalFileName &&
                          <Grid item xs={12}>
                            <FileUploader
                              fileName={quicklookValuesFile?.originalFileName || undefined}
                              discFileName={quicklookValuesFile?.discFileName}
                              file={quicklookFile}
                              setFile={setNewQuicklookFile}
                              acceptedFormats={quicklookFormatsArray}
                              addFilePreview={true}
                            />
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                    <Dialog
                      open={isColorPickerOpen}
                      onClose={handleColorPickerClose}
                      scroll={"paper"}
                      maxWidth={"sm"}
                    >
                      <DialogContent>
                        <SketchPicker
                          color={
                            formik.values.markerColor
                              ? hexToRgb(formik.values.markerColor)
                              : ""
                          }
                          disableAlpha
                          presetColors={[]}
                          onChange={(
                            color: ColorResult,
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleColorChange(color, event, formik);
                          }}
                        />
                      </DialogContent>
                    </Dialog>
                    <Divider />
                    <DialogActions>
                      <ButtonSecondary onClick={handleClose}>
                        {t("cancel")}
                      </ButtonSecondary>
                      <ButtonPrimary
                        type="submit"
                        onClick={formik.handleSubmit}
                      >
                        {t("save")}
                      </ButtonPrimary>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </BlockUi>
        </Dialog>
      </React.Fragment>
    );
  };

export default SBSObjectEditorAdmin;
