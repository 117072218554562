import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import commonResources_en from "Translations/en/common-resources.json";
import enumResources_en from "Translations/en/enum-resources.json";
import validationResources_en from "Translations/en/validation-resources.json";
import commonResources_pl from "Translations/pl/common-resources.json";
import enumResources_pl from "Translations/pl/enum-resources.json";
import validationResources_pl from "Translations/pl/validation-resources.json";

const resources = {
  en: {
    commonResources: commonResources_en,
    enumResources: enumResources_en,
    validationResources: validationResources_en,
  },
  pl: {
    commonResources: commonResources_pl,
    enumResources: enumResources_pl,
    validationResources: validationResources_pl,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "pl",
    keySeparator: ".",
    supportedLngs: ["en", "pl"],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["cookie", "localStorage", "sessionStorage", "navigator"],
      lookupCookie: "i18nextLng",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      caches: ["cookie", "localStorage", "sessionStorage"],
      checkWhitelist: true,
    },
  });

export default i18n;
