import * as React from "react";
import { IUserExternalLoginModel } from "Services/UserApi/interfaces-model";
import { stylesButtonFacebook } from "./styles";
import { getStoredUserAuthToken } from "Utils/helpers";
import { useHistory, useLocation } from "react-router-dom";
import { ApiResultStatus, ExternalLoginType } from "Utils/enums";
import { IButtonLoadProps } from "./interfaces";
import { useUserApi } from "Services/UserApi/user-api";
import FacebookLogin, {
  ReactFacebookLoginInfo,
  ReactFacebookFailureResponse,
} from "react-facebook-login";
import useAuthorization from "Hooks/use-auth-token";
import useModalError from "Hooks/use-modal-error";
import { useTranslation } from "react-i18next";

const ButtonFacebook: (props: IButtonLoadProps) => JSX.Element = (
  props: IButtonLoadProps
): JSX.Element => {
  const showModalError: (errorResult: string) => void = useModalError();
  const customStyles: Record<string, string> = stylesButtonFacebook();
  const { setUser, setAuthorize } = useAuthorization(getStoredUserAuthToken());
  const { externalLogin } = useUserApi();
  const { t } = useTranslation(["commonResources"]);
  const history = useHistory();
  const query: URLSearchParams = new URLSearchParams(useLocation().search);

  const handleSuccessResponseFacebook: (
    response: ReactFacebookLoginInfo
  ) => Promise<void> = async (response: ReactFacebookLoginInfo) => {
    props.setLoading(true);
    let model: IUserExternalLoginModel = {
      accessToken: response.accessToken,
      type: ExternalLoginType.Facebook,
    };
    await externalLogin(model)
      .then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          setAuthorize(response.data.token);
          setUser(response.data);
          const redirect = query.get("redirectTo");
          if (redirect) {
            history.push(`/${redirect}`);
          } else {
            history.push("/");
          }
        }
      })
      .finally(() => {
        props.setLoading(false);
      });
  };

  const handleFailureResponseFromFacebook: (
    response: ReactFacebookFailureResponse
  ) => void = (response: ReactFacebookFailureResponse): void => {
    showModalError(t("facebookLoginFailed"));
    props.setLoading(false);
  };

  return (
    <FacebookLogin
      appId="512251329314348"
      fields="email"
      callback={handleSuccessResponseFacebook}
      onFailure={handleFailureResponseFromFacebook}
      size="small"
      textButton={t("facebookLogin")}
      cssClass={customStyles.facebookButton}
    />
  );
};

export default ButtonFacebook;
