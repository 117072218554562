import * as React from "react";
import { Grid, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { IResetPasswordFailedProps } from "Views/interfaces";
import { useTranslation } from "react-i18next";
import PanelContainer from "Components/Panels/panel-container";
import TextInfo from "Components/Texts/text-info";
import TextHeader from "Components/Texts/text-header";
import TextSubheader from "Components/Texts/text-subheader";
import LayoutNotLogged from "Layouts/layout-not-logged";
import ThemeLink from "Components/Links/theme-link";

const ResetPasswordFailed: (props: IResetPasswordFailedProps) => JSX.Element = (
  props: IResetPasswordFailedProps
): JSX.Element => {
  const { t } = useTranslation(["commonResources"]);

  return (
    <PanelContainer centerElements={true} hidden={!props.isVisible}>
      <LayoutNotLogged>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <TextSubheader>{t("unfortunately").toUpperCase()}</TextSubheader>
            <TextHeader>{t("problem").toUpperCase()}</TextHeader>
            <Divider />
            <TextInfo>{props.message}</TextInfo>
            <Divider />
            <TextInfo>
              <ThemeLink to="/" text={t("backToLoginPage")} />
            </TextInfo>
            <TextInfo>
              <ThemeLink
                to="/resend-user-confirmation"
                text={t("resendActivationEmail")}
              />
            </TextInfo>
            <TextInfo>
              <ThemeLink to="/register" text={t("registerAgain")} />
            </TextInfo>
            <TextInfo>
              <ThemeLink
                to="/reset-password-request"
                text={t("resetPassword")}
              />
            </TextInfo>
          </Grid>
        </Grid>
      </LayoutNotLogged>
    </PanelContainer>
  );
};

export default ResetPasswordFailed;
