import * as React from "react";
import "Styles/custom-styles-sweetalert2.scss";
import Swal, { SweetAlertResult } from "sweetalert2";

const useModalError: () => (errorResult: string, isHtml?: boolean) => Promise<SweetAlertResult>
= (): (errorResult: string, isHtml?: boolean) => Promise<SweetAlertResult> => {

    const showModalError: (errorResult: string, isHtml?: boolean) => Promise<SweetAlertResult>
    = async (errorResult: string, isHtml?: boolean): Promise<SweetAlertResult> => {
        return await Swal.fire({
            icon: "error",
            text: !isHtml ? errorResult : undefined,
            html: isHtml ? errorResult : undefined,
            customClass: {
                title: "swal-title",
                container: "swal-container",
                content: "swal-content",
                confirmButton: "swal-confirm-button"
            }
        });
      };

    return showModalError;
};

export default useModalError;