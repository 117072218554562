import * as React from "react";
import * as _ from "lodash";
import { Dialog, DialogTitle, Divider, DialogContent, DialogActions } from "@material-ui/core";
import { ILocalization } from "Services/interfaces";
import { useOrderProductApi } from "Services/OrderProductApi/order-product-api";
import { IOrderProductMapProps } from "../interfaces";
import { ApiResultStatus } from "Utils/enums";
import { IOrderProductSetPlaygroundModel } from "Services/OrderProductApi/interfaces-model";
import { IOrderProductGetDetailsResultData, IOrderProductGetDetailsResult } from "Services/OrderProductApi/interfaces-result";
import { useTranslation } from "react-i18next";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";
import MapBasic from "Components/Maps/map-basic";
import useModalSuccess from "Hooks/use-modal-success";
import proj4 from "proj4";
import { EPSG4326_WKT, EPSG2180_WKT } from "Utils/consts";

const OrderProductMap: (props: IOrderProductMapProps) => JSX.Element
    = (props: IOrderProductMapProps): JSX.Element => {
        const { getDetails, setPlayground } = useOrderProductApi();
        const [isOpen, setIsOpen] = React.useState(false);
        const [details, setDetails] = React.useState<IOrderProductGetDetailsResultData>();
        const [playgroudPoints, setPlaygroundPoints] = React.useState<ILocalization[]>([]);
        const [placeRectanglePoints, setPlaceRectanglePoints] = React.useState<ILocalization[]>([]);
        const showModalSuccess: (successResult: string) => void = useModalSuccess(undefined);
        const { t } = useTranslation(["commonResources"]);


        const calculatePoint:
            (pointA: ILocalization, pointB: ILocalization, apiLengthAB: number, apiLengthBC: number,
                rightSite: boolean) => ILocalization
            = (pointA: ILocalization, pointB: ILocalization, apiLengthAB: number, apiLengthBC: number,
                rightSite: boolean): ILocalization => {
                let newPoint: ILocalization = {};

                let longDiff: number = (pointB.longitude ?? 0) - (pointA.longitude ?? 0);
                let latDiff: number = (pointB.latitude ?? 0) - (pointA.latitude ?? 0);
                let lengthAB: number = Math.sqrt(Math.pow(longDiff, 2) + Math.pow(latDiff, 2));
                let ratioAB: number = lengthAB / (apiLengthAB === 0 ? 1 : apiLengthAB);
                let azimuthBC: number = Math.atan2(longDiff, latDiff);

                azimuthBC = rightSite
                    ? (azimuthBC + (Math.PI / 2))
                    : (azimuthBC - (Math.PI / 2));

                newPoint.longitude = (pointB.longitude ?? 0) + apiLengthBC * ratioAB * Math.sin(azimuthBC);
                newPoint.latitude = (pointB.latitude ?? 0) + apiLengthBC * ratioAB * Math.cos(azimuthBC);
                return newPoint;
            };

        const generatePolygon: (newPoint: ILocalization) => void
            = (newPoint: ILocalization): void => {
                let newPlaygroundPoints: ILocalization[] = [...playgroudPoints];
                let pointBEPSG2180: proj4.TemplateCoordinates = proj4(EPSG4326_WKT, EPSG2180_WKT, 
                    [details?.place.rectanglePoint2EPSG4326?.longitude ?? 0, details?.place.rectanglePoint2EPSG4326?.latitude ?? 0]);
                let pointAEPSG2180: proj4.TemplateCoordinates = proj4(EPSG4326_WKT, EPSG2180_WKT, 
                    [details?.place.rectanglePoint1EPSG4326?.longitude ?? 0, details?.place.rectanglePoint1EPSG4326?.latitude ?? 0]);

                let apiLongABDiff: number = pointBEPSG2180[0] - pointAEPSG2180[0];
                let apiLatABDiff: number = pointBEPSG2180[1] - pointAEPSG2180[1];
                let apiLengthAB: number = Math.sqrt(Math.pow(apiLongABDiff, 2) + Math.pow(apiLatABDiff, 2));

                let pointAproj4: proj4.TemplateCoordinates = proj4(EPSG4326_WKT, EPSG2180_WKT, 
                    [newPlaygroundPoints[0].longitude ?? 0, newPlaygroundPoints[0].latitude ?? 0]);
                let pointBproj4: proj4.TemplateCoordinates = proj4(EPSG4326_WKT, EPSG2180_WKT, 
                    [newPoint.longitude ?? 0, newPoint.latitude ?? 0]);
                let pointA: ILocalization = { longitude: pointAproj4[0], latitude: pointAproj4[1] };
                let pointB: ILocalization = { longitude: pointBproj4[0], latitude: pointBproj4[1] };
                let pointC: ILocalization = calculatePoint(
                    pointA, pointB, apiLengthAB, 300, true);
                let pointD: ILocalization = calculatePoint(
                    pointB, pointA, apiLengthAB, 300, false);

                pointBproj4 = proj4(EPSG2180_WKT, EPSG4326_WKT,
                    [pointB.longitude ?? 0, pointB.latitude ?? 0]);
                let pointCproj4: proj4.TemplateCoordinates = proj4(EPSG2180_WKT, EPSG4326_WKT,
                    [pointC.longitude ?? 0, pointC.latitude ?? 0]);
                let pointDproj4: proj4.TemplateCoordinates = proj4(EPSG2180_WKT, EPSG4326_WKT,
                    [pointD.longitude ?? 0, pointD.latitude ?? 0]);
                pointB = { longitude: pointBproj4[0], latitude: pointBproj4[1] };
                pointC = { longitude: pointCproj4[0], latitude: pointCproj4[1] };
                pointD = { longitude: pointDproj4[0], latitude: pointDproj4[1] };
                newPlaygroundPoints.push(pointB, pointC, pointD);
                setPlaceRectanglePoints(newPlaygroundPoints);
            };

        const loadDetails: () => void
            = (): void => {
                getDetails(props.orderProductId)
                    .then((response: IOrderProductGetDetailsResult) => {
                        if (response && response.status === ApiResultStatus.Ok) {
                            setDetails(response.data);
                            let dataObject: IOrderProductGetDetailsResultData = { ...response.data };
                            let dataObjectRectangle: ILocalization[] = dataObject?.playgroundEPSG4326 ?? [];
                            dataObjectRectangle.pop();
                            setPlaceRectanglePoints(dataObjectRectangle);
                            setPlaygroundPoints(dataObjectRectangle);
                        }
                    });
            };

        const handleClear: () => void
            = (): void => {
                setPlaygroundPoints([]);
                setPlaceRectanglePoints([]);
            };

        const handleClose: () => void
            = (): void => {
                setIsOpen(false);
            };

        const handleOpen: () => void
            = (): void => {
                loadDetails();
                setIsOpen(true);
            };

        const handleClickSave: () => Promise<void>
            = async (): Promise<void> => {
                let pointsToSave: ILocalization[] = [...placeRectanglePoints];
                if (pointsToSave.length > 0) {
                    // the first and last point must be the same - duplicate first point and add it to the end of array
                    pointsToSave.push(placeRectanglePoints[0]);
                }
                let model: IOrderProductSetPlaygroundModel = {
                    id: props.orderProductId,
                    playgroundEPSG4326: pointsToSave.length > 0
                        ? pointsToSave
                        : null
                };
                setIsOpen(false);
                await setPlayground(model)
                    .then((response) => {
                        if (response && response.status === ApiResultStatus.Ok) {
                            showModalSuccess(t("saveChangesSuccess"));
                        }
                    });
            };

        const handleMouseMoveMap: (newPoint: ILocalization) => void
            = (newPoint: ILocalization): void => {
                if (playgroudPoints.length === 1) {
                    generatePolygon(newPoint);
                }
            };

        const handleClickMap: (newPoint: ILocalization) => void
            = (newPoint: ILocalization): void => {
                let newPlaygroundPoints: ILocalization[] = [...playgroudPoints];
                if (newPlaygroundPoints.length === 0) {
                    newPlaygroundPoints.push(newPoint);
                } else if (newPlaygroundPoints.length === 1) {
                    newPlaygroundPoints = [...placeRectanglePoints];
                } else {
                    newPlaygroundPoints.length = 0;
                    newPlaygroundPoints.push(newPoint);
                }
                setPlaygroundPoints(newPlaygroundPoints);
            };

        return (
            <React.Fragment>
                <ButtonGridIcon icon="fa-map-marker-alt"
                    color="primary"
                    onClick={handleOpen} />
                <Dialog
                    open={isOpen}
                    onClose={handleClose}
                    scroll={"paper"}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    <DialogTitle>
                        {t("localizationForm.edit")}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <MapBasic
                            pointList={playgroudPoints}
                            rectanglePointList={placeRectanglePoints}
                            handleClickMap={handleClickMap}
                            handleMouseMoveMap={handleMouseMoveMap} />
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <ButtonSecondary onClick={handleClose}>
                            {t("cancel")}
                            </ButtonSecondary>
                        <ButtonSecondary onClick={handleClear}>
                            {t("clear")}
                        </ButtonSecondary>
                        <ButtonPrimary onClick={handleClickSave}>
                            {t("save")}
                            </ButtonPrimary>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    };

export default OrderProductMap;