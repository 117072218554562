import * as React from "react";
import * as Yup from "yup";
import "react-block-ui/style.css";
import { Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { Link, useHistory } from "react-router-dom";
import { Grid, Divider, CircularProgress } from "@material-ui/core";
import { ButtonPrimary } from "Components/Buttons/button-primary";
import { IResetPasswordFinalFormProps } from "Views/interfaces";
import { IUserResetPasswordModel } from "Services/UserApi/interfaces-model";
import { useTranslation } from "react-i18next";
import { useUserApi } from "Services/UserApi/user-api";
import BlockUi from "react-block-ui";
import PanelContainer from "Components/Panels/panel-container";
import useModalSuccess from "Hooks/use-modal-success";
import TextInfo from "Components/Texts/text-info";
import TextHeader from "Components/Texts/text-header";
import TextSubheader from "Components/Texts/text-subheader";
import LayoutNotLogged from "Layouts/layout-not-logged";
import ThemeLink from "Components/Links/theme-link";

const ResetPasswordFinalForm: (
  props: IResetPasswordFinalFormProps
) => JSX.Element = (props: IResetPasswordFinalFormProps): JSX.Element => {
  const { resetPassword } = useUserApi();
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const history = useHistory();

  const handlerAfterResetPasswordFinalForm: () => void = (): void => {
    history.push("/");
  };
  const showModalSuccess: (successResult: string) => void = useModalSuccess(
    handlerAfterResetPasswordFinalForm
  );

  const initialValues: IUserResetPasswordModel = {
    password: "",
    confirmPassword: "",
    token: props.token,
  };

  const handleSave: (model: IUserResetPasswordModel) => Promise<void> = async (
    model: IUserResetPasswordModel
  ): Promise<void> => {
    setLoading(true);
    await resetPassword(model)
      .then((response) => {
        if (response) {
          showModalSuccess(t("resetPasswordForm.successInfo"));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BlockUi blocking={loading} loader={<CircularProgress color="secondary" />}>
      <PanelContainer centerElements={true} hidden={!props.isVisible}>
        <LayoutNotLogged>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <TextSubheader>{t("enterTo")}</TextSubheader>
              <TextHeader>GSOT</TextHeader>
              <Divider />
              <TextInfo>{t("resetPasswordForm.fillForm")}</TextInfo>
              <Divider />
            </Grid>
          </Grid>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              password: Yup.string()
                .min(6, t("validationResources:passwordMinLength"))
                .required(t("validationResources:required")),
              confirmPassword: Yup.string()
                .oneOf(
                  [Yup.ref("password"), null],
                  t("validationResources:passwordEqual")
                )
                .required(t("validationResources:required")),
            })}
            onSubmit={(values, { setSubmitting }) => {
              let formData: IUserResetPasswordModel = {
                password: values.password ?? "",
                confirmPassword: values.confirmPassword ?? "",
                token: props.token,
              };
              handleSave(formData);
              setSubmitting(false);
            }}
          >
            {(formik) => (
              <Form>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid container item xs={12}>
                    <TextField
                      fullWidth
                      label={t("password")}
                      variant="outlined"
                      size="small"
                      type="password"
                      id="password"
                      name="password"
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <TextField
                      fullWidth
                      label={t("passwordRetype")}
                      variant="outlined"
                      size="small"
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
                      {t("reset")}
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <Divider />
          <Grid container justify="flex-end" spacing={1}>
            <Grid item xs={12}>
              <TextInfo>
                {t("accountExists")} <ThemeLink to="/" text={t("logInMe")} />
              </TextInfo>
            </Grid>
            <Grid item xs={12}>
              <TextInfo>
                {t("noActivationEmail")}{" "}
                <ThemeLink to="/resend-user-confirmation" text={t("resend")} />
              </TextInfo>
            </Grid>
          </Grid>
        </LayoutNotLogged>
      </PanelContainer>
    </BlockUi>
  );
};

export default ResetPasswordFinalForm;
