import * as React from "react";
import { IReCaptchaProps } from "./interfaces";
import { stylesReCaptcha } from "./styles";
import ReCAPTCHA from "react-google-recaptcha";

const ReCaptcha: (props: IReCaptchaProps) => JSX.Element = (props: IReCaptchaProps): JSX.Element => {
    const customStyles: Record<string, string> = stylesReCaptcha();

    return (
        <div className={customStyles.root}>
            <ReCAPTCHA ref={el => { props.setRecaptchaRef(el); }}
                sitekey="6LeMh9sUAAAAAIpnMV-PKLM1OEiTCbIYDK5iAFFN"
                onChange={(token: string | null): void => {
                    props.onChangeFunction("recaptchaToken", token);
                }}
            />
        </div>
    );
};

export default ReCaptcha;