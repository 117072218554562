import { IResultBase } from "Services/interfaces";
import { ISBSSceneApiInterface, ISBSSceneData } from "./interfaces";
import {
  ISBSSceneGetDetailsResult,
  ISBSSceneGetListResult,
} from "./interfaces-result";
import {
  ISBSSceneGetListModel,
  ISBSSceneSaveModel,
  ISBSSceneSetStatusModel,
} from "./interfaces-model";
import useBaseDataApi from "Services/base-data-api";
import { useBaseApi } from "Services/base-api";

export const useSBSSceneApi: () => ISBSSceneApiInterface =
  (): ISBSSceneApiInterface => {
    const { baseDeleteById, baseGetDetails, baseGetList, baseSave } =
      useBaseDataApi({ apiControllerName: "SBSScene" });
    const { basePostRequest } = useBaseApi();

    const deleteById: (id: number) => Promise<IResultBase> = async (
      id: number
    ): Promise<IResultBase> => {
      return await baseDeleteById<IResultBase>(id);
    };

    const save: (model: ISBSSceneSaveModel) => Promise<IResultBase> = async (
      model: ISBSSceneSaveModel
    ): Promise<IResultBase> => {
      return await baseSave<ISBSSceneSaveModel, IResultBase>(model);
    };

    const getDetails: (
      id: number
    ) => Promise<ISBSSceneGetDetailsResult> = async (
      id: number
    ): Promise<ISBSSceneGetDetailsResult> => {
      return await baseGetDetails<ISBSSceneGetDetailsResult>(id);
    };

    const getList: (
      model: ISBSSceneGetListModel
    ) => Promise<ISBSSceneGetListResult> = async (
      model: ISBSSceneGetListModel
    ): Promise<ISBSSceneGetListResult> => {
      return await baseGetList<ISBSSceneGetListModel, ISBSSceneGetListResult>(
        model
      );
    };

    const setStatus: (
      model: ISBSSceneSetStatusModel
    ) => Promise<IResultBase> = async (
      model: ISBSSceneSetStatusModel
    ): Promise<IResultBase> => {
      let url: string = "/SBSScene/SetStatus";
      return await basePostRequest<ISBSSceneSetStatusModel, IResultBase>(
        url,
        model
      );
    };
    return { deleteById, save, getDetails, getList, setStatus };
  };
