import { createStyles, makeStyles, Theme } from "@material-ui/core";
import background from "Images/bg_banner.jpg";

export const stylesInfoPortal: () => Record<string, string> = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
      },
      infoContainer: {
        padding: "80px",
      },
      sliderImage: {
        paddingTop: "80px",
        width: "20rem",
        margin: "auto",
        [theme.breakpoints.up("lg")]: {
          width: "30rem",
        },
      },
      titleText: {
        color: "#fff",
        fontFamily: "Poppins",
        fontWeight: "bold",
        paddingTop: "30px",
        paddingBottom: "30px",
      },
      infoText: {
        color: "#fff",
        paddingBottom: "30px",
      },
    })
);
