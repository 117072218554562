import * as React from "react";
import * as _ from "lodash";
import { useUserApi } from "Services/UserApi/user-api";
import { IUserDetailsData } from "Services/UserApi/interfaces";
import { ApiResultStatus } from "Utils/enums";
import { IUserListResult } from "Services/UserApi/interfaces-result";
import { IResultBase } from "Services/interfaces";
import { useTranslation } from "react-i18next";
import useModalQuestion from "Hooks/use-modal-question";
import useModalSuccess from "Hooks/use-modal-success";
import UserCard from "./user-card";

const UserCardList: () => JSX.Element
    = (): JSX.Element => {
        const [userList, setUserList] = React.useState<IUserDetailsData[]>([]);
        const showModalSuccess: (successResult: string) => void = useModalSuccess();
        const { showModalQuestion, setYesFunction } = useModalQuestion();
        const { t } = useTranslation(["commonResources"]);
        const { deleteById, getList } = useUserApi();

        const handleClickDelete: (id: number) => Promise<void>
            = async (id: number): Promise<void> => {
                showModalQuestion(t("userForm.deleteInfo"), id);
            };

        const handleLoadData: () => Promise<void>
            = async (): Promise<void> => {
                await getList({})
                    .then((response: IUserListResult) => {
                        if (response && response.status === ApiResultStatus.Ok) {
                            setUserList(response.data.gridData);
                        }
                    })
            };
        const handleDeleteUser: (id: number) => Promise<void>
            = async (id: number): Promise<void> => {
                await deleteById(id)
                    .then((response: IResultBase) => {
                        if (response && response.status === ApiResultStatus.Ok) {
                            showModalSuccess(t("userForm.deleteSuccess"));
                            handleLoadData();
                        }
                    });
            };

        React.useEffect(() => {
            setYesFunction(() => handleDeleteUser);
            handleLoadData();
        }, []);

        return (
            <React.Fragment>
                {
                    _.map(userList, (value) => {
                        return (<UserCard
                            user={value}
                            key={`card-user-${value.id}`}
                            handleLoadData={handleLoadData}
                            handleClickDelete={handleClickDelete} />);
                    })
                }
            </React.Fragment>
        );
    };

export default UserCardList;