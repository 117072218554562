import { IPlaceApiInterface, IPlaceData } from "./interfaces";
import { IResultBase } from "Services/interfaces";
import { IPlaceGetListModel } from "./interfaces-model";
import { IPlaceGetListResult, IPlaceGetDetailsResult } from "./interfaces-result";
import useBaseDataApi from "Services/base-data-api";


export const usePlaceApi: () => IPlaceApiInterface
    = (): IPlaceApiInterface => {
        const { baseDeleteById, baseGetDetails, baseGetList, baseSave } = useBaseDataApi({ apiControllerName: "Place"});

        const deleteById: (id: number) => Promise<IResultBase>
        = async (id: number): Promise<IResultBase> => {
            return await baseDeleteById<IResultBase>(id);
        };

        const save: (model: IPlaceData) => Promise<IResultBase>
            = async (model: IPlaceData): Promise<IResultBase> => {
                return await baseSave<IPlaceData, IResultBase>(model);
            };

        const getDetails: (id: number) => Promise<IPlaceGetDetailsResult>
            = async (id: number): Promise<IPlaceGetDetailsResult> => {
                return await baseGetDetails<IPlaceGetDetailsResult>(id);
            };

        const getList: (model: IPlaceGetListModel) => Promise<IPlaceGetListResult>
            = async (model: IPlaceGetListModel): Promise<IPlaceGetListResult> => {
                return await baseGetList<IPlaceGetListModel, IPlaceGetListResult>(model);
            };

        return { deleteById, save, getDetails, getList };
    };