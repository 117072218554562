import * as React from "react";
import { useOrderProductApi } from "Services/OrderProductApi/order-product-api";
import { MUIDataTableColumn } from "mui-datatables";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import { OrderProductGridGetListColumn } from "Utils/enums";
import { IOrderProductGetListModel } from "Services/OrderProductApi/interfaces-model";
import { IGridModel } from "Services/interfaces";
import { IOrderProductListProps } from "../interfaces";
import { IOrderProductGetListResult } from "Services/OrderProductApi/interfaces-result";
import { IOrderProductData } from "Services/OrderApi/interfaces";
import { Link, Theme, useTheme, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GridTable from "Components/Grids/grid-table";
import OrderProductMap from "./order-product-map";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import TextInfo from "Components/Texts/text-info";

const OrderProductList: (props: IOrderProductListProps) => JSX.Element = (
  props: IOrderProductListProps
): JSX.Element => {
  const theme: Theme = useTheme();
  const matches: boolean = useMediaQuery(theme.breakpoints.up("lg"));
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const { t } = useTranslation(["commonResources"]);
  const { getList } = useOrderProductApi();

  const columnSortables: string[] = [
    "place.name",
    "productPrice",
    "quantity",
    "totalPrice",
  ];

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "playgroundEPSG4326",
      label: "playground",
      options: {
        display: "false",
      },
    },
    {
      name: "place",
      label: t("name"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id: number = tableMeta.rowData[0];
          return (
            <Link
              color="secondary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.handleChangeOrderProduct(id, value.name);
              }}
            >
              {value.name}
            </Link>
          );
        },
      },
    },
    {
      name: "quantity",
      label: t("quantity"),
    },
    {
      name: "productPrice",
      label: t("productForm.price"),
      options: {
        setCellHeaderProps: () => {
          return {
            float: "right",
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return matches ? (
            <p style={{ textAlign: "right" }}>{value} zł</p>
          ) : (
            `${value} zł`
          );
        },
      },
    },
    {
      name: "totalPrice",
      label: t("totalPrice"),
      options: {
        setCellHeaderProps: () => {
          return {
            float: "right",
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ textAlign: "right" }}>{value} zł</p>;
        },
        display: matches ? "true" : "false",
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let isPlaceRectangle: boolean =
            !!tableMeta.rowData[2].rectangleEPSG4326 &&
            !!tableMeta.rowData[2].rectanglePoint1EPSG4326;
          if (isPlaceRectangle) {
            let id: number = tableMeta.rowData[0];
            return <OrderProductMap orderProductId={id} />;
          } else {
            return (
              <ButtonGridIcon
                icon="fa-exclamation-circle"
                title={t("localizationForm.editError")}
              />
            );
          }
        },
        display: matches ? "true" : "false",
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let isPlayground: boolean = !!tableMeta.rowData[1];
          if (isPlayground) {
            return matches ? (
              <ButtonGridIcon
                icon="fa-check-circle"
                title={t("localPointsDefined")}
                color="primary"
              />
            ) : (
              <TextInfo>{t("pointsDefined")}</TextInfo>
            );
          } else {
            return matches ? (
              <ButtonGridIcon
                icon="fa-times-circle"
                title={t("localizationForm.localPointsNotDefined")}
                color="secondary"
              />
            ) : (
              <TextInfo>{t("pointsNotDefined")}</TextInfo>
            );
          }
        },
      },
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "product.name":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = OrderProductGridGetListColumn.Product;
        break;
      case "productPrice":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn = OrderProductGridGetListColumn.ProductPrice;
        break;
      case "quantity":
        newSortParams.columnIndex = 2;
        newSortParams.orderColumn = OrderProductGridGetListColumn.Quantity;
        break;
      case "totalPrice":
        newSortParams.columnIndex = 3;
        newSortParams.orderColumn = OrderProductGridGetListColumn.TotalPrice;
        break;
    }

    return newSortParams;
  };

  const generateParams: (
    tableState: IGridModel
  ) => IOrderProductGetListModel = (
    tableState: IGridModel
  ): IOrderProductGetListModel => {
    return {
      gsOrderId: props.orderId,
      ...tableState,
    };
  };

  React.useEffect(() => {
    if (props.orderId > 0) {
      dispatchReloadTable();
    }
  }, [props.orderId]);

  return (
    <React.Fragment>
      <GridTable<
        IOrderProductGetListModel,
        IOrderProductData,
        IOrderProductGetListResult
      >
        reloadCount={reloadTable}
        columns={columns}
        columnsSortableNames={columnSortables}
        generateSortParams={generateSortParams}
        generateParams={generateParams}
        getList={getList}
      />
    </React.Fragment>
  );
};

export default OrderProductList;
