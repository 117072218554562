import * as React from "react";
import { getStoredUserAuthToken } from "Utils/helpers";
import { IContextAuthInterface, IAuthProviderProps } from "./intefaces";
import { DEFAULT_USER_AUTH_TOKEN } from "Utils/consts";
import useAuthorization from "Hooks/use-auth-token";

export const authContext: React.Context<IContextAuthInterface> = React.createContext<IContextAuthInterface>({
    authToken: DEFAULT_USER_AUTH_TOKEN,
    setUser: () => {},
    setAuthorize: () => { },
    setUnauthorize: () => { }
});

const { Provider } = authContext;

export const AuthProvider: (props: IAuthProviderProps) => JSX.Element
    = (props: IAuthProviderProps): JSX.Element => {
        const { authToken, setUser, setAuthorize, setUnauthorize } = useAuthorization(getStoredUserAuthToken());

        return (
            <Provider value={{ authToken, setUser, setAuthorize, setUnauthorize: setUnauthorize }}>
                {props.children}
            </Provider>
        );
    };