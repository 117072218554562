import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import GridTable from "Components/Grids/grid-table";
import useModalQuestion from "Hooks/use-modal-question";
import useModalSuccess from "Hooks/use-modal-success";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import React from "react";
import { useTranslation } from "react-i18next";
import { IGridModel, ILocalization, IResultBase } from "Services/interfaces";
import { ISBSSceneObjectTimelineData } from "Services/SBSSceneObjectTimelineApi/interfaces";
import { ISBSSceneObjectTimelineGetListModel } from "Services/SBSSceneObjectTimelineApi/interfaces-model";
import { ISBSSceneObjectTimelineGetListResult } from "Services/SBSSceneObjectTimelineApi/interfaces-result";
import useSBSSceneObjectTimelineApi from "Services/SBSSceneObjectTimelineApi/sbs-scene-object-timeline-api";
import { ApiResultStatus, ObjectType } from "Utils/enums";
import { ISBSSceneObjectTimelineListGridProps } from "../interfaces";
import SBSSceneObjectTimelineEditorAdmin from "../SceneObjectTimelineEdiotr/sbs-scene-object-timeline-editor-admin";

const SBSSceneObjectTimelineListGrid: (
  props: ISBSSceneObjectTimelineListGridProps
) => JSX.Element = (
  props: ISBSSceneObjectTimelineListGridProps
): JSX.Element => {
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { deleteById, getList } = useSBSSceneObjectTimelineApi();
  const { t } = useTranslation(["commonResources"]);

  const deleteSceneObjectTimeline: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await deleteById(id).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("animationForm.deleteSuccess"));
        props.handleReloadTable();
      }
    });
  };

  const handleClickDelete: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    showModalQuestion(t("animationForm.deleteInfo"), id);
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
        download: false,
      },
    },
    {
      name: "startTimeInSeconds",
      label: t("animationForm.startTime"),
    },
    {
      name: "endTimeInSeconds",
      label: t("animationForm.endTime"),
    },
    {
      name: "caption",
      label: t("caption"),
      options: {
        display: props.sceneObjectType !== ObjectType.Sound ? "true" : "false",
      },
    },
    {
      name: "labelOnMap",
      label: t("labelOnMap"),
      options: {
        display: props.sceneObjectType !== ObjectType.Sound ? "true" : "false",
      },
    },
    {
      name: "startLocalizationEPSG4326",
      label: t("animationForm.localizationStart"),
      options: {
        display: props.sceneObjectType !== ObjectType.Sound ? "true" : "false",
        customBodyRender: (value: ILocalization, tableMeta, updateValue) => {
          return `L: ${value?.longitude ?? "-"} B: ${
            value?.latitude ?? "-"
          } h: ${value?.altitude ?? "-"}`;
        },
      },
    },
    {
      name: "endLocalizationEPSG4326",
      label: t("animationForm.localizationEnd"),
      options: {
        display: props.sceneObjectType !== ObjectType.Sound ? "true" : "false",
        customBodyRender: (value: ILocalization, tableMeta, updateValue) => {
          return `L: ${value?.longitude ?? "-"} B: ${
            value?.latitude ?? "-"
          } h: ${value?.altitude ?? "-"}`;
        },
      },
    },
    {
      name: "animationIndex",
      label: t("animationForm.indexShort"),
      options: {
        display:
          props.sceneObjectType === ObjectType.Model3D ? "true" : "false",
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[0] && !props.disabled) {
            return (
              <SBSSceneObjectTimelineEditorAdmin
                sbsSceneObjectType={props.sceneObjectType}
                sbsSceneObjectId={props.sbsSceneObjectId}
                sbsSceneObjectTimelineId={tableMeta.rowData[0]}
                handleSaveSuccess={props.handleReloadTable}
                handleClose={props.handleCloseEditor}
                isOpen={props.isOpenEditor}
                withIcon
              />
            );
          }
        },
        download: false,
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!props.disabled) {
            return (
              <ButtonGridIcon
                icon="fa-trash-alt"
                color="primary"
                onClick={() => {
                  handleClickDelete(tableMeta.rowData[0]);
                }}
              />
            );
          }
        },
        download: false,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    search: false,
    sort: false,
  };

  const generateParams: (
    tableState: IGridModel
  ) => ISBSSceneObjectTimelineGetListModel = (
    tableState: IGridModel
  ): ISBSSceneObjectTimelineGetListModel => {
    return {
      GSSBSSceneObjectId: props.sbsSceneObjectId,
      ...tableState,
    };
  };

  React.useEffect(() => {
    setYesFunction(() => deleteSceneObjectTimeline);
  }, []);

  return (
    <GridTable<
      ISBSSceneObjectTimelineGetListModel,
      ISBSSceneObjectTimelineData,
      ISBSSceneObjectTimelineGetListResult
    >
      reloadCount={props.reloadTable}
      columns={columns}
      customTableOptions={options}
      generateParams={generateParams}
      getList={getList}
    />
  );
};

export default SBSSceneObjectTimelineListGrid;
