import * as React from "react";
import { IPlacePanelState } from "../interfaces";
import { ObjectType } from "Utils/enums";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GridContainer from "Components/Grids/grid-container";
import PanelExpansion from "Components/Panels/panel-expansion";
import PlaceList from "../PlaceList/place-list";
import SceneList from "Components/Scenes/SceneList/scene-list";
import SceneObjectList from "Components/SceneObjects/SceneObjectList/scene-object-list";
import SceneObjectTimelineList from "Components/SceneObjectTimelines/SceneObjectTimelineList/scene-object-timeline-list";
import { IBaseListItem } from "Services/interfaces";

const PlacePanel: () => JSX.Element = (): JSX.Element => {
  const panelStateReducer: (
    panelState: IPlacePanelState,
    newPanelState: IPlacePanelState
  ) => IPlacePanelState = (
    panelState: IPlacePanelState,
    newPanelState: IPlacePanelState
  ): IPlacePanelState => {
    return { ...panelState, ...newPanelState };
  };
  const [panelState, dispatchPanelState] = React.useReducer(
    panelStateReducer,
    {} as IPlacePanelState
  );
  const { t } = useTranslation(["commonResources"]);

  const generateTitle: (
    placeText?: string,
    sceneText?: string,
    sceneObjectText?: string
  ) => string = (
    placeText?: string,
    sceneText?: string,
    sceneObjectText?: string
  ): string => {
    let newPlaceText: string =
      placeText ?? panelState.selectedPlaceText
        ? `${t("place")}: ${placeText ?? panelState.selectedPlaceText}`
        : "";
    let newSceneText: string =
      sceneText ?? panelState.selectedSceneText
        ? ` - ${t("scene")}: ${sceneText ?? panelState.selectedSceneText}`
        : "";
    let newSceneObjectText: string =
      sceneObjectText ?? panelState.selectedSceneObjectText
        ? ` - ${t("object")}: ${
            sceneObjectText ?? panelState.selectedSceneObjectText
          }`
        : "";
    return `${newPlaceText}${newSceneText}${newSceneObjectText}`;
  };

  const handleChangeExpandPlacePanel: (expanded: boolean) => void = (
    expanded: boolean
  ): void => {
    let newPanelState: IPlacePanelState = {
      isPlaceExpanded: expanded,
      isSceneExpanded: false,
      isSceneObjectExpanded: false,
      isSceneObjectTimelineExpanded: false,
    };
    dispatchPanelState(newPanelState);
  };

  const handleChangeExpandScenePanel: (expanded: boolean) => void = (
    expanded: boolean
  ): void => {
    let newPanelState: IPlacePanelState = {
      isPlaceExpanded: false,
      isSceneExpanded: expanded,
      isSceneObjectExpanded: false,
      isSceneObjectTimelineExpanded: false,
    };
    dispatchPanelState(newPanelState);
  };

  const handleChangeExpandSceneObjectPanel: (expanded: boolean) => void = (
    expanded: boolean
  ): void => {
    let newPanelState: IPlacePanelState = {
      isPlaceExpanded: false,
      isSceneExpanded: false,
      isSceneObjectExpanded: expanded,
      isSceneObjectTimelineExpanded: false,
    };
    dispatchPanelState(newPanelState);
  };

  const handleChangeExpandSceneObjectTimelinePanel: (
    expanded: boolean
  ) => void = (expanded: boolean): void => {
    let newPanelState: IPlacePanelState = {
      isPlaceExpanded: false,
      isSceneExpanded: false,
      isSceneObjectExpanded: false,
      isSceneObjectTimelineExpanded: expanded,
    };
    dispatchPanelState(newPanelState);
  };

  const handleChangePlace: (
    placeId: number,
    placeText: string,
    placeIsAvailableInDemo: boolean,
    groupList: IBaseListItem[]
  ) => void = (
    placeId: number,
    placeText: string,
    placeIsAvailableInDemo: boolean,
    groupList: IBaseListItem[]
  ): void => {
    let newPanelState: IPlacePanelState = {
      groupList: groupList,
      selectedPlaceId: placeId,
      selectedPlaceText: placeText,
      selectedPlaceIsAvailableInDemo: placeIsAvailableInDemo,
      selectedSceneId: undefined,
      selectedSceneText: undefined,
      selectedSceneObjectId: undefined,
      selectedSceneObjectText: undefined,
      selectedSceneObjectType: undefined,
      isPlaceExpanded: false,
      isSceneExpanded: true,
      isSceneObjectExpanded: false,
      isSceneObjectTimelineExpanded: false,
      titleText: generateTitle(placeText, "", ""),
    };
    dispatchPanelState(newPanelState);
  };

  const handleChangeScene: (sceneId: number, sceneText: string) => void = (
    sceneId: number,
    sceneText: string
  ): void => {
    let newPanelState: IPlacePanelState = {
      selectedSceneId: sceneId,
      selectedSceneText: sceneText,
      selectedSceneObjectId: undefined,
      selectedSceneObjectText: undefined,
      selectedSceneObjectType: undefined,
      isPlaceExpanded: false,
      isSceneExpanded: false,
      isSceneObjectExpanded: true,
      isSceneObjectTimelineExpanded: false,
      titleText: generateTitle(undefined, sceneText, ""),
    };
    dispatchPanelState(newPanelState);
  };

  const handleChangeSceneObject: (
    sceneObjectId: number,
    sceneObjectText: string,
    sceneObjectType: ObjectType
  ) => void = (
    sceneObjectId: number,
    sceneObjectText: string,
    sceneObjectType: ObjectType
  ): void => {
    let newPanelState: IPlacePanelState = {
      selectedSceneObjectId: sceneObjectId,
      selectedSceneObjectText: sceneObjectText,
      selectedSceneObjectType: sceneObjectType,
      isPlaceExpanded: false,
      isSceneExpanded: false,
      isSceneObjectExpanded: false,
      isSceneObjectTimelineExpanded: true,
      titleText: generateTitle(undefined, undefined, sceneObjectText),
    };
    dispatchPanelState(newPanelState);
  };

  return (
    <React.Fragment>
      <GridContainer title={panelState.titleText ?? ""} titleSize={"sm"}>
        <Grid item container xs={12}>
          <PanelExpansion
            isExpanded={panelState.isPlaceExpanded ?? false}
            handleChangeExpand={handleChangeExpandPlacePanel}
            title={t("placeForm.listTitle")}
          >
            <PlaceList handlePlaceChange={handleChangePlace} />
          </PanelExpansion>
          <PanelExpansion
            isExpanded={panelState.isSceneExpanded ?? false}
            isDisabled={!panelState.selectedPlaceId}
            handleChangeExpand={handleChangeExpandScenePanel}
            title={t("placeForm.listSceneTitle")}
          >
            <SceneList
              handleSceneChange={handleChangeScene}
              placeId={panelState.selectedPlaceId ?? 0}
              placeIsAvailableInDemo={
                panelState.selectedPlaceIsAvailableInDemo ?? false
              }
              groupList={panelState.groupList ?? []}
              isExpanded={panelState.isSceneExpanded ?? false}
            />
          </PanelExpansion>
          <PanelExpansion
            isExpanded={panelState.isSceneObjectExpanded ?? false}
            isDisabled={!panelState.selectedSceneId}
            handleChangeExpand={handleChangeExpandSceneObjectPanel}
            title={t("placeForm.listObjectTitle")}
          >
            <SceneObjectList
              sceneId={panelState.selectedSceneId ?? 0}
              handleSceneObjectChange={handleChangeSceneObject}
            />
          </PanelExpansion>
          <PanelExpansion
            isExpanded={panelState.isSceneObjectTimelineExpanded ?? false}
            isDisabled={!panelState.selectedSceneObjectId}
            handleChangeExpand={handleChangeExpandSceneObjectTimelinePanel}
            title={t("placeForm.listAnimationTitle")}
          >
            <SceneObjectTimelineList
              sceneObjectId={panelState.selectedSceneObjectId ?? 0}
              sceneObjectType={
                panelState.selectedSceneObjectType ?? ObjectType.Model3D
              }
            />
          </PanelExpansion>
        </Grid>
      </GridContainer>
    </React.Fragment>
  );
};

export default PlacePanel;
