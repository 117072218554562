import * as React from "react";
import { Button } from "@material-ui/core";
import { stylesButtonSecondary } from "./styles";
import { IButtonPrimaryProps } from "./interfaces";

export const ButtonSecondary: (props: IButtonPrimaryProps) => JSX.Element = (props: IButtonPrimaryProps): JSX.Element => {
    const customStyles: Record<string, string> = stylesButtonSecondary();
    const { children, size, ...other } = props;

    return (
        <Button classes={{
            root: customStyles.main,
            disabled: customStyles.disabled }} {...other}>
            {children}
        </Button>
    );
};

export default ButtonSecondary;