import * as React from "react";
import * as _ from "lodash";
import { IOrderProductData } from "Services/OrderApi/interfaces";
import { IProductData } from "Services/ProductApi/interfaces";
import { MethodType, OrderPaymentType, ApiResultStatus } from "Utils/enums";
import { useOrderApi } from "Services/OrderApi/order-api";
import { IOrderAddModel } from "Services/OrderApi/interfaces-model";
import { IOrderAddResult } from "Services/OrderApi/interfaces-result";
import { Grid, Hidden } from "@material-ui/core";
import ProductList from "Components/Products/ProductList/product-list";
import OrderCart from "./order-cart";
import useModalSuccess from "Hooks/use-modal-success";
import ProductCardList from "Components/Products/ProductList/product-card-list";
import GridContainer from "Components/Grids/grid-container";
import { useTranslation } from "react-i18next";

const OrderCreator: () => JSX.Element
    = (): JSX.Element => {
        const [productsInCart, setProductsInCart] = React.useState<IProductData[]>([]);
        const showModalSuccess: (successResult: string) => void = useModalSuccess();
        const { t } = useTranslation(["commonResources"]);
        const { add } = useOrderApi();

        const handleAddToCart: (product: IProductData) => void
            = (product: IProductData): void => {
                let newProductList: IProductData[] = [];
                const productAlreadyInCartIndex: number = _.findIndex(productsInCart, (p) => { return p.id === product.id; });
                if (productAlreadyInCartIndex > -1) {
                    newProductList = [...productsInCart];
                    newProductList[productAlreadyInCartIndex].quantity =
                        (newProductList[productAlreadyInCartIndex].quantity ?? 0) +
                        (product.quantity ?? 0);
                } else {
                    newProductList = [...productsInCart, product];
                }
                setProductsInCart(newProductList);
            };

        const handleChangeQuantity: (productId: number, method: MethodType) => void
            = (productId: number, method: MethodType): void => {
                const productIndex: number = _.findIndex(productsInCart, (p) => { return p.id === productId; });
                if (productIndex > -1) {
                    let newProductList: IProductData[] = [...productsInCart];
                    let newQuantity: number = (newProductList[productIndex].quantity ?? 0) +
                        (method === MethodType.Increment ? 1 : -1);
                    if (newQuantity === 0) {
                        _.remove(newProductList, (p) => { return p.id === productId; });
                    } else {
                        newProductList[productIndex].quantity = newQuantity;
                    }
                    setProductsInCart(newProductList);
                }
            };

        const mapToOrderProduct: (product: IProductData) => IOrderProductData
            = (product: IProductData): IOrderProductData => {
                return {
                    gsProductId: product.id ?? 0,
                    quantity: product.quantity ?? 0
                };
            };

        const handleFinilize: (paymentType: OrderPaymentType, languageId: number) => Promise<void>
            = async (paymentType: OrderPaymentType, languageId: number): Promise<void> => {
                let productListToSave: IOrderProductData[] = _.map(productsInCart, mapToOrderProduct);
                let model: IOrderAddModel = {
                    paymentType: paymentType,
                    products: productListToSave,
                    gsLanguageId: languageId
                };
                await add(model)
                    .then((response: IOrderAddResult) => {
                        if (response && response.status === ApiResultStatus.Ok) {
                            setProductsInCart([]);
                            if (response.data.redirectUrl) {
                                window.location.href = response.data.redirectUrl;
                            }
                            showModalSuccess(t("orderForm.addSuccess"));
                        }
                    });
            };

        const handleRemoveFromCart: (productId: number) => void
            = (productId: number): void => {
                let newProductList: IProductData[] = [...productsInCart];
                _.remove(newProductList, (p) => { return p.id === productId; });
                setProductsInCart(newProductList);
            };

        return (
            <React.Fragment>
                <GridContainer title={t("orderForm.title")} >
                    <Grid container spacing={2}>
                        <Hidden lgUp>
                            <Grid item xs={12} md={4}>
                                <OrderCart
                                    productList={productsInCart}
                                    handleRemoveFromCart={handleRemoveFromCart}
                                    handleChangeQuantity={handleChangeQuantity}
                                    handleFinilize={handleFinilize}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <ProductCardList handleAddToCart={handleAddToCart} />
                            </Grid>
                        </Hidden>
                        <Hidden mdDown>
                            <Grid item md={8}>
                                <ProductList adminMode={false} handleAddToCart={handleAddToCart} />
                            </Grid>
                            <Grid item md={4}>
                                <OrderCart
                                    productList={productsInCart}
                                    handleRemoveFromCart={handleRemoveFromCart}
                                    handleChangeQuantity={handleChangeQuantity}
                                    handleFinilize={handleFinilize}
                                />
                            </Grid>
                        </Hidden>
                    </Grid>
                </GridContainer>
            </React.Fragment>
        )
    };

export default OrderCreator;