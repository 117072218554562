import * as React from "react";
import "react-block-ui/style.css";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { IUserConfirmModel } from "Services/UserApi/interfaces-model";
import { useUserApi } from "Services/UserApi/user-api";
import RegisterSuccess from "./register-success";
import RegisterFailed from "./register-failed";
import BlockUi from "react-block-ui";
import PanelContainer from "Components/Panels/panel-container";

const RegisterConfirm: () => JSX.Element = (): JSX.Element => {
    const { confirm } = useUserApi();
    const [loading, setLoading] = React.useState(true);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const { token } = useParams();

    React.useEffect(() => {
        const handleConfirm: () => Promise<void> = async (): Promise<void> => {
            if (token) {
                let model: IUserConfirmModel = {
                    token: token
                };
                setLoading(true);
                await confirm(model)
                    .then((response) => {
                        if (response) {
                            setIsSuccess(true);
                        } else {
                            setIsSuccess(false);
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            setLoading(false);
                        }, 500);
                    });
            }
        };
        handleConfirm();
    }, [token]);

    return (
        <BlockUi blocking={loading} loader={<CircularProgress color="secondary" />} >
            <PanelContainer centerElements={true}>
                <RegisterSuccess isVisible={!loading && isSuccess} />
                <RegisterFailed message={message} isVisible={!loading && !isSuccess} />
            </PanelContainer>
        </BlockUi>
    );
};

export default RegisterConfirm;