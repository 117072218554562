import * as React from "react";
import { PaperProps, Paper } from "@material-ui/core";
import { stylesPanel } from "./styles";

const PanelBasic: (props: PaperProps) => JSX.Element = (props: PaperProps): JSX.Element => {
    const customStyles: Record<string, string> = stylesPanel();
    const {children, ...other} = props;

    return (
        <Paper {...other} className={customStyles.root}>
            {children}
        </Paper>
    );
}

export default PanelBasic;