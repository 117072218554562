import * as React from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { ISceneObjectData } from "Services/SceneObjectApi/interfaces";
import { ISceneObjectGetDetailsResult } from "Services/SceneObjectApi/interfaces-result";
import { ApiResultStatus } from "Utils/enums";
import { ISceneObjectEditorAdminProps } from "../interfaces";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";
import useModalSuccess from "Hooks/use-modal-success";
import useSceneObjectApi from "Services/SceneObjectApi/scene-object-api";

const SceneObjectEditorAdmin: (
  props: ISceneObjectEditorAdminProps
) => JSX.Element = (props: ISceneObjectEditorAdminProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const showModalSuccess: (successResult: string) => void = useModalSuccess(
    undefined
  );
  const { save, getDetails } = useSceneObjectApi();
  const [values, setValues] = React.useState<ISceneObjectData>({
    description: "",
    isAvailableInTableSceneUsingMode: false,
  });
  const { t } = useTranslation(["commonResources", "validationResources"]);

  const handleLoadData: () => Promise<void> = React.useCallback(async () => {
    if (props.sceneObjectId) {
      return await getDetails(props.sceneObjectId).then(
        (response: ISceneObjectGetDetailsResult) => {
          if (response && response.data) {
            setValues(response.data);
          }
        }
      );
    }
  }, [props.sceneObjectId]);

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    if (props.sceneObjectId) {
      handleLoadData();
    }
    setIsOpen(true);
  };

  const handleSave: (sceneObject: ISceneObjectData) => Promise<void> = async (
    place: ISceneObjectData
  ): Promise<void> => {
    await save(place).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("saveChangesSuccess"));
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
        handleClose();
      }
    });
  };

  return (
    <React.Fragment>
      <ButtonGridIcon icon={"fa-edit"} onClick={handleOpen} color="secondary" />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>{t("sceneObjectForm.edit")}</DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<ISceneObjectData>({
              description: Yup.string()
                .max(1000, t("validationResources:descriptionMaxLength"))
                .nullable(),
            })}
            onSubmit={(values: ISceneObjectData) => {
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid
                  container
                  justify="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.description ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.description &&
                            formik.touched.description
                          )
                        }
                        helperText={
                          formik.errors.description &&
                          formik.touched.description &&
                          formik.errors.description
                        }
                        label={t("description")}
                        variant="outlined"
                        size="small"
                        name="description"
                        multiline
                        rowsMax="5"
                        rows="5"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                formik.values
                                  .isAvailableInTableSceneUsingMode ?? false
                              }
                              onChange={formik.handleChange}
                              value={
                                formik.values
                                  .isAvailableInTableSceneUsingMode ?? false
                              }
                              name="isAvailableInTableSceneUsingMode"
                            />
                          }
                          label={t("isAvailableInTableSceneUsingMode")}
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SceneObjectEditorAdmin;
