import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from "./routes";
import { UserRole } from "Utils/enums";
import ConfigurationEditorAdmin from "Components/Configurations/ConfigurationEditors/configuration-editor-admin";
import ProductListContainer from "Components/Products/ProductList/product-list-container";
import OrderRewardRangeList from "Components/Orders/OrderRewardRangeList/order-reward-range-list";
import ObjectListContainer from "Components/Object/ObjectList/object-list-container";
import RouteAlreadyLogged from "./route-already-logged";
import RouteSimpleConfig from "./route-simple-config";
import UserListContainer from "Components/Users/UserList/user-list-container";
import RoutePrivate from "./route-private";
import OrderCreator from "Components/Orders/OrderCreator/order-creator";
import EventLogList from "Components/EventLogs/EventLogList/event-log-list";
import UserDetails from "Components/Users/UserDetails/user-details";
import PlacePanel from "Components/Places/PlacePanel/place-panel";
import AdvertList from "Components/Adverts/AdvertList/advert-list";
import LayoutBase from "Layouts/layout-base";
import OrderPanel from "Components/Orders/OrderPanels/order-panel";
import Login from "Views/Main/login";
import ApplicationList from "Components/Applications/ApplicationList/application-list";
import OrderProductLicensePanel from "Components/Orders/OrderPanels/order-product-license-panel";
import InfoPortal from "Components/Info/info-portal";
import SbsObjectListContainer from "Components/Object/ObjectList/sbs-object-list-container";
import SBSSceneEditorAdmin from "Components/Scenes/SceneEditor/sbs-scene-editor-admin";
import SbsSceneListContainer from "Components/Scenes/SceneList/sbs-scene-list-container";
import SBSSceneObjectList from "Components/SceneObjects/SceneObjectList/sbs-scene-object-list";
import NotificationList from "Components/Notifications/NotificationList/notification-list";

const RouterApp: () => JSX.Element = (): JSX.Element => {
  return (
    <Router>
      <Switch>
        <RouteAlreadyLogged exact path="/login" childrenElement={Login} />
        <Redirect exact from="/" to={PublicRoutes.login} />
        <RoutePrivate
          exact
          path={PrivateRoutes.info}
          layoutElement={LayoutBase}
          childrenElement={InfoPortal}
          childrenType={PrivateRoutes.info}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.yourAccount}
          layoutElement={LayoutBase}
          childrenElement={UserDetails}
          childrenType={PrivateRoutes.yourAccount}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.sbsObjects}
          layoutElement={LayoutBase}
          childrenElement={SbsObjectListContainer}
          childrenType={PrivateRoutes.sbsObjects}
          userRoleAccessList={[UserRole.Admin, UserRole.SBS]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.orders}
          layoutElement={LayoutBase}
          childrenElement={OrderPanel}
          childrenType={PrivateRoutes.orders}
          userRoleAccessList={[
            UserRole.Standard,
            UserRole.Admin,
            UserRole.Partner,
          ]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.orderCreator}
          layoutElement={LayoutBase}
          childrenElement={OrderCreator}
          childrenType={PrivateRoutes.orderCreator}
          userRoleAccessList={[UserRole.Standard]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.users}
          layoutElement={LayoutBase}
          childrenElement={UserListContainer}
          childrenType={PrivateRoutes.users}
          userRoleAccessList={[UserRole.Admin]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.applications}
          layoutElement={LayoutBase}
          childrenElement={ApplicationList}
          childrenType={PrivateRoutes.applications}
          userRoleAccessList={[UserRole.Admin]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.places}
          layoutElement={LayoutBase}
          childrenElement={PlacePanel}
          childrenType={PrivateRoutes.places}
          userRoleAccessList={[UserRole.Admin]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.objects}
          layoutElement={LayoutBase}
          childrenElement={ObjectListContainer}
          childrenType={PrivateRoutes.objects}
          userRoleAccessList={[UserRole.Admin]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.products}
          layoutElement={LayoutBase}
          childrenElement={ProductListContainer}
          childrenType={PrivateRoutes.products}
          userRoleAccessList={[UserRole.Admin]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.configurations}
          layoutElement={LayoutBase}
          childrenElement={ConfigurationEditorAdmin}
          childrenType={PrivateRoutes.configurations}
          userRoleAccessList={[UserRole.Admin]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.orderRewards}
          layoutElement={LayoutBase}
          childrenElement={OrderRewardRangeList}
          childrenType={PrivateRoutes.orderRewards}
          userRoleAccessList={[UserRole.Admin]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.notificationList}
          layoutElement={LayoutBase}
          childrenElement={NotificationList}
          childrenType={PrivateRoutes.notificationList}
          userRoleAccessList={[UserRole.Admin]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.eventLog}
          layoutElement={LayoutBase}
          childrenElement={EventLogList}
          childrenType={PrivateRoutes.eventLog}
          userRoleAccessList={[UserRole.Admin, UserRole.Partner]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.adverts}
          layoutElement={LayoutBase}
          childrenElement={AdvertList}
          childrenType={PrivateRoutes.adverts}
          userRoleAccessList={[UserRole.Admin]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.ordersLicense}
          layoutElement={LayoutBase}
          childrenElement={OrderProductLicensePanel}
          childrenType={PrivateRoutes.ordersLicense}
          userRoleAccessList={[UserRole.Admin, UserRole.Standard]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.sbsScenes}
          layoutElement={LayoutBase}
          childrenElement={SbsSceneListContainer}
          childrenType={PrivateRoutes.sbsScenes}
          userRoleAccessList={[UserRole.Admin, UserRole.SBS]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.sbsSceneEditor}
          layoutElement={LayoutBase}
          childrenElement={SBSSceneEditorAdmin}
          childrenType={PrivateRoutes.sbsSceneEditor}
          userRoleAccessList={[UserRole.Admin, UserRole.SBS]}
        />
        <RoutePrivate
          exact
          path={PrivateRoutes.sbsSceneObjects}
          layoutElement={LayoutBase}
          childrenElement={SBSSceneObjectList}
          childrenType={PrivateRoutes.sbsSceneObjects}
          userRoleAccessList={[UserRole.Admin, UserRole.SBS]}
        />
        <RouteSimpleConfig />
      </Switch>
    </Router>
  );
};

export default RouterApp;
