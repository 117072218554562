import * as React from "react";
import { Grid, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { IConfirmProps } from "Views/interfaces";
import { useTranslation } from "react-i18next";
import PanelContainer from "Components/Panels/panel-container";
import TextInfo from "Components/Texts/text-info";
import TextHeader from "Components/Texts/text-header";
import TextSubheader from "Components/Texts/text-subheader";
import LayoutNotLogged from "Layouts/layout-not-logged";
import ThemeLink from "Components/Links/theme-link";

const RegisterSuccess: (props: IConfirmProps) => JSX.Element = (
  props: IConfirmProps
): JSX.Element => {
  const { t } = useTranslation(["commonResources"]);

  return (
    <PanelContainer centerElements={true} hidden={!props.isVisible}>
      <LayoutNotLogged>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <TextSubheader>{t("welcomeIn")}</TextSubheader>
            <TextHeader>GSOT</TextHeader>
            <Divider />
            <TextInfo>{t("registrationForm.successActivationInfo")}</TextInfo>
            <Divider />
            <TextInfo>
              <ThemeLink to="/login" text={t("backToLoginPage")} />
            </TextInfo>
          </Grid>
        </Grid>
      </LayoutNotLogged>
    </PanelContainer>
  );
};

export default RegisterSuccess;
