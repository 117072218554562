import * as React from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ILanguageData } from "Services/LanguageApi/interfaces";
import { useLanguageApi } from "Services/LanguageApi/language-api";
import { DEFAULT_LANGUAGE_ID } from "Utils/consts";
import { getSelectedLanguageId } from "Utils/helpers";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";
import { IOrderAddModel } from "Services/OrderApi/interfaces-model";
import { ApiResultStatus, OrderPaymentType } from "Utils/enums";
import { ILanguageGetListResult } from "Services/LanguageApi/interfaces-result";
import { useProductApi } from "Services/ProductApi/product-api";
import { IProductGetListResult } from "Services/ProductApi/interfaces-result";
import { Form, Formik, getIn } from "formik";
import {
  IOrderCreatorAdminAddModel,
  IOrderCreatorAdminProps,
} from "../interfaces";
import { useOrderApi } from "Services/OrderApi/order-api";
import { IOrderAddResult } from "Services/OrderApi/interfaces-result";
import useModalSuccess from "Hooks/use-modal-success";
import { IProductData } from "Services/ProductApi/interfaces";

const OrderCreatorAdmin: (props: IOrderCreatorAdminProps) => JSX.Element = (
  props: IOrderCreatorAdminProps
): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] =
    React.useState<number>(DEFAULT_LANGUAGE_ID);
  const [languages, setLanguages] = React.useState<ILanguageData[]>([]);
  const [products, setProducts] = React.useState<IProductData[]>([]);
  const { t } = useTranslation(["commonResources", "enumResources"]);
  const { getList: getLanguageList } = useLanguageApi();
  const { getList } = useProductApi();
  const { add } = useOrderApi();
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const [values, setValues] = React.useState<IOrderCreatorAdminAddModel>({
    description: "",
    product: {
      gsProductId: 0,
      quantity: 0,
    },
  });

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    setIsOpen(true);
  };

  const handleSave: (order: IOrderCreatorAdminAddModel) => Promise<void> =
    async (order: IOrderCreatorAdminAddModel): Promise<void> => {
      let newOrder: IOrderAddModel = {
        gsLanguageId: selectedLanguage,
        paymentType: OrderPaymentType.None,
        description: order.description,
        products: [order.product],
      };

      await add(newOrder).then((response: IOrderAddResult) => {
        if (response && response.status === ApiResultStatus.Ok) {
          showModalSuccess(t("licenseForm.addSuccess"));
          setIsOpen(false);
          if (props.handleSaveSuccess) {
            props.handleSaveSuccess();
          }
        }
      });
    };

  const loadLanguages: () => Promise<void> = async () => {
    return await getLanguageList().then((response: ILanguageGetListResult) => {
      if (response?.data) {
        setLanguages(response.data);
      }
    });
  };

  const loadProducts: () => Promise<void> = async () => {
    return await getList({}).then((response: IProductGetListResult) => {
      if (response?.data?.gridData) {
        setProducts(response.data.gridData);
      }
    });
  };

  React.useEffect(() => {
    if (languages && languages.length > 0) {
      setSelectedLanguage(getSelectedLanguageId(languages));
    }
  }, [languages]);

  React.useEffect(() => {
    loadProducts();
    loadLanguages();
  }, []);

  return (
    <React.Fragment>
      <ButtonPrimary size="sm" onClick={handleOpen}>
        {t("licenseForm.add")}
      </ButtonPrimary>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>{t("licenseForm.add")}</DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<IOrderCreatorAdminAddModel>({
              description: Yup.string()
                .max(1000, t("validationResources:descriptionMaxLength"))
                .nullable(),
              product: Yup.object().shape({
                gsProductId: Yup.number()
                  .min(1, t("validationResources:required"))
                  .required(t("validationResources:required")),
                quantity: Yup.number()
                  .min(
                    1,
                    t("validationResources:numericMinValue", { value: 1 })
                  )
                  .required(t("validationResources:required")),
              }),
            })}
            onSubmit={(values: IOrderCreatorAdminAddModel) => {
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <FormControl
                      fullWidth
                      size="small"
                      onChange={formik.handleChange}
                      variant="outlined"
                    >
                      <InputLabel id="gsProductId-select-id">
                        {t("product")}
                      </InputLabel>
                      <Select
                        name="product.gsProductId"
                        fullWidth
                        labelId="gsProductId-select-id"
                        value={formik.values.product?.gsProductId ?? ""}
                        error={
                          !!(
                            getIn(formik.errors, "product.gsProductId") &&
                            getIn(formik.touched, "product.gsProductId")
                          )
                        }
                        onChange={formik.handleChange}
                      >
                        {products.map(
                          (product: IProductData) =>
                            product.id && (
                              <MenuItem
                                key={`place_${product.id}`}
                                value={product.id}
                              >
                                {product.name ?? ""}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      value={formik.values.product?.quantity ?? ""}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          getIn(formik.errors, "product.quantity") &&
                          getIn(formik.touched, "product.quantity")
                        )
                      }
                      helperText={
                        getIn(formik.errors, "product.quantity") &&
                        getIn(formik.touched, "product.quantity") &&
                        getIn(formik.errors, "product.quantity")
                      }
                      label={t("quantity")}
                      variant="outlined"
                      size="small"
                      name="product.quantity"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      value={formik.values.description ?? ""}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          formik.errors.description &&
                          formik.touched.description
                        )
                      }
                      helperText={
                        formik.errors.description &&
                        formik.touched.description &&
                        formik.errors.description
                      }
                      label={t("description")}
                      variant="outlined"
                      size="small"
                      name="description"
                    />
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default OrderCreatorAdmin;
