import {
  ISBSSceneObjectTimelineApiInterface,
  ISBSSceneObjectTimelineData,
  ISBSSceneObjectTimelineImportCsvFileModel,
} from "./interfaces";
import {
  ISBSSceneObjectTimelineGetDetailsResult,
  ISBSSceneObjectTimelineGetLastItemDetailsResult,
  ISBSSceneObjectTimelineGetListResult,
} from "./interfaces-result";
import {
  ISBSSceneObjectTimelineAddPointByTimeModel,
  ISBSSceneObjectTimelineGetListModel,
  ISBSSceneObjectTimelineSavePointsModel,
  ISBSSceneObjectTimelineUpdateModel,
  ISBSSceneObjectTimelineValidateModel,
} from "./interfaces-model";
import { IResultBase } from "Services/interfaces";
import { axiosInstance, useBaseApi } from "Services/base-api";
import {
  getSelectedLanguageWithCulture,
  getStoredUserAuthToken,
} from "Utils/helpers";
import { SweetAlertResult } from "sweetalert2";
import { useHistory } from "react-router-dom";
import useBaseDataApi from "Services/base-data-api";
import useModalError from "Hooks/use-modal-error";
import useAuthorization from "Hooks/use-auth-token";

const useSBSSceneObjectTimelineApi: () => ISBSSceneObjectTimelineApiInterface =
  (): ISBSSceneObjectTimelineApiInterface => {
    const showModalError: (
      errorResult: string,
      isHtml?: boolean
    ) => Promise<SweetAlertResult> = useModalError();
    const { setUnauthorize } = useAuthorization(getStoredUserAuthToken());
    const { baseDeleteById, baseGetDetails, baseGetList, baseSave } =
      useBaseDataApi({ apiControllerName: "SBSSceneObjectTimeline" });
    const { baseGetRequest, basePostRequest } = useBaseApi();
    const history = useHistory();

    const addPointByTime: (
      model: ISBSSceneObjectTimelineAddPointByTimeModel
    ) => Promise<IResultBase> = async (
      model: ISBSSceneObjectTimelineAddPointByTimeModel
    ): Promise<IResultBase> => {
      let url: string = "/SBSSceneObjectTimeline/AddPointByTime";
      return await basePostRequest<
        ISBSSceneObjectTimelineAddPointByTimeModel,
        IResultBase
      >(url, model);
    };

    const importCSVFile: (
      model: ISBSSceneObjectTimelineImportCsvFileModel
    ) => Promise<IResultBase> = async (
      model: ISBSSceneObjectTimelineImportCsvFileModel
    ): Promise<IResultBase> => {
      let url: string = "/SBSSceneObjectTimeline/Import";
      let formData: FormData = new FormData();
      formData.append(
        "GSSBSSceneObjectId",
        model.GSSBSSceneObjectId.toString()
      );
      formData.append("File", model.File!);

      return await axiosInstance
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: getStoredUserAuthToken(),
          },
        })
        .then((response) => response.data)
        .catch((error) => {
          console.error(error);
          if (error.response && error.response.status === 401) {
            showModalError("Twoja sesja wygasła, zaloguj się ponownie!").then(
              () => {
                setUnauthorize();
                history.push("/login");
              }
            );
          } else if (error.response && error.response.status === 500) {
            showModalError("Coś poszło nie tak, spróbuj ponownie później");
          } else {
            showModalError(error.response.data.statusMessage, true);
          }
        });
    };

    const deleteById: (id: number) => Promise<IResultBase> = async (
      id: number
    ): Promise<IResultBase> => {
      return await baseDeleteById<IResultBase>(id);
    };

    const save: (
      model: ISBSSceneObjectTimelineData
    ) => Promise<IResultBase> = async (
      model: ISBSSceneObjectTimelineData
    ): Promise<IResultBase> => {
      return await baseSave<ISBSSceneObjectTimelineData, IResultBase>(model);
    };

    const savePoints: (
      model: ISBSSceneObjectTimelineSavePointsModel
    ) => Promise<IResultBase> = async (
      model: ISBSSceneObjectTimelineSavePointsModel
    ): Promise<IResultBase> => {
      let url: string = "/SBSSceneObjectTimeline/SavePoints";
      return await basePostRequest<
        ISBSSceneObjectTimelineSavePointsModel,
        IResultBase
      >(url, model);
    };

    const getDetails: (
      id: number
    ) => Promise<ISBSSceneObjectTimelineGetDetailsResult> = async (
      id: number
    ): Promise<ISBSSceneObjectTimelineGetDetailsResult> => {
      return await baseGetDetails<ISBSSceneObjectTimelineGetDetailsResult>(id);
    };

    const getLastItemDetails: (
      gsSBSSceneObjectId: number
    ) => Promise<ISBSSceneObjectTimelineGetLastItemDetailsResult> = async (
      gsSBSSceneObjectId: number
    ): Promise<ISBSSceneObjectTimelineGetLastItemDetailsResult> => {
      let url: string = `/SBSSceneObjectTimeline/GetLastItemDetails/${gsSBSSceneObjectId}`;
      return await baseGetRequest<ISBSSceneObjectTimelineGetLastItemDetailsResult>(
        url,
        true
      );
    };

    const getList: (
      model: ISBSSceneObjectTimelineGetListModel
    ) => Promise<ISBSSceneObjectTimelineGetListResult> = async (
      model: ISBSSceneObjectTimelineGetListModel
    ): Promise<ISBSSceneObjectTimelineGetListResult> => {
      return await baseGetList<
        ISBSSceneObjectTimelineGetListModel,
        ISBSSceneObjectTimelineGetListResult
      >(model);
    };

    const validate: (
      model: ISBSSceneObjectTimelineValidateModel
    ) => Promise<IResultBase> = async (
      model: ISBSSceneObjectTimelineValidateModel
    ): Promise<IResultBase> => {
      let url: string = "/SBSSceneObjectTimeline/Validate";
      if (model.withoutDefaultErrorCatching) {
        return await axiosInstance
          .post(url, JSON.stringify({ id: model.id }), {
            headers: {
              Authorization: getStoredUserAuthToken(),
              language: getSelectedLanguageWithCulture(),
            },
          })
          .then((response) => response.data);
      }
      return await basePostRequest<
        ISBSSceneObjectTimelineValidateModel,
        IResultBase
      >(url, model);
    };

    return {
      addPointByTime,
      importCSVFile,
      deleteById,
      save,
      savePoints,
      getDetails,
      getLastItemDetails,
      getList,
      validate,
    };
  };

export default useSBSSceneObjectTimelineApi;
