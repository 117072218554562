import * as React from "react";
import { ITextProps } from "./interfaces";
import { Typography } from "@material-ui/core";
import { stylesTextTitle } from "./styles";

const TextTitle: (props: ITextProps) => JSX.Element = (props: ITextProps): JSX.Element => {
    const customStyles: Record<string, string> = stylesTextTitle();
    const { children, ...other } = props;

    return (
        <Typography variant="h6" color="primary" className={customStyles.main} {...other}>
            {children}
        </Typography>
    );
};

export default TextTitle;