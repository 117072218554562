import * as React from "react";
import * as _ from "lodash";
import { getStoredUserAuthToken, getStoredUserRole } from "Utils/helpers";
import { DEFAULT_USER_AUTH_TOKEN } from "Utils/consts";
import { IRoutePrivateProps } from "./interfaces";
import { Route, Redirect } from "react-router-dom";
import { UserRole } from "Utils/enums";
import useAuthorization from "Hooks/use-auth-token";

const RoutePrivate: (props: IRoutePrivateProps) => JSX.Element = (props: IRoutePrivateProps): JSX.Element => {
  const { authToken } = useAuthorization(getStoredUserAuthToken());
  const userRole: UserRole = getStoredUserRole();

  return (
    <Route
      {...props}
      render={({ location }) =>
        (authToken === DEFAULT_USER_AUTH_TOKEN || (props.userRoleAccessList && _.indexOf(props.userRoleAccessList, userRole) < 0))
          ? (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
          : (
            <props.layoutElement childrenType={props.childrenType}>
                <props.childrenElement />
            </props.layoutElement>
          )
      }
    />
  );
};

export default RoutePrivate;