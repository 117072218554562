import * as React from "react";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  FormControl,
  DialogActions,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { IOrderCreatorCartFinilizeProps } from "../interfaces";
import { useUserApi } from "Services/UserApi/user-api";
import { IUserDetailsData } from "Services/UserApi/interfaces";
import { getSelectedLanguageId, getStoredUserId } from "Utils/helpers";
import { IUserDetailsResult } from "Services/UserApi/interfaces-result";
import { OrderPaymentType } from "Utils/enums";
import { useTranslation } from "react-i18next";
import { ILanguageData } from "Services/LanguageApi/interfaces";
import { useLanguageApi } from "Services/LanguageApi/language-api";
import { ILanguageGetListResult } from "Services/LanguageApi/interfaces-result";
import { DEFAULT_LANGUAGE_ID } from "Utils/consts";
import ButtonSecondary from "Components/Buttons/button-secondary";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import TextInfo from "Components/Texts/text-info";
import Numeral from "numeral";

const OrderFinilize: (props: IOrderCreatorCartFinilizeProps) => JSX.Element = (
  props: IOrderCreatorCartFinilizeProps
): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState(
    OrderPaymentType.Przelewy24
  );
  const [selectedLanguage, setSelectedLanguage] =
    React.useState<number>(DEFAULT_LANGUAGE_ID);
  const [languages, setLanguages] = React.useState<ILanguageData[]>([]);
  const [userDetails, setUserDetails] = React.useState<IUserDetailsData>();
  const { t } = useTranslation(["commonResources", "enumResources"]);
  const { getDetails } = useUserApi();
  const { getList } = useLanguageApi();

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    setIsOpen(true);
  };

  const handleChangePayment: (payment: OrderPaymentType) => void = (
    payment: OrderPaymentType
  ): void => {
    setPaymentType(payment);
  };

  const loadLanguages: () => Promise<void> = async () => {
    return await getList().then((response: ILanguageGetListResult) => {
      if (response?.data) {
        setLanguages(response.data);
      }
    });
  };

  const loadUserDetails: () => Promise<void> = async (): Promise<void> => {
    await getDetails(getStoredUserId()).then((response: IUserDetailsResult) => {
      if (response && response.data) {
        setUserDetails(response.data);
      }
    });
  };

  const handleClickFinilize: () => void = (): void => {
    if (props.handleFinilize) {
      props.handleFinilize(paymentType, selectedLanguage);
    }
    handleClose();
  };

  React.useEffect(() => {
    if (languages && languages.length > 0) {
      setSelectedLanguage(getSelectedLanguageId(languages));
    }
  }, [languages]);

  React.useEffect(() => {
    loadUserDetails();
    loadLanguages();
  }, []);

  return (
    <React.Fragment>
      <ButtonPrimary size="sm" onClick={handleOpen}>
        <ButtonGridIcon icon={"fa-cart-arrow-down"} color="secondary" />
        {t("orderForm.title")}
      </ButtonPrimary>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          {t("orderForm.totalPrizeInfo")}{" "}
          {props.totalPrize ? Numeral(props.totalPrize).format("0,0.00") : 0} zł
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {t("orderForm.paymentType")}
                </FormLabel>
                <RadioGroup
                  value={paymentType}
                  onChange={(event) => {
                    handleChangePayment(
                      parseInt(event.target.value) as OrderPaymentType
                    );
                  }}
                >
                  <FormControlLabel
                    key={`mi-${OrderPaymentType.Przelewy24}`}
                    value={OrderPaymentType.Przelewy24}
                    control={<Radio />}
                    label={t("enumResources:orderPaymentType.przelewy24")}
                  />
                  <FormControlLabel
                    key={`mi-${OrderPaymentType.VirtualWallet}`}
                    value={OrderPaymentType.VirtualWallet}
                    control={<Radio />}
                    label={t("enumResources:orderPaymentType.virutalWallet")}
                  />
                </RadioGroup>
              </FormControl>
              <TextInfo
                isHidden={paymentType !== OrderPaymentType.VirtualWallet}
                style={{ marginTop: "5px" }}
              >
                {t("orderForm.virtualWalletBallance")}{" "}
                {userDetails?.virtualWalletBalance ?? 0} zł
              </TextInfo>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <ButtonSecondary onClick={handleClose}>{t("cancel")}</ButtonSecondary>
          <ButtonPrimary type="submit" onClick={handleClickFinilize}>
            {t("pay")}
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default OrderFinilize;
