import * as React from "react";
import { IUserCardProps } from "../interfaces";
import { IUserDetailsData } from "Services/UserApi/interfaces";
import { stylesUserCard } from "../styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UserEditorAdmin from "../UserEditors/user-editor-admin";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import TextSubtitle from "Components/Texts/text-subtitle";
import TextInfo from "Components/Texts/text-info";
import clsx from "clsx";

const UserCard: (props: IUserCardProps) => JSX.Element = (
  props: IUserCardProps
): JSX.Element => {
  const customStyles: Record<string, string> = stylesUserCard();
  const user: IUserDetailsData = { ...props.user };
  const [expanded, setExpanded] = React.useState(false);
  const { t } = useTranslation(["commonResources"]);

  const handleExpandClick: () => void = (): void => {
    setExpanded(!expanded);
  };

  return (
    <React.Fragment>
      <Card className={customStyles.root}>
        <CardHeader
          className={customStyles.header}
          action={
            <React.Fragment>
              <UserEditorAdmin
                userId={user.id ?? 0}
                handleSaveSuccess={props.handleLoadData}
              />
              <ButtonGridIcon
                icon="fa-trash-alt"
                color="primary"
                onClick={() => {
                  if (props.handleClickDelete && user.id) {
                    props.handleClickDelete(user.id);
                  }
                }}
              />
            </React.Fragment>
          }
        ></CardHeader>
        <CardContent className={customStyles.main}>
          <TextSubtitle>{user.email}</TextSubtitle>
          <TextInfo>
            {t("role")}: {user.roleText}
          </TextInfo>
          <TextInfo>
            {t("status")}: {user.statusText}
          </TextInfo>
        </CardContent>
        <CardActions className={customStyles.main}>
          <IconButton
            className={clsx(
              customStyles.expand,
              {
                [customStyles.expandOpen]: expanded,
              },
              customStyles.button
            )}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <TextInfo color="textSecondary">
              {t("invoiceForm.companyName")}: {user.invoiceCompanyName}
            </TextInfo>
            <TextInfo color="textSecondary">
              {t("invoiceForm.nip")}: {user.invoiceNIP}
            </TextInfo>
          </CardContent>
        </Collapse>
      </Card>
    </React.Fragment>
  );
};

export default UserCard;
