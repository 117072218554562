import * as React from "react";
import { Grid, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TextInfo from "Components/Texts/text-info";
import TextHeader from "Components/Texts/text-header";
import TextSubheader from "Components/Texts/text-subheader";
import LayoutNotLogged from "Layouts/layout-not-logged";
import ThemeLink from "Components/Links/theme-link";

const PaymentSuccess: () => JSX.Element = (): JSX.Element => {
  const { t } = useTranslation(["commonResources"]);

  return (
    <LayoutNotLogged>
      <Grid container justify="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <TextSubheader>{t("welcomeIn")}</TextSubheader>
          <TextHeader>GSOT</TextHeader>
          <Divider />
          <TextInfo>{t("paymentForm.successInfo")}</TextInfo>
          <Divider />
          <TextInfo>
            <ThemeLink to="/" text={t("backToMainPage")} />
          </TextInfo>
        </Grid>
      </Grid>
    </LayoutNotLogged>
  );
};

export default PaymentSuccess;
