import * as React from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";
import { Form, Formik, getIn } from "formik";
import useModalSuccess from "Hooks/use-modal-success";
import { useTranslation } from "react-i18next";
import { useApplicationApi } from "Services/ApplicationApi/application-api";
import { IApplicationData } from "Services/ApplicationApi/interfaces";
import useFileApi from "Services/FileApi/file-api";
import { IApplicationEditorAdminProps } from "../interfaces";
import { IFileUploadModel } from "Services/FileApi/interfaces-models";
import { IFileUploadResult } from "Services/FileApi/interfaces-results";
import { ApiResultStatus, FileType } from "Utils/enums";
import { IFileData } from "Services/FileApi/interfaces";
import FileUploader from "Components/File/file-uploader";
import TextInfo from "Components/Texts/text-info";
import { IApplicationGetDetailsResult } from "Services/ApplicationApi/interfaces-result";
import { ILocalization } from "Services/interfaces";

const ApplicationEditorAdmin: (
  props: IApplicationEditorAdminProps
) => JSX.Element = (props: IApplicationEditorAdminProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [toSave, setToSave] = React.useState(false);
  const { upload } = useFileApi();
  const { save, getDetails } = useApplicationApi();
  const { t } = useTranslation([
    "commonResources",
    "enumResources",
    "validationResources",
  ]);
  const showModalSuccess: (successResult: string) => void =
    useModalSuccess(undefined);
  const fileFormats: string[] = [".png"];
  const applicationId: number = props.applicationId ?? 0;
  const [backgroundFile, setBackgroundFile] = React.useState<File>();
  const [buttonGroupAFile, setButtonGroupAFile] = React.useState<File>();
  const [buttonGroupBFile, setButtonGroupBFile] = React.useState<File>();
  const [buttonGuideFile, setButtonGuideFile] = React.useState<File>();
  const [buttonTableFile, setButtonTableFile] = React.useState<File>();
  const [buttonMuseumFile, setButtonMuseumFile] = React.useState<File>();
  const [buttonPlaygroundFile, setButtonPlaygroundFile] =
    React.useState<File>();
  const [buttonDefaultFile, setButtonDefaultFile] = React.useState<File>();
  const [buttonCloseFile, setButtonCloseFile] = React.useState<File>();
  const [buttonLicenseFile, setButtonLicenseFile] = React.useState<File>();
  const [logoFile, setLogoFile] = React.useState<File>();
  const [guideMapFile, setGuideMapFile] = React.useState<File>();
  const [guideSceneLocalizationFile, setGuideSceneLocalizationFile] =
    React.useState<File>();
  const [guideUserLocalizationFile, setGuideUserLocalizationFile] =
    React.useState<File>();
  const [values, setValues] = React.useState<IApplicationData>({
    name: "",
    guid: "",
    guideSD1PointLocalizationEPSG4326: null,
    guideSD2PointLocalizationEPSG4326: null,
    mobileAppMinVersion: 1,
  });
  const [valueBackgroundFile, setValueBackgroundFile] =
    React.useState<IFileData | null>();
  const [valueButtonGroupAFile, setValueButtonGroupAFile] =
    React.useState<IFileData | null>();
  const [valueButtonGroupBFile, setValueButtonGroupBFile] =
    React.useState<IFileData | null>();
  const [valueButtonGuideFile, setValueButtonGuideFile] =
    React.useState<IFileData | null>();
  const [valueButtonTableFile, setValueButtonTableFile] =
    React.useState<IFileData | null>();
  const [valueButtonMuseumFile, setValueButtonMuseumFile] =
    React.useState<IFileData | null>();
  const [valueButtonPlaygroundFile, setValueButtonPlaygroundFile] =
    React.useState<IFileData | null>();
  const [valueButtonDefaultFile, setValueButtonDefaultFile] =
    React.useState<IFileData | null>();
  const [valueButtonCloseFile, setValueButtonCloseFile] =
    React.useState<IFileData | null>();
  const [valueButtonLicenseFile, setValueButtonLicenseFile] =
    React.useState<IFileData | null>();
  const [valueLogoFile, setValueLogoFile] = React.useState<IFileData | null>();
  const [valueGuideMapFile, setValueGuideMapFile] =
    React.useState<IFileData | null>();
  const [valueGuideSceneLocalizationFile, setValueGuideSceneLocalizationFile] =
    React.useState<IFileData | null>();
  const [valueGuideUserLocalizationFile, setValueGuideUserLocalizationFile] =
    React.useState<IFileData | null>();

  const setFile: (file: File | undefined, fileType: FileType) => void = (
    file: File | undefined,
    fileType: FileType
  ): void => {
    if (file) {
      switch (fileType) {
        case FileType.ApplicationBackground:
          let newValueBackgroundFile: IFileData = {
            originalFileName: file.name,
          };
          setValueBackgroundFile(newValueBackgroundFile);
          setBackgroundFile(file);
          break;
        case FileType.ApplicationButtonGroupA:
          let newValueButtonGroupAFile: IFileData = {
            originalFileName: file.name,
          };
          setValueButtonGroupAFile(newValueButtonGroupAFile);
          setButtonGroupAFile(file);
          break;
        case FileType.ApplicationButtonGroupB:
          let newValueButtonGroupBFile: IFileData = {
            originalFileName: file.name,
          };
          setValueButtonGroupBFile(newValueButtonGroupBFile);
          setButtonGroupBFile(file);
          break;
        case FileType.ApplicationButtonGuide:
          let newValueButtonGuideFile: IFileData = {
            originalFileName: file.name,
          };
          setValueButtonGuideFile(newValueButtonGuideFile);
          setButtonGuideFile(file);
          break;
        case FileType.ApplicationButtonTable:
          let newValueButtonTableFile: IFileData = {
            originalFileName: file.name,
          };
          setValueButtonTableFile(newValueButtonTableFile);
          setButtonTableFile(file);
          break;
        case FileType.ApplicationButtonMuseum:
          let newValueButtonMuseumFile: IFileData = {
            originalFileName: file.name,
          };
          setValueButtonMuseumFile(newValueButtonMuseumFile);
          setButtonMuseumFile(file);
          break;
        case FileType.ApplicationButtonPlayground:
          let newValueButtonPlaygroundFile: IFileData = {
            originalFileName: file.name,
          };
          setValueButtonPlaygroundFile(newValueButtonPlaygroundFile);
          setButtonPlaygroundFile(file);
          break;
        case FileType.ApplicationButtonDefault:
          let newValueButtonDefaultFile: IFileData = {
            originalFileName: file.name,
          };
          setValueButtonDefaultFile(newValueButtonDefaultFile);
          setButtonDefaultFile(file);
          break;
        case FileType.ApplicationButtonClose:
          let newValueButtonCloseFile: IFileData = {
            originalFileName: file.name,
          };
          setValueButtonCloseFile(newValueButtonCloseFile);
          setButtonCloseFile(file);
          break;
        case FileType.ApplicationButtonLicense:
          let newValueButtonLicenseFile: IFileData = {
            originalFileName: file.name,
          };
          setValueButtonLicenseFile(newValueButtonLicenseFile);
          setButtonLicenseFile(file);
          break;
        case FileType.ApplicationLogo:
          let newValueLogoFile: IFileData = {
            originalFileName: file.name,
          };
          setValueLogoFile(newValueLogoFile);
          setLogoFile(file);
          break;
        case FileType.ApplicationGuideMap:
          let newValueGuideMapFile: IFileData = {
            originalFileName: file.name,
          };
          setValueGuideMapFile(newValueGuideMapFile);
          setGuideMapFile(file);
          break;
        case FileType.ApplicationGuideSceneLocalization:
          let newValueGuideSceneLocalizationFile: IFileData = {
            originalFileName: file.name,
          };
          setValueGuideSceneLocalizationFile(
            newValueGuideSceneLocalizationFile
          );
          setGuideSceneLocalizationFile(file);
          break;
        case FileType.ApplicationGuideUserLocalization:
          let newValueGuideUserLocalization: IFileData = {
            originalFileName: file.name,
          };
          setValueGuideUserLocalizationFile(newValueGuideUserLocalization);
          setGuideUserLocalizationFile(file);
          break;
      }
    }
  };

  const uploadFile: (file: IFileUploadModel) => Promise<IFileUploadResult> =
    async (file: IFileUploadModel): Promise<IFileUploadResult> => {
      return await upload(file);
    };

  const handleClose: () => void = (): void => {
    setValues({ mobileAppMinVersion: 1 });
    setValueBackgroundFile(null);
    setValueButtonGroupAFile(null);
    setValueButtonGroupBFile(null);
    setValueButtonGuideFile(null);
    setValueButtonTableFile(null);
    setValueButtonMuseumFile(null);
    setValueButtonPlaygroundFile(null);
    setValueButtonDefaultFile(null);
    setValueButtonCloseFile(null);
    setValueButtonLicenseFile(null);
    setValueLogoFile(null);
    setValueGuideMapFile(null);
    setValueGuideSceneLocalizationFile(null);
    setValueGuideUserLocalizationFile(null);
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    if (applicationId) {
      handleLoadData();
    }
    setIsOpen(true);
  };

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    if (props.applicationId) {
      return await getDetails(props.applicationId).then(
        (response: IApplicationGetDetailsResult) => {
          if (response && response.data) {
            setValues(response.data);
            setValueBackgroundFile(response.data.backgroundFile ?? null);
            setValueButtonGroupAFile(response.data.buttonGroupAFile ?? null);
            setValueButtonGroupBFile(response.data.buttonGroupBFile ?? null);
            setValueButtonGuideFile(response.data.buttonGuideFile ?? null);
            setValueButtonTableFile(response.data.buttonTableFile ?? null);
            setValueButtonMuseumFile(response.data.buttonMuseumFile ?? null);
            setValueButtonPlaygroundFile(
              response.data.buttonPlaygroundFile ?? null
            );
            setValueButtonDefaultFile(response.data.buttonDefaultFile ?? null);
            setValueButtonCloseFile(response.data.buttonCloseFile ?? null);
            setValueButtonLicenseFile(response.data.buttonLicenseFile ?? null);
            setValueLogoFile(response.data.logoFile ?? null);
            setValueGuideMapFile(response.data.guideMapFile ?? null);
            setValueGuideSceneLocalizationFile(
              response.data.guideSceneLocalizationFile ?? null
            );
            setValueGuideUserLocalizationFile(
              response.data.guideUserLocalizationFile ?? null
            );
          }
        }
      );
    }
  };

  const handleSave: () => void = (): void => {
    save(values).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(
          applicationId
            ? t("saveChangesSuccess")
            : t("applicationForm.addSuccess")
        );
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
        handleClose();
        setToSave(false);
      }
    });
  };

  const handleFilesSave: (application: IApplicationData) => Promise<void> =
    async (application: IApplicationData): Promise<void> => {
      let fileToUpload: IFileUploadModel;
      if (backgroundFile) {
        fileToUpload = {
          type: FileType.ApplicationBackground,
          file: backgroundFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.backgroundFile = response.data;
          }
        });
      } else {
        application.backgroundFile = valueBackgroundFile;
      }
      if (buttonGroupAFile) {
        fileToUpload = {
          type: FileType.ApplicationBackground,
          file: buttonGroupAFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.buttonGroupAFile = response.data;
          }
        });
      } else {
        application.buttonGroupAFile = valueButtonGroupAFile;
      }
      if (buttonGroupBFile) {
        fileToUpload = {
          type: FileType.ApplicationBackground,
          file: buttonGroupBFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.buttonGroupBFile = response.data;
          }
        });
      } else {
        application.buttonGroupBFile = valueButtonGroupBFile;
      }
      if (buttonGuideFile) {
        fileToUpload = {
          type: FileType.ApplicationBackground,
          file: buttonGuideFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.buttonGuideFile = response.data;
          }
        });
      } else {
        application.buttonGuideFile = valueButtonGuideFile;
      }
      if (buttonTableFile) {
        fileToUpload = {
          type: FileType.ApplicationButtonTable,
          file: buttonTableFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.buttonTableFile = response.data;
          }
        });
      } else {
        application.buttonTableFile = valueButtonTableFile;
      }
      if (buttonMuseumFile) {
        fileToUpload = {
          type: FileType.ApplicationButtonMuseum,
          file: buttonMuseumFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.buttonMuseumFile = response.data;
          }
        });
      } else {
        application.buttonMuseumFile = valueButtonMuseumFile;
      }
      if (buttonPlaygroundFile) {
        fileToUpload = {
          type: FileType.ApplicationButtonPlayground,
          file: buttonPlaygroundFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.buttonPlaygroundFile = response.data;
          }
        });
      } else {
        application.buttonPlaygroundFile = valueButtonPlaygroundFile;
      }
      if (buttonDefaultFile) {
        fileToUpload = {
          type: FileType.ApplicationButtonDefault,
          file: buttonDefaultFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.buttonDefaultFile = response.data;
          }
        });
      } else {
        application.buttonDefaultFile = valueButtonDefaultFile;
      }
      if (buttonCloseFile) {
        fileToUpload = {
          type: FileType.ApplicationButtonClose,
          file: buttonCloseFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.buttonCloseFile = response.data;
          }
        });
      } else {
        application.buttonCloseFile = valueButtonCloseFile;
      }
      if (buttonLicenseFile) {
        fileToUpload = {
          type: FileType.ApplicationButtonLicense,
          file: buttonLicenseFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.buttonLicenseFile = response.data;
          }
        });
      } else {
        application.buttonLicenseFile = valueButtonLicenseFile;
      }
      if (logoFile) {
        fileToUpload = {
          type: FileType.ApplicationLogo,
          file: logoFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.logoFile = response.data;
          }
        });
      } else {
        application.logoFile = valueLogoFile;
      }
      if (guideMapFile) {
        fileToUpload = {
          type: FileType.ApplicationGuideMap,
          file: guideMapFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.guideMapFile = response.data;
          }
        });
      } else {
        application.guideMapFile = valueGuideMapFile;
      }
      if (guideSceneLocalizationFile) {
        fileToUpload = {
          type: FileType.ApplicationGuideSceneLocalization,
          file: guideSceneLocalizationFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.guideSceneLocalizationFile = response.data;
          }
        });
      } else {
        application.guideSceneLocalizationFile =
          valueGuideSceneLocalizationFile;
      }
      if (guideUserLocalizationFile) {
        fileToUpload = {
          type: FileType.ApplicationGuideUserLocalization,
          file: guideUserLocalizationFile,
        };
        await uploadFile(fileToUpload).then((response) => {
          if (response && response.status === ApiResultStatus.Ok) {
            application.guideUserLocalizationFile = response.data;
          }
        });
      } else {
        application.guideUserLocalizationFile = valueGuideUserLocalizationFile;
      }
      setValues(application);
      setToSave(true);
    };

  React.useEffect(() => {
    if (toSave) {
      handleSave();
    }
  }, [toSave]);

  return (
    <React.Fragment>
      {!!applicationId && (
        <ButtonGridIcon
          icon={"fa-edit"}
          onClick={handleOpen}
          color="secondary"
        />
      )}
      {!applicationId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-mobile-alt"} color="secondary" />
          {t("applicationForm.add")}
        </ButtonPrimary>
      )}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          {applicationId
            ? t("applicationForm.edit")
            : t("applicationForm.addInfo")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<IApplicationData>({
              name: Yup.string()
                .max(100, t("validationResources:nameMaxLength"))
                .required(t("validationResources:required")),
              guid: Yup.string().nullable(),
              buttonGuideFile: Yup.object().nullable(),
              backgroundFile: Yup.object().nullable(),
              buttonGroupAFile: Yup.object().nullable(),
              buttonGroupBFile: Yup.object().nullable(),
              buttonTableFile: Yup.object().nullable(),
              buttonMuseumFile: Yup.object().nullable(),
              buttonPlaygroundFile: Yup.object().nullable(),
              buttonDefaultFile: Yup.object().nullable(),
              buttonCloseFile: Yup.object().nullable(),
              buttonLicenseFile: Yup.object().nullable(),
              logoFile: Yup.object().nullable(),
              guideMapFile: Yup.object().nullable(),
              guideSceneLocalizationFile: Yup.object().nullable(),
              guideUserLocalizationFile: Yup.object().nullable(),
              guideSD1PointLocalizationEPSG4326: Yup.object<ILocalization>({
                altitude: Yup.number().notRequired().nullable(),
                latitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
                longitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
              }).nullable(),
              guideSD2PointLocalizationEPSG4326: Yup.object<ILocalization>({
                altitude: Yup.number().notRequired().nullable(),
                latitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
                longitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
              }).nullable(),
              mobileAppMinVersion: Yup.number()
                .min(1, t("validationResources:numericMin0"))
                .max(255, t("validationResources:numericMax255"))
                .typeError(t("validationResources:requiredNumeric"))
                .required(t("validationResources:required")),
            })}
            onSubmit={(values: IApplicationData) => {
              if (
                !values.guideSD1PointLocalizationEPSG4326?.latitude ||
                !values.guideSD1PointLocalizationEPSG4326?.longitude
              ) {
                values.guideSD1PointLocalizationEPSG4326 = null;
              }
              if (
                !values.guideSD2PointLocalizationEPSG4326?.latitude ||
                !values.guideSD2PointLocalizationEPSG4326?.longitude
              ) {
                values.guideSD2PointLocalizationEPSG4326 = null;
              }
              handleFilesSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid container item spacing={1}>
                  <Grid container item spacing={1} xs={12}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.name ?? ""}
                        onChange={formik.handleChange}
                        error={!!(formik.errors.name && formik.touched.name)}
                        helperText={
                          formik.errors.name &&
                          formik.touched.name &&
                          formik.errors.name
                        }
                        label={t("name")}
                        variant="outlined"
                        size="small"
                        name="name"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} xs={12}>
                    <Grid item xs={3}>
                      <TextInfo>
                        {t("applicationForm.backgroundFile")} - 720x1020
                      </TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <TextInfo>
                        {t("applicationForm.buttonGroupAFile")} - 720x270
                      </TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <TextInfo>
                        {t("applicationForm.buttonGroupBFile")} - 720x270
                      </TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <TextInfo>
                        {t("applicationForm.buttonGuideFile")} - 720x270
                      </TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={
                          valueBackgroundFile?.originalFileName ?? undefined
                        }
                        discFileName={valueBackgroundFile?.discFileName}
                        file={backgroundFile}
                        setFile={(file: File | undefined) => {
                          setFile(file, FileType.ApplicationBackground);
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                        size="720x1020"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={
                          valueButtonGroupAFile?.originalFileName ?? undefined
                        }
                        discFileName={valueButtonGroupAFile?.discFileName}
                        file={buttonGroupAFile}
                        setFile={(file: File | undefined) => {
                          setFile(file, FileType.ApplicationButtonGroupA);
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                        size="720x270"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={
                          valueButtonGroupBFile?.originalFileName ?? undefined
                        }
                        discFileName={valueButtonGroupBFile?.discFileName}
                        file={buttonGroupBFile}
                        setFile={(file: File | undefined) => {
                          setFile(file, FileType.ApplicationButtonGroupB);
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                        size="720x270"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={
                          valueButtonGuideFile?.originalFileName ?? undefined
                        }
                        discFileName={valueButtonGuideFile?.discFileName}
                        file={buttonGuideFile}
                        setFile={(file: File | undefined) => {
                          setFile(file, FileType.ApplicationButtonGuide);
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                        size="720x270"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} xs={12}>
                    <Grid item xs={3}>
                      <TextInfo>
                        {t("applicationForm.buttonTableFile")} - 720x270
                      </TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <TextInfo>
                        {t("applicationForm.buttonMuseumFile")} - 720x270
                      </TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <TextInfo>
                        {t("applicationForm.buttonPlaygroundFile")} - 720x270
                      </TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <TextInfo>
                        {t("applicationForm.buttonCloseFile")} - 720x90
                      </TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={
                          valueButtonTableFile?.originalFileName ?? undefined
                        }
                        discFileName={valueButtonTableFile?.discFileName}
                        file={buttonTableFile}
                        setFile={(file: File | undefined) => {
                          setFile(file, FileType.ApplicationButtonTable);
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                        size="720x270"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={
                          valueButtonMuseumFile?.originalFileName ?? undefined
                        }
                        discFileName={valueButtonMuseumFile?.discFileName}
                        file={buttonMuseumFile}
                        setFile={(file: File | undefined) => {
                          setFile(file, FileType.ApplicationButtonMuseum);
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                        size="720x270"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={
                          valueButtonPlaygroundFile?.originalFileName ??
                          undefined
                        }
                        discFileName={valueButtonPlaygroundFile?.discFileName}
                        file={buttonPlaygroundFile}
                        setFile={(file: File | undefined) => {
                          setFile(file, FileType.ApplicationButtonPlayground);
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                        size="720x270"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={
                          valueButtonCloseFile?.originalFileName ?? undefined
                        }
                        discFileName={valueButtonCloseFile?.discFileName}
                        file={buttonCloseFile}
                        setFile={(file: File | undefined) => {
                          setFile(file, FileType.ApplicationButtonClose);
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                        size="720x90"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} xs={12}>
                    <Grid item xs={3}>
                      <TextInfo>
                        {t("applicationForm.buttonLicenseFile")} - 720x90
                      </TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <TextInfo>
                        {t("applicationForm.logoFile")} - 220x220
                      </TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <TextInfo>{t("applicationForm.guideMapFile")}</TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <TextInfo>
                        {t("applicationForm.guideSceneLocalizationFile")}
                      </TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={
                          valueButtonLicenseFile?.originalFileName ?? undefined
                        }
                        discFileName={valueButtonLicenseFile?.discFileName}
                        file={buttonLicenseFile}
                        setFile={(file: File | undefined) => {
                          setFile(file, FileType.ApplicationButtonLicense);
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                        size="720x90"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={valueLogoFile?.originalFileName ?? undefined}
                        discFileName={valueLogoFile?.discFileName}
                        file={logoFile}
                        setFile={(file: File | undefined) => {
                          setFile(file, FileType.ApplicationLogo);
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                        size="220x220"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={
                          valueGuideMapFile?.originalFileName ?? undefined
                        }
                        discFileName={valueGuideMapFile?.discFileName}
                        file={guideMapFile}
                        setFile={(file: File | undefined) => {
                          setFile(file, FileType.ApplicationGuideMap);
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={
                          valueGuideSceneLocalizationFile?.originalFileName ??
                          undefined
                        }
                        discFileName={
                          valueGuideSceneLocalizationFile?.discFileName
                        }
                        file={guideSceneLocalizationFile}
                        setFile={(file: File | undefined) => {
                          setFile(
                            file,
                            FileType.ApplicationGuideSceneLocalization
                          );
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} xs={12}>
                    <Grid item xs={12}>
                      <TextInfo>
                        {t("applicationForm.guideUserLocalizationFile")}
                      </TextInfo>
                    </Grid>
                    <Grid item xs={3}>
                      <FileUploader
                        fileName={
                          valueGuideUserLocalizationFile?.originalFileName ??
                          undefined
                        }
                        discFileName={
                          valueGuideUserLocalizationFile?.discFileName
                        }
                        file={guideUserLocalizationFile}
                        setFile={(file: File | undefined) => {
                          setFile(
                            file,
                            FileType.ApplicationGuideUserLocalization
                          );
                        }}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} sm={12}>
                    <Grid item xs={12}>
                      <Divider />
                      <TextInfo color={"secondary"}>
                        {t("applicationForm.guideSD1PointLocalizationEPSG4326")}
                      </TextInfo>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.guideSD1PointLocalizationEPSG4326
                            ?.longitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "guideSD1PointLocalizationEPSG4326.longitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "guideSD1PointLocalizationEPSG4326.longitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.touched,
                            "guideSD1PointLocalizationEPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "guideSD1PointLocalizationEPSG4326.longitude"
                          )
                        }
                        label="L"
                        variant="outlined"
                        size="small"
                        name="guideSD1PointLocalizationEPSG4326.longitude"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.guideSD1PointLocalizationEPSG4326
                            ?.latitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "guideSD1PointLocalizationEPSG4326.latitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "guideSD1PointLocalizationEPSG4326.latitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.touched,
                            "guideSD1PointLocalizationEPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "guideSD1PointLocalizationEPSG4326.latitude"
                          )
                        }
                        label="B"
                        variant="outlined"
                        size="small"
                        name="guideSD1PointLocalizationEPSG4326.latitude"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} sm={12}>
                    <Grid item xs={12}>
                      <Divider />
                      <TextInfo color={"secondary"}>
                        {t("applicationForm.guideSD2PointLocalizationEPSG4326")}
                      </TextInfo>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.guideSD2PointLocalizationEPSG4326
                            ?.longitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "guideSD2PointLocalizationEPSG4326.longitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "guideSD2PointLocalizationEPSG4326.longitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.touched,
                            "guideSD2PointLocalizationEPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "guideSD2PointLocalizationEPSG4326.longitude"
                          )
                        }
                        label="L"
                        variant="outlined"
                        size="small"
                        name="guideSD2PointLocalizationEPSG4326.longitude"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.guideSD2PointLocalizationEPSG4326
                            ?.latitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "guideSD2PointLocalizationEPSG4326.latitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "guideSD2PointLocalizationEPSG4326.latitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.touched,
                            "guideSD2PointLocalizationEPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "guideSD2PointLocalizationEPSG4326.latitude"
                          )
                        }
                        label="B"
                        variant="outlined"
                        size="small"
                        name="guideSD2PointLocalizationEPSG4326.latitude"
                      />
                    </Grid>
                  </Grid>

                  <Grid container item spacing={1} sm={12}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>

                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={formik.values.mobileAppMinVersion}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.mobileAppMinVersion &&
                            formik.touched.mobileAppMinVersion
                          )
                        }
                        helperText={
                          formik.errors.mobileAppMinVersion &&
                          formik.touched.mobileAppMinVersion &&
                          formik.errors.mobileAppMinVersion
                        }
                        label={t("applicationForm.mobileAppMinVersion")}
                        variant="outlined"
                        size="small"
                        name="mobileAppMinVersion"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ApplicationEditorAdmin;
