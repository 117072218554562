import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  hexToRgb,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";
import TextInfo from "Components/Texts/text-info";
import { Form, Formik, FormikProps, getIn } from "formik";
import useModalSuccess from "Hooks/use-modal-success";
import * as React from "react";
import { ColorResult, SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { ILocalization } from "Services/interfaces";
import { ISBSSceneObjectTimelineData } from "Services/SBSSceneObjectTimelineApi/interfaces";
import {
  ISBSSceneObjectTimelineGetDetailsResult,
  ISBSSceneObjectTimelineGetLastItemDetailsResult,
} from "Services/SBSSceneObjectTimelineApi/interfaces-result";
import useSBSSceneObjectTimelineApi from "Services/SBSSceneObjectTimelineApi/sbs-scene-object-timeline-api";
import {
  ApiResultStatus,
  SceneObjectTimelineGraphicVideoPosition,
} from "Utils/enums";
import * as Yup from "yup";
import { ISBSSceneObjectTimelineEditorAdminProps } from "../interfaces";

const SBSSceneObjectTimelineGraphicVideoEditorAdmin: (
  props: ISBSSceneObjectTimelineEditorAdminProps
) => JSX.Element = (
  props: ISBSSceneObjectTimelineEditorAdminProps
): JSX.Element => {
  const [isColorPickerOpen, setIsColorPickerOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { save, getDetails, getLastItemDetails } =
    useSBSSceneObjectTimelineApi();
  const showModalSuccess: (successResult: string) => void =
    useModalSuccess(undefined);
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const [values, setValues] = React.useState<ISBSSceneObjectTimelineData>({
    id: 0,
    gssbsSceneObjectId: 0,
    caption: "",
    startLocalizationEPSG4326: {
      latitude: null,
      longitude: null,
      altitude: null,
    },
    endLocalizationEPSG4326: null,
    startTimeInSeconds: 0,
    endTimeInSeconds: null,
    captionLocalizationAltitude: null,
    height: null,
    width: null,
    vx: null,
    vy: null,
    vz: null,
    graphicVideoPosition:
      SceneObjectTimelineGraphicVideoPosition.OneSideNoFollow,
    labelOnMap: "",
  });

  const handleLoadLastItemData: () => Promise<void> =
    async (): Promise<void> => {
      if (props.sbsSceneObjectId) {
        return await getLastItemDetails(props.sbsSceneObjectId).then(
          (response: ISBSSceneObjectTimelineGetLastItemDetailsResult) => {
            if (response && response.data) {
              let newValues: ISBSSceneObjectTimelineData = { ...values };
              newValues.startLocalizationEPSG4326 = response.data
                ?.endLocalizationEPSG4326 ?? {
                latitude: null,
                longitude: null,
                altitude: null,
              };
              setValues(newValues);
            } else {
              setValues({
                id: 0,
                gssbsSceneObjectId: 0,
                caption: "",
                startLocalizationEPSG4326: {
                  latitude: null,
                  longitude: null,
                  altitude: null,
                },
                endLocalizationEPSG4326: null,
                startTimeInSeconds: 0,
                endTimeInSeconds: null,
                captionLocalizationAltitude: null,
                height: null,
                width: null,
                vx: null,
                vy: null,
                vz: null,
                graphicVideoPosition:
                  SceneObjectTimelineGraphicVideoPosition.OneSideNoFollow,
                labelOnMap: "",
              });
            }
          }
        );
      }
    };

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    if (props.sbsSceneObjectTimelineId) {
      return await getDetails(props.sbsSceneObjectTimelineId).then(
        (response: ISBSSceneObjectTimelineGetDetailsResult) => {
          if (response && response.data) {
            setValues(response.data);
          }
        }
      );
    }
  };

  const handleOpen: () => void = (): void => {
    setIsOpen(true);
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
    props.handleClose();
  };

  const handleSave: (
    sceneObjectTimeline: ISBSSceneObjectTimelineData
  ) => Promise<void> = async (
    sceneObjectTimeline: ISBSSceneObjectTimelineData
  ): Promise<void> => {
    sceneObjectTimeline.gssbsSceneObjectId = props.sbsSceneObjectId;
    await save(sceneObjectTimeline).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("saveChangesSuccess"));
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
        handleClose();
      }
    });
  };

  const handleColorChange: (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<ISBSSceneObjectTimelineData>
  ) => void = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<ISBSSceneObjectTimelineData>
  ): void => {
    let newColor: string = color.hex;
    let newValues: ISBSSceneObjectTimelineData = { ...formik.values };
    newValues.color = newColor;
    formik.setValues(newValues);
  };

  const handleColorClear: (formik: FormikProps<ISBSSceneObjectTimelineData>) => void = (
    formik: FormikProps<ISBSSceneObjectTimelineData>
  ): void => {
    let newValues: ISBSSceneObjectTimelineData = { ...formik.values };
    newValues.color = undefined;
    formik.setValues(newValues);
  };

  const handleColorPickerClose: () => void = (): void => {
    setIsColorPickerOpen(false);
  };

  const handleColorPickerOpen: () => void = (): void => {
    setIsColorPickerOpen(true);
  };

  React.useEffect(() => {
    if (props.isOpen || isOpen) {
      if (props.sbsSceneObjectTimelineId) {
        handleLoadData();
      } else {
        handleLoadLastItemData();
      }
    }
  }, [props.isOpen || isOpen]);

  return (
    <React.Fragment>
      {props.withIcon && props.sbsSceneObjectTimelineId && (
        <ButtonGridIcon
          icon={"fa-edit"}
          onClick={handleOpen}
          color="secondary"
        />
      )}
      {props.withAddNewButton && !props.sbsSceneObjectTimelineId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-route"} color="secondary" />
          {t("animationForm.add")}
        </ButtonPrimary>
      )}
      <Dialog
        open={props.isOpen || isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          {props.sbsSceneObjectTimelineId
            ? t("animationForm.edit")
            : t("animationForm.addInfo")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<ISBSSceneObjectTimelineData>({
              id: Yup.number().required(),
              gssbsSceneObjectId: Yup.number().required(),
              endLocalizationEPSG4326: Yup.object<ILocalization>({
                altitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
                latitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
                longitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
              }).nullable(),
              startLocalizationEPSG4326: Yup.object<ILocalization>({
                altitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .required(t("validationResources:required")),
                latitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .required(t("validationResources:required")),
                longitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .required(t("validationResources:required")),
              }).required(t("validationResources:required")),
              endTimeInSeconds: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .max(1000000, t("validationResources:numericMax1000000"))
                .required(t("validationResources:required")),
              startTimeInSeconds: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .max(1000000, t("validationResources:numericMax1000000"))
                .required(t("validationResources:required")),
              caption: Yup.string()
                .max(
                  100,
                  t("validationResources:stringMaxLength", { length: 100 })
                )
                .nullable(),
              captionLocalizationAltitude: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .max(
                  9999,
                  t("validationResources:numericMaxValue", { value: 9999 })
                )
                .min(
                  -9999,
                  t("validationResources:numericMinValue", { value: -9999 })
                )
                .nullable(),
              graphicVideoPosition: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .required(t("validationResources:required")),
              height: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(
                  0.01,
                  t("validationResources:numericMinValue", { value: 0.01 })
                )
                .max(
                  1000,
                  t("validationResources:numericMaxValue", { value: 1000 })
                )
                .required(t("validationResources:required")),
              width: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(
                  0.01,
                  t("validationResources:numericMinValue", { value: 0.01 })
                )
                .max(
                  1000,
                  t("validationResources:numericMaxValue", { value: 1000 })
                )
                .required(t("validationResources:required")),
              vx: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(0, t("validationResources:numericMinValue", { value: 0 }))
                .max(
                  180,
                  t("validationResources:numericMaxValue", { value: 180 })
                )
                .required(t("validationResources:required")),
              vy: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(0, t("validationResources:numericMinValue", { value: 0 }))
                .max(
                  180,
                  t("validationResources:numericMaxValue", { value: 180 })
                )
                .required(t("validationResources:required")),
              vz: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(0, t("validationResources:numericMinValue", { value: 0 }))
                .max(
                  180,
                  t("validationResources:numericMaxValue", { value: 180 })
                )
                .required(t("validationResources:required")),
              labelOnMap: Yup.string()
                .max(
                  100,
                  t("validationResources:stringMaxLength", { length: 100 })
                )
                .nullable(),
            })}
            onSubmit={(values: ISBSSceneObjectTimelineData) => {
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid container justify="center" alignItems="flex-start">
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        value={formik.values.caption ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(formik.errors.caption && formik.touched.caption)
                        }
                        helperText={
                          formik.errors.caption &&
                          formik.touched.caption &&
                          formik.errors.caption
                        }
                        label={t("caption")}
                        variant="outlined"
                        size="small"
                        name="caption"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={formik.values.captionLocalizationAltitude ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.captionLocalizationAltitude &&
                            formik.touched.captionLocalizationAltitude
                          )
                        }
                        helperText={
                          formik.touched.captionLocalizationAltitude &&
                          formik.errors.captionLocalizationAltitude
                        }
                        label={t("captionAltitude")}
                        variant="outlined"
                        size="small"
                        name="captionLocalizationAltitude"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        value={formik.values.labelOnMap ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.labelOnMap &&
                            formik.touched.labelOnMap
                          )
                        }
                        helperText={
                          formik.errors.labelOnMap &&
                          formik.touched.labelOnMap &&
                          formik.errors.labelOnMap
                        }
                        label={t("labelOnMap")}
                        variant="outlined"
                        size="small"
                        name="labelOnMap"
                      />
                    </Grid>
                    <Grid container item xs={4} spacing={1}>
                        <Grid item xs={10}>
                          <FormControl variant="outlined" size={"small"}>
                            <InputLabel htmlFor="object-timeline-graphic-color">
                              {t("color")}
                            </InputLabel>
                            <OutlinedInput
                              id="object-timeline-graphic-color"
                              name="color"
                              type={"text"}
                              value={formik.values.color ?? ""}
                              onChange={formik.handleChange}
                              disabled={true}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      handleColorClear(formik);
                                    }}
                                    onMouseDown={() => {
                                      handleColorClear(formik);
                                    }}
                                    edge="end"
                                  >
                                    <Close />
                                  </IconButton>
                                </InputAdornment>
                              }
                              labelWidth={70}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <ButtonGridIcon
                            icon={"fa-palette"}
                            onClick={handleColorPickerOpen}
                            iconSize={"20px"}
                            style={{
                              color:
                                formik.values.color ?? "#32CD32",
                              paddingTop: "10px",
                            }}
                          />
                        </Grid>
                      </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={formik.values.startTimeInSeconds ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.startTimeInSeconds &&
                            formik.touched.startTimeInSeconds
                          )
                        }
                        helperText={
                          formik.touched.startTimeInSeconds &&
                          formik.errors.startTimeInSeconds
                        }
                        label={t("animationForm.startTime")}
                        variant="outlined"
                        size="small"
                        name="startTimeInSeconds"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={formik.values.endTimeInSeconds ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.endTimeInSeconds &&
                            formik.touched.endTimeInSeconds
                          )
                        }
                        helperText={
                          formik.touched.endTimeInSeconds &&
                          formik.errors.endTimeInSeconds
                        }
                        label={t("animationForm.endTime")}
                        variant="outlined"
                        size="small"
                        name="endTimeInSeconds"
                      />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        value={formik.values.width ?? ""}
                        onChange={formik.handleChange}
                        error={!!(formik.errors.width && formik.touched.width)}
                        helperText={
                          formik.errors.width &&
                          formik.touched.width &&
                          formik.errors.width
                        }
                        label={t("animationForm.width")}
                        variant="outlined"
                        size="small"
                        name="width"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        value={formik.values.height ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(formik.errors.height && formik.touched.height)
                        }
                        helperText={
                          formik.errors.height &&
                          formik.touched.height &&
                          formik.errors.height
                        }
                        label={t("animationForm.height")}
                        variant="outlined"
                        size="small"
                        name="height"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth size="small" variant="outlined">
                        <InputLabel id="graphicVideoPosition">
                          {t("animationForm.position")}
                        </InputLabel>
                        <Select
                          name="graphicVideoPosition"
                          fullWidth
                          labelId="graphicVideoPosition"
                          value={
                            formik.values.graphicVideoPosition ??
                            SceneObjectTimelineGraphicVideoPosition.OneSideNoFollow
                          }
                          onChange={formik.handleChange}
                        >
                          <MenuItem
                            value={
                              SceneObjectTimelineGraphicVideoPosition.OneSideNoFollow
                            }
                          >
                            {t(
                              "enumResources:sceneObjectTimelineGraphicVideoPosition.oneSideNoFollow"
                            )}
                          </MenuItem>
                          <MenuItem
                            value={
                              SceneObjectTimelineGraphicVideoPosition.OneSideFollow
                            }
                          >
                            {t(
                              "enumResources:sceneObjectTimelineGraphicVideoPosition.oneSideFollow"
                            )}
                          </MenuItem>
                          <MenuItem
                            value={
                              SceneObjectTimelineGraphicVideoPosition.TwoSidesNoFollow
                            }
                          >
                            {t(
                              "enumResources:sceneObjectTimelineGraphicVideoPosition.twoSidesNoFollow"
                            )}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        value={formik.values.vx ?? ""}
                        onChange={formik.handleChange}
                        error={!!(formik.errors.vx && formik.touched.vx)}
                        helperText={
                          formik.errors.vx &&
                          formik.touched.vx &&
                          formik.errors.vx
                        }
                        label={"L"}
                        variant="outlined"
                        size="small"
                        name="vx"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        value={formik.values.vy ?? ""}
                        onChange={formik.handleChange}
                        error={!!(formik.errors.vy && formik.touched.vy)}
                        helperText={
                          formik.errors.vy &&
                          formik.touched.vy &&
                          formik.errors.vy
                        }
                        label={"B"}
                        variant="outlined"
                        size="small"
                        name="vy"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        value={formik.values.vz ?? ""}
                        onChange={formik.handleChange}
                        error={!!(formik.errors.vz && formik.touched.vz)}
                        helperText={
                          formik.errors.vz &&
                          formik.touched.vz &&
                          formik.errors.vz
                        }
                        label={t("height")}
                        variant="outlined"
                        size="small"
                        name="vz"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={12}>
                      <Divider />
                      <TextInfo color={"secondary"}>
                        {t("animationForm.startPosition")}
                      </TextInfo>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.startLocalizationEPSG4326?.longitude ??
                          ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "startLocalizationEPSG4326.longitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "startLocalizationEPSG4326.longitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.touched,
                            "startLocalizationEPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "startLocalizationEPSG4326.longitude"
                          )
                        }
                        label="L"
                        variant="outlined"
                        size="small"
                        name="startLocalizationEPSG4326.longitude"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.startLocalizationEPSG4326?.latitude ??
                          ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "startLocalizationEPSG4326.latitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "startLocalizationEPSG4326.latitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.touched,
                            "startLocalizationEPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "startLocalizationEPSG4326.latitude"
                          )
                        }
                        label="B"
                        variant="outlined"
                        size="small"
                        name="startLocalizationEPSG4326.latitude"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.startLocalizationEPSG4326?.altitude ??
                          ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "startLocalizationEPSG4326.altitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "startLocalizationEPSG4326.altitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.touched,
                            "startLocalizationEPSG4326.altitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "startLocalizationEPSG4326.altitude"
                          )
                        }
                        label={t("height")}
                        variant="outlined"
                        size="small"
                        name="startLocalizationEPSG4326.altitude"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={12}>
                      <Divider />
                      <TextInfo color={"secondary"}>
                        {t("animationForm.endPosition")}
                      </TextInfo>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.endLocalizationEPSG4326?.longitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "endLocalizationEPSG4326.longitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "endLocalizationEPSG4326.longitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.errors,
                            "endLocalizationEPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "endLocalizationEPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "endLocalizationEPSG4326.longitude"
                          )
                        }
                        label="L"
                        variant="outlined"
                        size="small"
                        name="endLocalizationEPSG4326.longitude"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.endLocalizationEPSG4326?.latitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "endLocalizationEPSG4326.latitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "endLocalizationEPSG4326.latitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.errors,
                            "endLocalizationEPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "endLocalizationEPSG4326.latitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "endLocalizationEPSG4326.latitude"
                          )
                        }
                        label="B"
                        variant="outlined"
                        size="small"
                        name="endLocalizationEPSG4326.latitude"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={
                          formik.values.endLocalizationEPSG4326?.altitude ?? ""
                        }
                        onChange={formik.handleChange}
                        error={
                          !!(
                            getIn(
                              formik.errors,
                              "endLocalizationEPSG4326.altitude"
                            ) &&
                            getIn(
                              formik.touched,
                              "endLocalizationEPSG4326.altitude"
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.errors,
                            "endLocalizationEPSG4326.altitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "endLocalizationEPSG4326.altitude"
                          ) &&
                          getIn(
                            formik.errors,
                            "endLocalizationEPSG4326.altitude"
                          )
                        }
                        label={t("height")}
                        variant="outlined"
                        size="small"
                        name="endLocalizationEPSG4326.altitude"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Dialog
                  open={isColorPickerOpen}
                  onClose={handleColorPickerClose}
                  scroll={"paper"}
                  maxWidth={"sm"}
                >
                  <DialogContent>
                    <SketchPicker
                      color={
                        formik.values.color
                          ? hexToRgb(formik.values.color)
                          : ""
                      }
                      disableAlpha
                      presetColors={[]}
                      onChange={(
                        color: ColorResult,
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleColorChange(color, event, formik);
                      }}
                    />
                  </DialogContent>
                </Dialog>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SBSSceneObjectTimelineGraphicVideoEditorAdmin;
