import * as _ from "lodash";
import * as $ from "jquery";
import { IBaseDataApi, IBaseDataApiInterface } from "./interfaces";
import { useBaseApi } from "./base-api";

function useBaseDataApi(props: IBaseDataApi): IBaseDataApiInterface {
    const { baseDeleteRequest, baseGetRequest, basePostRequest } = useBaseApi();

    async function baseDeleteById<T>(id: number): Promise<T> {
        let url: string = `/${props.apiControllerName}/Delete/${id}`;
        return await baseDeleteRequest<T>(url);
    }

    async function baseGetDetails<T>(id?: number): Promise<T> {
        let url: string = id
            ? `/${props.apiControllerName}/GetDetails/${id}`
            : `/${props.apiControllerName}/GetDetails`;
        return await baseGetRequest<T>(url);
    }

    async function baseGetList<modelT, resultT>(model?: modelT): Promise<resultT> {
        let url: string = `/${props.apiControllerName}/GridGetList`;
        if(model) {
            let paramsArray: string[] = $.map(model, (value, key) => {
                return key ? `${[key]}=${value}` : "";
            });
            let params: string = _.join(paramsArray, "&");
            url += `?${params}`;
        }
        return await baseGetRequest<resultT>(url);
    }

    async function baseSave<modelT, resultT>(model: modelT): Promise<resultT> {
        let url: string = `/${props.apiControllerName}/Save`;
        return await basePostRequest<modelT, resultT>(url, model);
    }

    return { baseDeleteById, baseGetDetails, baseGetList, baseSave };
};

export default useBaseDataApi;