import { IMapMarker } from "Components/Maps/interfaces";
import _ from "lodash";
import { ILocalization } from "Services/interfaces";
import { ISBSSceneObjectTimelinePointData } from "Services/SBSSceneObjectTimelineApi/interfaces";
import { MAP_MIN_RADIUS_POINTS_MAGNES } from "./consts";

//TODO: stworzenie metody do wyliczania listy dystansow

export const CheckIsPointOnAnyMagnesRadius: (
  point: ILocalization,
  markerList: IMapMarker[]
) => number = (point: ILocalization, markerList: IMapMarker[]): number => {
  const distancePointList = _.map(markerList, (v) => {
    return {
      id: v.id,
      distance: CalculateDistanceBetweenPoints(v.point, point),
    };
  });
  const minDistance = _.minBy(distancePointList, (d) => d.distance);
  if ((minDistance?.distance ?? 1000) <= MAP_MIN_RADIUS_POINTS_MAGNES) {
    return minDistance?.id ?? -1;
  }
  return -1;
};

export const CalculateDistanceBetweenPoints: (
  point1: ILocalization,
  point2: ILocalization
) => number = (point1: ILocalization, point2: ILocalization): number => {
  return Math.sqrt(
    Math.pow(point1.latitude! - point2.latitude!, 2) +
      Math.pow(point1.longitude! - point2.longitude!, 2)
  );
};

export const GenerateNewPointList: (
  point: ILocalization,
  markerList: IMapMarker[]
) => ISBSSceneObjectTimelinePointData[] = (
  point: ILocalization,
  markerCopy: IMapMarker[]
): ISBSSceneObjectTimelinePointData[] => {
  const distancePointList = _.map(markerCopy, (v) => {
    return {
      id: v.id,
      distance: CalculateDistanceBetweenPoints(v.point, point),
    };
  });

  const distanceLineList: number[] = [];
  for (let i = 0; i < markerCopy.length - 1; i++) {
    distanceLineList.push(
      CalculateDistanceBetweenPoints(
        markerCopy[i].point,
        markerCopy[i + 1].point
      )
    );
  }

  const distancePointLineList: number[] = [];
  let lineListIndex: number = 0;
  let pointListIndex: number = 0;
  while (pointListIndex < distancePointList.length) {
    distancePointLineList.push(
      distancePointList[pointListIndex].distance /
        distanceLineList[lineListIndex]
    );
    if (pointListIndex === lineListIndex) {
      pointListIndex += 1;
    } else {
      lineListIndex += 1;
    }
  }

  const minDistance = _.min(distancePointLineList) ?? 1000;
  let newMarkerList: ISBSSceneObjectTimelinePointData[] = [];
  if (distancePointList[distancePointList.length - 1].distance < minDistance) {
    newMarkerList = _.concat(
      _.concat(
        _.map(markerCopy, (v) => {
          return { id: v.id, localizationEPSG4326: v.point };
        })
      ),
      {
        id: undefined,
        localizationEPSG4326: point,
      } as ISBSSceneObjectTimelinePointData
    );
  } else if (distancePointList[0].distance < minDistance) {
    newMarkerList = _.concat(
      {
        id: undefined,
        localizationEPSG4326: point,
      } as ISBSSceneObjectTimelinePointData,
      _.concat(
        _.map(markerCopy, (v) => {
          return { id: v.id, localizationEPSG4326: v.point };
        })
      )
    );
  } else {
    const minIndex = Math.floor(
      _.findIndex(distancePointLineList, (v) => {
        return v === minDistance;
      }) / 2
    );

    const a = _.slice(markerCopy, 0, minIndex + 1);
    const b = _.slice(markerCopy, minIndex + 1, distancePointList.length);
    newMarkerList = _.concat(
      _.map(a, (v) => {
        return { id: v.id, localizationEPSG4326: v.point };
      }),
      {
        id: undefined,
        localizationEPSG4326: point,
      } as ISBSSceneObjectTimelinePointData,
      _.map(b, (v) => {
        return { id: v.id, localizationEPSG4326: v.point };
      })
    );
  }

  return newMarkerList;
};

export const RegeneratePointListWithLocalizationChange: (
  id: number,
  oldPoint: ILocalization,
  newPoint: ILocalization,
  markerList: IMapMarker[]
) => ISBSSceneObjectTimelinePointData[] = (
  id: number,
  oldPoint: ILocalization,
  newPoint: ILocalization,
  markerList: IMapMarker[]
): ISBSSceneObjectTimelinePointData[] => {
  let editedMarkerIndex = _.findIndex(markerList, (v) => {
    return v.id === id && ComparePoints(v.point, oldPoint);
  });
  if (editedMarkerIndex > -1) {
    markerList[editedMarkerIndex].point = newPoint;
  }
  return _.map(markerList, (v) => {
    return { id: v.id, localizationEPSG4326: v.point };
  });
};

const ComparePoints: (p1: ILocalization, p2: ILocalization) => boolean = (
  p1: ILocalization,
  p2: ILocalization
): boolean => {
  return p1.longitude === p2.longitude && p1.latitude === p2.latitude;
};
