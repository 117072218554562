import * as React from "react";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { IGridModel, ILocalization, IResultBase } from "Services/interfaces";
import {
  ApiResultStatus,
  ObjectType,
  SBSSceneObjectGridGetListColumn,
  SBSSceneStatus,
} from "Utils/enums";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import { Avatar, Container, Divider, Grid } from "@material-ui/core";
import { ISBSSceneObjectGetListModel } from "Services/SBSSceneObjectApi/interfaces-model";
import { ISBSSceneObjectData } from "Services/SBSSceneObjectApi/interfaces";
import { ISBSSceneObjectGetListResult } from "Services/SBSSceneObjectApi/interfaces-result";
import { ISBSSceneObjectListProps } from "../interfaces";
import {
  IAnimationIstance,
  IAnimationMarker,
  IMapMarker,
  IMapPolyline,
} from "Components/Maps/interfaces";
import { ISBSSceneObjectTimelineGetListResult } from "Services/SBSSceneObjectTimelineApi/interfaces-result";
import { ISBSSceneObjectTimelineEditorDispatcher } from "Components/SceneObjectTimelines/interfaces";
import MapBasic from "Components/Maps/map-basic";
import GridTable from "Components/Grids/grid-table";
import TextTitle from "Components/Texts/text-title";
import useModalSuccess from "Hooks/use-modal-success";
import useModalQuestion from "Hooks/use-modal-question";
import useSBSSceneObjectApi from "Services/SBSSceneObjectApi/sbs-scene-object-api";
import SBSSceneObjectAddAdmin from "../SceneObjectEditor/sbs-scene-object-add-admin";
import SBSSceneObjectEditorAdmin from "../SceneObjectEditor/sbs-scene-object-editor-admin";
import useSBSSceneObjectTimelineApi from "Services/SBSSceneObjectTimelineApi/sbs-scene-object-timeline-api";
import SBSSceneObjectTimelineEditorAdmin from "Components/SceneObjectTimelines/SceneObjectTimelineEdiotr/sbs-scene-object-timeline-editor-admin";
import SBSSceneObjectTimelineList from "Components/SceneObjectTimelines/SceneObjectTimelineList/sbs-scene-object-timeline-list";
import { useCookies } from "react-cookie";
import {
  ISBSSceneObjectTimelineData,
  ISBSSceneObjectTimelineImportCsvFileModel,
  ISBSSceneObjectTimelinePointData,
} from "Services/SBSSceneObjectTimelineApi/interfaces";
import SceneObjectTimelineImport from "Components/SceneObjectTimelines/SceneObjectTimelineImport/scene-object-timeline-import";
import { getStoredUserAuthToken } from "Utils/helpers";
import axios from "axios";
import { MAP_ANIMATION_FRAME_PER_SECOND } from "Utils/consts";
import SBSSceneObjectTimelineValidator from "Components/SceneObjectTimelines/SceneObjectTimelineValidator/sbs-scene-object-timeline-validator";
import SBSSceneObjectValidator from "../SceneObjectValidator/sbs-scene-object-validator";
import {
  GenerateNewPointList,
  RegeneratePointListWithLocalizationChange,
} from "Utils/helper-point";
import SBSSceneObjectAddPoint from "../SceneObjectEditor/sbs-scene-object-add-point";
import { useSBSSceneApi } from "Services/SBSSceneApi/sbs-scene-api";
import { ISBSSceneGetDetailsResult } from "Services/SBSSceneApi/interfaces-result";
import { ISBSSceneData } from "Services/SBSSceneApi/interfaces";
import { stylesSceneObjectList } from "./styles";

const envBaseURL: string =
  process.env.REACT_APP_API_BASE_URL ?? "https://webapi.gsot.pl/api";
const envApiVersion: string = process.env.REACT_APP_API_VERSION ?? "v1";
type EditAction = "ADD_POINT" | "ADD_TRACE" | undefined;

const SBSSceneObjectList: () => JSX.Element = (): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { getDetails } = useSBSSceneApi();
  const { deleteById, getList } = useSBSSceneObjectApi();
  const {
    getList: getTimelinesList,
    savePoints,
    importCSVFile,
  } = useSBSSceneObjectTimelineApi();
  const [mapPolylineList, setMapPolylineList] = React.useState<IMapPolyline[]>(
    []
  );
  const [markerList, setMarkerList] = React.useState<ILocalization[]>([]);
  const [markerListWithOnClick, setMarkerListWithOnClick] = React.useState<
    IMapMarker[]
  >([]);
  const [markerListWithDragDrop, setMarkerListWithDragDrop] = React.useState<
    IMapMarker[]
  >([]);
  const [animationInstaceList, setAnimationInstanceList] = React.useState<
    IAnimationIstance[]
  >([]);
  const [animationFrames, setAnimationFrames] = React.useState<number>(0);
  const [editedSceneObjectId, setEditedSceneObjectId] =
    React.useState<number>();
  const [markerColor, setMarkerColor] = React.useState<string>();
  const { t } = useTranslation(["commonResources", "sbsSceneObjectTimelineForm"]);
  const { sbsSceneId } = useParams<ISBSSceneObjectListProps>();
  const [cookies, setCookie] = useCookies(["isSceneFinished"]);
  const [editorProps, setEditorProps] =
    React.useState<ISBSSceneObjectTimelineEditorDispatcher>({
      isOpen: false,
      sbsSceneObjectId: 0,
      sbsSceneObjectType: ObjectType.Graphic,
    });
  const [editAction, setEditAction] = React.useState<EditAction>(undefined);
  const [timelineIdList, setTimelineIdList] = React.useState<number[]>([]);
  const [sceneData, setSceneData] = React.useState<ISBSSceneData>({
    name: "",
    description: "",
    status: SBSSceneStatus.Draft,
  });
  const history = useHistory();
  const customStyles: Record<string, string> = stylesSceneObjectList();
  const isSceneFinished: boolean = cookies.isSceneFinished === "true";

  const handleDragEnd: (
    id: number,
    parentId: number,
    oldPoint: ILocalization,
    newPoint: ILocalization
  ) => void = (
    id: number,
    parentId: number,
    oldPoint: ILocalization,
    newPoint: ILocalization
  ): void => {
    const markerCopy = _.filter([...markerListWithDragDrop], (v) => {
      return v.parentObjectId === parentId;
    });
    const newMarkerList = RegeneratePointListWithLocalizationChange(
      id,
      oldPoint,
      newPoint,
      markerCopy
    );
    handleSave(parentId, newMarkerList, true);
  };

  const handleClickAdd: (id: number) => void = (id: number): void => {
    setEditedSceneObjectId(id);
    setEditAction("ADD_POINT");
  };

  const handleClickDelete: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    showModalQuestion(t("sbsSceneObjectForm.deleteInfo"), id);
  };

  const handleClickBack: () => void = (): void => {
    history.push(`/sbs-scenes`);
  };

  const handleCloseEditor: () => void = (): void => {
    let newEditorProps = { ...editorProps };
    newEditorProps.isOpen = false;
    setEditorProps(newEditorProps);
    dispatchReloadTable();
  };

  const handleImport: (
    file: File,
    objectId: number
  ) => Promise<IResultBase> = async (
    file: File,
    objectId: number
  ): Promise<IResultBase> => {
    const model: ISBSSceneObjectTimelineImportCsvFileModel = {
      File: file,
      GSSBSSceneObjectId: objectId,
    };
    return await importCSVFile(model);
  };

  const handleRowSelectedChange: (data: ISBSSceneObjectData[]) => void = (
    data: ISBSSceneObjectData[]
  ): void => {
    let newAnimationInstanceList = _.map({ ...animationInstaceList }, (a) => {
      _.each(a.animationPoints, (ap) => {
        ap.isVisible =
          _.findIndex(data, (d) => d.id === ap.parentObjectId) >= 0;
      });
      return a;
    });
    setAnimationInstanceList(newAnimationInstanceList);

    const markerList: IMapMarker[] = _.map(
      { ...markerListWithOnClick },
      (m) => {
        m.isVisible = _.findIndex(data, (d) => d.id === m.parentObjectId) >= 0;
        return m;
      }
    );
    setMarkerListWithOnClick(markerList);

    const newMarkerListWithDragDrop: IMapMarker[] = _.map(
      { ...markerListWithDragDrop },
      (m) => {
        m.isVisible = _.findIndex(data, (d) => d.id === m.parentObjectId) >= 0;
        return m;
      }
    );
    setMarkerListWithDragDrop(newMarkerListWithDragDrop);

    let newPolylineList = _.map({ ...mapPolylineList }, (p) => {
      p.isVisible = _.findIndex(data, (d) => d.id === p.parentObjectId) >= 0;
      return p;
    });
    setMapPolylineList(newPolylineList);
  };

  const deleteSBSScene: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await deleteById(id).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("sbsSceneObjectForm.deleteSuccess"));
        dispatchReloadTable();
      }
    });
  };

  const loadTimelines: () => void = (): void => {
    if (parseInt(sbsSceneId ?? "0") > 0) {
      getTimelinesList({ GSSBSSceneId: parseInt(sbsSceneId!) }).then(
        (response: ISBSSceneObjectTimelineGetListResult) => {
          if (response && response.status === ApiResultStatus.Ok) {
            setTimelineIdList(
              _.uniq(
                _.map(response.data.gridData, (v, i) => {
                  return v.gssbsSceneObjectId!;
                }) ?? []
              )
            );

            let notEmptyValues = _.filter(response.data.gridData, (w) => {
              return !!w.endLocalizationEPSG4326;
            });

            const currentMarkerListWithOnClick = [...markerListWithOnClick];
            const lineCenterMarkerList: IMapMarker[] = _.map(
              notEmptyValues,
              (v) => {
                let centerPoint: ILocalization = {
                  longitude:
                    (v.endLocalizationEPSG4326!.longitude! +
                      v.startLocalizationEPSG4326!.longitude!) /
                    2,
                  latitude:
                    (v.endLocalizationEPSG4326!.latitude! +
                      v.startLocalizationEPSG4326!.latitude!) /
                    2,
                };
                return {
                  point: centerPoint,
                  id: v.id!,
                  isVisible:
                    _.find(currentMarkerListWithOnClick, (m) => m.id === v.id)
                      ?.isVisible ?? true,
                  objectType: v.sbsObject?.type ?? ObjectType.Sound,
                  parentObjectId: v.gssbsSceneObjectId!,
                  tooltipText: v.labelOnMap ?? undefined,
                  handlerOnClick: handleClickMarkerMap,
                };
              }
            );
            setMarkerListWithOnClick(lineCenterMarkerList);

            const groupedByObjectId = _.chain([...notEmptyValues])
              .groupBy((v) => {
                return v.gssbsSceneObjectId;
              })
              .map((v) => {
                let additionalFirstItem: ISBSSceneObjectTimelineData = {
                  ...v[0],
                };
                additionalFirstItem.endLocalizationEPSG4326 =
                  additionalFirstItem.startLocalizationEPSG4326;
                notEmptyValues = _.concat(additionalFirstItem, notEmptyValues);
                return [additionalFirstItem, ...v];
              })
              .value();

            const currentMapPolylineList = [...mapPolylineList];
            const mapObjectList = _.map(groupedByObjectId, (v) => {
              return {
                pointList:
                  _.map(v, (w) => {
                    return w.endLocalizationEPSG4326!;
                  }) ?? [],
                color: _.first(v)?.sbsObject?.markerColor ?? "#32CD32",
                isVisible:
                  _.find(
                    currentMapPolylineList,
                    (m) => m.parentObjectId === _.first(v)?.gssbsSceneObjectId
                  )?.isVisible ?? true,
                parentObjectId: _.first(v)?.gssbsSceneObjectId,
              };
            });
            setMapPolylineList(mapObjectList);

            const currentMarkerListWithDragDrop = [...markerListWithDragDrop];
            const newMarkerListWithDragDrop: IMapMarker[] = _.map(
              notEmptyValues,
              (v) => {
                return {
                  point: v.endLocalizationEPSG4326!,
                  id: v.id!,
                  isVisible:
                    _.find(currentMarkerListWithDragDrop, (m) => m.id === v.id)
                      ?.isVisible ?? true,
                  objectType: v.sbsObject?.type ?? ObjectType.Sound,
                  parentObjectId: v.gssbsSceneObjectId!,
                };
              }
            );
            setMarkerListWithDragDrop(newMarkerListWithDragDrop);

            const maxTime = _.max(
              _.map(notEmptyValues, (v) => {
                return v.endTimeInSeconds;
              })
            );
            const minTime = _.min(
              _.map(notEmptyValues, (v) => {
                return v.startTimeInSeconds;
              })
            );
            let newAnimationInstanceList: IAnimationIstance[] = [];
            const timeInSec = (maxTime ?? 0) + 1 - (minTime ?? 0);
            const allFrames = timeInSec * MAP_ANIMATION_FRAME_PER_SECOND;

            setAnimationFrames(allFrames);
            const notEmptyValuesWithTime = _.filter(
              [...notEmptyValues],
              (v) => {
                return (
                  (v.startTimeInSeconds === 0 || !!v.startTimeInSeconds) &&
                  !!v.endTimeInSeconds &&
                  v.startLocalizationEPSG4326?.latitude !=
                    v.endLocalizationEPSG4326?.latitude &&
                  v.startLocalizationEPSG4326?.longitude !=
                    v.endLocalizationEPSG4326?.longitude
                );
              }
            );

            let braekpointList: {
              id: number;
              subIndex: number;
              lastTime: number;
            }[] = [];

            const currentAnimationInstanceList = [...animationInstaceList];
            for (let i = 0; i < allFrames; i++) {
              let valuesToAnimate =
                _.filter(notEmptyValuesWithTime, (n) => {
                  return (
                    n.startTimeInSeconds * MAP_ANIMATION_FRAME_PER_SECOND <=
                      i &&
                    ((n.endTimeInSeconds ?? 0) + 1) *
                      MAP_ANIMATION_FRAME_PER_SECOND >
                      i &&
                    !!n.startLocalizationEPSG4326 &&
                    !!n.endLocalizationEPSG4326
                  );
                }) ?? [];

              let animationSceneMarkerList: IAnimationMarker[] = [];

              _.each(valuesToAnimate, (v) => {
                const time = Math.round(
                  (v.endTimeInSeconds ?? 0) + 1 - v.startTimeInSeconds
                );

                let breakpointIndex = _.findIndex(braekpointList, (b, i) => {
                  return b.id === v.id;
                });
                if (breakpointIndex < 0) {
                  braekpointList.push({
                    id: v.id!,
                    lastTime: time,
                    subIndex: i,
                  });
                  breakpointIndex = _.findIndex(braekpointList, (b, i) => {
                    return b.id === v.id;
                  });
                }

                if (time !== braekpointList[breakpointIndex].lastTime) {
                  braekpointList[breakpointIndex].lastTime = time;
                  braekpointList[breakpointIndex].subIndex = i;
                }

                const stepCount = time * MAP_ANIMATION_FRAME_PER_SECOND;
                let animationIndexInSector =
                  (i - braekpointList[breakpointIndex].subIndex) % stepCount;

                const stepX =
                  ((v!.endLocalizationEPSG4326!.latitude ?? 0) -
                    (v!.startLocalizationEPSG4326!.latitude ?? 0)) /
                  stepCount;
                const stepY =
                  ((v!.endLocalizationEPSG4326!.longitude ?? 0) -
                    (v!.startLocalizationEPSG4326!.longitude ?? 0)) /
                  stepCount;
                
                const isAnimationPointVisible = currentAnimationInstanceList.length === 0
                || _.findIndex(
                  currentAnimationInstanceList,
                  (c) =>
                    _.findIndex(
                      c.animationPoints,
                      (a) =>
                        a.parentObjectId === v.gssbsSceneObjectId && a.isVisible
                    ) >= 0
                ) >= 0;
                animationSceneMarkerList.push({
                  point: {
                    latitude:
                      v!.startLocalizationEPSG4326!.latitude! +
                      stepX * animationIndexInSector,
                    longitude:
                      v!.startLocalizationEPSG4326!.longitude! +
                      stepY * animationIndexInSector,
                  },
                  color: v.color ?? v.sbsObject?.markerColor ?? "#32CD32",
                  size: v.sbsObject?.markerSize ?? 20,
                  isVisible: isAnimationPointVisible,
                  parentObjectId: v.gssbsSceneObjectId,
                  tooltipText: v.labelOnMap ?? undefined
                });
              });
              newAnimationInstanceList.push({
                frameNo: i,
                animationPoints: animationSceneMarkerList,
              });
            }
            setAnimationInstanceList(newAnimationInstanceList);
          }
        }
      );
    }
  };

  const loadDetails: () => void = (): void => {
    if (parseInt(sbsSceneId ?? "0") > 0) {
      getDetails(parseInt(sbsSceneId!)).then(
        (response: ISBSSceneGetDetailsResult) => {
          if (response?.data) {
            setSceneData(response.data);
          }
        }
      );
    }
  };

  React.useEffect(() => {
    loadTimelines();
  }, [reloadTable]);

  React.useEffect(() => {
    loadDetails();
    setYesFunction(() => deleteSBSScene);
  }, []);

  const columnSortables: string[] = [
    "sbsObject.name",
    "sbsObject.typeText",
    "sbsObject.file.originalFileName",
    "description",
    "timelinesCount",
  ];

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "sbsObject",
      label: "SBSObject",
      options: {
        display: "false",
      },
    },
    {
      name: "previousObjectName",
      label: "previousObjectName",
      options: {
        display: "false",
      },
    },
    {
      name: "timelinesCount",
      label: "timelinesCount",
      options: {
        display: "false",
      },
    },
    {
      name: "startTime",
      label: "startTime",
      options: {
        display: "false",
      },
    },
    {
      name: "endTime",
      label: "endTime",
      options: {
        display: "false",
      },
    },
    {
      name: "sbsObject",
      label: t("name"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let previousName: string = tableMeta.rowData[2]
            ? `(${tableMeta.rowData[2]})`
            : "";
          return `${value.name} ${previousName}`;
        },
      },
    },
    {
      name: "sbsObject",
      label: t("type"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.typeText;
        },
      },
    },
    {
      name: "sbsObject",
      label: t("file"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.file.originalFileName;
        },
      },
    },
    {
      name: "sbsObject",
      label: t("preview"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.quicklookFile?.discFileName 
          ? <Avatar
              alt={value.quicklookFile.originalFileName}
              className={customStyles.imageContainer}
              src={`${envBaseURL}/${envApiVersion}/File/Download/${value.quicklookFile.discFileName}`}
              variant="square"
            /> 
          : "";
        },
      },
    },
    {
      name: "",
      label: t("editLabel"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <SBSSceneObjectEditorAdmin
              sbsSceneObjectId={tableMeta.rowData[0]}
              objectType={tableMeta.rowData[1].type}
              handleSaveSuccess={dispatchReloadTable}
              disabled={isSceneFinished}
            />
          );
        },
        setCellProps: (
          cellValue: string,
          rowIndex: number,
          columnIndex: number
        ) => {
          return {
            className: "compact",
          };
        },
        display: editedSceneObjectId ? "false" : "true",
      },
    },
    {
      name: "",
      label: t("trajectory"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const objectType: ObjectType = tableMeta.rowData[1].type;
          if (objectType !== ObjectType.Sound) {
            return (
              <ButtonGridIcon
                icon="fa-drafting-compass"
                title={t(
                  "sbsSceneObjectTimelineForm.determiningTrajectoryPoints"
                )}
                onClick={() => {
                  setEditAction("ADD_TRACE");
                  setMarkerColor(tableMeta.rowData[1].markerColor);
                  setEditedSceneObjectId(tableMeta.rowData[0]);
                }}
                style={{ padding: 0 }}
              />
            );
          }
        },
        setCellProps: (
          cellValue: string,
          rowIndex: number,
          columnIndex: number
        ) => {
          return {
            className: "compact",
          };
        },
        display: editedSceneObjectId ? "false" : "true",
      },
    },
    {
      name: "",
      label: t("pointList"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const objectType: ObjectType = tableMeta.rowData[1].type;
          return (
            <SBSSceneObjectTimelineList
              sbsSceneObjectId={tableMeta.rowData[0]}
              disabled={isSceneFinished}
              isOpenEditor={editorProps.isOpen}
              sceneObjectType={objectType}
              handleCloseEditor={handleCloseEditor}
            />
          );
        },
        setCellProps: (
          cellValue: string,
          rowIndex: number,
          columnIndex: number
        ) => {
          return {
            className: "compact",
          };
        },
        display: editedSceneObjectId ? "false" : "true",
      },
    },
    {
      name: "",
      label: t("importCSV"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const objectType: ObjectType = tableMeta.rowData[1].type;
          if (!isSceneFinished && objectType === ObjectType.Model3D) {
            return (
              <SceneObjectTimelineImport
                buttonSize="sm"
                sceneObjectId={tableMeta.rowData[0]}
                handleImport={handleImport}
                handleSaveSuccess={dispatchReloadTable}
              />
            );
          }
        },
        setCellProps: (
          cellValue: string,
          rowIndex: number,
          columnIndex: number
        ) => {
          return {
            className: "compact",
          };
        },
        display: editedSceneObjectId ? "false" : "true",
      },
    },
    {
      name: "",
      label: t("exportCSV"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const objectType: ObjectType = tableMeta.rowData[1].type;
          if (objectType === ObjectType.Model3D) {
            return (
              <ButtonGridIcon
                icon="fa-file-csv"
                title={t("exportToCsv")}
                onClick={() => {
                  axios({
                    url: `${envBaseURL}/${envApiVersion}/SBSSceneObjectTimeline/ExportToCsv`,
                    method: "GET",
                    responseType: "blob", // important
                    params: {
                      gsSBSSceneObjectId: tableMeta.rowData[0],
                    },
                    headers: {
                      Authorization: getStoredUserAuthToken(),
                    },
                  }).then((response) => {
                    let fileDownload = require("js-file-download");
                    fileDownload(response.data, `${t("animations")}.csv`);
                  });
                }}
                style={{ padding: 0 }}
              />
            );
          }
        },
        setCellProps: (
          cellValue: string,
          rowIndex: number,
          columnIndex: number
        ) => {
          return {
            className: "compact",
          };
        },
        display: editedSceneObjectId ? "false" : "true",
      },
    },
    {
      name: "",
      label: t("addMap"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const objectType: ObjectType = tableMeta.rowData[1].type;
          const timelineCount: number = tableMeta.rowData[3];
          if (
            !isSceneFinished &&
            objectType !== ObjectType.Sound &&
            timelineCount > 0
          ) {
            return (
              <ButtonGridIcon
                icon="fa-plus-circle"
                color="primary"
                title={t("add")}
                onClick={() => {
                  handleClickAdd(tableMeta.rowData[0]);
                }}
                style={{ padding: 0 }}
              />
            );
          }
        },
        setCellProps: (
          cellValue: string,
          rowIndex: number,
          columnIndex: number
        ) => {
          return {
            className: "compact",
          };
        },
        display: editedSceneObjectId ? "false" : "true",
      },
    },
    {
      name: "",
      label: t("addTime"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <SBSSceneObjectAddPoint
              sbsSceneObjectId={tableMeta.rowData[0]}
              startTime={tableMeta.rowData[4]}
              endTime={tableMeta.rowData[5]}
              handleSaveSuccess={dispatchReloadTable}
            />
          );
        },
        setCellProps: (
          cellValue: string,
          rowIndex: number,
          columnIndex: number
        ) => {
          return {
            className: "compact",
          };
        },
        display: editedSceneObjectId ? "false" : "true",
      },
    },
    {
      name: "",
      label: t("validate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!isSceneFinished) {
            return (
              <SBSSceneObjectTimelineValidator
                sbsSceneObjectTimelineId={tableMeta.rowData[0]}
              />
            );
          }
        },
        setCellProps: (
          cellValue: string,
          rowIndex: number,
          columnIndex: number
        ) => {
          return {
            className: "compact",
          };
        },
        display: editedSceneObjectId ? "false" : "true",
      },
    },
    {
      name: "",
      label: t("delete"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!isSceneFinished) {
            return (
              <ButtonGridIcon
                icon="fa-trash-alt"
                color="primary"
                title={t("delete")}
                onClick={() => {
                  handleClickDelete(tableMeta.rowData[0]);
                }}
                style={{ padding: 0 }}
              />
            );
          }
        },
        setCellProps: (
          cellValue: string,
          rowIndex: number,
          columnIndex: number
        ) => {
          return {
            className: "compact",
          };
        },
        display: editedSceneObjectId ? "false" : "true",
      },
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "sbsObject.name":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = SBSSceneObjectGridGetListColumn.Name;
        break;
      case "sbsObject.typeText":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn = SBSSceneObjectGridGetListColumn.Type;
        break;
      case "sbsObject.file.originalFileName":
        newSortParams.columnIndex = 2;
        newSortParams.orderColumn =
          SBSSceneObjectGridGetListColumn.OriginalFileName;
        break;
      case "description":
        newSortParams.columnIndex = 3;
        newSortParams.orderColumn = SBSSceneObjectGridGetListColumn.Description;
        break;
      case "timelinesCount":
        newSortParams.columnIndex = 4;
        newSortParams.orderColumn =
          SBSSceneObjectGridGetListColumn.TimelinesCount;
        break;
    }

    return newSortParams;
  };

  const generateParams: (
    tableState: IGridModel
  ) => ISBSSceneObjectGetListModel = (
    tableState: IGridModel
  ): ISBSSceneObjectGetListModel => {
    let params: ISBSSceneObjectGetListModel = { ...tableState };
    if (parseInt(sbsSceneId ?? "0") > 0) {
      params = {
        gsSBSSceneId: parseInt(sbsSceneId!),
        ...tableState,
      };
    }
    return params;
  };

  const handleClickMap: (point: ILocalization) => void = (
    point: ILocalization
  ): void => {
    if (!!editedSceneObjectId) {
      if (editAction === "ADD_TRACE") {
        let newMarkerList = [...markerList];
        newMarkerList.push(point);
        setMarkerList(newMarkerList);
      } else if (editAction === "ADD_POINT") {
        const markerCopy = _.filter([...markerListWithOnClick], (v) => {
          return v.parentObjectId === editedSceneObjectId;
        });
        const newMarkerList = GenerateNewPointList(point, markerCopy);
        handleSave(editedSceneObjectId, newMarkerList);
      }
    }
  };

  const handleRightClickMap: () => void = (): void => {
    if (!!editedSceneObjectId) {
      let acceptedMarkerList = [...markerList];
      handleSave(
        editedSceneObjectId,
        _.map(acceptedMarkerList, (v) => {
          return { localizationEPSG4326: v };
        })
      );
    }
  };

  const handleClickMarkerMap: (
    id: number,
    objectId: number,
    objectType: ObjectType
  ) => void = (id: number, objectId: number, objectType: ObjectType): void => {
    let newEditorProps = { ...editorProps };
    newEditorProps.sbsSceneObjectId = objectId;
    newEditorProps.sbsSceneObjectType = objectType;
    newEditorProps.sbsSceneObjectTimelineId = id;
    newEditorProps.isOpen = true;
    setEditorProps(newEditorProps);
  };

  const handleSave: (
    sceneObjectId: number,
    points: ISBSSceneObjectTimelinePointData[],
    isMovePoint?: boolean
  ) => void = (
    sceneObjectId: number,
    points: ISBSSceneObjectTimelinePointData[],
    isMovePoint?: boolean
  ): void => {
    savePoints({
      GSSBSSceneObjectId: sceneObjectId,
      Points: points,
    }).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(
          isMovePoint
            ? t("sbsSceneObjectTimelineForm.movePointSuccess")
            : t("sbsSceneObjectTimelineForm.savePointsSuccess")
        );
        setEditedSceneObjectId(undefined);
        setMarkerList([]);
        dispatchReloadTable();
      }
    });
  };

  const tableOptions: MUIDataTableOptions = {
    selectableRows: "multiple",
    selectToolbarPlacement: "none",
  };

  return (
    <React.Fragment>
      <Container maxWidth={false}>
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid container item xs={12}>
            <Grid item xs={12} style={{ paddingTop: "10px" }}>
              <TextTitle>
                <ButtonGridIcon
                  icon={"fa-chevron-circle-left"}
                  onClick={handleClickBack}
                  color="secondary"
                />
                {t("sbsSceneObjectForm.title")}
              </TextTitle>
              <Divider />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6} spacing={2}>
            {!isSceneFinished && (
              <Grid container item sm={12}>
                <Grid item>
                  <SBSSceneObjectAddAdmin
                    sbsSceneId={parseInt(sbsSceneId ?? "0")}
                    handleSaveSuccess={dispatchReloadTable}
                  />
                </Grid>
                <Grid item style={{ marginLeft: "5px" }}>
                  <SBSSceneObjectValidator
                    sbsSceneObjectTimelineIdList={timelineIdList}
                  />
                </Grid>
              </Grid>
            )}
            <Divider />
            <Grid item xs={12}>
              <GridTable<
                ISBSSceneObjectGetListModel,
                ISBSSceneObjectData,
                ISBSSceneObjectGetListResult
              >
                reloadCount={reloadTable}
                columns={columns}
                columnsSortableNames={columnSortables}
                generateSortParams={generateSortParams}
                generateParams={generateParams}
                getList={getList}
                customTableOptions={tableOptions}
                handleRowSelectedChange={handleRowSelectedChange}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={6}>
            <MapBasic
              pointListWithDragDrop={markerListWithDragDrop}
              pointListWithOnClick={markerListWithOnClick}
              polylineList={mapPolylineList}
              pointList={markerList}
              animationPointList={animationInstaceList}
              animationFrames={animationFrames}
              handleClickMap={handleClickMap}
              handleClickRightMap={handleRightClickMap}
              handleDragEnd={handleDragEnd}
              wmsUrl={sceneData.wmsUrl ?? undefined}
              wmsLayer={sceneData.wmsLayer ?? undefined}
              imageUrl={
                sceneData.rasterFile
                  ? `${envBaseURL}/${envApiVersion}/File/Download/${sceneData.rasterFile.discFileName}`
                  : undefined
              }
              imageBounds={
                sceneData.point1EPSG4326 && sceneData.point2EPSG4326
                  ? [
                      sceneData.point1EPSG4326,
                      sceneData.point2EPSG4326,
                    ]
                  : []
              }
            />
          </Grid>
        </Grid>
      </Container>
      {parseInt(sbsSceneId ?? "0") > 0 && (
        <SBSSceneObjectTimelineEditorAdmin
          {...editorProps}
          handleClose={handleCloseEditor}
        />
      )}
    </React.Fragment>
  );
};

export default SBSSceneObjectList;
