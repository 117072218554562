import { IOrderRewardRangeData, IOrderRewardRangeApiInterface } from "./interfaces";
import useBaseDataApi from "Services/base-data-api";
import { IResultBase } from "Services/interfaces";
import { IOrderRewardRangeGetDetailsResult, IOrderRewardRangeGetListResult } from "./interfaces-results";

export const useOrderRewardRangeApi: () => IOrderRewardRangeApiInterface
    = (): IOrderRewardRangeApiInterface => {
        const { baseDeleteById, baseGetDetails, baseGetList, baseSave } = useBaseDataApi({ apiControllerName: "OrderRewardRange" });

        const deleteById: (id: number) => Promise<IResultBase>
            = async (id: number): Promise<IResultBase> => {
                return await baseDeleteById<IResultBase>(id);
            };

        const save: (model: IOrderRewardRangeData) => Promise<IResultBase>
            = async (model: IOrderRewardRangeData): Promise<IResultBase> => {
                return await baseSave<IOrderRewardRangeData, IResultBase>(model);
            };

        const getDetails: (id: number) => Promise<IOrderRewardRangeGetDetailsResult>
            = async (id: number): Promise<IOrderRewardRangeGetDetailsResult> => {
                return await baseGetDetails<IOrderRewardRangeGetDetailsResult>(id);
            };

        const getList: () => Promise<IOrderRewardRangeGetListResult>
            = async (): Promise<IOrderRewardRangeGetListResult> => {
                return await baseGetList<undefined, IOrderRewardRangeGetListResult>();
            };

        return { deleteById, save, getDetails, getList };
    };