import * as React from "react";
import { Divider, Grid } from "@material-ui/core";
import { IResultBase, IGridModel, ILocalization } from "Services/interfaces";
import { ISceneObjectTimelineData } from "Services/SceneObjectTimelineApi/interfaces";
import { ISceneObjectTimelineGetListResult } from "Services/SceneObjectTimelineApi/interfaces-result";
import { ISceneObjectTimelineListProps } from "../interfaces";
import { ApiResultStatus, ObjectType } from "Utils/enums";
import { MUIDataTableOptions, MUIDataTableColumn } from "mui-datatables";
import {
  ISceneObjectTimelineGetListModel,
  ISceneObjectTimelineImportCsvFileModel,
} from "Services/SceneObjectTimelineApi/interfaces-model";
import { useTranslation } from "react-i18next";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import useModalQuestion from "Hooks/use-modal-question";
import useModalSuccess from "Hooks/use-modal-success";
import useSceneObjectTimelineApi from "Services/SceneObjectTimelineApi/scene-object-timeline-api";
import SceneObjectTimelineEditorAdmin from "../SceneObjectTimelineEdiotr/scene-object-timeline-editor-admin";
import GridTable from "Components/Grids/grid-table";
import SceneObjectTimelineImport from "../SceneObjectTimelineImport/scene-object-timeline-import";
import SceneObjectTimelineOrientationPointEditorAdmin from "../SceneObjectTimelineEdiotr/scene-object-timeline-orientation-point-editor-admin";
import axios from "axios";
import { getStoredUserAuthToken } from "Utils/helpers";

const envBaseURL: string =
  process.env.REACT_APP_API_BASE_URL ?? "https://webapi.gsot.pl/api";
const envApiVersion: string = process.env.REACT_APP_API_VERSION ?? "v1";

const SceneObjectTimelineList: (
  props: ISceneObjectTimelineListProps
) => JSX.Element = (props: ISceneObjectTimelineListProps): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { deleteById, getList, importCSVFile } = useSceneObjectTimelineApi();
  const { t } = useTranslation(["commonResources"]);
  const sceneObjectId: number = props.sceneObjectId;
  const sceneObjectType: ObjectType = props.sceneObjectType;

  const deleteSceneObjectTimeline: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await deleteById(id).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("animationForm.deleteSuccess"));
        dispatchReloadTable();
      }
    });
  };

  const handleClickDelete: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    showModalQuestion(t("animationForm.deleteInfo"), id);
  };

  const handleImport: (
    file: File,
    objectId: number
  ) => Promise<IResultBase> = async (
    file: File,
    objectId: number
  ): Promise<IResultBase> => {
    const model: ISceneObjectTimelineImportCsvFileModel = {
      File: file,
      GSSceneObjectId: objectId,
    };
    return await importCSVFile(model);
  };

  React.useEffect(() => {
    setYesFunction(() => deleteSceneObjectTimeline);
  }, []);

  React.useEffect(() => {
    if (props.sceneObjectId > 0) {
      dispatchReloadTable();
    }
  }, [props.sceneObjectId]);

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
        download: false,
      },
    },
    {
      name: "startTimeInSeconds",
      label: t("animationForm.startTime"),
    },
    {
      name: "endTimeInSeconds",
      label: t("animationForm.endTime"),
    },
    {
      name: "startLocalizationEPSG4326",
      label: t("animationForm.localizationStart"),
      options: {
        display: sceneObjectType !== ObjectType.Sound ? "true" : "false",
        customBodyRender: (value: ILocalization, tableMeta, updateValue) => {
          return `L: ${value?.longitude ?? "-"} B: ${
            value?.latitude ?? "-"
          } h: ${value?.altitude ?? "-"}`;
        },
      },
    },
    {
      name: "endLocalizationEPSG4326",
      label: t("animationForm.localizationEnd"),
      options: {
        display: sceneObjectType !== ObjectType.Sound ? "true" : "false",
        customBodyRender: (value: ILocalization, tableMeta, updateValue) => {
          return `L: ${value?.longitude ?? "-"} B: ${
            value?.latitude ?? "-"
          } h: ${value?.altitude ?? "-"}`;
        },
      },
    },
    {
      name: "animationIndex",
      label: t("animationForm.indexShort"),
      options: {
        display: sceneObjectType === ObjectType.Model3D ? "true" : "false",
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <SceneObjectTimelineEditorAdmin
              index={tableMeta.rowIndex}
              sceneObjectId={sceneObjectId}
              sceneObjectType={sceneObjectType}
              sceneObjectTimelineId={tableMeta.rowData[0]}
              handleSaveSuccess={dispatchReloadTable}
            />
          );
        },
        download: false,
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGridIcon
              icon="fa-trash-alt"
              color="primary"
              onClick={() => {
                handleClickDelete(tableMeta.rowData[0]);
              }}
            />
          );
        },
        download: false,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    search: false,
    pagination: false,
    sort: false,
  };

  const generateParams: (
    tableState: IGridModel
  ) => ISceneObjectTimelineGetListModel = (
    tableState: IGridModel
  ): ISceneObjectTimelineGetListModel => {
    return {
      GSSceneObjectId: sceneObjectId,
      ...tableState,
    };
  };

  return (
    <React.Fragment>
      <Divider />
      <Grid container item>
        <Grid
          container
          item
          xs={10}
          justify="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item>
            <SceneObjectTimelineEditorAdmin
              sceneObjectId={sceneObjectId}
              sceneObjectType={sceneObjectType}
              handleSaveSuccess={dispatchReloadTable}
            />
          </Grid>
          <Grid item>
            {sceneObjectType === ObjectType.Model3D && (
              <SceneObjectTimelineImport
                buttonSize="xl"
                sceneObjectId={sceneObjectId}
                handleImport={handleImport}
                handleSaveSuccess={dispatchReloadTable}
              />
            )}
          </Grid>
          {sceneObjectType === ObjectType.Model3D && (
            <Grid item>
              <SceneObjectTimelineOrientationPointEditorAdmin
                sceneObjectId={sceneObjectId}
              />
            </Grid>
          )}
        </Grid>
        <Grid container item justify="flex-end" alignItems="flex-end" xs={2}>
          <Grid item>
            {sceneObjectType === ObjectType.Model3D && (
              <ButtonGridIcon
                icon="fa-file-csv"
                color="primary"
                title={t("exportToCsv")}
                onClick={() => {
                  axios({
                    url: `${envBaseURL}/${envApiVersion}/SceneObjectTimeline/ExportToCsv`,
                    method: "GET",
                    responseType: "blob", // important
                    params: {
                      gsSceneObjectId: sceneObjectId,
                    },
                    headers: {
                      Authorization: getStoredUserAuthToken(),
                    },
                  }).then((response) => {
                    let fileDownload = require("js-file-download");
                    fileDownload(response.data, `${t("animations")}.csv`);
                  });
                }}
              />
            )}
          </Grid>
          <Grid item>
            <a
              href={`${process.env.PUBLIC_URL}/docs/doc_help_animation.pdf`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <ButtonGridIcon
                icon="fa-question-circle"
                color="primary"
                title={t("downloadHelpFile")}
              />
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <GridTable<
        ISceneObjectTimelineGetListModel,
        ISceneObjectTimelineData,
        ISceneObjectTimelineGetListResult
      >
        reloadCount={reloadTable}
        columns={columns}
        customTableOptions={options}
        generateParams={generateParams}
        getList={getList}
      />
    </React.Fragment>
  );
};

export default SceneObjectTimelineList;
