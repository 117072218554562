import * as React from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Formik, Form, getIn } from "formik";
import { IUserEditorAdminProps } from "../interfaces";
import { IUserDetailsData } from "Services/UserApi/interfaces";
import { IUserDetailsResult } from "Services/UserApi/interfaces-result";
import { UserRole, UserStatus, ApiResultStatus } from "Utils/enums";
import { useUserApi } from "Services/UserApi/user-api";
import { ILocalization } from "Services/interfaces";
import ButtonSecondary from "Components/Buttons/button-secondary";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import useModalSuccess from "Hooks/use-modal-success";
import TextInfo from "Components/Texts/text-info";
import { useTranslation } from "react-i18next";

const UserEditorAdmin: (props: IUserEditorAdminProps) => JSX.Element = (
  props: IUserEditorAdminProps
): JSX.Element => {
  const showModalSuccess: (successResult: string) => void =
    useModalSuccess(undefined);
  const { add, getDetails, update } = useUserApi();
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation([
    "commonResources",
    "enumResources",
    "validationResources",
  ]);
  const [values, setValues] = React.useState<IUserDetailsData>({
    role: UserRole.Standard,
    status: UserStatus.Active,
  });

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    setIsOpen(true);
  };

  const handleLoadData: (userId: number) => Promise<void> = async (
    userId: number
  ): Promise<void> => {
    await getDetails(userId).then((response: IUserDetailsResult) => {
      if (response && response.data) {
        setValues(response.data);
      }
    });
  };

  const handleSave: (user: IUserDetailsData) => Promise<void> = async (
    user: IUserDetailsData
  ): Promise<void> => {
    if (props.userId) {
      await update(user).then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          showModalSuccess(t("saveChangesSuccess"));
          if (props.handleSaveSuccess) {
            props.handleSaveSuccess();
          }
          handleClose();
        }
      });
    } else {
      await add(user).then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          showModalSuccess(t("userForm.addSuccess"));
          if (props.handleSaveSuccess) {
            props.handleSaveSuccess();
          }
          handleClose();
        }
      });
    }
  };

  React.useEffect(() => {
    if (isOpen && props.userId) {
      handleLoadData(props.userId);
    }
  }, [isOpen, props.userId]);

  return (
    <React.Fragment>
      {props.userId && (
        <ButtonGridIcon
          icon={"fa-edit"}
          onClick={handleOpen}
          color="secondary"
        />
      )}
      {!props.userId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-user-plus"} color="secondary" />
          {t("userForm.add")}
        </ButtonPrimary>
      )}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          {props.userId ? t("userForm.edit") : t("userForm.addInfo")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<IUserDetailsData>({
              email: Yup.string()
                .email(t("validationResources:emailInvalid"))
                .max(255, t("validationResources:emailMaxLength"))
                .required(t("validationResources:required")),
              password: Yup.string()
                .min(6, t("validationResources:passwordMinLength"))
                .nullable(),
              confirmPassword: Yup.string()
                .oneOf(
                  [Yup.ref("password"), null],
                  t("validationResources:passwordEqual")
                )
                .nullable(),
              invoiceCompanyName: Yup.string()
                .max(
                  200,
                  t("validationResources:stringMaxLength", { length: 200 })
                )
                .nullable(),
              invoiceNIP: Yup.string()
                .max(10, t("validationResources:nipMaxLength"))
                .nullable(),
              invoiceAddressLine1: Yup.string()
                .max(
                  200,
                  t("validationResources:stringMaxLength", { length: 200 })
                )
                .nullable(),
              invoiceAddressLine2: Yup.string()
                .max(
                  200,
                  t("validationResources:stringMaxLength", { length: 200 })
                )
                .nullable(),
              virtualWalletBalance: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(0, t("validationResources:numericMin0"))
                .max(9999, t("validationResources:numericMax9999"))
                .nullable(),
              partnerLocalizationEPSG4326: Yup.object<ILocalization>({
                altitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
                latitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
                longitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .nullable(),
              })
                .nullable()
                .when("role", {
                  is: UserRole.Partner,
                  then: Yup.object<ILocalization>({
                    latitude: Yup.number()
                      .typeError(t("validationResources:requiredNumeric"))
                      .test(
                        "len",
                        t("validationResources:numericBeforeComa"),
                        (val) =>
                          Math.floor(Math.abs(val)).toString().length <= 6
                      )
                      .required(t("validationResources:required")),
                    longitude: Yup.number()
                      .typeError(t("validationResources:requiredNumeric"))
                      .test(
                        "len",
                        t("validationResources:numericBeforeComa"),
                        (val) =>
                          Math.floor(Math.abs(val)).toString().length <= 6
                      )
                      .required(t("validationResources:required")),
                  }).required(),
                }),
              partnerRadiusInMeters: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(0, t("validationResources:numericMin0"))
                .max(1000000, t("validationResources:numericMax1000000"))
                .nullable()
                .when("role", {
                  is: UserRole.Partner,
                  then: Yup.number()
                    .typeError(t("validationResources:requiredNumeric"))
                    .min(0, t("validationResources:numericMin0"))
                    .max(1000000, t("validationResources:numericMax1000000"))
                    .required(t("validationResources:required")),
                }),
            })}
            onSubmit={(values: IUserDetailsData) => {
              if (values.virtualWalletBalance?.toString() === "") {
                values.virtualWalletBalance = 0;
              }
              if (values.role !== UserRole.Partner) {
                values.partnerLocalizationEPSG4326 = null;
                values.partnerRadiusInMeters = null;
              } else {
                if (values.partnerLocalizationEPSG4326) {
                  values.partnerLocalizationEPSG4326.altitude = 0;
                }
              }
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid container justify="center" alignItems="flex-start">
                  <Grid container item md={6} sm={12}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        item
                        xs={12}
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid container item xs={12}>
                          <TextField
                            fullWidth
                            value={formik.values.email ?? ""}
                            onChange={formik.handleChange}
                            error={
                              !!(formik.errors.email && formik.touched.email)
                            }
                            helperText={
                              formik.errors.email &&
                              formik.touched.email &&
                              formik.errors.email
                            }
                            label={t("email")}
                            variant="outlined"
                            size="small"
                            type="email"
                            name="email"
                            disabled={!!formik.values.id}
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <TextField
                            fullWidth
                            value={formik.values.password ?? ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              !!(
                                formik.errors.password &&
                                formik.touched.password
                              )
                            }
                            helperText={
                              formik.errors.password &&
                              formik.touched.password &&
                              formik.errors.password
                            }
                            label={t("password")}
                            variant="outlined"
                            size="small"
                            type="password"
                            name="password"
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <TextField
                            fullWidth
                            value={formik.values.confirmPassword ?? ""}
                            onChange={formik.handleChange}
                            error={
                              !!(
                                formik.errors.confirmPassword &&
                                formik.touched.confirmPassword
                              )
                            }
                            helperText={
                              formik.errors.confirmPassword &&
                              formik.touched.confirmPassword &&
                              formik.errors.confirmPassword
                            }
                            label={t("passwordRetype")}
                            variant="outlined"
                            size="small"
                            type="password"
                            name="confirmPassword"
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <FormControl
                            fullWidth
                            size="small"
                            onChange={formik.handleChange}
                            variant="outlined"
                          >
                            <InputLabel id="role-select-id">
                              {t("role")}
                            </InputLabel>
                            <Select
                              name="role"
                              fullWidth
                              labelId="role-select-id"
                              value={formik.values.role ?? UserRole.Standard}
                              onChange={formik.handleChange}
                              disabled={!!props.userId}
                            >
                              <MenuItem value={UserRole.Admin}>
                                {t("enumResources:userRole.admin")}
                              </MenuItem>
                              <MenuItem value={UserRole.Standard}>
                                {t("enumResources:userRole.standard")}
                              </MenuItem>
                              <MenuItem value={UserRole.Partner}>
                                {t("enumResources:userRole.partner")}
                              </MenuItem>
                              <MenuItem value={UserRole.SBS}>
                                {t("enumResources:userRole.sbs")}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid container item xs={12}>
                          <FormControl
                            fullWidth
                            size="small"
                            onChange={formik.handleChange}
                            variant="outlined"
                          >
                            <InputLabel id="status-select-id">
                              {t("status")}
                            </InputLabel>
                            <Select
                              name="status"
                              fullWidth
                              labelId="status-select-id"
                              value={formik.values.status ?? UserStatus.Active}
                              onChange={formik.handleChange}
                            >
                              <MenuItem value={UserStatus.Active}>
                                {t("enumResources:userStatus.active")}
                              </MenuItem>
                              <MenuItem value={UserStatus.Blocked}>
                                {t("enumResources:userStatus.blocked")}
                              </MenuItem>
                              <MenuItem value={UserStatus.NotConfirmed}>
                                {t("enumResources:userStatus.notConfirmed")}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item md={6} sm={12}>
                    <Grid item xs={12}>
                      {(formik.values.role === UserRole.Standard ||
                        formik.values.role === UserRole.Admin) && (
                        <Grid
                          container
                          item
                          xs={12}
                          direction="column"
                          justify="flex-start"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          {formik.values.role === UserRole.Standard && (
                            <Grid container item xs={12}>
                              <TextField
                                fullWidth
                                value={formik.values.virtualWalletBalance ?? ""}
                                onChange={formik.handleChange}
                                error={
                                  !!(
                                    formik.errors.virtualWalletBalance &&
                                    formik.touched.virtualWalletBalance
                                  )
                                }
                                helperText={
                                  formik.errors.virtualWalletBalance &&
                                  formik.touched.virtualWalletBalance &&
                                  formik.errors.virtualWalletBalance
                                }
                                label={t("virtualWalletStatus")}
                                variant="outlined"
                                size="small"
                                name="virtualWalletBalance"
                              />
                            </Grid>
                          )}
                          <Grid container item xs={12}>
                            <TextField
                              fullWidth
                              value={formik.values.invoiceCompanyName ?? ""}
                              onChange={formik.handleChange}
                              error={
                                !!(
                                  formik.errors.invoiceCompanyName &&
                                  formik.touched.invoiceCompanyName
                                )
                              }
                              helperText={
                                formik.errors.invoiceCompanyName &&
                                formik.touched.invoiceCompanyName &&
                                formik.errors.invoiceCompanyName
                              }
                              label={t("invoiceForm.companyName")}
                              variant="outlined"
                              size="small"
                              name="invoiceCompanyName"
                            />
                          </Grid>
                          <Grid container item xs={12}>
                            <TextField
                              fullWidth
                              value={formik.values.invoiceNIP ?? ""}
                              onChange={formik.handleChange}
                              error={
                                !!(
                                  formik.errors.invoiceNIP &&
                                  formik.touched.invoiceNIP
                                )
                              }
                              helperText={
                                formik.errors.invoiceNIP &&
                                formik.touched.invoiceNIP &&
                                formik.errors.invoiceNIP
                              }
                              label={t("invoiceForm.nip")}
                              variant="outlined"
                              size="small"
                              name="invoiceNIP"
                            />
                          </Grid>
                          <Grid container item xs={12}>
                            <TextField
                              fullWidth
                              value={formik.values.invoiceAddressLine1 ?? ""}
                              onChange={formik.handleChange}
                              error={
                                !!(
                                  formik.errors.invoiceAddressLine1 &&
                                  formik.touched.invoiceAddressLine1
                                )
                              }
                              helperText={
                                formik.errors.invoiceAddressLine1 &&
                                formik.touched.invoiceAddressLine1 &&
                                formik.errors.invoiceAddressLine1
                              }
                              label={t("invoiceForm.addressLine1")}
                              variant="outlined"
                              size="small"
                              name="invoiceAddressLine1"
                            />
                          </Grid>
                          <Grid container item xs={12}>
                            <TextField
                              fullWidth
                              value={formik.values.invoiceAddressLine2 ?? ""}
                              onChange={formik.handleChange}
                              error={
                                !!(
                                  formik.errors.invoiceAddressLine2 &&
                                  formik.touched.invoiceAddressLine2
                                )
                              }
                              helperText={
                                formik.errors.invoiceAddressLine2 &&
                                formik.touched.invoiceAddressLine2 &&
                                formik.errors.invoiceAddressLine2
                              }
                              label={t("invoiceForm.addressLine2")}
                              variant="outlined"
                              size="small"
                              name="invoiceAddressLine2"
                            />
                          </Grid>
                        </Grid>
                      )}
                      {formik.values.role === UserRole.Partner && (
                        <Grid container item sm={12} spacing={2}>
                          <Grid container item xs={12}>
                            <Grid item xs={12}>
                              <TextInfo color={"secondary"}>
                                {t("userForm.possitionInEpsg4326")}
                              </TextInfo>
                              <Divider />
                            </Grid>
                          </Grid>
                          <Grid container item sm={12} spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                value={
                                  formik.values.partnerLocalizationEPSG4326
                                    ?.longitude ?? ""
                                }
                                onChange={formik.handleChange}
                                error={
                                  !!(
                                    getIn(
                                      formik.errors,
                                      "partnerLocalizationEPSG4326.longitude"
                                    ) &&
                                    getIn(
                                      formik.touched,
                                      "partnerLocalizationEPSG4326.longitude"
                                    )
                                  )
                                }
                                helperText={
                                  getIn(
                                    formik.errors,
                                    "partnerLocalizationEPSG4326.longitude"
                                  ) &&
                                  getIn(
                                    formik.touched,
                                    "partnerLocalizationEPSG4326.longitude"
                                  ) &&
                                  getIn(
                                    formik.errors,
                                    "partnerLocalizationEPSG4326.longitude"
                                  )
                                }
                                label="L"
                                variant="outlined"
                                size="small"
                                name="partnerLocalizationEPSG4326.longitude"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                value={
                                  formik.values.partnerLocalizationEPSG4326
                                    ?.latitude ?? ""
                                }
                                onChange={formik.handleChange}
                                error={
                                  !!(
                                    getIn(
                                      formik.errors,
                                      "partnerLocalizationEPSG4326.latitude"
                                    ) &&
                                    getIn(
                                      formik.touched,
                                      "partnerLocalizationEPSG4326.latitude"
                                    )
                                  )
                                }
                                helperText={
                                  getIn(
                                    formik.errors,
                                    "partnerLocalizationEPSG4326.latitude"
                                  ) &&
                                  getIn(
                                    formik.touched,
                                    "partnerLocalizationEPSG4326.latitude"
                                  ) &&
                                  getIn(
                                    formik.errors,
                                    "partnerLocalizationEPSG4326.latitude"
                                  )
                                }
                                label="B"
                                variant="outlined"
                                size="small"
                                name="partnerLocalizationEPSG4326.latitude"
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs={12}>
                            <Grid item xs={12}>
                              <TextInfo color={"secondary"}>
                                {t("radiusInMeters")}
                              </TextInfo>
                              <Divider />
                            </Grid>
                          </Grid>
                          <Grid container item sm={12}>
                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                value={
                                  formik.values.partnerRadiusInMeters ?? ""
                                }
                                onChange={formik.handleChange}
                                error={
                                  !!(
                                    formik.errors.partnerRadiusInMeters &&
                                    formik.touched.partnerRadiusInMeters
                                  )
                                }
                                helperText={
                                  formik.errors.partnerRadiusInMeters &&
                                  formik.touched.partnerRadiusInMeters &&
                                  formik.errors.partnerRadiusInMeters
                                }
                                label="R"
                                variant="outlined"
                                size="small"
                                name="partnerRadiusInMeters"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default UserEditorAdmin;
