import * as React from "react";
import { AuthProvider } from "Contexts/context-auth";
import { CookiesProvider } from "react-cookie";
import RouterApp from "Routes/router-app";

const Main: () => JSX.Element = (): JSX.Element => {
  return (
    <AuthProvider>
      <CookiesProvider>
        <RouterApp />
      </CookiesProvider>
    </AuthProvider>
  );
};

export default Main;
