import * as React from "react";
import { ITextProps } from "./interfaces";
import { Typography } from "@material-ui/core";

const TextSubheader: (props: ITextProps) => JSX.Element = (props: ITextProps): JSX.Element => {
    const { children, ...other } = props;

    return (
        <Typography variant="subtitle2" {...other}>
            {children}
        </Typography>
    );
};

export default TextSubheader;