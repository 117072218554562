import * as React from "react";
import clsx from "clsx";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Collapse,
} from "@material-ui/core";
import { IProductCardProps } from "../interfaces";
import { stylesProductCard } from "../styles";
import { IProductData } from "Services/ProductApi/interfaces";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import TextInfo from "Components/Texts/text-info";
import TextSubtitle from "Components/Texts/text-subtitle";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import { useTranslation } from "react-i18next";

const ProductCard: (props: IProductCardProps) => JSX.Element = (
  props: IProductCardProps
): JSX.Element => {
  const customStyles: Record<string, string> = stylesProductCard();
  const product: IProductData = { ...props.product };
  const [expanded, setExpanded] = React.useState(false);
  const [productQuantity, setProductQuantity] = React.useState(0);
  const { t } = useTranslation(["commonResources"]);

  const handleExpandClick: () => void = (): void => {
    setExpanded(!expanded);
  };

  return (
    <React.Fragment>
      <Card className={customStyles.root}>
        <CardHeader
          className={customStyles.header}
          action={
            <React.Fragment>
              <ButtonGridIcon
                icon="fa-minus-square"
                color="secondary"
                onClick={() => {
                  if (productQuantity > 0) {
                    setProductQuantity(productQuantity - 1);
                  }
                }}
              />
              <TextInfo
                style={{
                  display: "inline-block",
                  marginRight: "5px",
                  width: "20px",
                  textAlign: "center",
                }}
              >
                {productQuantity}
              </TextInfo>
              <ButtonGridIcon
                icon="fa-plus-square"
                color="secondary"
                onClick={() => {
                  setProductQuantity(productQuantity + 1);
                }}
              />
              <ButtonGridIcon
                icon="fa-cart-plus"
                color="primary"
                style={{ padding: "10px", paddingBottom: "0" }}
                onClick={() => {
                  if (productQuantity > 0) {
                    if (props.handleAddToCart) {
                      props.handleAddToCart({
                        id: product.id,
                        name: product.name,
                        price: product.price,
                        quantity: productQuantity,
                      });
                    }
                    setProductQuantity(0);
                  }
                }}
              />
            </React.Fragment>
          }
        ></CardHeader>
        <CardContent className={customStyles.main}>
          <TextSubtitle>{product.name}</TextSubtitle>
          <TextInfo>
            {t("place")}: {product.placeName}
          </TextInfo>
          <TextInfo>
            {t("price")}: {product.price ?? "-"} zł
          </TextInfo>
        </CardContent>
        <CardActions className={customStyles.main}>
          <IconButton
            className={clsx(
              customStyles.expand,
              {
                [customStyles.expandOpen]: expanded,
              },
              customStyles.button
            )}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <TextInfo color="textSecondary">
              {t("description")}: {product.description ?? "-"}
            </TextInfo>
            <TextInfo color="textSecondary">
              {t("licenseForm.activeDays")} {product.licenseActiveDays ?? "-"}{" "}
              {t("days")}
            </TextInfo>
            <TextInfo color="textSecondary">
              {t("expirationDate")}:{" "}
              {product.expireDate
                ? moment(product.expireDate).format("DD.MM.YYYY")
                : " - "}
            </TextInfo>
            <TextInfo color="textSecondary">
              {t("licenseForm.codeActiveDays")}{" "}
              {product.licenseCodeActiveDays ?? "-"} {t("days")}
            </TextInfo>
          </CardContent>
        </Collapse>
      </Card>
    </React.Fragment>
  );
};

export default ProductCard;
