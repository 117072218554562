import * as _ from "lodash";
import {
  DEFAULT_LANGUAGE_ID,
  DEFAULT_USER_AUTH_TOKEN,
  DEFAULT_USER_EMAIL,
  DEFAULT_USER_ROLE,
} from "./consts";
import { ILanguageData } from "Services/LanguageApi/interfaces";
import { UserRole } from "./enums";
import i18n from "Configurations/i18n";

/** Return user auth token from local storage value */
export const getStoredUserAuthToken: () => string = (): string => {
  const authToken: string | null =
    window.sessionStorage.getItem("UserAuthToken");
  if (authToken) {
    return authToken;
  }
  return DEFAULT_USER_AUTH_TOKEN;
};

/** Return user email from local storage value */
export const getStoredUserEmail: () => string = (): string => {
  const email: string | null = window.sessionStorage.getItem("UserEmail");
  if (email) {
    return email;
  }
  return DEFAULT_USER_EMAIL;
};

export const getStoredUserId: () => number = (): number => {
  const id: number = parseInt(window.sessionStorage.getItem("UserId") ?? "0");
  return id;
};

export const getStoredUserRole: () => UserRole = (): UserRole => {
  const role: UserRole = parseInt(
    window.sessionStorage.getItem("UserRole") ?? DEFAULT_USER_ROLE.toString(),
    undefined
  );
  if (role) {
    return role;
  }
  return DEFAULT_USER_ROLE;
};

export const getStoredUserVirtualWalletBalance: () => string = (): string => {
  const balance: string | null = window.sessionStorage.getItem(
    "UserVirtualWalletBalance"
  );
  return balance ?? "0";
};

export const getSelectedLanguageWithCulture: () => string = (): string => {
  switch (i18n.language) {
    case "en":
      return "en-US";
    case "pl":
    default:
      return "pl-PL";
  }
};

export const getSelectedLanguageId: (
  languageList: ILanguageData[]
) => number = (languageList: ILanguageData[]): number => {
  let selectedCulture: string = getSelectedLanguageWithCulture();
  return (
    _.find(languageList, (value: ILanguageData) => {
      return value.cultureName === selectedCulture;
    })?.id ?? DEFAULT_LANGUAGE_ID
  );
};

export const convertMillisecondsToTime: (milliseconds: number) => string = (
  milliseconds: number
): string => {
  let ms: number = Math.round((milliseconds % 1000) / 10);
  let val: number = Math.round((milliseconds - ms) / 1000);
  let s: number = val % 60;
  val = (val - s) / 60;
  let m: number = val % 60;
  return `${m}:${s}:${ms}`;
};
