import * as React from "react";
import * as _ from "lodash";
import proj4 from "proj4";
import {
  Map,
  TileLayer,
  LatLng,
  Marker,
  Polygon,
  WMSTileLayer,
  ImageOverlay,
  Polyline,
  Tooltip,
} from "react-leaflet";
import {
  DivIcon,
  DragEndEvent,
  Icon,
  LatLngExpression,
  LeafletMouseEvent,
} from "leaflet";
import { ILocalization } from "Services/interfaces";
import {
  IAnimationIstance,
  IMapBasicProps,
  IMapMarker,
  IMapPolyline,
} from "./interfaces";
import { useBaseApi } from "Services/base-api";
import markDragable from "../../Images/mark_5.png";
import markClickable from "../../Images/mark_6.png";
import { Grid, LinearProgress } from "@material-ui/core";
import { MAP_ANIMATION_FRAME_PER_SECOND } from "Utils/consts";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import { convertMillisecondsToTime } from "Utils/helpers";
import { CheckIsPointOnAnyMagnesRadius } from "Utils/helper-point";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

type DispatchAction = "increment" | "reset";

const MapBasic: (props: IMapBasicProps) => JSX.Element = (
  props: IMapBasicProps
): JSX.Element => {
  const { t } = useTranslation(["commonResources"]);
  const { baseGetRequest } = useBaseApi();
  const [initialCount, setInitialCount] = React.useState<number>(0);
  const dispatchFunction: (state: number, action: DispatchAction) => number = (
    state: number,
    action: DispatchAction
  ): number => {
    switch (action) {
      case "increment":
        return state === props.animationFrames ? 0 : state + 1;
      case "reset":
        let newValue = initialCount;
        setInitialCount(0);
        return newValue;
    }
  };
  const [currentIndex, setCurrentIndex] = React.useReducer(
    dispatchFunction,
    initialCount
  );
  const markerList: ILocalization[] = props.pointList ?? [];
  const animationMarkerList: IAnimationIstance[] =
    props.animationPointList ?? [];
  const markerListWithOnClik: IMapMarker[] = props.pointListWithOnClick ?? [];
  const markerListWithDragDrop: IMapMarker[] =
    props.pointListWithDragDrop ?? [];
  const polygonPoints: ILocalization[] = props.rectanglePointList ?? [];
  const polylineList: IMapPolyline[] = props.polylineList ?? [];
  const mapRef: React.RefObject<Map> = React.useRef<Map>(null);
  const [localInterval, setLocalInterval] = React.useState<NodeJS.Timeout>();
  const initialLocalization: LatLng = {
    lat: 52.0693234,
    lng: 19.4781225,
  };
  const iconDragable: Icon = new Icon({
    iconUrl: markDragable,
    iconSize: [20, 20],
  });

  const iconClickable: Icon = new Icon({
    iconUrl: markClickable,
    iconSize: [15, 15],
  });

  const handleClick: (e: LeafletMouseEvent) => void = (
    e: LeafletMouseEvent
  ): void => {
    if (props.handleClickMap) {
      let newPoint: ILocalization = {
        longitude: e.latlng.lng,
        latitude: e.latlng.lat,
      };
      props.handleClickMap(newPoint);
    }
  };

  const handleDragEnd: (
    id: number,
    parentId: number,
    oldPoint: ILocalization,
    e: DragEndEvent
  ) => void = (
    id: number,
    parentId: number,
    oldPoint: ILocalization,
    e: DragEndEvent
  ): void => {
    if (props.handleDragEnd) {
      let newPoint: ILocalization = {
        longitude: e.target._latlng.lng,
        latitude: e.target._latlng.lat,
      };
      const magnesPointId = CheckIsPointOnAnyMagnesRadius(
        newPoint,
        _.filter(props.pointListWithOnClick!, (v) => {
          return v.isVisible;
        })
      );
      if (magnesPointId > -1) {
        Swal.fire({
          icon: "question",
          text: t("map.magnesPointQuestion"),
          customClass: {
            title: "swal-title",
            container: "swal-container",
            content: "swal-content",
            confirmButton: "swal-confirm-button",
          },
          showCancelButton: true,
          confirmButtonText: t("yes"),
          cancelButtonText: t("no"),
          reverseButtons: true,
        })
          .then((result) => {
            if (result.value) {
              newPoint =
                _.find(props.pointListWithOnClick, (v) => {
                  return v.id === magnesPointId;
                })?.point ?? newPoint;
            }
          })
          .finally(() => {
            props.handleDragEnd!(id, parentId, oldPoint, newPoint);
          });
      } else {
        props.handleDragEnd(id, parentId, oldPoint, newPoint);
      }
    }
  };

  const handleMouseMove: (e: LeafletMouseEvent) => void = (
    e: LeafletMouseEvent
  ): void => {
    if (props.handleMouseMoveMap) {
      let newPoint: ILocalization = {
        longitude: e.latlng.lng,
        latitude: e.latlng.lat,
      };
      props.handleMouseMoveMap(newPoint);
    }
  };

  const handleClickPause: () => void = (): void => {
    if (localInterval) {
      clearInterval(localInterval);
      setLocalInterval(undefined);
    }
  };

  const handleClickPlay: () => void = (): void => {
    setLocalInterval(
      setInterval(() => {
        setCurrentIndex("increment");
      }, 1000 / MAP_ANIMATION_FRAME_PER_SECOND)
    );
  };

  const handleClickStop: () => void = (): void => {
    if (localInterval) {
      clearInterval(localInterval);
      setLocalInterval(undefined);
    }
    setCurrentIndex("reset");
  };

  React.useEffect(() => {
    if (props.wmsUrl) {
      baseGetRequest<any>(props.wmsUrl + "request=GetCapabilities", false).then(
        (r) => {}
      );
    }
  }, [props.wmsUrl]);

  React.useEffect(() => {
    if (localInterval) {
      return () => clearInterval(localInterval);
    }
  }, [localInterval]);

  return (
    <React.Fragment>
      <div style={{ height: "500px", width: "100%" }}>
        <Map
          style={{ height: "500px", width: "100%" }}
          ref={mapRef}
          center={initialLocalization as LatLngExpression}
          onclick={handleClick}
          oncontextmenu={props.handleClickRightMap}
          onmousemove={handleMouseMove}
          zoom={6}
          maxZoom={35}
        >
          {props.wmsUrl && props.wmsLayer ? (
            <WMSTileLayer
              url={props.wmsUrl}
              layers={props.wmsLayer}
              detectRetina={false}
            />
          ) : (
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          )}
          {_.map(markerList, (pp) => {
            let pointEPSG4326: proj4.TemplateCoordinates = [
              pp.longitude ?? 0,
              pp.latitude ?? 0,
            ];
            return (
              <Marker
                position={[pointEPSG4326[1], pointEPSG4326[0]]}
                key={`${Math.random()},${pointEPSG4326[1]},${pointEPSG4326[0]}`}
              ></Marker>
            );
          })}
          {animationMarkerList[currentIndex] &&
            _.map(animationMarkerList[currentIndex].animationPoints, (pp) => {
              if (pp.isVisible) {
                let pointEPSG4326: proj4.TemplateCoordinates = [
                  pp.point.longitude ?? 0,
                  pp.point.latitude ?? 0,
                ];
                return (
                  <Marker
                    position={[pointEPSG4326[1], pointEPSG4326[0]]}
                    icon={
                      new DivIcon({
                        html: `<svg width="${pp.size}" height="${
                          pp.size
                        }" xmlns="http://www.w3.org/2000/svg">
                      <g>
                       <ellipse stroke-width="3" ry="${pp.size / 2 - 2}" rx="${
                          pp.size / 2 - 2
                        }" id="svg_1" cy="${pp.size / 2}" cx="${
                          pp.size / 2
                        }" stroke="#01060e" fill="${pp.color}"/>
                      </g>
                     </svg>`,
                        className: "",
                        iconSize: [pp.size, pp.size],
                      })
                    }
                    key={`${currentIndex},${pointEPSG4326[1]},${pointEPSG4326[0]}`}
                  >
                    {pp.tooltipText && (
                    <Tooltip direction="top" permanent>
                      {pp.tooltipText}
                    </Tooltip>
                  )}
                  </Marker>
                );
              }
            })}

          {_.map(markerListWithDragDrop, (marker) => {
            if (marker.isVisible) {
              let pointEPSG4326: proj4.TemplateCoordinates = [
                marker.point.longitude ?? 0,
                marker.point.latitude ?? 0,
              ];
              return (
                <Marker
                  position={[pointEPSG4326[1], pointEPSG4326[0]]}
                  key={`${Math.random()},${pointEPSG4326[1]},${
                    pointEPSG4326[0]
                  }`}
                  icon={iconDragable}
                  draggable
                  ondragend={(e) => {
                    handleDragEnd(
                      marker.id,
                      marker.parentObjectId,
                      marker.point,
                      e
                    );
                  }}
                  zIndexOffset={1000}
                ></Marker>
              );
            }
          })}
          {_.map(markerListWithOnClik, (marker) => {
            if (marker.isVisible) {
              let pointEPSG4326: proj4.TemplateCoordinates = [
                marker.point.longitude ?? 0,
                marker.point.latitude ?? 0,
              ];
              return (
                <Marker
                  position={[pointEPSG4326[1], pointEPSG4326[0]]}
                  key={`${marker.id},${pointEPSG4326[1]},${pointEPSG4326[0]}`}
                  icon={iconClickable}
                  onclick={() => {
                    if (marker.handlerOnClick)
                      marker.handlerOnClick(
                        marker.id,
                        marker.parentObjectId,
                        marker.objectType
                      );
                  }}
                  zIndexOffset={1000}
                >
                  {marker.tooltipText && (
                    <Tooltip direction="top" permanent>
                      {marker.tooltipText}
                    </Tooltip>
                  )}
                </Marker>
              );
            }
          })}
          {props.imageUrl && props.imageBounds?.length == 2 && (
            <ImageOverlay
              url={props.imageUrl}
              bounds={[
                [
                  props.imageBounds[0].latitude ?? 0,
                  props.imageBounds[0].longitude ?? 0,
                ],
                [
                  props.imageBounds[1].latitude ?? 0,
                  props.imageBounds[1].longitude ?? 0,
                ],
              ]}
            ></ImageOverlay>
          )}
          {polygonPoints.length === 4 && (
            <Polygon
              color="orange"
              positions={
                _.map(polygonPoints, (pp) => {
                  let pointEPSG4326: proj4.TemplateCoordinates = [
                    pp.longitude ?? 0,
                    pp.latitude ?? 0,
                  ];
                  return [pointEPSG4326[1], pointEPSG4326[0]];
                }) ?? []
              }
            ></Polygon>
          )}
          {_.map(polylineList, (pp) => {
            if (pp.isVisible) {
              return (
                <Polyline
                  color={(pp.color ?? "") === "" ? "green" : pp.color}
                  positions={
                    _.map(pp.pointList, (p) => {
                      let pointEPSG4326: proj4.TemplateCoordinates = [
                        p.longitude ?? 0,
                        p.latitude ?? 0,
                      ];
                      return [pointEPSG4326[1], pointEPSG4326[0]];
                    }) ?? []
                  }
                ></Polyline>
              );
            }
          })}
        </Map>
      </div>
      {props.animationFrames && (
        <Grid container item xs={12} justify={"space-between"}>
          <Grid item>
            {localInterval ? (
              <ButtonGridIcon
                icon={"fa-pause-circle"}
                onClick={handleClickPause}
                color="secondary"
              />
            ) : (
              <ButtonGridIcon
                icon={"fa-play-circle"}
                onClick={handleClickPlay}
                color="secondary"
              />
            )}
            <ButtonGridIcon
              icon={"fa-stop-circle"}
              onClick={handleClickStop}
              color="secondary"
            />
          </Grid>
          <Grid item xs={10}>
            <LinearProgress
              variant="determinate"
              value={(currentIndex * 100) / props.animationFrames}
              style={{
                marginTop: "10px",
                cursor: localInterval ? "default" : "pointer",
              }}
              onClick={(e) => {
                if (!localInterval && props.animationFrames) {
                  let newValue = Math.floor(
                    ((e.clientX - e.currentTarget.offsetLeft) *
                      props.animationFrames) /
                      e.currentTarget.offsetWidth
                  );
                  setInitialCount(newValue);
                  setCurrentIndex("reset");
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            {convertMillisecondsToTime(
              currentIndex * (1000 / MAP_ANIMATION_FRAME_PER_SECOND)
            )}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default MapBasic;
