import * as React from "react";
import * as Yup from "yup";
import * as _ from "lodash";
import { IProductEditorAdminProps } from "../interfaces";
import { useProductApi } from "Services/ProductApi/product-api";
import { IProductData } from "Services/ProductApi/interfaces";
import { IProductGetDetailsResult } from "Services/ProductApi/interfaces-result";
import { ApiResultStatus } from "Utils/enums";
import {
  DialogActions,
  DialogContent,
  Dialog,
  Divider,
  Grid,
  FormGroup,
  Switch,
  FormControlLabel,
  TextField,
  DialogTitle,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { usePlaceApi } from "Services/PlaceApi/place-api";
import { ILanguageData } from "Services/LanguageApi/interfaces";
import { IPlaceData } from "Services/PlaceApi/interfaces";
import { IPlaceGetListResult } from "Services/PlaceApi/interfaces-result";
import { useLanguageApi } from "Services/LanguageApi/language-api";
import { useTranslation } from "react-i18next";
import { ILanguageGetListResult } from "Services/LanguageApi/interfaces-result";
import { DEFAULT_LANGUAGE_ID } from "Utils/consts";
import plLocale from "date-fns/locale/pl";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import useModalSuccess from "Hooks/use-modal-success";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const ProductEditorAdmin: (props: IProductEditorAdminProps) => JSX.Element = (
  props: IProductEditorAdminProps
): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { save, getDetails } = useProductApi();
  const { getList } = usePlaceApi();
  const { getList: getLanguageList } = useLanguageApi();
  const [languages, setLanguages] = React.useState<ILanguageData[]>([]);
  const showModalSuccess: (successResult: string) => void = useModalSuccess(
    undefined
  );
  const [placeList, setPlaceList] = React.useState<IPlaceData[]>([]);
  const [allPlaceList, setAllPlaceList] = React.useState<IPlaceData[]>([]);
  const [values, setValues] = React.useState<IProductData>({
    name: "",
    description: "",
    price: undefined,
    gsPlaceId: null,
    isEnabled: true,
    expireDate: null,
    licenseActiveDays: 7,
    licenseCodeActiveDays: 365,
    gsLanguageId: DEFAULT_LANGUAGE_ID,
  });
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const productId: number = props.productId ?? 0;

  const loadLanguages: () => Promise<void> = async () => {
    return await getLanguageList().then((response: ILanguageGetListResult) => {
      if (response?.data) {
        setLanguages(response.data);
      }
    });
  };

  const loadOptions: () => Promise<void> = async (): Promise<void> => {
    return await getList({}).then((response: IPlaceGetListResult) => {
      if (response && response.data) {
        let newPlaceList: IPlaceData[] = _.filter(
          [...response.data.gridData],
          (value: IPlaceData) => {
            return value.gsLanguageId === DEFAULT_LANGUAGE_ID;
          }
        );
        setAllPlaceList(response.data.gridData);
        setPlaceList(newPlaceList);
      }
    });
  };

  const handleLanguageChange: (languageId: number) => void = (
    languageId: number
  ): void => {
    let newPlaceList: IPlaceData[] = _.filter(
      [...allPlaceList],
      (value: IPlaceData) => {
        return value.gsLanguageId === languageId;
      }
    );
    setPlaceList(newPlaceList);
  };

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    return await getDetails(productId).then(
      (response: IProductGetDetailsResult) => {
        if (response && response.data) {
          setValues(response.data);
          if (response.data.gsLanguageId) {
            handleLanguageChange(response.data.gsLanguageId);
          }
        }
      }
    );
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    if (productId) {
      handleLoadData();
    }
    setIsOpen(true);
  };

  const handleSave: (product: IProductData) => Promise<void> = async (
    product: IProductData
  ): Promise<void> => {
    if (product.expireDate) {
      product.expireDate = moment(product.expireDate).format("YYYY-MM-DD");
    }
    await save(product).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(
          product.id ? t("saveChangesSuccess") : t("productForm.addSuccess")
        );
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
        handleClose();
      }
    });
  };

  React.useEffect(() => {
    loadOptions();
    loadLanguages();
  }, []);

  return (
    <React.Fragment>
      {!!productId && (
        <ButtonGridIcon
          icon={"fa-edit"}
          onClick={handleOpen}
          color="secondary"
        />
      )}
      {!productId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-gift"} color="secondary" />
          {t("productForm.add")}
        </ButtonPrimary>
      )}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          {productId ? t("productForm.edit") : t("productForm.addInfo")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<IProductData>({
              name: Yup.string()
                .max(100, t("validationResources:nameMaxLength"))
                .required(t("validationResources:required")),
              gsPlaceId: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .required(t("validationResources:required")),
              price: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(0, t("validationResources:numericMinValue", { value: 0 }))
                .max(9999, t("validationResources:numericMax9999"))
                .nullable()
                .required(t("validationResources:required")),
              licenseActiveDays: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(1, t("validationResources:numericMinValue", { value: 1 }))
                .max(9999, t("validationResources:numericMax9999"))
                .required(t("validationResources:required")),
              licenseCodeActiveDays: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(1, t("validationResources:numericMinValue", { value: 1 }))
                .max(9999, t("validationResources:numericMax9999"))
                .required(t("validationResources:required")),
              isEnabled: Yup.boolean(),
              expireDate: Yup.string().nullable(),
              description: Yup.string()
                .max(1000, t("validationResources:descriptionMaxLength"))
                .nullable(),
              gsLanguageId: Yup.number().required(
                t("validationResources:required")
              ),
            })}
            onSubmit={(values: IProductData) => {
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={formik.values.name ?? ""}
                      onChange={formik.handleChange}
                      error={!!(formik.errors.name && formik.touched.name)}
                      helperText={
                        formik.errors.name &&
                        formik.touched.name &&
                        formik.errors.name
                      }
                      label={t("name")}
                      variant="outlined"
                      size="small"
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      value={formik.values.price ?? ""}
                      onChange={formik.handleChange}
                      error={!!(formik.errors.price && formik.touched.price)}
                      helperText={
                        formik.errors.price &&
                        formik.touched.price &&
                        formik.errors.price
                      }
                      label={t("price")}
                      variant="outlined"
                      size="small"
                      name="price"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formik.values.isEnabled}
                            onChange={formik.handleChange}
                            value={formik.values.isEnabled}
                            name="isEnabled"
                          />
                        }
                        label={t("enable")}
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl
                      fullWidth
                      size="small"
                      onChange={formik.handleChange}
                      variant="outlined"
                    >
                      <InputLabel id="gsPlaceId-select-id">
                        {t("place")}
                      </InputLabel>
                      <Select
                        name="gsPlaceId"
                        fullWidth
                        labelId="gsPlaceId-select-id"
                        value={formik.values.gsPlaceId ?? ""}
                        error={
                          !!(
                            formik.errors.gsPlaceId && formik.touched.gsPlaceId
                          )
                        }
                        onChange={formik.handleChange}
                      >
                        {placeList.map(
                          (place: IPlaceData) =>
                            place.id && (
                              <MenuItem
                                key={`place_${place.id}`}
                                value={place.id}
                              >
                                {place.name ?? ""}
                              </MenuItem>
                            )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth size="small" variant="outlined">
                      <InputLabel id="language-select-id">
                        {t("language")}
                      </InputLabel>
                      <Select
                        name="gsLanguageId"
                        fullWidth
                        labelId="language-select-id"
                        value={formik.values.gsLanguageId ?? languages[0].id}
                        disabled={!!values.id}
                        onChange={(e: React.ChangeEvent<any>) => {
                          handleLanguageChange(e.target.value);
                          formik.handleChange(e);
                        }}
                      >
                        {_.map(languages, (value: ILanguageData) => {
                          return (
                            <MenuItem
                              value={value.id ?? 0}
                              key={`language-id-${value.id}`}
                            >
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      value={formik.values.licenseActiveDays ?? "7"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {t("days")}
                          </InputAdornment>
                        ),
                      }}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          formik.errors.licenseActiveDays &&
                          formik.touched.licenseActiveDays
                        )
                      }
                      helperText={
                        formik.errors.licenseActiveDays &&
                        formik.touched.licenseActiveDays &&
                        formik.errors.licenseActiveDays
                      }
                      label={t("licenseForm.activeDays")}
                      variant="outlined"
                      size="small"
                      name="licenseActiveDays"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      value={formik.values.licenseCodeActiveDays ?? "365"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {t("days")}
                          </InputAdornment>
                        ),
                      }}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          formik.errors.licenseCodeActiveDays &&
                          formik.touched.licenseCodeActiveDays
                        )
                      }
                      helperText={
                        formik.errors.licenseCodeActiveDays &&
                        formik.touched.licenseCodeActiveDays &&
                        formik.errors.licenseCodeActiveDays
                      }
                      label={t("licenseForm.codeActiveDays")}
                      variant="outlined"
                      size="small"
                      name="licenseCodeActiveDays"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={formik.values.description ?? ""}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          formik.errors.description &&
                          formik.touched.description
                        )
                      }
                      helperText={
                        formik.errors.description &&
                        formik.touched.description &&
                        formik.errors.description
                      }
                      label={t("description")}
                      variant="outlined"
                      size="small"
                      name="description"
                      multiline
                      rowsMax="5"
                      rows="5"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={plLocale}
                    >
                      <DatePicker
                        autoOk={true}
                        format={"dd.MM.yyy"}
                        name="expireDate"
                        value={formik.values.expireDate}
                        onChange={(ed) =>
                          formik.setFieldValue(
                            "expireDate",
                            ed ? moment(ed).toString() : null
                          )
                        }
                        label={t("expirationDate")}
                        size="small"
                        inputVariant="outlined"
                        clearable={true}
                        error={
                          !!(
                            formik.errors.expireDate &&
                            formik.touched.expireDate
                          )
                        }
                        helperText={
                          formik.errors.expireDate &&
                          formik.touched.expireDate &&
                          formik.errors.expireDate
                        }
                        cancelLabel={t("cancel")}
                        clearLabel={t("clear")}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ProductEditorAdmin;
