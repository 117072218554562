import * as React from "react";
import { Grid, Theme, useTheme, useMediaQuery } from "@material-ui/core";
import { IOrderPanelState } from "../interfaces";
import OrderProductLicenseList from "../OrderProductLicenseList/order-product-license-list";
import OrderProductList from "../OrderProductList/order-product-list";
import PanelExpansion from "Components/Panels/panel-expansion";
import GridContainer from "Components/Grids/grid-container";
import OrderList from "../OrderList/order-list";
import { useTranslation } from "react-i18next";

const OrderPanel: () => JSX.Element = (): JSX.Element => {
  const theme: Theme = useTheme();
  const matches: boolean = useMediaQuery(theme.breakpoints.up("lg"));
  const { t } = useTranslation(["commonResources"]);
  const panelStateReducer: (
    panelState: IOrderPanelState,
    newPanelState: IOrderPanelState
  ) => IOrderPanelState = (
    panelState: IOrderPanelState,
    newPanelState: IOrderPanelState
  ): IOrderPanelState => {
    return { ...panelState, ...newPanelState };
  };
  const [panelState, dispatchPanelState] = React.useReducer(panelStateReducer, {});

  const generateTitle: (orderText?: string, orderProductText?: string) => string = (
    orderText?: string,
    orderProductText?: string
  ): string => {
    let newOrderText: string =
      orderText ?? panelState.selectedOrderText
        ? `${t("order")}: ${orderText ?? panelState.selectedOrderText}`
        : "";
    let newOrderProductText: string =
      orderProductText ?? panelState.selectedOrderProductText
        ? ` - ${t("product")}: ${orderProductText ?? panelState.selectedOrderProductText}`
        : "";
    return `${newOrderText}${newOrderProductText}`;
  };

  const handleChangeOrder: (orderId: number, orderText: string) => void = (
    orderId: number,
    orderText: string
  ): void => {
    let newPanelState: IOrderPanelState = {
      selectedOrderId: orderId,
      selectedOrderText: orderText,
      selectedOrderProductId: undefined,
      selectedOrderProductText: undefined,
      isOrderPanelExpanded: false,
      isOrderProductPanelExpanded: true,
      isOrderProductLicensePanelExpanded: false,
      titleText: generateTitle(orderText),
    };
    dispatchPanelState(newPanelState);
  };

  const handleChangeOrderProduct: (orderProductId: number, orderProductText: string) => void = (
    orderProductId: number,
    orderProductText: string
  ): void => {
    let newPanelState: IOrderPanelState = {
      selectedOrderProductId: orderProductId,
      selectedOrderProductText: orderProductText,
      isOrderPanelExpanded: false,
      isOrderProductPanelExpanded: false,
      isOrderProductLicensePanelExpanded: true,
      titleText: generateTitle(undefined, orderProductText),
    };
    dispatchPanelState(newPanelState);
  };

  const handleChangeExpandOrderPanel: (expanded: boolean) => void = (expanded: boolean): void => {
    let newPanelState: IOrderPanelState = {
      isOrderPanelExpanded: expanded,
      isOrderProductPanelExpanded: false,
      isOrderProductLicensePanelExpanded: false,
    };
    dispatchPanelState(newPanelState);
  };

  const handleChangeExpandOrderProductPanel: (expanded: boolean) => void = (
    expanded: boolean
  ): void => {
    let newPanelState: IOrderPanelState = {
      isOrderPanelExpanded: false,
      isOrderProductPanelExpanded: expanded,
      isOrderProductLicensePanelExpanded: false,
    };
    dispatchPanelState(newPanelState);
  };

  const handleChangeExpandOrderProductLicensePanel: (expanded: boolean) => void = (
    expanded: boolean
  ): void => {
    let newPanelState: IOrderPanelState = {
      isOrderPanelExpanded: false,
      isOrderProductPanelExpanded: false,
      isOrderProductLicensePanelExpanded: expanded,
    };
    dispatchPanelState(newPanelState);
  };

  return (
    <React.Fragment>
      <GridContainer title={panelState.titleText ?? ""} titleSize={"sm"}>
        <Grid item container xs={12}>
          <PanelExpansion
            isExpanded={panelState.isOrderPanelExpanded ?? false}
            handleChangeExpand={handleChangeExpandOrderPanel}
            title={t("orderForm.listTitle")}>
            <OrderList handleChangeOrder={handleChangeOrder} />
          </PanelExpansion>
          <PanelExpansion
            isExpanded={panelState.isOrderProductPanelExpanded ?? false}
            isDisabled={!panelState.selectedOrderId}
            handleChangeExpand={handleChangeExpandOrderProductPanel}
            title={matches ? t("orderForm.listProductsTitle") : t("productForm.listTitle")}>
            <OrderProductList
              orderId={panelState.selectedOrderId ?? 0}
              handleChangeOrderProduct={handleChangeOrderProduct}
            />
          </PanelExpansion>
          <PanelExpansion
            isExpanded={panelState.isOrderProductLicensePanelExpanded ?? false}
            isDisabled={!panelState.selectedOrderProductId}
            handleChangeExpand={handleChangeExpandOrderProductLicensePanel}
            title={t("licenseForm.listTitle")}>
            <OrderProductLicenseList orderProductId={panelState.selectedOrderProductId ?? 0} />
          </PanelExpansion>
        </Grid>
      </GridContainer>
    </React.Fragment>
  );
};

export default OrderPanel;
