import "Styles/custom-styles-sweetalert2.scss";
import { useState } from "react";
import { IUseModalQuestionResult } from "./interfaces";
import Swal from "sweetalert2";
import id from "date-fns/esm/locale/id/index.js";

const useModalQuestion: () => IUseModalQuestionResult =
  (): IUseModalQuestionResult => {
    const [yesFunction, setYesFunction] = useState();
    const [noFunction, setNoFunction] = useState();

    const showModalQuestion: (
      question: string,
      itemId?: number,
      dynamicYesFunction?: any
    ) => Promise<void> = async (
      question: string,
      itemId?: number,
      dynamicYesFunction?: any
    ): Promise<void> => {
      Swal.fire({
        icon: "question",
        text: question,
        customClass: {
          title: "swal-title",
          container: "swal-container",
          content: "swal-content",
          confirmButton: "swal-confirm-button",
        },
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (yesFunction) {
            yesFunction(itemId);
          }
          if (dynamicYesFunction) {
            dynamicYesFunction(itemId);
          }
        } else {
          if (noFunction) {
            noFunction();
          }
        }
      });
    };

    return { showModalQuestion, setYesFunction, setNoFunction };
  };

export default useModalQuestion;
