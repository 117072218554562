import React from "react";
import { Link } from "react-router-dom";
import { themeGsot, themeSbs } from "Styles/themes";
import { IThemeLinkProps } from "./interfaces";

const ThemeLink: (props: IThemeLinkProps) => JSX.Element = (
  props: IThemeLinkProps
): JSX.Element => {
  const isSbs: boolean = window.location.href.indexOf("sbs.gsot") > 0;

  return (
    <Link
      style={{ color: (isSbs ? themeSbs : themeGsot).palette.primary.main }}
      to={props.to}
    >
      {props.text}
    </Link>
  );
};

export default ThemeLink;
