import * as React from "react";
import * as ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@material-ui/core/styles";
import Main from "Views/Main/main";
import "Configurations/i18n";
import "Styles/index.scss";
import { themeGsot, themeSbs } from "Styles/themes";

ReactDOM.render(
  <ThemeProvider
    theme={window.location.href.indexOf("sbs.gsot") > 0 ? themeSbs : themeGsot}
  >
    <Main />
  </ThemeProvider>,
  document.getElementById("root")
);

// if you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
