import * as React from "react";
import { Grid, AppBar, Toolbar } from "@material-ui/core";
import { ILayoutNotLoggedProps } from "./interfaces";
import { stylesLayoutNotLogged } from "./styles";
import PanelContainer from "Components/Panels/panel-container";
import PanelBasic from "Components/Panels/panel-basic";
import ButtonLanguage from "Components/Buttons/button-language";

const LayoutNotLogged: (props: ILayoutNotLoggedProps) => JSX.Element = (props: ILayoutNotLoggedProps): JSX.Element => {
  const customStyles: Record<string, string> = stylesLayoutNotLogged();
  const { children } = {...props};

  return (
    <React.Fragment>
      <AppBar className={customStyles.root}>
        <Toolbar className={customStyles.toolbar}>
          <ButtonLanguage />
        </Toolbar>
      </AppBar>
      <PanelContainer centerElements={true}>
          <Grid container justify="center" alignItems="center">
              <Grid item md={4}>
                <PanelBasic>
                  {children}
                </PanelBasic>
              </Grid>
          </Grid>
      </PanelContainer>

    </React.Fragment>
  );
};

export default LayoutNotLogged;
