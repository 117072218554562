import { IResultBase } from "Services/interfaces";
import { ISceneObjectApiInterface, ISceneObjectData } from "./interfaces";
import {
  ISceneObjectGetDetailsResult,
  ISceneObjectGetListResult,
} from "./interfaces-result";
import {
  ISceneObjectGetListModel,
  ISceneObjectUpdateModel,
} from "./interfaces-model";
import useBaseDataApi from "Services/base-data-api";
import { useBaseApi } from "Services/base-api";

const useSceneObjectApi: () => ISceneObjectApiInterface =
  (): ISceneObjectApiInterface => {
    const { baseDeleteById, baseGetDetails, baseGetList, baseSave } =
      useBaseDataApi({ apiControllerName: "SceneObject" });
    const { basePatchRequest } = useBaseApi();

    const deleteById: (id: number) => Promise<IResultBase> = async (
      id: number
    ): Promise<IResultBase> => {
      return await baseDeleteById<IResultBase>(id);
    };

    const save: (model: ISceneObjectData) => Promise<IResultBase> = async (
      model: ISceneObjectData
    ): Promise<IResultBase> => {
      return await baseSave<ISceneObjectData, IResultBase>(model);
    };

    const update: (model: ISceneObjectUpdateModel) => Promise<IResultBase> =
      async (model: ISceneObjectUpdateModel): Promise<IResultBase> => {
        let url: string = "/SceneObject/Update";
        return await basePatchRequest<ISceneObjectUpdateModel, IResultBase>(
          url,
          model
        );
      };

    const getDetails: (id: number) => Promise<ISceneObjectGetDetailsResult> =
      async (id: number): Promise<ISceneObjectGetDetailsResult> => {
        return await baseGetDetails<ISceneObjectGetDetailsResult>(id);
      };

    const getList: (
      model: ISceneObjectGetListModel
    ) => Promise<ISceneObjectGetListResult> = async (
      model: ISceneObjectGetListModel
    ): Promise<ISceneObjectGetListResult> => {
      return await baseGetList<
        ISceneObjectGetListModel,
        ISceneObjectGetListResult
      >(model);
    };

    return { deleteById, save, update, getDetails, getList };
  };

export default useSceneObjectApi;
