import * as React from "react";
import * as Yup from "yup";
import "react-block-ui/style.css";
import { Grid, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { ButtonPrimary } from "Components/Buttons/button-primary";
import { ApiResultStatus } from "Utils/enums";
import { IUserDetailsData } from "Services/UserApi/interfaces";
import { getStoredUserId } from "Utils/helpers";
import { useUserApi } from "Services/UserApi/user-api";
import { useTranslation } from "react-i18next";
import BlockUi from "react-block-ui";
import PanelBasic from "Components/Panels/panel-basic";
import useModalSuccess from "Hooks/use-modal-success";
import TextSubtitle from "Components/Texts/text-subtitle";

const UserDetailsInvoice: () => JSX.Element = (): JSX.Element => {
  const showModalSuccess: (successResult: string) => void = useModalSuccess(
    undefined
  );
  const { getDetails, update } = useUserApi();
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const [initialValues, setInitialValues] = React.useState({
    companyName: "",
    nip: "",
    addressLine1: "",
    addressLine2: "",
  });

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    setLoading(true);
    await getDetails(getStoredUserId())
      .then((response) => {
        if (response && response.data) {
          setInitialValues({
            companyName: response.data.invoiceCompanyName ?? "",
            nip: response.data.invoiceNIP ?? "",
            addressLine1: response.data.invoiceAddressLine1 ?? "",
            addressLine2: response.data.invoiceAddressLine2 ?? "",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate: (user: IUserDetailsData) => Promise<void> = async (
    user: IUserDetailsData
  ): Promise<void> => {
    setLoading(true);
    await update(user)
      .then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          showModalSuccess(t("saveChangesSuccess"));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <PanelBasic>
      <BlockUi
        blocking={loading}
        loader={<CircularProgress color="secondary" />}
      >
        <TextSubtitle>{t("invoiceForm.titleTo")}</TextSubtitle>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={Yup.object({
            companyName: Yup.string().max(
              200,
              t("validationResources:stringMaxLength", { length: 200 })
            ),
            nip: Yup.string().max(10, t("validationResources:nipMaxLength")),
            addressLine1: Yup.string().max(
              200,
              t("validationResources:stringMaxLength", { length: 200 })
            ),
            addressLine2: Yup.string().max(
              200,
              t("validationResources:stringMaxLength", { length: 200 })
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            let user: IUserDetailsData = {
              invoiceAddressLine1: values.addressLine1,
              invoiceAddressLine2: values.addressLine2,
              invoiceCompanyName: values.companyName,
              invoiceNIP: values.nip,
            };
            handleUpdate(user);
            setSubmitting(false);
          }}
        >
          {(formik) => (
            <Form>
              <Grid
                container
                item
                xs={12}
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid container item xs={12}>
                  <TextField
                    fullWidth
                    label={t("invoiceForm.companyName")}
                    variant="outlined"
                    size="small"
                    id="companyName"
                    name="companyName"
                  />
                </Grid>
                <Grid container item xs={12}>
                  <TextField
                    fullWidth
                    label={t("invoiceForm.nip")}
                    variant="outlined"
                    size="small"
                    id="nip"
                    name="nip"
                  />
                </Grid>
                <Grid container item xs={12}>
                  <TextField
                    fullWidth
                    label={t("invoiceForm.addressLine1")}
                    variant="outlined"
                    size="small"
                    id="addressLine1"
                    name="addressLine1"
                  />
                </Grid>
                <Grid container item xs={12}>
                  <TextField
                    fullWidth
                    label={t("invoiceForm.addressLine2")}
                    variant="outlined"
                    size="small"
                    id="addressLine2"
                    name="addressLine2"
                  />
                </Grid>
                <Grid container item xs={12} justify="center">
                  <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
                    {t("save")}
                  </ButtonPrimary>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </BlockUi>
    </PanelBasic>
  );
};

export default UserDetailsInvoice;
