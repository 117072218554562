import * as React from "react";
import { ApiResultStatus } from "Utils/enums";
import { IResultBase } from "Services/interfaces";
import { ISceneObjectTimelineImportCsvFileModel } from "Services/SceneObjectTimelineApi/interfaces-model";
import { ISceneObjectTimelineImportProps } from "../interfaces";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useSceneObjectTimelineApi from "Services/SceneObjectTimelineApi/scene-object-timeline-api";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import FileUploader from "Components/File/file-uploader";
import ButtonSecondary from "Components/Buttons/button-secondary";
import useModalSuccess from "Hooks/use-modal-success";
import useModalQuestion from "Hooks/use-modal-question";

const SceneObjectTimelineImport: (
  props: ISceneObjectTimelineImportProps
) => JSX.Element = (props: ISceneObjectTimelineImportProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [csvFile, setCsvFile] = React.useState<File>();
  const { importCSVFile } = useSceneObjectTimelineApi();
  const showModalSuccess: (successResult: string) => void =
    useModalSuccess(undefined);
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { t } = useTranslation(["commonResources"]);
  const importFileFormats: string[] = [".csv"];
  const refToFile: React.MutableRefObject<File | undefined> =
    React.useRef(csvFile);

  const handleClose: () => void = (): void => {
    setCsvFile(undefined);
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    setIsOpen(true);
  };

  const handleUploadFile: () => void = (): void => {
    if (csvFile) {
      refToFile.current = csvFile;
    }
    showModalQuestion(t("animationForm.importInfo"), props.sceneObjectId);
  };

  const uploadFile: (sceneObjectId: number) => Promise<void> = async (
    sceneObjectId: number
  ): Promise<void> => {
    if (refToFile.current) {
      await props
        .handleImport(refToFile.current, sceneObjectId)
        .then((response: IResultBase) => {
          if (response && response.status === ApiResultStatus.Ok) {
            showModalSuccess(t("animationForm.importSuccess"));
            if (props.handleSaveSuccess) {
              props.handleSaveSuccess();
            }
            handleClose();
          }
        });
    }
  };

  React.useEffect(() => {
    setYesFunction(() => uploadFile);
  }, []);

  return (
    <React.Fragment>
      {props.buttonSize == "xl" ? (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-file-csv"} color="secondary" />
          {t("importFromCsv")}
        </ButtonPrimary>
      ) : (
        <ButtonGridIcon
          icon={"fa-file-import"}
          color="secondary"
          onClick={handleOpen}
          title={t("importFromCsv")}
          style={{ padding: 0 }}
        />
      )}

      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>{t("animationForm.importFromCsv")}</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container justify="center" alignItems="flex-start">
            <Grid container item sm={12} spacing={2}>
              <Grid item xs={12}>
                <FileUploader
                  fileName={csvFile?.name}
                  file={csvFile}
                  setFile={setCsvFile}
                  acceptedFormats={importFileFormats}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <DialogActions>
            <ButtonSecondary onClick={handleClose}>
              {t("cancel")}
            </ButtonSecondary>
            <ButtonPrimary type="submit" onClick={handleUploadFile}>
              {t("save")}
            </ButtonPrimary>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SceneObjectTimelineImport;
