import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ISBSSceneObjectTimelineSoundListProps } from "../interfaces";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonSecondary from "Components/Buttons/button-secondary";
import SBSSceneObjectTimelineListGrid from "./sbs-scene-object-timeline-list-grid";
import SBSSceneObjectTimelineEditorAdmin from "../SceneObjectTimelineEdiotr/sbs-scene-object-timeline-editor-admin";

const SBSSceneObjectTimelineList: (
  props: ISBSSceneObjectTimelineSoundListProps
) => JSX.Element = (
  props: ISBSSceneObjectTimelineSoundListProps
): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const { t } = useTranslation(["commonResources"]);

  const handleOpen: () => void = (): void => {
    setIsOpen(true);
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
    props.handleCloseEditor();
  };

  return (
    <React.Fragment>
      <ButtonGridIcon
        icon={"fa-route"}
        title={t("sbsSceneObjectTimelineForm.showTimelinePointsList")}
        onClick={handleOpen}
        color="secondary"
        style={{ padding: 0 }}
      />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>{t("animationForm.edit")}</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            {!props.disabled && (
              <Grid item xs={12}>
                <SBSSceneObjectTimelineEditorAdmin
                  sbsSceneObjectType={props.sceneObjectType}
                  sbsSceneObjectId={props.sbsSceneObjectId}
                  handleClose={props.handleCloseEditor}
                  isOpen={props.isOpenEditor}
                  handleSaveSuccess={dispatchReloadTable}
                  withAddNewButton
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <SBSSceneObjectTimelineListGrid
                sceneObjectType={props.sceneObjectType}
                disabled={props.disabled}
                handleReloadTable={dispatchReloadTable}
                reloadTable={reloadTable}
                sbsSceneObjectId={props.sbsSceneObjectId}
                handleCloseEditor={props.handleCloseEditor}
                isOpenEditor={props.isOpenEditor}
              />
            </Grid>
          </Grid>
          <Divider />
          <DialogActions>
            <ButtonSecondary onClick={handleClose}>
              {t("cancel")}
            </ButtonSecondary>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SBSSceneObjectTimelineList;
