import * as React from "react";
import * as Yup from "yup";
import * as _ from "lodash";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { IObjectData } from "Services/ObjectApi/interfaces";
import { IObjectEditorAdminProps } from "../interfaces";
import { IObjectGetDetailsResult } from "Services/ObjectApi/interfaces-results";
import { ObjectType, ApiResultStatus, FileType } from "Utils/enums";
import { useFileApi } from "Services/FileApi/file-api";
import { useObjectApi } from "Services/ObjectApi/object-api";
import { IFileData } from "Services/FileApi/interfaces";
import { IFileUploadModel } from "Services/FileApi/interfaces-models";
import { IFileUploadResult } from "Services/FileApi/interfaces-results";
import { ILanguageGetListResult } from "Services/LanguageApi/interfaces-result";
import { ILanguageData } from "Services/LanguageApi/interfaces";
import { useLanguageApi } from "Services/LanguageApi/language-api";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE_ID } from "Utils/consts";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";
import useModalSuccess from "Hooks/use-modal-success";
import FileUploader from "Components/File/file-uploader";
import BlockUi from "react-block-ui";
import cogoToast from "cogo-toast";

const ObjectEditorAdmin: (props: IObjectEditorAdminProps) => JSX.Element = (
  props: IObjectEditorAdminProps
): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [languages, setLanguages] = React.useState<ILanguageData[]>([]);
  const [objectFile, setObjectFile] = React.useState<File>();
  const [quicklookFile, setQuicklookFile] = React.useState<File>();
  const { upload } = useFileApi();
  const { save, getDetails } = useObjectApi();
  const { getList } = useLanguageApi();

  const showModalSuccess: (successResult: string) => void = useModalSuccess(
    undefined
  );
  const [values, setValues] = React.useState<IObjectData>({
    name: "",
    description: "",
    type: ObjectType.Model3D,
    gsLanguageId: DEFAULT_LANGUAGE_ID,
  });
  const [valuesFile, setValuesFile] = React.useState<IFileData>({});
  const [quicklookValuesFile, setQuicklookValuesFile] = React.useState<IFileData>({});
  const { t } = useTranslation([
    "commonResources",
    "enumResources",
    "validationResources",
  ]);
  const model3DFileFormats: string[] = [".fbx", ".zip"];
  const quicklookFormatsArray: string[] = [".jpg"];

  const formatReducer: (
    formatState: string[],
    formatType: ObjectType
  ) => string[] = (formatState: string[], formatType: ObjectType): string[] => {
    switch (formatType) {
      case ObjectType.Sound:
        return [".mp3"];
      case ObjectType.Graphic:
        return [".jpg"];
        case ObjectType.Video:
        return [".webm"];
      case ObjectType.Model3D:
      default:
        return model3DFileFormats;
    }
  };
  const [formatsArray, setFormatsArray] = React.useReducer(
    formatReducer,
    model3DFileFormats
  );

  const setFile: (file: File | undefined) => void = (
    file: File | undefined
  ): void => {
    if (file) {
      let newValuesFile: IFileData = { ...valuesFile };
      newValuesFile = {
        originalFileName: file.name,
      };
      setValuesFile(newValuesFile);
    }
    setObjectFile(file);
  };

  const setNewQuicklookFile: (file: File | undefined) => void = (file: File | undefined): void => {
    if (file) {
      let newQuicklookFile: IFileData = { ...quicklookValuesFile };
      newQuicklookFile = {
        originalFileName: file.name,
      };
      setQuicklookValuesFile(newQuicklookFile);
    }
    setQuicklookFile(file);
  }

  const uploadFile: (
    file: IFileUploadModel
  ) => Promise<IFileUploadResult> = async (
    file: IFileUploadModel
  ): Promise<IFileUploadResult> => {
    return await upload(file);
  };

  const loadLanguages: () => Promise<void> = async () => {
    return await getList().then((response: ILanguageGetListResult) => {
      if (response?.data) {
        setLanguages(response.data);
      }
    });
  };

  const handleSaveObjectFile: (object: IObjectData) => Promise<IObjectData> = async (object: IObjectData): Promise<IObjectData> => {
    if (objectFile) {
      let fileToUpload: IFileUploadModel = {
        type: FileType.ObjectFile,
        file: objectFile,
      };
      await uploadFile(fileToUpload)
        .then((response) => {
          if (response?.status === ApiResultStatus.Ok) {
            object.file = response.data;
          } else {
            object = {};
          }
        });
      setValuesFile({});
    } else if (valuesFile) {
      object.file = valuesFile;
    }
    return object;
  }

  const handleSaveQuicklookFile: (object: IObjectData) => Promise<IObjectData> = async (object: IObjectData): Promise<IObjectData> => {
    if (quicklookFile) {
      let fileToUpload: IFileUploadModel = {
        type: FileType.ObjectQuicklookFile,
        file: quicklookFile,
      };
      await uploadFile(fileToUpload)
        .then((response) => {
          if (response?.status === ApiResultStatus.Ok) {
            object.quicklookFile = response.data;
          }
        });
      setQuicklookValuesFile({});
    } else if (quicklookValuesFile?.id) {
      object.quicklookFile = quicklookValuesFile;
    } else {
      object.quicklookFile = null;
    }
    return object;
  }

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    if (props.objectId) {
      setLoading(true);
      return await getDetails(props.objectId)
        .then((response: IObjectGetDetailsResult) => {
          if (response && response.data) {
            setValues(response.data);
            setValuesFile(response.data.file ?? {});
            setFormatsArray(response.data?.type ?? ObjectType.Model3D);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    if (props.objectId) {
      handleLoadData();
    }
    setIsOpen(true);
  };

  const handleSave: (object: IObjectData) => Promise<void> = async (
    object: IObjectData
  ): Promise<void> => {
    setLoading(true);
    await handleSaveObjectFile(object)
      .then((newObject) => {
        if(newObject.file) {
          handleSaveQuicklookFile(newObject)
          .then((newObject) => {
            save(newObject).then((response) => {
              if (response?.status === ApiResultStatus.Ok) {
                showModalSuccess(
                  newObject.id
                    ? t("saveChangesSuccess")
                    : t("objectForm.addSuccess")
                );
                if (props.handleSaveSuccess) {
                  props.handleSaveSuccess();
                }
                handleClose();
              }
            });
          })
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    loadLanguages();
  }, []);

  return (
    <React.Fragment>
      {props.objectId && (
        <ButtonGridIcon
          icon={"fa-edit"}
          onClick={handleOpen}
          color="secondary"
        />
      )}
      {!props.objectId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-cube"} color="secondary" />
          {t("objectForm.add")}
        </ButtonPrimary>
      )}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <BlockUi
          blocking={loading}
          loader={<CircularProgress color="secondary" />}
        >
          <DialogTitle>
            {props.objectId ? t("objectForm.edit") : t("objectForm.addInfo")}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Formik
              initialValues={values}
              enableReinitialize={true}
              validationSchema={Yup.object<IObjectData>({
                name: Yup.string()
                  .max(100, t("validationResources:nameMaxLength"))
                  .required(t("validationResources:required")),
                description: Yup.string()
                  .max(1000, t("validationResources:descriptionMaxLength"))
                  .nullable(),
                type: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .required(t("validationResources:required")),
                file: Yup.object(),
                gsLanguageId: Yup.number().required(
                  t("validationResources:required")
                ),
              })}
              onSubmit={(values: IObjectData) => {
                if (valuesFile.originalFileName) {
                  handleSave(values);
                } else {
                  cogoToast.error(t("fileForm.noChoose"));
                }
              }}
            >
              {(formik) => (
                <Form>
                  <Grid container justify="center" alignItems="flex-start">
                    <Grid container item md={6} sm={12}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          item
                          xs={12}
                          direction="column"
                          justify="flex-start"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Grid container item xs={12}>
                            <TextField
                              fullWidth
                              value={formik.values.name ?? ""}
                              onChange={formik.handleChange}
                              error={
                                !!(formik.errors.name && formik.touched.name)
                              }
                              helperText={
                                formik.errors.name &&
                                formik.touched.name &&
                                formik.errors.name
                              }
                              label={t("name")}
                              variant="outlined"
                              size="small"
                              name="name"
                            />
                          </Grid>
                          <Grid container item xs={12}>
                            <TextField
                              fullWidth
                              value={formik.values.description ?? ""}
                              onChange={formik.handleChange}
                              error={
                                !!(
                                  formik.errors.description &&
                                  formik.touched.description
                                )
                              }
                              helperText={
                                formik.errors.description &&
                                formik.touched.description &&
                                formik.errors.description
                              }
                              label={t("description")}
                              variant="outlined"
                              size="small"
                              name="description"
                              multiline
                              rowsMax="5"
                              rows="5"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item md={6} sm={12} spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl
                          fullWidth
                          size="small"
                          onChange={formik.handleChange}
                          variant="outlined"
                        >
                          <InputLabel id="language-select-id">
                            {t("language")}
                          </InputLabel>
                          <Select
                            name="gsLanguageId"
                            fullWidth
                            labelId="language-select-id"
                            value={
                              formik.values.gsLanguageId ?? languages[0].id
                            }
                            disabled={!!values.id}
                            onChange={formik.handleChange}
                          >
                            {_.map(languages, (value: ILanguageData) => {
                              return (
                                <MenuItem
                                  value={value.id ?? 0}
                                  key={`language-id-${value.id}`}
                                >
                                  {value.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth size="small" variant="outlined">
                          <InputLabel id="object-type">
                            {t("objectForm.type")}
                          </InputLabel>
                          <Select
                            name="type"
                            fullWidth
                            labelId="object-type"
                            value={formik.values.type || ObjectType.Model3D}
                            onChange={(event) => {
                              formik.handleChange(event);
                              setFormatsArray(event.target.value as ObjectType);
                            }}
                          >
                             <MenuItem value={ObjectType.Graphic}>
                              {t("enumResources:objectType.graphic")}
                            </MenuItem>
                            <MenuItem value={ObjectType.Model3D}>
                              {t("enumResources:objectType.model3D")}
                            </MenuItem>
                            <MenuItem value={ObjectType.Sound}>
                              {t("enumResources:objectType.sound")}
                            </MenuItem>
                            <MenuItem value={ObjectType.Video}>
                              {t("enumResources:objectType.video")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FileUploader
                          fileName={valuesFile?.originalFileName || undefined}
                          discFileName={valuesFile?.discFileName}
                          file={objectFile}
                          setFile={setFile}
                          acceptedFormats={formatsArray}
                        />
                      </Grid>
                      {formik.values.type === ObjectType.Model3D && valuesFile?.originalFileName &&
                          <Grid item xs={12}>
                            <FileUploader
                              fileName={quicklookValuesFile?.originalFileName || undefined}
                              discFileName={quicklookValuesFile?.discFileName}
                              file={quicklookFile}
                              setFile={setNewQuicklookFile}
                              acceptedFormats={quicklookFormatsArray}
                              addFilePreview={true}
                            />
                          </Grid>
                        }
                    </Grid>
                  </Grid>
                  <Divider />
                  <DialogActions>
                    <ButtonSecondary onClick={handleClose}>
                      {t("cancel")}
                    </ButtonSecondary>
                    <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                      {t("save")}
                    </ButtonPrimary>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </BlockUi>
      </Dialog>
    </React.Fragment>
  );
};

export default ObjectEditorAdmin;
