import * as React from "react";
import { ITextProps } from "./interfaces";
import { Typography } from "@material-ui/core";
import { stylesTextOverflow } from "./styles";

const TextInfo: (props: ITextProps) => JSX.Element = (
  props: ITextProps
): JSX.Element => {
  const customStyles: Record<string, string> = stylesTextOverflow();
  const { children, isHidden, overflowText, ...other } = props;

  return (
    <Typography
      variant="body2"
      hidden={!!isHidden}
      className={overflowText ? customStyles.overflowText : ""}
      {...other}
    >
      {children}
    </Typography>
  );
};

export default TextInfo;
