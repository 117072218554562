import * as React from "react";
import GridContainer from "Components/Grids/grid-container";
import OrderProductLicenseList from "../OrderProductLicenseList/order-product-license-list";
import { useTranslation } from "react-i18next";

const OrderProductLicensePanel: () => JSX.Element = (): JSX.Element => {
  const { t } = useTranslation(["commonResources"]);

  return (
    <React.Fragment>
      <GridContainer title={t("licenseForm.listTitle")}>
        <OrderProductLicenseList />
      </GridContainer>
    </React.Fragment>
  );
};

export default OrderProductLicensePanel;
