import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { IPanelContainerProps } from "./interfaces";

export const stylesPanel: () => Record<string, string> = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette.background.paper,
        padding: "20px",
        textAlign: "center",
      },
    })
);

export const stylesContainer: (
  props: IPanelContainerProps
) => Record<string, string> = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100vw",
      minHeight: "100vh",
      height: "100%",
      paddingTop: "65px",
      backgroundColor: theme.palette.background.default,
      display: (props: IPanelContainerProps) =>
        props.hidden ? "none" : "flex !important",
      [theme.breakpoints.up("lg")]: {
        margin: (props: IPanelContainerProps) =>
          props.centerElements ? "auto" : "0 !important",
        padding: "65px 0 0 0 !important",
      },
    },
  })
);

export const stylesPanelExpansion: () => Record<string, string> = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette.background.default,
      },
      extended: {
        margin: "0 !important",
        marginBottom: "0 !important",
        minHeight: "40px !important",
        height: "40px",
        paddingTop: "5px",
      },
      rootExtended: {
        boxShadow: `0px 0px 10px 1px black`,
      },
      details: {
        paddingTop: "0px",
      },
    })
);
