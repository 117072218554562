import * as React from "react";
import { ILayoutBaseProps } from "./interfaces";
import PanelContainer from "Components/Panels/panel-container";
import BarLeft from "Components/Bars/bar-left";
import BarTop from "Components/Bars/bar-top";
import { NOTIFICATION_INTERVAL_IN_MILISECONDS } from "Utils/consts";
import { useNotificationApi } from "Services/NotificationApi/notification-api";
import { ApiResultStatus, NotificationType, UserRole } from "Utils/enums";
import { INotificationGetListResult } from "Services/NotificationApi/interfaces-result";
import cogoToast from "cogo-toast";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { getStoredUserRole } from "Utils/helpers";

const LayoutBase: (props: ILayoutBaseProps) => JSX.Element = (
  props: ILayoutBaseProps
): JSX.Element => {
  const { children, childrenType } = { ...props };
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { getList } = useNotificationApi();
  const { t } = useTranslation(["commonResources"]);

  const handleMenuClick: () => void = (): void => {
    setDrawerOpen(!drawerOpen);
  };

  const loadNotifications: () => void = (): void => {
    getList({ isOnlyNew: true }).then(
      (response: INotificationGetListResult) => {
        if (
          response?.status === ApiResultStatus.Ok &&
          response?.data.recordsTotal > 0
        ) {
          _.each(response.data.gridData, (d) => {
            switch (d.type) {
              case NotificationType.SBSSceneFinished:
                cogoToast.success(
                  t("notificationTextByType.SBSSceneFinished", {
                    scene: d.sbsScene.name,
                    email: d.insertUser.email,
                  }),
                  { position: "bottom-right", hideAfter: 30 }
                );
                break;
            }
          });
        }
      }
    );
  };

  React.useEffect(() => {
    if (getStoredUserRole() === UserRole.Admin) {
      loadNotifications();

      const interval = setInterval(() => {
        loadNotifications();
      }, NOTIFICATION_INTERVAL_IN_MILISECONDS);

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <React.Fragment>
      <BarTop handleOnMenuClick={handleMenuClick} />
      <PanelContainer>
        <BarLeft
          drawerOpen={drawerOpen}
          handleDrawerToogle={handleMenuClick}
          value={childrenType}
        />
        {children}
      </PanelContainer>
    </React.Fragment>
  );
};

export default LayoutBase;
