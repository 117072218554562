import * as React from "react";
import * as Yup from "yup";
import "react-block-ui/style.css";
import { useConfigurationApi } from "Services/ConfigurationApi/configuration-api";
import { IConfigurationData } from "Services/ConfigurationApi/interfaces";
import { ApiResultStatus, AdvertMode } from "Utils/enums";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import {
  Grid,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import useModalSuccess from "Hooks/use-modal-success";
import PanelBasic from "Components/Panels/panel-basic";
import BlockUi from "react-block-ui";
import TextSubtitle from "Components/Texts/text-subtitle";
import ButtonPrimary from "Components/Buttons/button-primary";
import GridContainer from "Components/Grids/grid-container";

const ConfigurationEditorAdmin: () => JSX.Element = (): JSX.Element => {
  const showModalSuccess: (successResult: string) => void = useModalSuccess(
    undefined
  );
  const { getDetails, save } = useConfigurationApi();
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation([
    "commonResources",
    "enumResources",
    "validationResources",
  ]);
  const [initialValues, setInitialValues] = React.useState<IConfigurationData>({
    invoiceAddressLine1: "",
    invoiceAddressLine2: "",
    invoiceCompanyName: "",
    invoiceNIP: "",
    mobileAppMaxOfflineHours: 0,
    advertMode: AdvertMode.Picture,
    googleAndroidAdUnitId: "",
    mobileAppAdvertRefreshTimeInSeconds: 0,
  });

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    setLoading(true);
    await getDetails()
      .then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          setInitialValues({
            invoiceNIP: response.data.invoiceNIP ?? "",
            invoiceAddressLine1: response.data.invoiceAddressLine1 ?? "",
            invoiceAddressLine2: response.data.invoiceAddressLine2 ?? "",
            invoiceCompanyName: response.data.invoiceCompanyName ?? "",
            mobileAppMaxOfflineHours:
              response.data.mobileAppMaxOfflineHours ?? 0,
            advertMode: response.data.advertMode ?? AdvertMode.Picture,
            googleAndroidAdUnitId: response.data.googleAndroidAdUnitId ?? "",
            mobileAppAdvertRefreshTimeInSeconds:
              response.data.mobileAppAdvertRefreshTimeInSeconds ?? null,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave: (
    configuration: IConfigurationData
  ) => Promise<void> = async (
    configuration: IConfigurationData
  ): Promise<void> => {
    setLoading(true);
    await save(configuration)
      .then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          showModalSuccess(t("saveChangesSuccess"));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <React.Fragment>
      <GridContainer title={t("configurations")}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={Yup.object<IConfigurationData>({
            invoiceCompanyName: Yup.string().max(
              200,
              t("validationResources:stringMaxLength", { length: 200 })
            ),
            invoiceNIP: Yup.string().max(
              10,
              t("validationResources:nipMaxLength")
            ),
            invoiceAddressLine1: Yup.string().max(
              200,
              t("validationResources:stringMaxLength", { length: 200 })
            ),
            invoiceAddressLine2: Yup.string().max(
              200,
              t("validationResources:stringMaxLength", { length: 200 })
            ),
            mobileAppMaxOfflineHours: Yup.number()
              .typeError(t("validationResources:requiredNumeric"))
              .max(255, t("validationResources:numericMax255"))
              .min(0, t("validationResources:numericMin0"))
              .required(t("validationResources:required")),
            googleAndroidAdUnitId: Yup.string()
              .max(38, t("validationResources:stringMaxLength", { length: 38 }))
              .when("advertMode", {
                is: AdvertMode.Google,
                then: Yup.string()
                  .max(
                    38,
                    t("validationResources:stringMaxLength", { length: 38 })
                  )
                  .required(t("validationResources:required")),
              }),
            mobileAppAdvertRefreshTimeInSeconds: Yup.number()
              .typeError(t("validationResources:requiredNumeric"))
              .max(2147483647, t("validationResources:numericMax"))
              .min(0, t("validationResources:numericMin0"))
              .required(t("validationResources:required")),
          })}
          onSubmit={(values, { setSubmitting }) => {
            handleSave(values);
            setSubmitting(false);
          }}
        >
          {(formik) => (
            <Form style={{ width: "100%" }}>
              <Grid
                container
                justify="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid
                  container
                  item
                  xs={12}
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <ButtonPrimary
                    size="sm"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    {t("saveChanges")}
                  </ButtonPrimary>
                </Grid>
                <Grid container item xs={12} md={6}>
                  <PanelBasic>
                    <BlockUi
                      blocking={loading}
                      loader={<CircularProgress color="secondary" />}
                    >
                      <TextSubtitle>{t("invoiceForm.title")}</TextSubtitle>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            value={formik.values.invoiceCompanyName ?? ""}
                            onChange={formik.handleChange}
                            error={
                              !!(
                                formik.errors.invoiceCompanyName &&
                                formik.touched.invoiceCompanyName
                              )
                            }
                            helperText={
                              formik.errors.invoiceCompanyName &&
                              formik.touched.invoiceCompanyName &&
                              formik.errors.invoiceCompanyName
                            }
                            label={t("invoiceForm.companyName")}
                            variant="outlined"
                            size="small"
                            id="invoiceCompanyName"
                            name="invoiceCompanyName"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            value={formik.values.invoiceNIP ?? ""}
                            onChange={formik.handleChange}
                            error={
                              !!(
                                formik.errors.invoiceNIP &&
                                formik.touched.invoiceNIP
                              )
                            }
                            helperText={
                              formik.errors.invoiceNIP &&
                              formik.touched.invoiceNIP &&
                              formik.errors.invoiceNIP
                            }
                            label={t("invoiceForm.nip")}
                            variant="outlined"
                            size="small"
                            id="invoiceNIP"
                            name="invoiceNIP"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            value={formik.values.invoiceAddressLine1 ?? ""}
                            onChange={formik.handleChange}
                            error={
                              !!(
                                formik.errors.invoiceAddressLine1 &&
                                formik.touched.invoiceAddressLine1
                              )
                            }
                            helperText={
                              formik.errors.invoiceAddressLine1 &&
                              formik.touched.invoiceAddressLine1 &&
                              formik.errors.invoiceAddressLine1
                            }
                            label={t("invoiceForm.addressLine1")}
                            variant="outlined"
                            size="small"
                            id="invoiceAddressLine1"
                            name="invoiceAddressLine1"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            value={formik.values.invoiceAddressLine2 ?? ""}
                            onChange={formik.handleChange}
                            error={
                              !!(
                                formik.errors.invoiceAddressLine2 &&
                                formik.touched.invoiceAddressLine2
                              )
                            }
                            helperText={
                              formik.errors.invoiceAddressLine2 &&
                              formik.touched.invoiceAddressLine2 &&
                              formik.errors.invoiceAddressLine2
                            }
                            label={t("invoiceForm.addressLine2")}
                            variant="outlined"
                            size="small"
                            id="invoiceAddressLine2"
                            name="invoiceAddressLine2"
                          />
                        </Grid>
                      </Grid>
                    </BlockUi>
                  </PanelBasic>
                </Grid>
                <Grid container item xs={12} md={6}>
                  <PanelBasic>
                    <BlockUi
                      blocking={loading}
                      loader={<CircularProgress color="secondary" />}
                    >
                      <TextSubtitle>{t("mobileAppForm.title")}</TextSubtitle>
                      <Grid container item xs={12} spacing={2}>
                        <Grid container item xs={12} spacing={2}>
                          <Grid container item xs={6}>
                            <TextField
                              value={
                                formik.values.mobileAppMaxOfflineHours ?? ""
                              }
                              onChange={formik.handleChange}
                              error={
                                !!(
                                  formik.errors.mobileAppMaxOfflineHours &&
                                  formik.touched.mobileAppMaxOfflineHours
                                )
                              }
                              helperText={
                                formik.errors.mobileAppMaxOfflineHours &&
                                formik.touched.mobileAppMaxOfflineHours &&
                                formik.errors.mobileAppMaxOfflineHours
                              }
                              label={t("mobileAppForm.maxOfflineHours")}
                              variant="outlined"
                              size="small"
                              id="mobileAppMaxOfflineHours"
                              name="mobileAppMaxOfflineHours"
                            />
                          </Grid>
                          <Grid container item xs={6}>
                            <TextField
                              value={
                                formik.values
                                  .mobileAppAdvertRefreshTimeInSeconds
                              }
                              onChange={formik.handleChange}
                              error={
                                !!(
                                  formik.errors
                                    .mobileAppAdvertRefreshTimeInSeconds &&
                                  formik.touched
                                    .mobileAppAdvertRefreshTimeInSeconds
                                )
                              }
                              helperText={
                                formik.errors
                                  .mobileAppAdvertRefreshTimeInSeconds &&
                                formik.touched
                                  .mobileAppAdvertRefreshTimeInSeconds &&
                                formik.errors
                                  .mobileAppAdvertRefreshTimeInSeconds
                              }
                              label={t(
                                "mobileAppForm.advertRefreshTimeInSeconds"
                              )}
                              variant="outlined"
                              size="small"
                              id="mobileAppAdvertRefreshTimeInSeconds"
                              name="mobileAppAdvertRefreshTimeInSeconds"
                            />
                          </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                          <Grid container item xs={6}>
                            <FormControl
                              fullWidth
                              size="small"
                              onChange={formik.handleChange}
                              variant="outlined"
                            >
                              <InputLabel id="advert-mode-select-id">
                                {t("mobileAppForm.advertMode")}
                              </InputLabel>
                              <Select
                                name="advertMode"
                                fullWidth
                                labelId="advert-mode-select-id"
                                value={
                                  formik.values.advertMode ?? AdvertMode.Picture
                                }
                                onChange={formik.handleChange}
                              >
                                <MenuItem value={AdvertMode.Picture}>
                                  {t("enumResources:advertMode.picture")}
                                </MenuItem>
                                <MenuItem value={AdvertMode.Google}>
                                  {t("enumResources:advertMode.google")}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {formik.values.advertMode === AdvertMode.Google && (
                            <Grid container item xs={12}>
                              <TextField
                                fullWidth
                                value={
                                  formik.values.googleAndroidAdUnitId ?? ""
                                }
                                onChange={formik.handleChange}
                                error={
                                  !!(
                                    formik.errors.googleAndroidAdUnitId &&
                                    formik.touched.googleAndroidAdUnitId
                                  )
                                }
                                helperText={
                                  formik.errors.googleAndroidAdUnitId &&
                                  formik.touched.googleAndroidAdUnitId &&
                                  formik.errors.googleAndroidAdUnitId
                                }
                                label={t("mobileAppForm.googleAndroidAdUnitId")}
                                variant="outlined"
                                size="small"
                                id="googleAndroidAdUnitId"
                                name="googleAndroidAdUnitId"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </BlockUi>
                  </PanelBasic>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </GridContainer>
    </React.Fragment>
  );
};

export default ConfigurationEditorAdmin;
