import { axiosInstance, useBaseApi } from "Services/base-api";
import {
    IUserResetPasswordModel, IUserResetPasswordValidateTokenModel, IUserResetPasswordRequestModel,
    IUserResendUserConfirmationModel, IUserConfirmModel, IUserExternalLoginModel, IUserRegisterModel,
    IUserLoginModel, IUserGetUserListModel
} from "./interfaces-model";
import { IUserDetailsData, IUserApiInterface } from "./interfaces";
import { IUserDetailsResult, IUserLoginResult, IUserListResult } from "./interfaces-result";
import { IResultBase } from "Services/interfaces";
import useBaseDataApi from "Services/base-data-api";


export const useUserApi: () => IUserApiInterface
    = (): IUserApiInterface => {
        const { baseDeleteById, baseGetDetails, baseGetList } = useBaseDataApi({ apiControllerName: "User" });
        const { basePatchRequest, basePostRequest } = useBaseApi();

        const add: (model: IUserDetailsData) => Promise<IResultBase>
            = async (model: IUserDetailsData): Promise<IResultBase> => {
                let url: string = "/User/Add";
                return await basePostRequest<IUserDetailsData, IResultBase>(url, model);
            };

        const confirm: (model: IUserConfirmModel) => Promise<IResultBase>
            = async (model: IUserConfirmModel): Promise<IResultBase> => {
                let url: string = "/User/Confirm";
                return await basePostRequest<IUserConfirmModel, IResultBase>(url, model, false);
            };

        const deleteById: (id: number) => Promise<IResultBase>
            = async (id: number): Promise<IResultBase> => {
                return await baseDeleteById<IResultBase>(id);
            };

        const externalLogin: (model: IUserExternalLoginModel) => Promise<IUserLoginResult>
            = async (model: IUserExternalLoginModel): Promise<IUserLoginResult> => {
                let url: string = "/User/ExternalLogin";
                return await basePostRequest<IUserExternalLoginModel, IUserLoginResult>(url, model, false);
            };

        const getDetails: (id: number) => Promise<IUserDetailsResult>
            = async (id: number): Promise<IUserDetailsResult> => {
                return await baseGetDetails<IUserDetailsResult>(id);
            };

        const getList: (model: IUserGetUserListModel) => Promise<IUserListResult>
            = async (model: IUserGetUserListModel): Promise<IUserListResult> => {
                return await baseGetList<IUserGetUserListModel, IUserListResult>(model);
            };

        const login: (model: IUserLoginModel) => Promise<IUserLoginResult>
            = async (model: IUserLoginModel): Promise<IUserLoginResult> => {
                let url: string = "/User/Login";
                return await basePostRequest<IUserLoginModel, IUserLoginResult>(url, model, false);
            };

        const register: (model: IUserRegisterModel) => Promise<IResultBase>
            = async (model: IUserRegisterModel): Promise<IResultBase> => {
                let url: string = "/User/Register";
                return await basePostRequest<IUserRegisterModel, IResultBase>(url, model, false);
            };

        const resendUserConfirmation: (model: IUserResendUserConfirmationModel) => Promise<IResultBase>
            = async (model: IUserResendUserConfirmationModel): Promise<IResultBase> => {
                let url: string = "/User/ResendUserConfirmation";
                return await basePostRequest<IUserResendUserConfirmationModel, IResultBase>(url, model, false);
            };

        const resetPassword: (model: IUserResetPasswordModel) => Promise<IResultBase>
            = async (model: IUserResetPasswordModel): Promise<IResultBase> => {
                let url: string = "/User/ResetPassword";
                return await basePostRequest<IUserResetPasswordModel, IResultBase>(url, model, false);
            };

        const resetPasswordRequest: (model: IUserResetPasswordRequestModel) => Promise<IResultBase>
            = async (model: IUserResetPasswordRequestModel): Promise<IResultBase> => {
                let url: string = "/User/ResetPasswordRequest";
                return await basePostRequest<IUserResetPasswordRequestModel, IResultBase>(url, model, false);
            };

        const resetPasswordValidateToken: (model: IUserResetPasswordValidateTokenModel) => Promise<IResultBase>
            = async (model: IUserResetPasswordValidateTokenModel): Promise<IResultBase> => {
                return await axiosInstance
                    .post("/User/ResetPasswordValidateToken", JSON.stringify(model));
            };

        const update: (model: IUserDetailsData) => Promise<IResultBase>
            = async (model: IUserDetailsData): Promise<IResultBase> => {
                let url: string = "/User/Update";
                return await basePatchRequest<IUserDetailsData, IResultBase>(url, model);
            };

        return {
            add, confirm, deleteById, externalLogin, getDetails, getList, login, register,
            resendUserConfirmation, resetPassword, resetPasswordRequest, resetPasswordValidateToken, update
        };
    };