import * as React from "react";
import logo from "../../Images/logo.png";
import logo_sbs from "../../Images/logo_sbs.png";
import "@fortawesome/fontawesome-free/css/all.css";
import { Tab, Tabs, Icon, Hidden, Drawer, Box } from "@material-ui/core";
import { getStoredUserRole } from "Utils/helpers";
import { stylesBarLeft } from "./styles";
import { IBarLeftProps } from "./interfaces";
import { PrivateRoutes } from "Routes/routes";
import { Link, useHistory } from "react-router-dom";
import { UserRole } from "Utils/enums";
import { useTranslation } from "react-i18next";

const BarLeft: (props: IBarLeftProps) => JSX.Element = (
  props: IBarLeftProps
): JSX.Element => {
  const customStyles: Record<string, string> = stylesBarLeft();
  const userRole: UserRole = getStoredUserRole();
  const [value, setValue] = React.useState(props.value);
  const { t } = useTranslation(["commonResources"]);
  const history = useHistory();

  const handleChange: (
    event: React.ChangeEvent<{}>,
    newValue: string
  ) => void = (event: React.ChangeEvent<{}>, newValue: string): void => {
    setValue(newValue);
    history.push(newValue);
  };

  const tabsStructure: JSX.Element = (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      className={customStyles.tabs}
    >
      <Tab
        label={t("yourAccount")}
        value={PrivateRoutes.yourAccount}
        classes={{ selected: customStyles.selected }}
        icon={
          <Icon className="fa fa-user" classes={{ root: customStyles.icon }} />
        }
      />
      {userRole === UserRole.Admin && (
        <Tab
          label={t("users")}
          value={PrivateRoutes.users}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-users"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {userRole === UserRole.Admin && (
        <Tab
          label={t("applications")}
          value={PrivateRoutes.applications}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-mobile-alt"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {userRole === UserRole.Admin && (
        <Tab
          label={t("placesAndScenes")}
          value={PrivateRoutes.places}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-map-marked-alt"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {(userRole === UserRole.Admin || userRole === UserRole.SBS) && (
        <Tab
          label={t("sbsScenes")}
          value={PrivateRoutes.sbsScenes}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-object-group"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {(userRole === UserRole.Admin || userRole === UserRole.SBS) && (
        <Tab
          label={t("sbsObjects")}
          value={PrivateRoutes.sbsObjects}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-draw-polygon"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {userRole === UserRole.Admin && (
        <Tab
          label={t("objects")}
          value={PrivateRoutes.objects}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-cube"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {userRole === UserRole.Admin && (
        <Tab
          label={t("products")}
          value={PrivateRoutes.products}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-gifts"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {userRole === UserRole.Admin && (
        <Tab
          label={t("configuration")}
          value={PrivateRoutes.configurations}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-cogs"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {userRole === UserRole.Admin && (
        <Tab
          label={t("rewards")}
          value={PrivateRoutes.orderRewards}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-award"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {userRole === UserRole.Admin && (
        <Tab
          label={t("notifications")}
          value={PrivateRoutes.notificationList}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-bell"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {(userRole === UserRole.Admin || userRole === UserRole.Partner) && (
        <Tab
          label={t("eventLog")}
          value={PrivateRoutes.eventLog}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-calendar-week"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {userRole === UserRole.Admin && (
        <Tab
          label={t("adverts")}
          value={PrivateRoutes.adverts}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon className="fa fa-ad" classes={{ root: customStyles.icon }} />
          }
        />
      )}
      {userRole === UserRole.Standard && (
        <Tab
          label={t("orderSubmit")}
          value={PrivateRoutes.orderCreator}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-shopping-basket"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {(userRole === UserRole.Admin || userRole === UserRole.Standard) && (
        <Tab
          label={t("orders")}
          value={PrivateRoutes.orders}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-boxes"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
      {(userRole === UserRole.Admin || userRole === UserRole.Standard) && (
        <Tab
          label={t("licenses")}
          value={PrivateRoutes.ordersLicense}
          classes={{ selected: customStyles.selected }}
          icon={
            <Icon
              className="fa fa-id-badge"
              classes={{ root: customStyles.icon }}
            />
          }
        />
      )}
    </Tabs>
  );

  return (
    <div className={customStyles.root}>
      <Hidden lgUp>
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={props.drawerOpen}
          onClose={props.handleDrawerToogle}
          ModalProps={{
            keepMounted: true, // better open performance on mobile.
          }}
        >
          <Link to="/info">
            <Box className={customStyles.logoBox}>
              <img
                src={
                  window.location.href.indexOf("sbs.gsot") > 0 ? logo_sbs : logo
                }
                className={customStyles.logo}
              />
            </Box>
          </Link>
          {tabsStructure}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer variant="permanent" open>
          <Link to="/info">
            <Box className={customStyles.logoBox}>
              <img
                src={
                  window.location.href.indexOf("sbs.gsot") > 0 ? logo_sbs : logo
                }
                className={customStyles.logo}
              />
            </Box>
          </Link>
          {tabsStructure}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default BarLeft;
