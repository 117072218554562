import { UserRole } from "./enums";

export const DEFAULT_USER_AUTH_TOKEN: string = "";
export const DEFAULT_USER_EMAIL: string = "";
export const DEFAULT_USER_ROLE: UserRole = UserRole.Standard;
export const EPSG4326_WKT: string = "+proj=longlat +datum=WGS84 +no_defs";
export const EPSG2180_WKT: string =
  "+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";
export const DEFAULT_LANGUAGE_ID: number = 1; /* polski */
export const NOTIFICATION_INTERVAL_IN_MILISECONDS: number = 60000;
export const MAP_ANIMATION_FRAME_PER_SECOND: number = 10;
export const MAP_MIN_RADIUS_POINTS_MAGNES: number = 0.1;
