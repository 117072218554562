import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const stylesLayoutNotLogged: () => Record<string, string> = makeStyles((theme: Theme) =>
createStyles({
    root: {
        backgroundColor: theme.palette.background.default
    },
    toolbar: {
        justifyContent: "flex-end"
    }
}));