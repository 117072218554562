import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const stylesBarTop: () => Record<string, string> = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        zIndex: 1201,
        position: "fixed",
        width: "100%",
      },
      appBar: {
        backgroundColor: theme.palette.background.default,
        boxShadow: `0px 0px 20px 2px ${theme.palette.primary.dark}`,
        border: `2px solid ${theme.palette.background.default}`,
        height: "65px",
      },
    })
);

export const stylesBarLeft: () => Record<string, string> = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        height: "calc(100vh - 65px)", // 100vh - TopBar.height
        [theme.breakpoints.up("lg")]: {
          //paddingTop: "70px", // topBar.height
          width: "190px",
        },
      },
      tabs: {
        borderRight: `1px solid ${theme.palette.background.paper}`,
        color: theme.palette.primary.contrastText,
      },
      selected: {
        backgroundColor: `${theme.palette.background.paper} !important`,
        color: `${theme.palette.primary.main} !important`,
        fontWeight: theme.typography.fontWeightMedium,
      },
      icon: {
        display: "contents",
        fontSize: "15px",
      },
      box: {
        height: "100%",
        width: "100%",
      },
      logoBox: {
        backgroundColor: theme.palette.primary.light, //"#00c9fd",
        padding: "10px 10px 0 10px",
        textAlign: "center",
        verticalAlign: "",
        [theme.breakpoints.up("lg")]: {
          paddingTop: "70px", // topBar.height
        },
      },
      logo: {
        padding: "5px",
        width: "5em",
      },
    })
);
