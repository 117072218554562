import * as React from "react";
import { ApiResultStatus, UserGridGetListColumn } from "Utils/enums";
import { IUserDetailsData } from "Services/UserApi/interfaces";
import { IUserGetUserListModel } from "Services/UserApi/interfaces-model";
import { IUserListResult } from "Services/UserApi/interfaces-result";
import { Divider, Grid } from "@material-ui/core";
import { IResultBase } from "Services/interfaces";
import { useUserApi } from "Services/UserApi/user-api";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import useModalQuestion from "Hooks/use-modal-question";
import useModalSuccess from "Hooks/use-modal-success";
import UserEditorAdmin from "../UserEditors/user-editor-admin";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import GridTable from "Components/Grids/grid-table";
import axios from "axios";
import { getStoredUserAuthToken } from "Utils/helpers";
import { useTranslation } from "react-i18next";

const envBaseURL: string =
  process.env.REACT_APP_API_BASE_URL ?? "https://webapi.gsot.pl/api";
const envApiVersion: string = process.env.REACT_APP_API_VERSION ?? "v1";

const UserList: () => JSX.Element = (): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number
    = (reloadTable: number): number => {
      reloadTable += 1;
      return reloadTable;
    };
  const [reloadTable, dispatchReloadTable] = React.useReducer(reloadTableReducer, 0);
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { deleteById, getList } = useUserApi();
  const { t } = useTranslation(["commonResources"]);

  const handleClickDelete: (id: number) => Promise<void>
    = async (id: number): Promise<void> => {
      showModalQuestion(t("userForm.deleteInfo"), id);
    };

  const deleteUser: (id: number) => Promise<void>
    = async (id: number): Promise<void> => {
      await deleteById(id)
        .then((response: IResultBase) => {
          if (response && response.status === ApiResultStatus.Ok) {
            showModalSuccess(t("userForm.deleteSuccess"));
            dispatchReloadTable();
          }
        });
    };

  React.useEffect(() => {
    setYesFunction(() => deleteUser);
  }, []);

  const columnSortables: string[] = ["email", "roleText", "statusText", "invoiceCompanyName"];

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
        download: false
      }
    },
    {
      name: "email",
      label: t("email")
    },
    {
      name: "roleText",
      label: t("role")
    },
    {
      name: "statusText",
      label: t("status")
    },
    {
      name: "invoiceCompanyName",
      label: t("invoiceForm.companyName")
    },
    {
      name: "",
      options: {
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <UserEditorAdmin userId={tableMeta.rowData[0]} handleSaveSuccess={dispatchReloadTable} />
          );
        }
      }
    },
    {
      name: "",
      options: {
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGridIcon icon="fa-trash-alt"
              color="primary"
              onClick={() => {
                handleClickDelete(tableMeta.rowData[0]);
              }} />
          );
        }
      }
    }
  ];

  const generateSortParams: (changedColumn: string) => IGenerateSortParamsResult
    = (changedColumn: string): IGenerateSortParamsResult => {
      let newSortParams: IGenerateSortParamsResult = {
        columnIndex: 0,
        orderColumn: 1
      };

      switch (changedColumn) {
        case "email":
          newSortParams.columnIndex = 0;
          newSortParams.orderColumn = UserGridGetListColumn.Email;
          break;
        case "roleText":
          newSortParams.columnIndex = 1;
          newSortParams.orderColumn = UserGridGetListColumn.Role;
          break;
        case "statusText":
          newSortParams.columnIndex = 2;
          newSortParams.orderColumn = UserGridGetListColumn.Status;
          break;
        case "invoiceCompanyName":
          newSortParams.columnIndex = 3;
          newSortParams.orderColumn = UserGridGetListColumn.InvoiceCompanyName;
          break;
      }

      return newSortParams;
    };

  const tableOptions: MUIDataTableOptions = {
    customToolbar: () => {
      return <ButtonGridIcon
        icon="fa-file-csv"
        color="primary"
        title={t("exportToCsv")}
        onClick={() => {
          axios({
            url: `${envBaseURL}/${envApiVersion}/User/ExportToCsv`,
            method: 'GET',
            responseType: 'blob', // important
            headers: {
              "Authorization": getStoredUserAuthToken()
            }
          }).then((response) => {
            let fileDownload = require('js-file-download');
            fileDownload(response.data, `${t("users")}.csv`);
          });
        }}
      />
    }
  };

  return (
    <React.Fragment>
      <Grid container justify="flex-start" alignItems="flex-start">
        <Grid container item md={6} sm={12}>
          <UserEditorAdmin handleSaveSuccess={dispatchReloadTable} />
        </Grid>
      </Grid>
      <Divider />
      <GridTable<IUserGetUserListModel, IUserDetailsData, IUserListResult>
        reloadCount={reloadTable}
        columns={columns}
        columnsSortableNames={columnSortables}
        generateSortParams={generateSortParams}
        getList={getList}
        customTableOptions={tableOptions}
      />
    </React.Fragment>
  );
};

export default UserList;