import { createTheme, Theme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

export const themeGsot: Theme = createTheme({
  palette: {
    type: "light",
    primary: {
      light: "#6BDB6B",
      main: "#32CD32",
      dark: "#1C731C",
      contrastText: "#00c9fc",
    },
    secondary: {
      light: "#456697",
      main: "#304665",
      dark: "#223147",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
    divider: "#304665",
    text: {
      primary: "#01060e",
      secondary: "#DEF7DE",
    },
    action: {
      active: "#32CD32",
      disabled: "#dfe8e8",
      disabledBackground: "#D6E3D6",
    },
  },
  typography: {
    fontFamily: "Poppins",
    body1: {
      fontFamily: "Poppins",
    },
    h4: {
      fontFamily: "ArJulian",
    },
    body2: {
      fontFamily: "Poppins",
      fontSize: "12px",
    },
  },
  overrides: {
    MuiAvatar: {
      root: {
        width: "auto",
        height: "70px",
      },
    },
    MuiInputLabel: {
      root: {
        color: "#9CE79C",
      },
    },
    MuiPaper: {
      root: {
        width: "inherit",
      },
    },
    MUIDataTableBodyCell: {
      root: {
        "&:not(.compact)": {
          padding: "10px",
        },
      },
      stackedCommon: {
        "&:not([data-testid])": {
          color: "#6BDB6B",
          fontSize: "14px",
          padding: "5px",
        },
        [breakpoints.down("md")]: {
          textAlign: "center",
        },
      },
    },
    MUIDataTableHeadCell: {
      root: {
        "&[float=right] > [role=button] ": {
          float: "right",
        },
      },
    },
  } as any,
});

export const themeSbs: Theme = createTheme({
  palette: {
    type: "light",
    primary: {
      light: "#F36884",
      main: "#ED254E",
      dark: "#BD0F32",
      contrastText: "#465362",
    },
    secondary: {
      light: "#66798F",
      main: "#465362",
      dark: "#333D48",
    },
    background: {
      default: "#F4FFFD",
      paper: "#F4FFFD",
    },
    divider: "#465362",
    text: {
      primary: "#011936",
      secondary: "#465362",
    },
    action: {
      active: "#ED254E",
      disabled: "#dfe8e8",
      disabledBackground: "#D6E3D6",
    },
  },
  typography: {
    fontFamily: "Poppins",
    body1: {
      fontFamily: "Poppins",
    },
    h4: {
      fontFamily: "ArJulian",
    },
    body2: {
      fontFamily: "Poppins",
      fontSize: "12px",
    },
  },
  overrides: {
    MuiAvatar: {
      root: {
        width: "auto",
        height: "70px",
      },
    },
    MuiInputLabel: {
      root: {
        color: "#F36884",
      },
    },
    MuiPaper: {
      root: {
        width: "inherit",
      },
    },
    MUIDataTableBodyCell: {
      root: {
        "&:not(.compact)": {
          padding: "10px",
        },
      },
      stackedCommon: {
        "&:not([data-testid])": {
          color: "#ED254E",
          fontSize: "14px",
          padding: "5px",
        },
        [breakpoints.down("md")]: {
          textAlign: "center",
        },
      },
    },
    MUIDataTableHeadCell: {
      root: {
        "&[float=right] > [role=button] ": {
          float: "right",
        },
      },
    },
  } as any,
});
