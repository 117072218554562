import * as React from "react";
import * as Yup from "yup";
import { ApiResultStatus } from "Utils/enums";
import { Formik, Form } from "formik";
import {
  ISBSSceneObjectTimelineEditorAdminProps,
  ISBSSceneObjectTimelineSoundEditorAdminProps,
} from "../interfaces";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ISBSSceneObjectTimelineData } from "Services/SBSSceneObjectTimelineApi/interfaces";
import { ISBSSceneObjectTimelineGetDetailsResult } from "Services/SBSSceneObjectTimelineApi/interfaces-result";
import useModalSuccess from "Hooks/use-modal-success";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";
import useSBSSceneObjectTimelineApi from "Services/SBSSceneObjectTimelineApi/sbs-scene-object-timeline-api";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";

const SBSSceneObjectTimelineSoundEditorAdmin: (
  props: ISBSSceneObjectTimelineSoundEditorAdminProps
) => JSX.Element = (
  props: ISBSSceneObjectTimelineSoundEditorAdminProps
): JSX.Element => {
  const { save, getDetails } = useSBSSceneObjectTimelineApi();
  const showModalSuccess: (successResult: string) => void =
    useModalSuccess(undefined);
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [values, setValues] = React.useState<ISBSSceneObjectTimelineData>({
    id: 0,
    description: "",
    gssbsSceneObjectId: props.sbsSceneObjectId,
    startTimeInSeconds: 0,
    endTimeInSeconds: null,
  });

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    if (props.sbsSceneObjectTimelineId) {
      return await getDetails(props.sbsSceneObjectTimelineId).then(
        (response: ISBSSceneObjectTimelineGetDetailsResult) => {
          if (response && response.data) {
            setValues(response.data);
          }
        }
      );
    }
  };

  const handleSave: (
    sbsSceneObjectTimeline: ISBSSceneObjectTimelineData
  ) => Promise<void> = async (
    sbsSceneObjectTimeline: ISBSSceneObjectTimelineData
  ): Promise<void> => {
    sbsSceneObjectTimeline.gssbsSceneObjectId = props.sbsSceneObjectId;
    await save(sbsSceneObjectTimeline).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(
          sbsSceneObjectTimeline.id
            ? t("saveChangesSuccess")
            : t("animationForm.addSuccess")
        );
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
        handleClose();
      }
    });
  };

  const handleOpen: () => void = (): void => {
    setIsOpen(true);
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (props.sbsSceneObjectTimelineId) {
      handleLoadData();
    }
  }, [props.sbsSceneObjectTimelineId]);

  return (
    <React.Fragment>
      {props.sbsSceneObjectTimelineId && (
        <ButtonGridIcon
          icon={"fa-edit"}
          onClick={handleOpen}
          color="secondary"
        />
      )}
      {!props.sbsSceneObjectTimelineId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-route"} color="secondary" />
          {t("animationForm.add")}
        </ButtonPrimary>
      )}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          {props.sbsSceneObjectTimelineId
            ? t("animationForm.edit")
            : t("animationForm.addInfo")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<ISBSSceneObjectTimelineData>({
              id: Yup.number().required(),
              gssbsSceneObjectId: Yup.number().required(),
              description: Yup.string()
                .max(1000, t("validationResources:descriptionMaxLength"))
                .nullable(),
              endTimeInSeconds: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .max(1000000, t("validationResources:numericMax1000000"))
                .nullable(),
              startTimeInSeconds: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .max(1000000, t("validationResources:numericMax1000000"))
                .required(t("validationResources:required")),
            })}
            onSubmit={(values: ISBSSceneObjectTimelineData) => {
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid container justify="center" alignItems="flex-start">
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.description ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.description &&
                            formik.touched.description
                          )
                        }
                        helperText={
                          formik.errors.description &&
                          formik.touched.description &&
                          formik.errors.description
                        }
                        label={t("description")}
                        variant="outlined"
                        size="small"
                        name="description"
                        multiline
                        rowsMax="5"
                        rows="5"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={formik.values.startTimeInSeconds ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.startTimeInSeconds &&
                            formik.touched.startTimeInSeconds
                          )
                        }
                        helperText={
                          formik.errors.startTimeInSeconds &&
                          formik.touched.startTimeInSeconds &&
                          formik.errors.startTimeInSeconds
                        }
                        label={t("animationForm.startTime")}
                        variant="outlined"
                        size="small"
                        name="startTimeInSeconds"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={formik.values.endTimeInSeconds ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.endTimeInSeconds &&
                            formik.touched.endTimeInSeconds
                          )
                        }
                        helperText={
                          formik.errors.endTimeInSeconds &&
                          formik.touched.endTimeInSeconds &&
                          formik.errors.endTimeInSeconds
                        }
                        label={t("animationForm.endTime")}
                        variant="outlined"
                        size="small"
                        name="endTimeInSeconds"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SBSSceneObjectTimelineSoundEditorAdmin;
