import * as React from "react";
import { useOrderApi } from "Services/OrderApi/order-api";
import {
  OrderGridGetListColumn,
  UserRole,
  OrderStatus,
  ApiResultStatus,
} from "Utils/enums";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import {
  IOrderGetListResult,
  IOrderRetryPaymentResult,
} from "Services/OrderApi/interfaces-result";
import { IOrderData } from "Services/OrderApi/interfaces";
import { IOrderGetListModel } from "Services/OrderApi/interfaces-model";
import { IOrderListProps } from "../interfaces";
import { Link, useMediaQuery, useTheme, Theme } from "@material-ui/core";
import { getStoredUserAuthToken, getStoredUserRole } from "Utils/helpers";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GridTable from "Components/Grids/grid-table";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import moment from "moment";
import TextInfo from "Components/Texts/text-info";
import axios from "axios";
import cogoToast from "cogo-toast";
import useModalQuestion from "Hooks/use-modal-question";

const envBaseURL: string =
  process.env.REACT_APP_API_BASE_URL ?? "https://webapi.gsot.pl/api";
const envApiVersion: string = process.env.REACT_APP_API_VERSION ?? "v1";

const OrderList: (props: IOrderListProps) => JSX.Element = (
  props: IOrderListProps
): JSX.Element => {
  const theme: Theme = useTheme();
  const matches: boolean = useMediaQuery(theme.breakpoints.up("lg"));
  const userRole: UserRole = getStoredUserRole();
  const { getList, retryPayment } = useOrderApi();
  const { t } = useTranslation(["commonResources"]);

  const columnSortables: string[] = [
    "statusText",
    "totalPrice",
    "paymentTypeText",
    "orderProductsCount",
    "insertDate",
  ];

  const { showModalQuestion, setYesFunction } = useModalQuestion();

  const handleClickPdf: (url: string) => void = (url: string): void => {
    window.location.href = url;
  };
  const handleClickRetryPayment: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    showModalQuestion(t("orderForm.paymentInfo"), id);
  };

  const retryPaymentOrder: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await retryPayment({ gsOrderId: id }).then(
      (response: IOrderRetryPaymentResult) => {
        if (response && response.status === ApiResultStatus.Ok) {
          if (response.data.redirectUrl) {
            window.location.href = response.data.redirectUrl;
          }
          cogoToast.error(t("orderForm.paymentError"));
        }
      }
    );
  };

  React.useEffect(() => {
    setYesFunction(() => retryPaymentOrder);
  }, []);

  const tableOptions: MUIDataTableOptions = {
    customToolbar: () => {
      if (getStoredUserRole() === UserRole.Admin) {
        return (
          <ButtonGridIcon
            icon="fa-file-csv"
            color="primary"
            onClick={() => {
              axios({
                url: `${envBaseURL}/${envApiVersion}/Order/ExportToCsv`,
                method: "GET",
                responseType: "blob", // important
                headers: {
                  Authorization: getStoredUserAuthToken(),
                },
              }).then((response) => {
                let fileDownload = require("js-file-download");
                fileDownload(response.data, `${t("orders")}.csv`);
              });
            }}
          />
        );
      }
    },
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "orderNumber",
      label: t("number"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id: number = tableMeta.rowData[0];
          return (
            <Link
              color="secondary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.handleChangeOrder(id, value);
              }}
            >
              {value}
            </Link>
          );
        },
        sort: false,
      },
    },
    {
      name: "statusText",
      label: t("status"),
    },
    {
      name: "totalPrice",
      label: t("amount"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ textAlign: "right" }}>{value} zł</p>;
        },
        display: matches ? "true" : "false",
      },
    },
    {
      name: "paymentTypeText",
      label: t("orderForm.paymentType"),
      options: {
        display: matches ? "true" : "false",
      },
    },
    {
      name: "orderProductsCount",
      label: t("orderForm.productsCount"),
      options: {
        setCellProps: () => {
          return {
            style: {
              textAlign: "right",
            },
          };
        },
        setCellHeaderProps: () => {
          return {
            float: "right",
          };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ textAlign: "right" }}>{value}</p>;
        },
        display: matches ? "true" : "false",
      },
    },
    {
      name: "insertDate",
      label: t("orderDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD.MM.YYYY");
        },
        display: matches ? "true" : "false",
      },
    },
    {
      name: "paymentDate",
      label: t("paymentDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("DD.MM.YYYY") : "-";
        },
        sort: false,
        display: matches ? "true" : "false",
      },
    },
    {
      name: "status",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (userRole === UserRole.Standard && value === OrderStatus.ToPay) {
            let id: number = tableMeta.rowData[0];
            return (
              <ButtonGridIcon
                icon="fa-cash-register"
                color="primary"
                title={t("paymentForm.make")}
                onClick={() => {
                  handleClickRetryPayment(id);
                }}
              />
            );
          }
        },
        sort: false,
      },
    },
    {
      name: "invoicePdfFile",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value.discFileName) {
            let url: string = `${envBaseURL}/${envApiVersion}/File/Download/${value.discFileName}`;
            return matches ? (
              <ButtonGridIcon
                icon="fa-file-pdf"
                color="primary"
                title={t("exportToPdf")}
                onClick={() => {
                  handleClickPdf(url);
                }}
              />
            ) : (
              <TextInfo>
                <RouterLink color="secondary" to={url}>
                  {t("invoiceForm.download")}
                </RouterLink>
              </TextInfo>
            );
          } else {
            return matches ? (
              ""
            ) : (
              <TextInfo>{t("invoiceForm.notExists")}</TextInfo>
            );
          }
        },
        sort: false,
      },
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "statusText":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = OrderGridGetListColumn.Status;
        break;
      case "totalPrice":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn = OrderGridGetListColumn.TotalPrice;
        break;
      case "paymentTypeText":
        newSortParams.columnIndex = 2;
        newSortParams.orderColumn = OrderGridGetListColumn.PaymentType;
        break;
      case "orderProductsCount":
        newSortParams.columnIndex = 3;
        newSortParams.orderColumn = OrderGridGetListColumn.OrderProductsCount;
        break;
      case "insertDate":
        newSortParams.columnIndex = 4;
        newSortParams.orderColumn = OrderGridGetListColumn.InsertDate;
        break;
    }

    return newSortParams;
  };

  return (
    <React.Fragment>
      <GridTable<IOrderGetListModel, IOrderData, IOrderGetListResult>
        // reloadCount={reloadTable}
        customTableOptions={tableOptions}
        columns={columns}
        columnsSortableNames={columnSortables}
        generateSortParams={generateSortParams}
        getList={getList}
      />
    </React.Fragment>
  );
};

export default OrderList;
