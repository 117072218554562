export enum ApiResultStatus {
  Ok = 1,
  InternalServerError = 2,
  SecurityKeyNotProvided = 3,
  IncorrectSecurityKey = 4,
  UserNotFound = 5,
  UserBlocked = 6,
  UserNotConfirmed = 7,
  IncorrectAuthorizationToken = 8,
  InsufficientPermissions = 9,
  EmailAlreadyTaken = 10,
  UserAlreadyConfirmed = 11,
  TokenIncorrect = 12,
  TokenExpired = 13,
  TokenAlreadyUsed = 14,
  ItemNotFound = 17,
  TimeRangeOverlapFound = 41,
}

export enum UserRole {
  Admin = 1,
  Standard = 2,
  Partner = 3,
  SBS = 4,
}

export enum ExternalLoginType {
  Facebook = 1,
  Google = 2,
}

export enum UserStatus {
  NotConfirmed = 1,
  Active = 2,
  Blocked = 3,
}

export enum GridOrderDirection {
  Asc = 1,
  Desc = 2,
}

export enum UserGridGetListColumn {
  Email = 1,
  Role = 2,
  Status = 3,
  InvoiceCompanyName = 4,
  InvoiceAddress = 5,
  InvoiceNIP = 6,
}

export enum PlaceGridGetListColumn {
  Name = 1,
  Description = 2,
  IsAvailableInDemoVersion = 4,
  ScenesCount = 5,
}

export enum SceneGridGetListColumn {
  Name = 1,
  Description = 2,
  DemoVersionTimeInSeconds = 3,
  ObjectsCount = 4,
}

export enum ObjectType {
  Model3D = 1,
  Sound = 2,
  Graphic = 3,
  Video = 4,
}

export enum ObjectGridGetListColumn {
  Name = 1,
  Type = 2,
  OriginalFileName = 3,
  Description = 4,
}

export enum SBSObjectGridGetListColumn {
  Name = 1,
  Type = 2,
  OriginalFileName = 3,
  Description = 4,
}

export enum SBSSceneGridGetListColumn {
  Name = 1,
  Description = 2,
  ObjectsCount = 3,
}

export enum SBSSceneObjectGridGetListColumn {
  Name = 1,
  Type = 2,
  OriginalFileName = 3,
  Description = 4,
  TimelinesCount = 5,
}

export enum SceneObjectGridGetListColumn {
  Name = 1,
  Type = 2,
  OriginalFileName = 3,
  Description = 4,
  TimelinesCount = 5,
}

export enum ProductGridGetListColumn {
  Name = 1,
  Description = 2,
  Place = 3,
  Price = 4,
  ExpireDate = 5,
  LicenseActiveDays = 6,
  LicenseCodeActiveDays = 7,
  IsEnabled = 8,
}

export enum FileType {
  ObjectFile = 1,
  OrderInvoice = 2,
  AdvertImage = 3,
  SceneLogo = 4,
  ApplicationBackground = 5,
  ApplicationButtonGroupA = 6,
  ApplicationButtonGroupB = 7,
  ApplicationButtonGuide = 8,
  ApplicationButtonTable = 9,
  ApplicationButtonMuseum = 10,
  ApplicationButtonPlayground = 11,
  ApplicationButtonDefault = 12,
  ApplicationButtonClose = 13,
  ApplicationButtonLicense = 14,
  ApplicationLogo = 15,
  ApplicationGuideMap = 16,
  ApplicationGuideSceneLocalization = 17,
  ApplicationGuideUserLocalization = 18,
  SceneThemeMusic = 19,
  SceneRectangle = 20,
  SBSObjectFile = 21,
  SBSSceneRasterFile = 22,
  SBSObjectQuicklookFile = 23,
  ObjectQuicklookFile = 24
}

export enum OrderStatus {
  ToPay = 1,
  Paid = 2,
}

export enum OrderPaymentType {
  None = 0,
  Przelewy24 = 1,
  VirtualWallet = 2,
}

export enum MethodType {
  Increment = 1,
  Decrement = 2,
  Initialize = 3,
  Reset = 4,
}

export enum OrderProductLicenseStatus {
  ToUse = 1,
  Active = 2,
  CodeExpired = 3,
  Expired = 4,
  ProductExpired = 5,
}

export enum OrderGridGetListColumn {
  InsertDate = 1,
  User = 2,
  Status = 3,
  TotalPrice = 4,
  PaymentType = 5,
  OrderProductsCount = 6,
}

export enum OrderProductGridGetListColumn {
  Product = 1,
  ProductPrice = 2,
  Quantity = 3,
  TotalPrice = 4,
}

export enum OrderProductLicenseGridGetListColumn {
  Code = 1,
  Status = 2,
  ExpirationDate = 3,
  UseDate = 4,
  Product = 5,
  InsertDate = 6,
  Description = 7,
}

export enum EventLogType {
  All = 0,
  MobileAppLogin = 1,
  MobileAppUsing = 2,
  MobileAppSceneUsing = 3,
  MobileAppRegisterLicenseCode = 4,
  MobileAppShopping = 5,
}

export enum SceneUsingMode {
  OriginalArea = 1,
  Playground = 2,
  Table = 3,
}

export enum EventLogGridGetListColumn {
  Device = 1,
  StartDate = 2,
  EndDate = 3,
  Duration = 4,
  Scene = 5,
  RequestApiStatus = 6,
  SceneUsingMode = 7,
}

export enum AdvertType {
  Standard = 1,
  Alert = 2,
}

export enum AdvertMode {
  Picture = 1,
  Google = 2,
}

export enum AdvertGridGetListColumn {
  Name = 1,
  Description = 2,
  OriginalFileName = 3,
  StartDate = 4,
  EndDate = 5,
  Type = 6,
  Duration = 7,
}

export enum ApplicationGridGetListColumn {
  Name = 1,
  Guid = 2,
}

export enum SceneGroupType {
  Group1 = 1,
  Group2 = 2,
  Group3 = 3,
}

export enum SceneObjectTimelineGraphicVideoPosition {
  OneSideNoFollow = 1,
  OneSideFollow = 2,
  TwoSidesNoFollow = 3,
}

export enum SBSSceneStatus {
  Draft = 1,
  Finished = 2,
}

export enum NotificationType {
  SBSSceneFinished = 1,
}

export enum NotificationGridGetListColumn {
  InsertDate = 1,
}
