import axios, { AxiosInstance } from "axios";
import {
  getStoredUserAuthToken,
  getSelectedLanguageWithCulture,
} from "Utils/helpers";
import { SweetAlertResult } from "sweetalert2";
import { useHistory } from "react-router-dom";
import { IBaseApiInterface } from "./interfaces";
import useModalError from "Hooks/use-modal-error";
import useAuthorization from "Hooks/use-auth-token";
import { useTranslation } from "react-i18next";

const envBaseURL: string =
  process.env.REACT_APP_API_BASE_URL ?? "https://webapi.gsot.pl/api";
const envApiVersion: string = process.env.REACT_APP_API_VERSION ?? "v1";

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${envBaseURL}/${envApiVersion}`,
  headers: {
    "Content-Type": "application/json",
    "Content-": "application/json",
  },
});

export function useBaseApi(): IBaseApiInterface {
  const showModalError: (errorResult: string) => Promise<SweetAlertResult> =
    useModalError();
  const { t } = useTranslation(["validationResources"]);
  const history = useHistory();
  const { setUnauthorize } = useAuthorization(getStoredUserAuthToken());

  const handleError: (error: any) => void = (error: any): void => {
    console.error(error);
    if (error.response && error.response.status === 401) {
      showModalError(t("validationResources:sessionTimeout")).then(() => {
        setUnauthorize();
        history.push("/login");
      });
    } else if (error.response && error.response.status === 406) {
      showModalError(error.response.data.statusMessage);
    } else if (error.response) {
      showModalError(t("validationResources:globalError"));
    } else {
      //Leaflet WMS CORS error - just ignore it
    }
  };

  async function baseDeleteRequest<T>(
    apiUrl: string,
    withAutorization: boolean = true
  ): Promise<T> {
    return await axiosInstance
      .delete(apiUrl, {
        headers: {
          Authorization: withAutorization ? getStoredUserAuthToken() : "",
          language: getSelectedLanguageWithCulture(),
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        handleError(error);
      });
  }

  async function baseGetRequest<T>(
    apiUrl: string,
    withAutorization: boolean = true
  ): Promise<T> {
    return await axiosInstance
      .get(apiUrl, {
        headers: {
          Authorization: withAutorization ? getStoredUserAuthToken() : "",
          language: getSelectedLanguageWithCulture(),
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        handleError(error);
      });
  }

  async function basePatchRequest<modelT, resultT>(
    apiUrl: string,
    model: modelT,
    withAutorization: boolean = true
  ): Promise<resultT> {
    return await axiosInstance
      .patch(apiUrl, JSON.stringify(model), {
        headers: {
          Authorization: withAutorization ? getStoredUserAuthToken() : "",
          language: getSelectedLanguageWithCulture(),
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        handleError(error);
      });
  }

  async function basePostRequest<modelT, resultT>(
    apiUrl: string,
    model: modelT,
    withAutorization: boolean = true
  ): Promise<resultT> {
    return await axiosInstance
      .post(apiUrl, JSON.stringify(model), {
        headers: {
          Authorization: withAutorization ? getStoredUserAuthToken() : "",
          language: getSelectedLanguageWithCulture(),
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        handleError(error);
      });
  }

  return {
    baseDeleteRequest,
    baseGetRequest,
    basePatchRequest,
    basePostRequest,
  };
}
