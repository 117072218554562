import { useBaseApi } from "Services/base-api";
import { ILanguageApiInterface } from "./interfaces";
import { ILanguageGetListResult } from "./interfaces-result";

export const useLanguageApi: () => ILanguageApiInterface = (): ILanguageApiInterface => {
  const { baseGetRequest } = useBaseApi();

  const getList: () => Promise<ILanguageGetListResult> = async (): Promise<
    ILanguageGetListResult
  > => {
    let url: string = "Language/GetList";
    return await baseGetRequest<ILanguageGetListResult>(url, true);
  };

  return { getList };
};
