import * as React from "react";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { ApiResultStatus } from "Utils/enums";
import { useTranslation } from "react-i18next";
import { ISBSSceneObjectAddAdminProps } from "../interfaces";
import { ISBSSceneObjectData } from "Services/SBSSceneObjectApi/interfaces";
import ButtonSecondary from "Components/Buttons/button-secondary";
import cogoToast from "cogo-toast";
import useSBSSceneObjectApi from "Services/SBSSceneObjectApi/sbs-scene-object-api";
import SBSObjectList from "Components/Object/ObjectList/sbs-object-list";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";

const SBSSceneObjectAddAdmin: (
  props: ISBSSceneObjectAddAdminProps
) => JSX.Element = (props: ISBSSceneObjectAddAdminProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation(["commonResources"]);
  const { save } = useSBSSceneObjectApi();

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    setIsOpen(true);
  };

  const handleSave: (sbsSceneObject: ISBSSceneObjectData) => Promise<void> =
    async (sbsSceneObject: ISBSSceneObjectData): Promise<void> => {
      await save(sbsSceneObject).then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          cogoToast.success(t("sbsSceneObjectForm.addSuccess"));
          if (props.handleSaveSuccess) {
            props.handleSaveSuccess();
          }
        }
      });
    };

  const handleAddClick: (
    isAvailableInDemoVersion: boolean,
    id: number
  ) => void = (isAvailableInDemoVersion: boolean, id: number): void => {
    let newSbsSceneObject: ISBSSceneObjectData = {
      gSSBSSceneId: props.sbsSceneId,
      isAvailableInDemoVersion: isAvailableInDemoVersion,
      isFacingSpectator: false,
      isAvailableInTableSceneUsingMode: false,
      gSSBSObjectId: id,
    };
    handleSave(newSbsSceneObject);
  };

  return (
    <React.Fragment>
      <ButtonPrimary size="sm" onClick={handleOpen}>
        <ButtonGridIcon icon={"fa-map"} color="secondary" />
        {t("sbsSceneObjectForm.add")}
      </ButtonPrimary>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>{t("sbsSceneObjectForm.addInfo")}</DialogTitle>
        <Divider />
        <DialogContent>
          <SBSObjectList
            enableEdit={false}
            enableSelect={true}
            handleSelect={handleAddClick}
          />
          <Divider />
          <DialogActions>
            <ButtonSecondary onClick={handleClose}>
              {t("close")}
            </ButtonSecondary>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SBSSceneObjectAddAdmin;
