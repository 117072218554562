import * as Yup from "yup";
import * as _ from "lodash";
import React from "react";
import GridContainer from "Components/Grids/grid-container";
import useFileApi from "Services/FileApi/file-api";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ISBSSceneData } from "Services/SBSSceneApi/interfaces";
import { ISBSSceneEditorAdminProps } from "../interfaces";
import { Form, Formik, getIn } from "formik";
import { IFileData } from "Services/FileApi/interfaces";
import { IFileUploadModel } from "Services/FileApi/interfaces-models";
import { IFileUploadResult } from "Services/FileApi/interfaces-results";
import { useSBSSceneApi } from "Services/SBSSceneApi/sbs-scene-api";
import {
  ISBSSceneGetDetailsData,
  ISBSSceneGetDetailsResult,
} from "Services/SBSSceneApi/interfaces-result";
import MapBasic from "Components/Maps/map-basic";
import FileUploader from "Components/File/file-uploader";
import TextInfo from "Components/Texts/text-info";
import ButtonPrimary from "Components/Buttons/button-primary";
import { Publish } from "@material-ui/icons";
import { ILocalization } from "Services/interfaces";
import { ApiResultStatus, FileType, SBSSceneStatus } from "Utils/enums";
import useModalSuccess from "Hooks/use-modal-success";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import { useSBSObjectApi } from "Services/SBSObjectApi/sbs-object-api";
import { ISBSObjectGetListResult } from "Services/SBSObjectApi/interfaces-result";
import { ISBSObjectData } from "Services/SBSObjectApi/interfaces";
import { ISBSSceneSaveModel } from "Services/SBSSceneApi/interfaces-model";
import { value } from "numeral";

const envBaseURL: string =
  process.env.REACT_APP_API_BASE_URL ?? "https://webapi.gsot.pl/api";
const envApiVersion: string = process.env.REACT_APP_API_VERSION ?? "v1";

const SBSSceneEditorAdmin: () => JSX.Element = (): JSX.Element => {
  const history = useHistory();
  const availableRasterFileFormats: string[] = [".png", ".jpg"];
  const showModalSuccess: (successResult: string) => void =
    useModalSuccess(undefined);
  const { t } = useTranslation(["commonResources"]);
  const { sbsSceneId } = useParams<ISBSSceneEditorAdminProps>();
  const { save, getDetails } = useSBSSceneApi();
  const { upload } = useFileApi();
  const { getList } = useSBSObjectApi();
  const [isBlocked, setIsBlocked] = React.useState<boolean>(false);
  const [rasterFile, setRasterFile] = React.useState<File | null>();
  const [wmsUrlView, setWmsUrlView] = React.useState<string>();
  const [valueRasterFile, setValueRasterFile] =
    React.useState<IFileData | null>(null);
  const [values, setValues] = React.useState<ISBSSceneGetDetailsData>({
    name: "",
    description: "",
    status: SBSSceneStatus.Draft,
  });
  const [rasterClickCount, setRasterClickCount] = React.useState<number>(0);
  const [sBSObjectList, setSBSObjectList] = React.useState<ISBSObjectData[]>(
    []
  );

  const setFile: (file: File | undefined) => void = (
    file: File | undefined
  ): void => {
    const newValueFile: IFileData | null = file
      ? {
          originalFileName: file.name,
        }
      : null;
    setValueRasterFile(newValueFile);
    setRasterFile(file);
  };

  const uploadFile: (
    file: IFileUploadModel
  ) => Promise<IFileUploadResult> = async (
    file: IFileUploadModel
  ): Promise<IFileUploadResult> => {
    return await upload(file);
  };

  const loadSBSObjects: () => Promise<void> = async () => {
    return await getList({}).then((result: ISBSObjectGetListResult) => {
      if (result?.data) {
        setSBSObjectList(_.sortBy(result.data?.gridData, ["name"]) ?? []);
      }
    });
  };

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    loadSBSObjects().then(() => {
      let id: number = parseInt(sbsSceneId ?? "0");
      if (id > 0) {
        return getDetails(id).then((response: ISBSSceneGetDetailsResult) => {
          if (response?.data) {
            setValues(response.data);
            setValueRasterFile(response.data.rasterFile ?? null);
          }
        });
      }
    });
  };

  const handleClickBack: () => void = (): void => {
    history.push(`/sbs-scenes`);
  };

  const handleFilesSave: () => Promise<void> = async (): Promise<void> => {
    let fileToUpload: IFileUploadModel;
    if (rasterFile) {
      let newValueRasterFile: IFileData | null = null;
      fileToUpload = {
        type: FileType.SBSSceneRasterFile,
        file: rasterFile,
      };
      await uploadFile(fileToUpload).then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          newValueRasterFile = response.data;
        }
      });
      setValueRasterFile(newValueRasterFile);
    }

  };

  const handleSave: (valuesToSave: ISBSSceneSaveModel) => void = (valuesToSave: ISBSSceneSaveModel): void => {
    let sceneId = parseInt(sbsSceneId ?? "0");
    valuesToSave.id = sceneId > 0 ? sceneId : undefined;
    valuesToSave.rasterFile = valueRasterFile ? {...valueRasterFile} : null;
    
    save(valuesToSave).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(
          valuesToSave.id ? t("saveChangesSuccess") : t("sbsSceneForm.addSuccess")
        );
        history.push("/sbs-scenes");
      }
    });
  };

  const handleSetRasterBoundsClick: (formikValues: ISBSSceneData) => void = (
    formikValues: ISBSSceneData
  ): void => {
    setValues(formikValues);
    setIsBlocked(true);
  };

  const handleRasterClickMap: (point: ILocalization) => void = (
    point: ILocalization
  ): void => {
    if (isBlocked) {
      let newValues: ISBSSceneData = { ...values };
      if (rasterClickCount === 0) {
        newValues.point1EPSG4326 = point;
      } else {
        newValues.point2EPSG4326 = point;
      }
      setValues(newValues);
      setRasterClickCount((c) => (c === 1 ? 0 : c + 1));
    }
  };

  React.useEffect(() => {
    if (rasterClickCount === 0) {
      setIsBlocked(false);
    }
  }, [rasterClickCount]);

  React.useEffect(() => {
    handleFilesSave();
  }, [rasterFile]);

  React.useEffect(() => {
    handleLoadData();
  }, []);

  return (
    <GridContainer
      titleChildren={
        <ButtonGridIcon
          icon={"fa-chevron-circle-left"}
          onClick={handleClickBack}
          color="secondary"
        />
      }
      title={
        parseInt(sbsSceneId ?? "0") > 0
          ? t("sbsSceneForm.edit")
          : t("sbsSceneForm.addInfo")
      }
    >
      <Formik
        initialValues={values}
        enableReinitialize={true}
        validationSchema={Yup.object<ISBSSceneGetDetailsData>({
          name: Yup.string()
            .max(100, t("validationResources:nameMaxLength"))
            .required(t("validationResources:required")),
          description: Yup.string()
            .max(1000, t("validationResources:descriptionMaxLength"))
            .nullable(),
          wmsUrl: Yup.string()
            .max(500, t("validationResources:stringMaxLength", { length: 500 }))
            .nullable(),
          point1EPSG4326: Yup.object<ILocalization>({
            latitude: Yup.number()
              .typeError(t("validationResources:requiredNumeric"))
              .nullable(),
            longitude: Yup.number()
              .typeError(t("validationResources:requiredNumeric"))
              .nullable(),
          }).nullable(),
          point2EPSG4326: Yup.object<ILocalization>({
            latitude: Yup.number()
              .typeError(t("validationResources:requiredNumeric"))
              .nullable(),
            longitude: Yup.number()
              .typeError(t("validationResources:requiredNumeric"))
              .nullable(),
          }).nullable(),
          status: Yup.number(),
          wmsLayer: Yup.string()
            .when("wmsUrl", {
              is: (wmsUrl) => (wmsUrl?.length ?? 0) > 0,
              then: Yup.string().required(t("validationResources:required")),
            })
            .max(500, t("validationResources:stringMaxLength", { length: 500 }))
            .nullable(),
        })}
        onSubmit={(values: ISBSSceneGetDetailsData) => {
          let valuesToSave: ISBSSceneSaveModel = {
            ...values,
            rasterGSSBSObjectId: values.rasterGSSBSObject?.id ?? undefined,
          };
          handleSave(valuesToSave);
        }}
      >
        {(formik) => (
          <Form>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid container item xs={12} md={6} spacing={1}>
                <Grid item xs={3}>
                  {formik.values.status === SBSSceneStatus.Draft && (
                    <ButtonPrimary onClick={formik.handleSubmit}>
                      {t("save")}
                    </ButtonPrimary>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={formik.values.name ?? ""}
                    onChange={formik.handleChange}
                    error={!!(formik.errors.name && formik.touched.name)}
                    helperText={
                      formik.errors.name &&
                      formik.touched.name &&
                      formik.errors.name
                    }
                    label={t("name")}
                    variant="outlined"
                    size="small"
                    name="name"
                    disabled={
                      formik.values.status === SBSSceneStatus.Finished ||
                      isBlocked
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={formik.values.description ?? ""}
                    onChange={formik.handleChange}
                    error={
                      !!(
                        formik.errors.description && formik.touched.description
                      )
                    }
                    helperText={
                      formik.errors.description &&
                      formik.touched.description &&
                      formik.errors.description
                    }
                    label={t("description")}
                    variant="outlined"
                    size="small"
                    name="description"
                    multiline
                    rowsMax="5"
                    rows="5"
                    disabled={
                      formik.values.status === SBSSceneStatus.Finished ||
                      isBlocked
                    }
                  />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    fullWidth
                    value={formik.values.wmsUrl ?? ""}
                    onChange={formik.handleChange}
                    error={!!(formik.errors.wmsUrl && formik.touched.wmsUrl)}
                    helperText={
                      formik.errors.wmsUrl &&
                      formik.touched.wmsUrl &&
                      formik.errors.wmsUrl
                    }
                    label={t("sbsSceneForm.wmsUrl")}
                    variant="outlined"
                    size="small"
                    name="wmsUrl"
                    disabled={
                      formik.values.status === SBSSceneStatus.Finished ||
                      isBlocked
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  {formik.values.status !== SBSSceneStatus.Finished ||
                    (!isBlocked && (
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => {
                          setWmsUrlView(formik.values.wmsUrl ?? undefined);
                        }}
                        disabled={
                          formik.values.status === SBSSceneStatus.Finished ||
                          isBlocked
                        }
                      >
                        <Publish />
                      </IconButton>
                    ))}
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    fullWidth
                    value={formik.values.wmsLayer ?? ""}
                    onChange={formik.handleChange}
                    error={
                      !!(formik.errors.wmsLayer && formik.touched.wmsLayer)
                    }
                    helperText={
                      formik.errors.wmsLayer &&
                      formik.touched.wmsLayer &&
                      formik.errors.wmsLayer
                    }
                    label={t("sbsSceneForm.wmsLayer")}
                    variant="outlined"
                    size="small"
                    name="wmsLayer"
                    disabled={
                      formik.values.status === SBSSceneStatus.Finished ||
                      isBlocked
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInfo>{t("sbsSceneForm.rasterFile")}</TextInfo>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <FileUploader
                    fileName={valueRasterFile?.originalFileName ?? undefined}
                    discFileName={valueRasterFile?.discFileName}
                    file={rasterFile}
                    setFile={setFile}
                    acceptedFormats={availableRasterFileFormats}
                    addFilePreview
                    showDeleteButton
                    disabled={formik.values.status === SBSSceneStatus.Finished}
                  />
                  {formik.values.status !== SBSSceneStatus.Finished &&
                    valueRasterFile && (
                      <ButtonPrimary
                        onClick={() => {
                          handleSetRasterBoundsClick(formik.values);
                        }}
                      >
                        {t("sbsSceneForm.setRasterBound")}
                      </ButtonPrimary>
                    )}
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  spacing={1}
                  alignContent="flex-start"
                >
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={formik.values.point1EPSG4326?.longitude ?? ""}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          getIn(formik.errors, "point1EPSG4326.longitude") &&
                          getIn(formik.touched, "point1EPSG4326.longitude")
                        )
                      }
                      helperText={
                        getIn(formik.errors, "point1EPSG4326.longitude") &&
                        getIn(formik.touched, "point1EPSG4326.longitude") &&
                        getIn(formik.errors, "point1EPSG4326.longitude")
                      }
                      label={t("sbsSceneForm.rasterALongitude")}
                      variant="outlined"
                      size="small"
                      name="point1EPSG4326.longitude"
                      disabled={
                        formik.values.status === SBSSceneStatus.Finished ||
                        isBlocked
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={formik.values.point1EPSG4326?.latitude ?? ""}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          getIn(formik.errors, "point1EPSG4326.latitude") &&
                          getIn(formik.touched, "point1EPSG4326.latitude")
                        )
                      }
                      helperText={
                        getIn(formik.errors, "point1EPSG4326.latitude") &&
                        getIn(formik.touched, "point1EPSG4326.latitude") &&
                        getIn(formik.errors, "point1EPSG4326.latitude")
                      }
                      label={t("sbsSceneForm.rasterALatitude")}
                      variant="outlined"
                      size="small"
                      name="point1EPSG4326.latitude"
                      disabled={
                        formik.values.status === SBSSceneStatus.Finished ||
                        isBlocked
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={formik.values.point2EPSG4326?.longitude ?? ""}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          getIn(formik.errors, "point2EPSG4326.longitude") &&
                          getIn(formik.touched, "point2EPSG4326.longitude")
                        )
                      }
                      helperText={
                        getIn(formik.errors, "point2EPSG4326.longitude") &&
                        getIn(formik.touched, "point2EPSG4326.longitude") &&
                        getIn(formik.errors, "point2EPSG4326.longitude")
                      }
                      label={t("sbsSceneForm.rasterBLongitude")}
                      variant="outlined"
                      size="small"
                      name="point2EPSG4326.longitude"
                      disabled={
                        formik.values.status === SBSSceneStatus.Finished ||
                        isBlocked
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={formik.values.point2EPSG4326?.latitude ?? ""}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          getIn(formik.errors, "point2EPSG4326.latitude") &&
                          getIn(formik.touched, "point2EPSG4326.latitude")
                        )
                      }
                      helperText={
                        getIn(formik.errors, "point2EPSG4326.latitude") &&
                        getIn(formik.touched, "point2EPSG4326.latitude") &&
                        getIn(formik.errors, "point2EPSG4326.latitude")
                      }
                      label={t("sbsSceneForm.rasterBLatitude")}
                      variant="outlined"
                      size="small"
                      name="point2EPSG4326.latitude"
                      disabled={
                        formik.values.status === SBSSceneStatus.Finished ||
                        isBlocked
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      size="small"
                      onChange={formik.handleChange}
                      variant="outlined"
                    >
                      <InputLabel
                        id="raster-object-id"
                        shrink={!!formik.values.rasterGSSBSObject?.id}
                      >
                        {t("sbsSceneForm.rasterModelDefinigScope")}
                      </InputLabel>
                      <Select
                        name="rasterGSSBSObject.id"
                        fullWidth
                        labelId="raster-object-id"
                        defaultValue={
                          formik.values.rasterGSSBSObject?.id ?? null
                        }
                        value={formik.values.rasterGSSBSObject?.id ?? null}
                        onChange={formik.handleChange}
                        disabled={
                          formik.values.status === SBSSceneStatus.Finished ||
                          isBlocked
                        }
                      >
                        <MenuItem value="">
                          <em>{t("none")}</em>
                        </MenuItem>
                        {_.map(sBSObjectList, (value: ISBSObjectData) => {
                          return (
                            <MenuItem
                              value={value.id ?? 0}
                              key={`raster-object-id-${value.id}`}
                            >
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <MapBasic
                  imageUrl={
                    valueRasterFile
                      ? `${envBaseURL}/${envApiVersion}/File/Download/${valueRasterFile.discFileName}`
                      : undefined
                  }
                  imageBounds={
                    formik.values.point1EPSG4326 && formik.values.point2EPSG4326
                      ? [
                          formik.values.point1EPSG4326,
                          formik.values.point2EPSG4326,
                        ]
                      : []
                  }
                  wmsUrl={formik.values.wmsUrl ?? undefined}
                  wmsLayer={formik.values.wmsLayer ?? undefined}
                  handleClickMap={handleRasterClickMap}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </GridContainer>
  );
};

export default SBSSceneEditorAdmin;
