import * as React from "react";
import { IconButton } from "@material-ui/core";
import { stylesButtonMenu } from "./styles";
import { IButtonBaseProps } from "./interfaces";
import MenuIcon from "@material-ui/icons/Menu";

const ButtonMenu: (props: IButtonBaseProps) =>  JSX.Element = (props: IButtonBaseProps): JSX.Element => {
    const customStyles: Record<string, string> = stylesButtonMenu();

    return (
        <IconButton edge="start" color="primary" className={customStyles.root}
            onClick={props.handlerOnClick}>
            <MenuIcon/>
        </IconButton>
    );
};

export default ButtonMenu;