import { IResultBase } from "Services/interfaces";
import { ISBSSceneObjectApiInterface, ISBSSceneObjectData } from "./interfaces";
import {
  ISBSSceneObjectGetDetailsResult,
  ISBSSceneObjectGetListResult,
} from "./interfaces-result";
import {
  ISBSSceneObjectGetListModel,
  ISBSSceneObjectUpdateModel,
} from "./interfaces-model";
import useBaseDataApi from "Services/base-data-api";
import { useBaseApi } from "Services/base-api";

const useSBSSceneObjectApi: () => ISBSSceneObjectApiInterface =
  (): ISBSSceneObjectApiInterface => {
    const { baseDeleteById, baseGetDetails, baseGetList, baseSave } =
      useBaseDataApi({ apiControllerName: "SBSSceneObject" });
    const { basePatchRequest } = useBaseApi();

    const deleteById: (id: number) => Promise<IResultBase> = async (
      id: number
    ): Promise<IResultBase> => {
      return await baseDeleteById<IResultBase>(id);
    };

    const save: (model: ISBSSceneObjectData) => Promise<IResultBase> = async (
      model: ISBSSceneObjectData
    ): Promise<IResultBase> => {
      return await baseSave<ISBSSceneObjectData, IResultBase>(model);
    };

    const update: (model: ISBSSceneObjectUpdateModel) => Promise<IResultBase> =
      async (model: ISBSSceneObjectUpdateModel): Promise<IResultBase> => {
        let url: string = "/SBSSceneObject/Update";
        return await basePatchRequest<ISBSSceneObjectUpdateModel, IResultBase>(
          url,
          model
        );
      };

    const getDetails: (id: number) => Promise<ISBSSceneObjectGetDetailsResult> =
      async (id: number): Promise<ISBSSceneObjectGetDetailsResult> => {
        return await baseGetDetails<ISBSSceneObjectGetDetailsResult>(id);
      };

    const getList: (
      model: ISBSSceneObjectGetListModel
    ) => Promise<ISBSSceneObjectGetListResult> = async (
      model: ISBSSceneObjectGetListModel
    ): Promise<ISBSSceneObjectGetListResult> => {
      return await baseGetList<
        ISBSSceneObjectGetListModel,
        ISBSSceneObjectGetListResult
      >(model);
    };

    return { deleteById, save, update, getDetails, getList };
  };

export default useSBSSceneObjectApi;
