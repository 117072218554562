import * as React from "react";
import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { ApiResultStatus, ObjectType } from "Utils/enums";
import { ISBSSceneObjectEditorAdminProps } from "../interfaces";
import { Form, Formik } from "formik";
import { ISBSSceneObjectData } from "Services/SBSSceneObjectApi/interfaces";
import { ISBSSceneObjectGetDetailsResult } from "Services/SBSSceneObjectApi/interfaces-result";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";
import useModalSuccess from "Hooks/use-modal-success";
import useSBSSceneObjectApi from "Services/SBSSceneObjectApi/sbs-scene-object-api";

const SBSSceneObjectEditorAdmin: (
  props: ISBSSceneObjectEditorAdminProps
) => JSX.Element = (props: ISBSSceneObjectEditorAdminProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const showModalSuccess: (successResult: string) => void =
    useModalSuccess(undefined);
  const { save, getDetails } = useSBSSceneObjectApi();
  const [values, setValues] = React.useState<ISBSSceneObjectData>({
    description: "",
    isAvailableInTableSceneUsingMode: false,
    isAvailableInDemoVersion: false,
    isFacingSpectator: false,
  });
  const { t } = useTranslation(["commonResources", "validationResources"]);

  const handleLoadData: () => Promise<void> = React.useCallback(async () => {
    if (props.sbsSceneObjectId) {
      return await getDetails(props.sbsSceneObjectId).then(
        (response: ISBSSceneObjectGetDetailsResult) => {
          if (response && response.data) {
            setValues(response.data);
          }
        }
      );
    }
  }, [props.sbsSceneObjectId]);

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    if (props.sbsSceneObjectId) {
      handleLoadData();
    }
    setIsOpen(true);
  };

  const handleSave: (
    sceneObject: ISBSSceneObjectData
  ) => Promise<void> = async (place: ISBSSceneObjectData): Promise<void> => {
    await save(place).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("saveChangesSuccess"));
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
        handleClose();
      }
    });
  };

  return (
    <React.Fragment>
      <ButtonGridIcon
        icon={"fa-edit"}
        onClick={handleOpen}
        color="secondary"
        title={t("edit")}
        style={{ padding: 0 }}
      />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>{t("sceneObjectForm.edit")}</DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<ISBSSceneObjectData>({
              description: Yup.string()
                .max(1000, t("validationResources:descriptionMaxLength"))
                .nullable(),
              isAvailableInDemoVersion: Yup.boolean(),
              isAvailableInTableSceneUsingMode: Yup.boolean(),
              isFacingSpectator: Yup.boolean(),
            })}
            onSubmit={(values: ISBSSceneObjectData) => {
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid
                  container
                  justify="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.description ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.description &&
                            formik.touched.description
                          )
                        }
                        helperText={
                          formik.errors.description &&
                          formik.touched.description &&
                          formik.errors.description
                        }
                        label={t("description")}
                        variant="outlined"
                        size="small"
                        name="description"
                        multiline
                        rowsMax="5"
                        rows="5"
                        disabled={props.disabled}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                formik.values
                                  .isAvailableInTableSceneUsingMode ?? false
                              }
                              onChange={formik.handleChange}
                              value={
                                formik.values
                                  .isAvailableInTableSceneUsingMode ?? false
                              }
                              name="isAvailableInTableSceneUsingMode"
                            />
                          }
                          label={t("isAvailableInTableSceneUsingMode")}
                          labelPlacement="start"
                          disabled={props.disabled}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                formik.values.isAvailableInDemoVersion ?? false
                              }
                              onChange={formik.handleChange}
                              value={
                                formik.values.isAvailableInDemoVersion ?? false
                              }
                              name="isAvailableInDemoVersion"
                            />
                          }
                          label={t("availableInDemoVersion")}
                          labelPlacement="start"
                          disabled={props.disabled}
                        />
                      </FormGroup>
                    </Grid>
                    {props.objectType === ObjectType.Model3D && (
                      <Grid item xs={12}>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  formik.values.isFacingSpectator ?? false
                                }
                                onChange={formik.handleChange}
                                value={formik.values.isFacingSpectator ?? false}
                                name="isFacingSpectator"
                              />
                            }
                            label={t("isFacingSpectator")}
                            labelPlacement="start"
                            disabled={props.disabled}
                          />
                        </FormGroup>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  {!props.disabled && (
                    <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                      {t("save")}
                    </ButtonPrimary>
                  )}
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SBSSceneObjectEditorAdmin;
