import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { DEFAULT_USER_AUTH_TOKEN } from "Utils/consts";
import { IRouteAlreadyLogged } from "./interfaces";
import { getStoredUserAuthToken } from "Utils/helpers";
import useAuthorization from "Hooks/use-auth-token";
import { PrivateRoutes } from "./routes";

const RouteAlreadyLogged: (props: IRouteAlreadyLogged) => JSX.Element = (
  props: IRouteAlreadyLogged
): JSX.Element => {
  const { authToken } = useAuthorization(getStoredUserAuthToken());
  const { childrenElement, ...restProps } = props;

  return (
    <Route
      {...restProps}
      render={() =>
        authToken === DEFAULT_USER_AUTH_TOKEN ? (
          <props.childrenElement />
        ) : (
          <Redirect
            to={{
              pathname: PrivateRoutes.info,
            }}
          />
        )
      }
    />
  );
};

export default RouteAlreadyLogged;
