import * as React from "react";
import { Icon } from "@material-ui/core";
import { IButtonGridIconProps } from "./interfaces";
import { stylesButtonGridIcon } from "./styles";

const ButtonGridIcon: (props: IButtonGridIconProps) => JSX.Element = (
  props: IButtonGridIconProps
): JSX.Element => {
  const customStyles: Record<string, string> = stylesButtonGridIcon(props);
  const { icon, ...other } = props;
  return (
    <Icon
      className={`fa ${icon}`}
      classes={{ root: customStyles.icon }}
      {...other}
    />
  );
};

export default ButtonGridIcon;
