import * as React from "react";
import { Divider, Grid } from "@material-ui/core";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import { MUIDataTableColumn } from "mui-datatables";
import { useTranslation } from "react-i18next";
import { useApplicationApi } from "Services/ApplicationApi/application-api";
import { IApplicationData } from "Services/ApplicationApi/interfaces";
import { IApplicationGetListModel } from "Services/ApplicationApi/interfaces-model";
import { IApplicationGetListResult } from "Services/ApplicationApi/interfaces-result";
import { IResultBase } from "Services/interfaces";
import { ApiResultStatus, ApplicationGridGetListColumn } from "Utils/enums";
import ApplicationEditorAdmin from "../ApplicationEditor/application-editor-admin";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import GridContainer from "Components/Grids/grid-container";
import GridTable from "Components/Grids/grid-table";
import useModalQuestion from "Hooks/use-modal-question";
import useModalSuccess from "Hooks/use-modal-success";

const ApplicationList: () => JSX.Element = (): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };

  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { deleteById, getList } = useApplicationApi();
  const { t } = useTranslation(["commonResources"]);

  const deleteApplication: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await deleteById(id).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("applicationForm.deleteSuccess"));
        dispatchReloadTable();
      }
    });
  };

  const handleClickDelete: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    showModalQuestion(t("applicationForm.deleteInfo"), id);
  };

  React.useEffect(() => {
    setYesFunction(() => deleteApplication);
  }, []);

  const columnSortables: string[] = ["name", "guid"];

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "name",
      label: t("name"),
    },
    {
      name: "guid",
      label: "Guid",
    },
    {
      name: "",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ApplicationEditorAdmin
              applicationId={tableMeta.rowData[0]}
              handleSaveSuccess={dispatchReloadTable}
            />
          );
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGridIcon
              icon="fa-trash-alt"
              color="primary"
              onClick={() => {
                handleClickDelete(tableMeta.rowData[0]);
              }}
            />
          );
        },
      },
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "name":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = ApplicationGridGetListColumn.Name;
        break;
      case "guid":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn = ApplicationGridGetListColumn.Guid;
        break;
    }

    return newSortParams;
  };

  return (
    <React.Fragment>
      <GridContainer title={t("applications")}>
        <Grid container justify="flex-start" alignItems="flex-start">
          <Divider />
          <Grid container item sm={12}>
            <ApplicationEditorAdmin handleSaveSuccess={dispatchReloadTable} />
          </Grid>
        </Grid>
        <Divider />
        <GridTable<
          IApplicationGetListModel,
          IApplicationData,
          IApplicationGetListResult
        >
          reloadCount={reloadTable}
          columns={columns}
          columnsSortableNames={columnSortables}
          generateSortParams={generateSortParams}
          getList={getList}
        />
      </GridContainer>
    </React.Fragment>
  );
};

export default ApplicationList;
