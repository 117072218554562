import * as React from "react";
import { IOrderProductLicenseListProps } from "../interfaces";
import { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";
import { IGridModel } from "Services/interfaces";
import { Divider, Grid } from "@material-ui/core";
import { IOrderProductLicenseGetListModel } from "Services/OrderProductLicenseApi/interfaces-model";
import { OrderProductLicenseGridGetListColumn, UserRole } from "Utils/enums";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import { useOrderProductLicenseApi } from "Services/OrderProductLicenseApi/order-product-license-api";
import { IOrderProductLicenseData } from "Services/OrderProductLicenseApi/interfaces";
import { IOrderProductLicenseGetListResult } from "Services/OrderProductLicenseApi/interfaces-result";
import { getStoredUserAuthToken, getStoredUserRole } from "Utils/helpers";
import { useTranslation } from "react-i18next";
import axios from "axios";
import GridTable from "Components/Grids/grid-table";
import moment from "moment";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import TextInfo from "Components/Texts/text-info";
import CopyToClipboard from "react-copy-to-clipboard";
import cogoToast from "cogo-toast";
import OrderCreatorAdmin from "../OrderCreator/order-creator-admin";

const envBaseURL: string =
  process.env.REACT_APP_API_BASE_URL ?? "https://webapi.gsot.pl/api";
const envApiVersion: string = process.env.REACT_APP_API_VERSION ?? "v1";

const OrderProductLicenseList: (
  props: IOrderProductLicenseListProps
) => JSX.Element = (props: IOrderProductLicenseListProps): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [search, setSearch] = React.useState<string>("");

  const handleClickCopyLicense: () => void = (): void => {
    cogoToast.success(t("copiedToClipboard"));
  };

  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const { getList } = useOrderProductLicenseApi();
  const { t } = useTranslation(["commonResources"]);

  const columnSortables: string[] = [
    "code",
    "expirationDateDisplay",
    "insertDate",
    "statusText",
    "useDate",
    "product.name",
    "description",
  ];

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      options: {
        display: "false",
      },
    },
    {
      name: "expirationDate",
      options: {
        display: "false",
      },
    },
    {
      name: "codeExpirationDate",
      options: {
        display: "false",
      },
    },
    {
      name: "code",
      label: t("code"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <React.Fragment>
              <TextInfo>
                {value}&nbsp;
                <CopyToClipboard text={value} onCopy={handleClickCopyLicense}>
                  <ButtonGridIcon
                    icon="fa-copy"
                    color="primary"
                    title={t("copyToClipboard")}
                  />
                </CopyToClipboard>
              </TextInfo>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: "statusText",
      label: t("status"),
    },
    {
      name: "insertDate",
      label: t("purchaseDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("DD.MM.YYYY") : "-";
        },
      },
    },
    {
      name: "useDate",
      label: t("useDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? moment(value).format("DD.MM.YYYY") : "-";
        },
      },
    },
    {
      name: "expirationDateDisplay",
      label: t("expirationDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let displayValue: string =
            tableMeta.rowData[2] ?? tableMeta.rowData[1];
          return displayValue ? moment(displayValue).format("DD.MM.YYYY") : "-";
        },
      },
    },
    {
      name: "product",
      label: t("product"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.name;
        },
      },
    },
    {
      name: "description",
      label: t("description"),
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 2,
      orderColumn: OrderProductLicenseGridGetListColumn.InsertDate,
    };
    switch (changedColumn) {
      case "code":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = OrderProductLicenseGridGetListColumn.Code;
        break;
      case "expirationDateDisplay":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn =
          OrderProductLicenseGridGetListColumn.ExpirationDate;
        break;
      case "insertDate":
        newSortParams.columnIndex = 2;
        newSortParams.orderColumn =
          OrderProductLicenseGridGetListColumn.InsertDate;
        break;
      case "statusText":
        newSortParams.columnIndex = 3;
        newSortParams.orderColumn = OrderProductLicenseGridGetListColumn.Status;
        break;
      case "useDate":
        newSortParams.columnIndex = 4;
        newSortParams.orderColumn =
          OrderProductLicenseGridGetListColumn.UseDate;
        break;
      case "product.name":
        newSortParams.columnIndex = 5;
        newSortParams.orderColumn =
          OrderProductLicenseGridGetListColumn.Product;
        break;
      case "description":
        newSortParams.columnIndex = 6;
        newSortParams.orderColumn =
          OrderProductLicenseGridGetListColumn.Description;
        break;
    }

    return newSortParams;
  };

  const generateParams: (
    tableState: IGridModel
  ) => IOrderProductLicenseGetListModel = (
    tableState: IGridModel
  ): IOrderProductLicenseGetListModel => {
    let params: IOrderProductLicenseGetListModel = { ...tableState };
    if (props.orderProductId) {
      params.gsOrderProductId = props.orderProductId;
    }
    return params;
  };

  const tableOptions: MUIDataTableOptions = {
    customToolbar: () => {
      return (
        <ButtonGridIcon
          icon="fa-file-csv"
          color="primary"
          title={t("exportToCsv")}
          onClick={() => {
            axios({
              url: `${envBaseURL}/${envApiVersion}/OrderProductLicense/ExportToCsv`,
              method: "GET",
              responseType: "blob", // important
              headers: {
                Authorization: getStoredUserAuthToken(),
              },
              params: {
                search: search,
              },
            }).then((response) => {
              let fileDownload = require("js-file-download");
              fileDownload(response.data, `${t("licenses")}.csv`);
            });
          }}
        />
      );
    },
  };

  React.useEffect(() => {
    if (props.orderProductId && props.orderProductId > 0) {
      dispatchReloadTable();
    }
  }, [props.orderProductId]);

  return (
    <React.Fragment>
      {getStoredUserRole() === UserRole.Admin && (
        <React.Fragment>
          <Grid container justify="flex-start" alignItems="flex-start">
            <Divider />
            <Grid container item sm={12}>
              <OrderCreatorAdmin handleSaveSuccess={dispatchReloadTable} />
            </Grid>
          </Grid>
          <Divider />
        </React.Fragment>
      )}
      <GridTable<
        IOrderProductLicenseGetListModel,
        IOrderProductLicenseData,
        IOrderProductLicenseGetListResult
      >
        reloadCount={reloadTable}
        columns={columns}
        columnsSortableNames={columnSortables}
        defaultOrderColumn={OrderProductLicenseGridGetListColumn.InsertDate}
        generateSortParams={generateSortParams}
        customTableOptions={tableOptions}
        generateParams={generateParams}
        getList={getList}
        setSearch={setSearch}
      />
    </React.Fragment>
  );
};

export default OrderProductLicenseList;
