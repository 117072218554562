import * as React from "react";
import * as _ from "lodash";
import {
  ApiResultStatus,
  PlaceGridGetListColumn,
  SceneGroupType,
} from "Utils/enums";
import { Divider, Grid, Link } from "@material-ui/core";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import { ILanguageData } from "Services/LanguageApi/interfaces";
import { ILanguageGetListResult } from "Services/LanguageApi/interfaces-result";
import { IPlaceData } from "Services/PlaceApi/interfaces";
import { IPlaceGetListModel } from "Services/PlaceApi/interfaces-model";
import { IPlaceGetListResult } from "Services/PlaceApi/interfaces-result";
import { IPlaceListProps } from "../interfaces";
import { IBaseListItem, IResultBase } from "Services/interfaces";
import { usePlaceApi } from "Services/PlaceApi/place-api";
import { MUIDataTableColumn } from "mui-datatables";
import { useTranslation } from "react-i18next";
import { useLanguageApi } from "Services/LanguageApi/language-api";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import PlaceEditorAdmin from "../PlaceEditors/place-editor-admin";
import useModalQuestion from "Hooks/use-modal-question";
import useModalSuccess from "Hooks/use-modal-success";
import GridTable from "Components/Grids/grid-table";

const PlaceList: (props: IPlaceListProps) => JSX.Element = (
  props: IPlaceListProps
): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [languages, setLanguages] = React.useState<ILanguageData[]>([]);
  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { getList: getLanguageList } = useLanguageApi();
  const { deleteById, getList } = usePlaceApi();
  const { t } = useTranslation(["commonResources"]);

  const loadLanguages: () => Promise<void> = async () => {
    return await getLanguageList().then((response: ILanguageGetListResult) => {
      if (response?.data) {
        setLanguages(response.data);
      }
    });
  };

  const deletePlace: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await deleteById(id).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("placeForm.deleteSuccess"));
        dispatchReloadTable();
      }
    });
  };

  const handleClickDelete: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    showModalQuestion(t("placeForm.deleteInfo"), id);
  };

  const handlePlaceClick: (
    id: number,
    name: string,
    isAvailableInDemo: boolean,
    groupList: IBaseListItem[]
  ) => void = (
    id: number,
    name: string,
    isAvailableInDemo: boolean,
    groupList: IBaseListItem[]
  ): void => {
    if (props.handlePlaceChange) {
      props.handlePlaceChange(id, name, isAvailableInDemo, groupList);
    }
  };

  React.useEffect(() => {
    setYesFunction(() => deletePlace);
    loadLanguages();
  }, []);

  const columnSortables: string[] = [
    "name",
    "description",
    "scenesCount",
    "isAvailableInDemoVersion",
  ];

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "name",
      label: t("name"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id: number = tableMeta.rowData[0];
          let isAvailableInDemo: boolean = tableMeta.rowData[7];
          let sceneGroup1Name: string = tableMeta.rowData[10] ?? "";
          let sceneGroup2Name: string = tableMeta.rowData[11] ?? "";
          let groupList: IBaseListItem[] = [
            {
              value: SceneGroupType.Group1,
              name: sceneGroup1Name,
            },
            {
              value: SceneGroupType.Group2,
              name: sceneGroup2Name,
            },
            {
              value: SceneGroupType.Group3,
              name: t("placeForm.guide"),
            },
          ];
          return (
            <Link
              color="secondary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handlePlaceClick(id, value, isAvailableInDemo, groupList);
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: "description",
      label: t("description"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{ width: "200px" }}>{value}</div>;
        },
      },
    },
    {
      name: "gsLanguageId",
      label: t("language"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            _.find(languages, (language) => {
              return language.id === (value as number);
            })?.name ?? ""
          );
        },
      },
    },
    {
      name: "application",
      label: t("application"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.name;
        },
      },
    },
    {
      name: "orderNo",
      label: t("possition"),
    },
    {
      name: "scenesCount",
      label: t("placeForm.sceneCount"),
    },
    {
      name: "isAvailableInDemoVersion",
      label: t("demo"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <ButtonGridIcon icon={"fa-check"} color="secondary" />
          ) : (
            <ButtonGridIcon icon={"fa-times"} color="secondary" />
          );
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <PlaceEditorAdmin
              placeId={tableMeta.rowData[0]}
              handleSaveSuccess={dispatchReloadTable}
            />
          );
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGridIcon
              icon="fa-trash-alt"
              color="primary"
              onClick={() => {
                handleClickDelete(tableMeta.rowData[0]);
              }}
            />
          );
        },
      },
    },
    {
      name: "sceneGroup1Name",
      label: "",
      options: {
        display: "false",
      },
    },
    {
      name: "sceneGroup2Name",
      label: "",
      options: {
        display: "false",
      },
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "name":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = PlaceGridGetListColumn.Name;
        break;
      case "description":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn = PlaceGridGetListColumn.Description;
        break;
      case "scenesCount":
        newSortParams.columnIndex = 2;
        newSortParams.orderColumn = PlaceGridGetListColumn.ScenesCount;
        break;
      case "isAvailableInDemoVersion":
        newSortParams.columnIndex = 3;
        newSortParams.orderColumn =
          PlaceGridGetListColumn.IsAvailableInDemoVersion;
        break;
    }

    return newSortParams;
  };

  return (
    <React.Fragment>
      <Divider />
      <Grid container item justify="flex-start" alignItems="flex-start">
        <PlaceEditorAdmin handleSaveSuccess={dispatchReloadTable} />
      </Grid>
      <Divider />
      <GridTable<IPlaceGetListModel, IPlaceData, IPlaceGetListResult>
        reloadCount={reloadTable}
        columns={columns}
        columnsSortableNames={columnSortables}
        generateSortParams={generateSortParams}
        getList={getList}
      />
    </React.Fragment>
  );
};

export default PlaceList;
