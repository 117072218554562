import useBaseDataApi from "Services/base-data-api";
import { IResultBase } from "Services/interfaces";
import { IApplicationApiInterface, IApplicationData } from "./interfaces";
import { IApplicationGetListModel } from "./interfaces-model";
import {
  IApplicationGetDetailsResult,
  IApplicationGetListResult,
} from "./interfaces-result";

export const useApplicationApi: () => IApplicationApiInterface = (): IApplicationApiInterface => {
  const {
    baseDeleteById,
    baseGetDetails,
    baseGetList,
    baseSave,
  } = useBaseDataApi({ apiControllerName: "Application" });

  const deleteById: (id: number) => Promise<IResultBase> = async (
    id: number
  ): Promise<IResultBase> => {
    return await baseDeleteById<IResultBase>(id);
  };

  const save: (model: IApplicationData) => Promise<IResultBase> = async (
    model: IApplicationData
  ): Promise<IResultBase> => {
    return await baseSave<IApplicationData, IResultBase>(model);
  };

  const getDetails: (
    id: number
  ) => Promise<IApplicationGetDetailsResult> = async (
    id: number
  ): Promise<IApplicationGetDetailsResult> => {
    return await baseGetDetails<IApplicationGetDetailsResult>(id);
  };

  const getList: (
    model: IApplicationGetListModel
  ) => Promise<IApplicationGetListResult> = async (
    model: IApplicationGetListModel
  ): Promise<IApplicationGetListResult> => {
    return await baseGetList<
      IApplicationGetListModel,
      IApplicationGetListResult
    >(model);
  };

  return { deleteById, save, getDetails, getList };
};
