import * as React from "react";
import { useSBSSceneApi } from "Services/SBSSceneApi/sbs-scene-api";
import { ISBSSceneData } from "Services/SBSSceneApi/interfaces";
import {
  ApiResultStatus,
  SBSSceneGridGetListColumn,
  SBSSceneStatus,
} from "Utils/enums";
import { Grid, Divider } from "@material-ui/core";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import { IResultBase } from "Services/interfaces";
import { MUIDataTableColumn } from "mui-datatables";
import { useTranslation } from "react-i18next";
import { ISBSSceneGetListModel } from "Services/SBSSceneApi/interfaces-model";
import { ISBSSceneGetListResult } from "Services/SBSSceneApi/interfaces-result";
import { useHistory } from "react-router-dom";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import GridTable from "Components/Grids/grid-table";
import useModalSuccess from "Hooks/use-modal-success";
import useModalQuestion from "Hooks/use-modal-question";
import { useCookies } from "react-cookie";

const SBSSceneList: () => JSX.Element = (): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion } = useModalQuestion();
  const { deleteById, getList, setStatus } = useSBSSceneApi();
  const { t } = useTranslation(["commonResources"]);
  const [cookies, setCookie] = useCookies(["isSceneFinished"]);
  const history = useHistory();

  const handleClickDelete: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    showModalQuestion(t("sbsSceneForm.deleteInfo"), id, deleteSBSScene);
  };

  const handleClickFinish: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    showModalQuestion(t("sbsSceneForm.finishInfo"), id, finishSBSScene);
  };

  const handleClickEdit: (id: number) => void = (id: number): void => {
    history.push(`/sbs-scene/${id}`);
  };

  const handleClickEditObjects: (id: number, isFinished: boolean) => void = (
    id: number,
    isFinished: boolean
  ): void => {
    setCookie("isSceneFinished", isFinished, { path: "/" });
    history.push(`/sbs-scene-objects/${id}`);
  };

  const deleteSBSScene: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await deleteById(id).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("sbsSceneForm.deleteSuccess"));
        dispatchReloadTable();
      }
    });
  };

  const finishSBSScene: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await setStatus({ id: id, status: SBSSceneStatus.Finished }).then(
      (response: IResultBase) => {
        if (response && response.status === ApiResultStatus.Ok) {
          showModalSuccess(t("sbsSceneForm.finishSuccess"));
          dispatchReloadTable();
        }
      }
    );
  };

  const columnSortables: string[] = ["name", "description", "typeText"];

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        display: "false",
      },
    },
    {
      name: "name",
      label: t("name"),
    },
    {
      name: "description",
      label: t("description"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{ width: "200px" }}>{value}</div>;
        },
      },
    },
    {
      name: "objectsCount",
      label: t("sbsSceneForm.objectCount"),
    },
    {
      name: "statusText",
      label: t("status"),
      options: {
        sort: false,
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[1] === SBSSceneStatus.Draft) {
            return (
              <ButtonGridIcon
                icon="fa-flag-checkered"
                title={t("sbsSceneForm.finish")}
                onClick={() => {
                  handleClickFinish(tableMeta.rowData[0]);
                }}
              />
            );
          } else {
            return <></>;
          }
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGridIcon
              icon={
                tableMeta.rowData[1] === SBSSceneStatus.Draft
                  ? "fa-edit"
                  : "fa-search-location"
              }
              title={
                tableMeta.rowData[1] === SBSSceneStatus.Draft
                  ? t("edit")
                  : t("preview")
              }
              onClick={() => {
                handleClickEdit(tableMeta.rowData[0]);
              }}
            />
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGridIcon
              icon="fa-draw-polygon"
              title={t("sbsSceneObjectForm.title")}
              onClick={() => {
                handleClickEditObjects(
                  tableMeta.rowData[0],
                  tableMeta.rowData[1] === SBSSceneStatus.Finished
                );
              }}
            />
          );
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGridIcon
              icon="fa-trash-alt"
              title={t("delete")}
              color="primary"
              onClick={() => {
                handleClickDelete(tableMeta.rowData[0]);
              }}
            />
          );
        },
      },
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "name":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = SBSSceneGridGetListColumn.Name;
        break;
      case "description":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn = SBSSceneGridGetListColumn.Description;
        break;
      case "objectsCount":
        newSortParams.columnIndex = 2;
        newSortParams.orderColumn = SBSSceneGridGetListColumn.ObjectsCount;
        break;
    }

    return newSortParams;
  };

  const handleAddNew: () => void = (): void => {
    history.push("/sbs-scene/0");
  };

  return (
    <React.Fragment>
      <Grid container justify="flex-start" alignItems="flex-start">
        <Grid container item sm={12}>
          <ButtonPrimary size="sm" onClick={handleAddNew}>
            <ButtonGridIcon icon={"fa-map"} color="secondary" />
            {t("sbsSceneForm.add")}
          </ButtonPrimary>
        </Grid>
      </Grid>
      <Divider />
      <GridTable<ISBSSceneGetListModel, ISBSSceneData, ISBSSceneGetListResult>
        reloadCount={reloadTable}
        columns={columns}
        columnsSortableNames={columnSortables}
        generateSortParams={generateSortParams}
        getList={getList}
      />
    </React.Fragment>
  );
};

export default SBSSceneList;
