import * as React from "react";
import * as _ from "lodash";
import { Divider, Grid, Link } from "@material-ui/core";
import { IResultBase, IGridModel, IBaseListItem } from "Services/interfaces";
import { ISceneData } from "Services/SceneApi/interfaces";
import { ISceneGetListModel } from "Services/SceneApi/interfaces-model";
import { useSceneApi } from "Services/SceneApi/scene-api";
import { ISceneGetListResult } from "Services/SceneApi/interfaces-result";
import { ISceneListProps } from "../interfaces";
import { ApiResultStatus, SceneGridGetListColumn } from "Utils/enums";
import { MUIDataTableColumn } from "mui-datatables";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import { useTranslation } from "react-i18next";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import useModalQuestion from "Hooks/use-modal-question";
import useModalSuccess from "Hooks/use-modal-success";
import SceneEditorAdmin from "../SceneEditor/scene-editor-admin";
import GridTable from "Components/Grids/grid-table";

const SceneList: (props: ISceneListProps) => JSX.Element = (
  props: ISceneListProps
): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { deleteById, getList } = useSceneApi();
  const { t } = useTranslation(["commonResources"]);
  const placeId: number = props.placeId;
  const placeIsAvailableInDemo: boolean = props.placeIsAvailableInDemo;

  const handleClickDelete: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    showModalQuestion(t("sceneForm.deleteInfo"), id);
  };

  const deleteScene: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await deleteById(id).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("sceneForm.deleteSuccess"));
        dispatchReloadTable();
      }
    });
  };

  const handleSceneClick: (id: number, name: string) => void = (
    id: number,
    name: string
  ): void => {
    if (props.handleSceneChange) {
      props.handleSceneChange(id, name);
    }
  };

  React.useEffect(() => {
    setYesFunction(() => deleteScene);
  }, []);

  React.useEffect(() => {
    if (props.isExpanded) {
      dispatchReloadTable();
    }
  }, [props.isExpanded]);

  const columnSortables: string[] = [
    "name",
    "description",
    "objectsCount",
    "demoVersionTimeInSeconds",
  ];

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "name",
      label: t("name"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id: number = tableMeta.rowData[0];
          return (
            <Link
              color="secondary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleSceneClick(id, value);
              }}
            >
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: "description",
      label: t("description"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{ width: "200px" }}>{value}</div>;
        },
      },
    },
    {
      name: "groupType",
      label: t("group"),
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let name: string =
            _.find(props.groupList, (group: IBaseListItem) => {
              return value === group.value;
            })?.name ?? "-";
          return name;
        },
      },
    },
    {
      name: "objectsCount",
      label: t("sceneForm.objectCount"),
    },
    {
      name: "demoVersionTimeInSeconds",
      label: t("durationInDemoShort"),
      options: {
        viewColumns: placeIsAvailableInDemo,
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id: number = tableMeta.rowData[0];
          return (
            <SceneEditorAdmin
              placeId={props.placeId}
              placeIsAvailableInDemoVersion={props.placeIsAvailableInDemo}
              sceneId={id}
              groupList={props.groupList}
              handleSaveSuccess={dispatchReloadTable}
            />
          );
        },
      },
    },
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGridIcon
              icon="fa-trash-alt"
              color="primary"
              onClick={() => {
                handleClickDelete(tableMeta.rowData[0]);
              }}
            />
          );
        },
      },
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "name":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = SceneGridGetListColumn.Name;
        break;
      case "description":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn = SceneGridGetListColumn.Description;
        break;
      case "objectsCount":
        newSortParams.columnIndex = 2;
        newSortParams.orderColumn = SceneGridGetListColumn.ObjectsCount;
        break;
      case "demoVersionTimeInSeconds":
        newSortParams.columnIndex = 3;
        newSortParams.orderColumn =
          SceneGridGetListColumn.DemoVersionTimeInSeconds;
        break;
    }

    return newSortParams;
  };

  const generateParams: (tableState: IGridModel) => ISceneGetListModel = (
    tableState: IGridModel
  ): ISceneGetListModel => {
    return {
      GSPlaceId: placeId,
      ...tableState,
    };
  };

  return (
    <React.Fragment>
      <Divider />
      <Grid container item justify="flex-start" alignItems="flex-start">
        <SceneEditorAdmin
          placeId={placeId}
          placeIsAvailableInDemoVersion={placeIsAvailableInDemo}
          groupList={props.groupList}
          handleSaveSuccess={dispatchReloadTable}
        />
      </Grid>
      <Divider />
      <GridTable<ISceneGetListModel, ISceneData, ISceneGetListResult>
        reloadCount={reloadTable}
        columns={columns}
        columnsSortableNames={columnSortables}
        generateSortParams={generateSortParams}
        generateParams={generateParams}
        getList={getList}
      />
    </React.Fragment>
  );
};

export default SceneList;
