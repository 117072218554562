import * as React from "react";
import * as Yup from "yup";
import { ApiResultStatus } from "Utils/enums";
import { Formik, Form } from "formik";
import { ISceneObjectTimelineData } from "Services/SceneObjectTimelineApi/interfaces";
import {
  ISBSSceneObjectTimelineEditorAdminProps,
  ISceneObjectTimelineEditorAdminProps,
} from "../interfaces";
import { ISceneObjectTimelineGetDetailsResult } from "Services/SceneObjectTimelineApi/interfaces-result";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useSceneObjectTimelineApi from "Services/SceneObjectTimelineApi/scene-object-timeline-api";
import useModalSuccess from "Hooks/use-modal-success";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import ButtonSecondary from "Components/Buttons/button-secondary";

const SceneObjectTimelineSoundEditorAdmin: (
  props: ISceneObjectTimelineEditorAdminProps
) => JSX.Element = (
  props: ISceneObjectTimelineEditorAdminProps
): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { save, getDetails } = useSceneObjectTimelineApi();
  const showModalSuccess: (successResult: string) => void =
    useModalSuccess(undefined);
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const [values, setValues] = React.useState<ISceneObjectTimelineData>({
    startTimeInSeconds: 0,
  });

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    if (props.sceneObjectTimelineId) {
      return await getDetails(props.sceneObjectTimelineId).then(
        (response: ISceneObjectTimelineGetDetailsResult) => {
          if (response && response.data) {
            setValues(response.data);
          }
        }
      );
    }
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    if (props.sceneObjectTimelineId) {
      handleLoadData();
    }
    setIsOpen(true);
  };

  const handleSave: (
    sceneObjectTimeline: ISceneObjectTimelineData
  ) => Promise<void> = async (
    sceneObjectTimeline: ISceneObjectTimelineData
  ): Promise<void> => {
    sceneObjectTimeline.gsSceneObjectId = props.sceneObjectId;
    await save(sceneObjectTimeline).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(
          sceneObjectTimeline.id
            ? t("saveChangesSuccess")
            : t("animationForm.addSuccess")
        );
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
        handleClose();
      }
    });
  };

  return (
    <React.Fragment>
      {props.sceneObjectTimelineId && (
        <ButtonGridIcon
          icon={"fa-edit"}
          onClick={handleOpen}
          color="secondary"
        />
      )}
      {!props.sceneObjectTimelineId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-route"} color="secondary" />
          {t("animationForm.add")}
        </ButtonPrimary>
      )}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          {props.sceneObjectTimelineId
            ? t("animationForm.edit")
            : t("animationForm.addInfo")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<ISceneObjectTimelineData>({
              endTimeInSeconds: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .max(1000000, t("validationResources:numericMax1000000"))
                .nullable(),
              startTimeInSeconds: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .max(1000000, t("validationResources:numericMax1000000"))
                .required(t("validationResources:required")),
            })}
            onSubmit={(values: ISceneObjectTimelineData) => {
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid container justify="center" alignItems="flex-start">
                  <Grid container item sm={12} spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={formik.values.startTimeInSeconds ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.startTimeInSeconds &&
                            formik.touched.startTimeInSeconds
                          )
                        }
                        helperText={
                          formik.errors.startTimeInSeconds &&
                          formik.touched.startTimeInSeconds &&
                          formik.errors.startTimeInSeconds
                        }
                        label={t("animationForm.startTime")}
                        variant="outlined"
                        size="small"
                        name="startTimeInSeconds"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        value={formik.values.endTimeInSeconds ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.endTimeInSeconds &&
                            formik.touched.endTimeInSeconds
                          )
                        }
                        helperText={
                          formik.errors.endTimeInSeconds &&
                          formik.touched.endTimeInSeconds &&
                          formik.errors.endTimeInSeconds
                        }
                        label={t("animationForm.endTime")}
                        variant="outlined"
                        size="small"
                        name="endTimeInSeconds"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SceneObjectTimelineSoundEditorAdmin;
