import { IEventLogApiInterface } from "./interfaces";
import useBaseDataApi from "Services/base-data-api";
import { IEventLogGetListModel } from "./interfaces-model";
import { IEventLogGetListResult } from "./interfaces-result";

export const useEventLogApi: () => IEventLogApiInterface
= (): IEventLogApiInterface => {
    const { baseGetList } = useBaseDataApi({ apiControllerName: "EventLog" });

    const getList: (model: IEventLogGetListModel) => Promise<IEventLogGetListResult>
            = async (model: IEventLogGetListModel): Promise<IEventLogGetListResult> => {
                return await baseGetList<IEventLogGetListModel, IEventLogGetListResult>(model);
            };

    return { getList };
};