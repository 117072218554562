import { IOrderGetDetailsResult, IOrderGetListResult, IOrderAddResult, IOrderRetryPaymentResult } from "./interfaces-result";
import { IOrderGetListModel, IOrderAddModel, IOrderRetryPaymentModel } from "./interfaces-model";
import { IOrderApiInterface } from "./interfaces";
import { useBaseApi } from "Services/base-api";
import useBaseDataApi from "Services/base-data-api";

export const useOrderApi: () => IOrderApiInterface
    = (): IOrderApiInterface => {
        const { baseGetDetails, baseGetList } = useBaseDataApi({ apiControllerName: "Order" });
        const { basePostRequest } = useBaseApi();

        const add: (model: IOrderAddModel) => Promise<IOrderAddResult>
            = async (model: IOrderAddModel): Promise<IOrderAddResult> => {
                let url: string = "/Order/Add";
                return await basePostRequest<IOrderAddModel, IOrderAddResult>(url, model);
            };

        const getDetails: (id: number) => Promise<IOrderGetDetailsResult>
            = async (id: number): Promise<IOrderGetDetailsResult> => {
                return await baseGetDetails<IOrderGetDetailsResult>(id);
            };

        const getList: (model: IOrderGetListModel) => Promise<IOrderGetListResult>
            = async (model: IOrderGetListModel): Promise<IOrderGetListResult> => {
                return await baseGetList<IOrderGetListModel, IOrderGetListResult>(model);
            };

        const retryPayment: (model: IOrderRetryPaymentModel) => Promise<IOrderRetryPaymentResult>
            = async (model: IOrderRetryPaymentModel): Promise<IOrderRetryPaymentResult> => {
                let url: string = "/Order/RetryPayment";
                return await basePostRequest<IOrderRetryPaymentModel, IOrderRetryPaymentResult>(url, model);
            };

        return { add, getDetails, getList, retryPayment };
    }