import { Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";
import GridContainer from "Components/Grids/grid-container";
import UserCardList from "./user-card-list";
import UserList from "./user-list";

const UserListContainer: () => JSX.Element
    = (): JSX.Element => {
        const { t } = useTranslation(["commonResources"]);

        return (
            <React.Fragment>
                <GridContainer title={t("userForm.title")} >
                    <Hidden lgUp>
                        <UserCardList />
                    </Hidden>
                    <Hidden mdDown>
                        <UserList />
                    </Hidden>
                </GridContainer>
            </React.Fragment>
        );
    };

export default UserListContainer;