import { ApiResultStatus } from "Utils/enums";
import { ISBSSceneObjectTimelineValidatorProps } from "../interfaces";
import { useTranslation } from "react-i18next";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import React from "react";
import useModalSuccess from "Hooks/use-modal-success";
import useSBSSceneObjectTimelineApi from "Services/SBSSceneObjectTimelineApi/sbs-scene-object-timeline-api";

const SBSSceneObjectTimelineValidator: (
  props: ISBSSceneObjectTimelineValidatorProps
) => JSX.Element = (
  props: ISBSSceneObjectTimelineValidatorProps
): JSX.Element => {
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { validate } = useSBSSceneObjectTimelineApi();
  const { t } = useTranslation(["commonResources"]);

  const handleClick: () => Promise<void> = async (): Promise<void> => {
    await validate({ id: props.sbsSceneObjectTimelineId }).then((response) => {
      if (response?.status === ApiResultStatus.Ok) {
        showModalSuccess(t("sbsSceneObjectTimelineForm.validateSuccess"));
      }
    });
  };

  return (
    <React.Fragment>
      <ButtonGridIcon
        icon="fa-spell-check"
        title={t("validate")}
        onClick={handleClick}
        style={{ padding: 0 }}
      />
    </React.Fragment>
  );
};

export default SBSSceneObjectTimelineValidator;
