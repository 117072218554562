import { IResultBase } from "Services/interfaces";
import { ISBSObjectApiInterface, ISBSObjectData } from "./interfaces";
import { ISBSObjectGetDetailsResult, ISBSObjectGetListResult } from "./interfaces-result";
import { ISBSObjectGetListModel } from "./interfaces-model";
import useBaseDataApi from "Services/base-data-api";

export const useSBSObjectApi: () => ISBSObjectApiInterface
    = (): ISBSObjectApiInterface => {
        const { baseDeleteById, baseGetDetails, baseGetList, baseSave } = useBaseDataApi({ apiControllerName: "SBSObject"});

        const deleteById: (id: number) => Promise<IResultBase>
        = async (id: number): Promise<IResultBase> => {
            return await baseDeleteById<IResultBase>(id);
        };

        const save: (model: ISBSObjectData) => Promise<IResultBase>
            = async (model: ISBSObjectData): Promise<IResultBase> => {
                return await baseSave<ISBSObjectData, IResultBase>(model);
            };

        const getDetails: (id: number) => Promise<ISBSObjectGetDetailsResult>
            = async (id: number): Promise<ISBSObjectGetDetailsResult> => {
                return await baseGetDetails<ISBSObjectGetDetailsResult>(id);
            };

        const getList: (model: ISBSObjectGetListModel) => Promise<ISBSObjectGetListResult>
            = async (model: ISBSObjectGetListModel): Promise<ISBSObjectGetListResult> => {
                return await baseGetList<ISBSObjectGetListModel, ISBSObjectGetListResult>(model);
            };

        return { deleteById, save, getDetails, getList };
    };