import { IObjectApiInterface, IObjectData } from "./interfaces";
import { IObjectGetDetailsResult, IObjectGetListResult } from "./interfaces-results";
import { IObjectGetListModel } from "./interfaces-models";
import { IResultBase } from "Services/interfaces";
import useBaseDataApi from "Services/base-data-api";

export const useObjectApi: () => IObjectApiInterface
    = (): IObjectApiInterface => {
        const { baseDeleteById, baseGetDetails, baseGetList, baseSave } = useBaseDataApi({ apiControllerName: "Object"});

        const deleteById: (id: number) => Promise<IResultBase>
        = async (id: number): Promise<IResultBase> => {
            return await baseDeleteById<IResultBase>(id);
        };

        const save: (model: IObjectData) => Promise<IResultBase>
            = async (model: IObjectData): Promise<IResultBase> => {
                return await baseSave<IObjectData, IResultBase>(model);
            };

        const getDetails: (id: number) => Promise<IObjectGetDetailsResult>
            = async (id: number): Promise<IObjectGetDetailsResult> => {
                return await baseGetDetails<IObjectGetDetailsResult>(id);
            };

        const getList: (model: IObjectGetListModel) => Promise<IObjectGetListResult>
            = async (model: IObjectGetListModel): Promise<IObjectGetListResult> => {
                return await baseGetList<IObjectGetListModel, IObjectGetListResult>(model);
            };

        return { deleteById, save, getDetails, getList };
    };