import GridContainer from "Components/Grids/grid-container";
import React from "react";
import { useTranslation } from "react-i18next";
import SBSSceneList from "./sbs-scene-list";

const SbsSceneListContainer: () => JSX.Element = (): JSX.Element => {
  const { t } = useTranslation(["commonResources"]);

  return (
    <React.Fragment>
      <GridContainer title={t("sbsSceneForm.title")}>
        <SBSSceneList />
      </GridContainer>
    </React.Fragment>
  );
};

export default SbsSceneListContainer;
