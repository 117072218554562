import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const stylesUserList: () => Record<string, string> = makeStyles((theme: Theme) =>
createStyles({
    icon: {
        cursor: "pointer",
        fontSize: "15px",
        overflow: "visible",
        width: "auto"
    }
}));

export const stylesUserCard: () => Record<string, string> = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: "left",
            marginBottom: "3px"
        },
        header: {
            padding: "10px"
        },
        main: {
            padding: "0px 10px"
        },
        button: {
            padding: "5px"
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        }
    }
    ));