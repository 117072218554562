import * as React from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { IUserResetPasswordValidateTokenModel } from "Services/UserApi/interfaces-model";
import { useUserApi } from "Services/UserApi/user-api";
import ResetPasswordFinalForm from "./reset-password-final-form";
import ResetPasswordFailed from "./reset-password-failed";
import BlockUi from "react-block-ui";
import PanelContainer from "Components/Panels/panel-container";
import { IResetPasswordConfirmProps } from "Views/interfaces";

const ResetPasswordConfirm: () => JSX.Element = (): JSX.Element => {
  const { resetPasswordValidateToken } = useUserApi();
  const [loading, setLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { token } = useParams<IResetPasswordConfirmProps>();

  React.useEffect(() => {
    const handleConfirm: () => Promise<void> = async (): Promise<void> => {
      if (token) {
        let model: IUserResetPasswordValidateTokenModel = {
          token: token,
        };

        setLoading(true);
        await resetPasswordValidateToken(model)
          .then((respone) => {
            setIsSuccess(true);
          })
          .catch((error) => {
            const errorMessage: string =
              error?.response?.data?.statusMessage ??
              "Coś poszło nie tak, spróbuj ponownie później";
            setMessage(errorMessage);
            setIsSuccess(false);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };

    handleConfirm();
  }, [token]);

  return (
    <BlockUi blocking={loading} loader={<CircularProgress color="secondary" />}>
      <PanelContainer centerElements={true}>
        <ResetPasswordFinalForm
          token={token ?? ""}
          isVisible={!loading && isSuccess}
        />
        <ResetPasswordFailed
          message={message}
          isVisible={!loading && !isSuccess}
        />
      </PanelContainer>
    </BlockUi>
  );
};

export default ResetPasswordConfirm;
