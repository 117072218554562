import * as React from "react";
import { stylesButtonLanguage } from "./styles";
import { IconButton, Icon, Menu, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ButtonLanguage: () => JSX.Element = (): JSX.Element => {
  const customStyles: Record<string, string> = stylesButtonLanguage();
  const { t, i18n } = useTranslation("commonResources");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick: (event: any) => void = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeLanguage: (abbr?: string) => void = (
    abbr?: string
  ): void => {
    if (abbr) {
        i18n.changeLanguage(abbr);
    }
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        edge="start"
        color="primary"
        title={t("changeLanguage")}
        className={customStyles.root}
        onClick={handleClick}
      >
        <Icon className={`fa fa-flag `} style={{fontSize: "0.8em", paddingRight: "5px"}} />
        {i18n.language}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleChangeLanguage()}
      >
        <MenuItem onClick={() => handleChangeLanguage("en")}>English</MenuItem>
        <MenuItem onClick={() => handleChangeLanguage("pl")}>Polski</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ButtonLanguage;
