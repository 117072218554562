import * as React from "react";
import * as _ from "lodash";
import Numeral from "numeral";
import { CircularProgress, Grid, Divider } from "@material-ui/core";
import PanelBasic from "Components/Panels/panel-basic";
import BlockUi from "react-block-ui";
import TextSubtitle from "Components/Texts/text-subtitle";
import { IOrderCreatorCartProps } from "../interfaces";
import { IProductData } from "Services/ProductApi/interfaces";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import TextInfo from "Components/Texts/text-info";
import { MethodType } from "Utils/enums";
import OrderFinilize from "./order-finilize";
import { useTranslation } from "react-i18next";

const OrderCart: (props: IOrderCreatorCartProps) => JSX.Element
    = (props: IOrderCreatorCartProps): JSX.Element => {
        const [loading, setLoading] = React.useState(false);
        const { t } = useTranslation(["commonResources"]);

        const onClickRemove: (id: number) => void
            = (id: number): void => {
                if (props.handleRemoveFromCart) {
                    props.handleRemoveFromCart(id);
                }
            };

        const onClickMinus: (id: number) => void
            = (id: number): void => {
                if (props.handleChangeQuantity) {
                    props.handleChangeQuantity(id, MethodType.Decrement);
                }
            };

        const onClickPlus: (id: number) => void
            = (id: number): void => {
                if (props.handleChangeQuantity) {
                    props.handleChangeQuantity(id, MethodType.Increment);
                }
            };

        return (
            <React.Fragment>
                <PanelBasic>
                    <BlockUi blocking={loading} loader={<CircularProgress color="secondary" />}>
                        <TextSubtitle>
                            {t("orderForm.yourCart")}
                        </TextSubtitle>
                        <Divider />
                        {props.productList.map((product: IProductData) => (
                            <React.Fragment key={`fragment-${product.id}`}>
                                <Grid container>
                                    <Grid item xs={7} style={{ textAlign: "left" }}>
                                        <TextInfo>
                                            {product.name}
                                        </TextInfo>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ButtonGridIcon icon="fa-minus-square"
                                            key={`btn-minus-${product.id}`}
                                            color="secondary"
                                            onClick={() => {
                                                if (product.id) {
                                                    onClickMinus(product.id);
                                                }
                                            }}
                                        />
                                        <TextInfo key={`txt-qantity-${product.id}`}
                                            style={{
                                                display: "inline-block",
                                                marginRight: "5px",
                                                width: "20px",
                                                textAlign: "center"
                                            }}>
                                            {product.quantity ?? 0}
                                        </TextInfo>
                                        <ButtonGridIcon icon="fa-plus-square"
                                            key={`btn-plus-${product.id}`}
                                            color="secondary"
                                            onClick={() => {
                                                if (product.id) {
                                                    onClickPlus(product.id);
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <ButtonGridIcon icon="fa-trash-alt"
                                            key={`btn-remove-${product.id}`}
                                            color="primary"
                                            onClick={() => {
                                                if (product.id) {
                                                    onClickRemove(product.id);
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                            </React.Fragment>
                        ))}
                        <TextSubtitle style={{ textAlign: "right" }}>
                            {t("sum")} {props.productList.length > 0
                                ? Numeral(_.sum(
                                    props.productList.map((p) => (p.price ?? 0) * (p.quantity ?? 1))))
                                    .format("0,0.00")
                                : 0} zł
                        </TextSubtitle>
                        <Divider />
                        {
                            props.productList.length > 0 &&
                            <OrderFinilize
                                totalPrize={_.sum(
                                    props.productList.map((p) => (p.price ?? 0) * (p.quantity ?? 1)))
                                    ?? 0}
                                handleFinilize={props.handleFinilize} />
                        }
                    </BlockUi>
                </PanelBasic>
            </React.Fragment>
        );
    };

export default OrderCart;