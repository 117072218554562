import * as React from "react";
import { Grid, Divider, Link, Switch } from "@material-ui/core";
import { ISceneObjectData } from "Services/SceneObjectApi/interfaces";
import {
  ISceneObjectGetListModel,
  ISceneObjectUpdateModel,
} from "Services/SceneObjectApi/interfaces-model";
import { ISceneObjectListProps } from "../interfaces";
import { ISceneObjectGetListResult } from "Services/SceneObjectApi/interfaces-result";
import {
  ApiResultStatus,
  SceneObjectGridGetListColumn,
  ObjectType,
} from "Utils/enums";
import { IResultBase, IGridModel } from "Services/interfaces";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import { MUIDataTableColumn } from "mui-datatables";
import { useTranslation } from "react-i18next";
import SceneObjectAddAdmin from "../SceneObjectEditor/scene-object-add-admin";
import useModalSuccess from "Hooks/use-modal-success";
import useModalQuestion from "Hooks/use-modal-question";
import useSceneObjectApi from "Services/SceneObjectApi/scene-object-api";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import GridTable from "Components/Grids/grid-table";
import cogoToast from "cogo-toast";
import SceneObjectEditorAdmin from "../SceneObjectEditor/scene-object-editor-admin";

const SceneObjectList: (props: ISceneObjectListProps) => JSX.Element = (
  props: ISceneObjectListProps
): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const showModalSuccess: (successResult: string) => void = useModalSuccess();
  const { showModalQuestion, setYesFunction } = useModalQuestion();
  const { save, update, deleteById, getList } = useSceneObjectApi();
  const { t } = useTranslation(["commonResources"]);
  const sceneId: number = props.sceneId;

  const handleSceneObjectClick: (
    id: number,
    type: ObjectType,
    name: string,
    description: string
  ) => void = (
    id: number,
    type: ObjectType,
    name: string,
    description: string
  ): void => {
    if (props.handleSceneObjectChange) {
      name = `${name} < ${description ?? "-"} >`;
      props.handleSceneObjectChange(id, name, type);
    }
  };

  const handleClickDelete: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    showModalQuestion(t("objectForm.deleteInfo"), id);
  };

  const deleteObject: (id: number) => Promise<void> = async (
    id: number
  ): Promise<void> => {
    await deleteById(id).then((response: IResultBase) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(t("objectForm.deleteSuccess"));
        dispatchReloadTable();
      }
    });
  };

  const handleSave: (sceneObject: ISceneObjectData) => Promise<void> = async (
    sceneObject: ISceneObjectData
  ): Promise<void> => {
    await save(sceneObject).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        cogoToast.success(t("saveChangesSuccess"));
        dispatchReloadTable();
      }
    });
  };

  const handleUpdate: (
    sceneObjectUpdate: ISceneObjectUpdateModel
  ) => Promise<void> = async (
    sceneObjectUpdate: ISceneObjectUpdateModel
  ): Promise<void> => {
    await update(sceneObjectUpdate).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        cogoToast.success(t("saveChangesSuccess"));
        dispatchReloadTable();
      }
    });
  };

  const handleChangeClick: (
    id: number,
    isAvailableInDemoVersion?: boolean,
    isFacingSpectator?: boolean
  ) => void = (
    id: number,
    isAvailableInDemoVersion?: boolean,
    isFacingSpectator?: boolean
  ): void => {
    let sceneObject: ISceneObjectUpdateModel = {
      id: id,
      isAvailableInDemoVersion: isAvailableInDemoVersion,
      isFacingSpectator: isFacingSpectator,
    };
    handleUpdate(sceneObject);
  };

  React.useEffect(() => {
    setYesFunction(() => deleteObject);
  }, []);

  React.useEffect(() => {
    if (props.sceneId > 0) {
      dispatchReloadTable();
    }
  }, [props.sceneId]);

  const columnSortables: string[] = [
    "object.name",
    "description",
    "object.typeText",
  ];

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "Id",
      options: {
        display: "false",
      },
    },
    {
      name: "object",
      label: t("objectForm.type"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.type;
        },
        display: "false",
      },
    },
    {
      name: "object",
      label: t("objectForm.name"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id: number = tableMeta.rowData[0];
          let type: ObjectType = tableMeta.rowData[1].type;
          let description: string = tableMeta.rowData[3];
          return (
            <Link
              color="secondary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleSceneObjectClick(id, type, value.name, description);
              }}
            >
              {value.name}
            </Link>
          );
        },
      },
    },
    {
      name: "description",
      label: t("description"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div style={{ width: "200px" }}>{value}</div>;
        },
      },
    },
    {
      name: "object",
      label: t("objectForm.type"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.typeText;
        },
      },
    },
    {
      //5
      name: "isAvailableInDemoVersion",
      label: t("availableInDemoVersion"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Switch
              checked={value}
              value={value}
              onChange={(event) => {
                handleChangeClick(
                  tableMeta.rowData[0],
                  event.target.checked,
                  undefined
                );
              }}
            />
          );
        },
      },
    },
    {
      //6
      name: "isFacingSpectator",
      label: t("isFacingSpectator"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.rowData[10].type === ObjectType.Model3D) {
            return (
              <Switch
                checked={value}
                value={value}
                onChange={(event) => {
                  handleChangeClick(
                    tableMeta.rowData[0],
                    undefined,
                    event.target.checked
                  );
                }}
              />
            );
          }
        },
      },
    },
    {
      //7
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <SceneObjectEditorAdmin
              sceneObjectId={tableMeta.rowData[0]}
              handleSaveSuccess={dispatchReloadTable}
            />
          );
        },
      },
    },
    {
      //8
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGridIcon
              icon="fa-trash-alt"
              color="primary"
              onClick={() => {
                handleClickDelete(tableMeta.rowData[0]);
              }}
            />
          );
        },
      },
    },
    {
      //9
      name: "gsObjectId",
      label: "gsObjectId",
      options: {
        display: "false",
      },
    },
    {
      //10
      name: "object",
      label: "object",
      options: {
        display: "false",
      },
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "object.name":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = SceneObjectGridGetListColumn.Name;
        break;
      case "description":
        newSortParams.columnIndex = 1;
        newSortParams.orderColumn = SceneObjectGridGetListColumn.Description;
        break;
      case "object.typeText":
        newSortParams.columnIndex = 2;
        newSortParams.orderColumn = SceneObjectGridGetListColumn.Type;
        break;
    }

    return newSortParams;
  };

  const generateParams: (tableState: IGridModel) => ISceneObjectGetListModel = (
    tableState: IGridModel
  ): ISceneObjectGetListModel => {
    return {
      gsSceneId: sceneId,
      ...tableState,
    };
  };

  return (
    <React.Fragment>
      <Divider />
      <Grid container item justify="flex-start" alignItems="center">
        <Grid
          container
          item
          xs={10}
          justify="flex-start"
          alignItems="flex-start"
        >
          <SceneObjectAddAdmin
            sceneId={sceneId}
            handleSaveSuccess={dispatchReloadTable}
          />
        </Grid>
        <Grid container item xs={2} justify="flex-end" alignItems="flex-end">
          <a
            href={`${process.env.PUBLIC_URL}/docs/doc_help_object.pdf`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <ButtonGridIcon
              icon="fa-question-circle"
              color="primary"
              title={t("downloadHelpFile")}
            />
          </a>
        </Grid>
      </Grid>
      <Divider />
      <GridTable<
        ISceneObjectGetListModel,
        ISceneObjectData,
        ISceneObjectGetListResult
      >
        reloadCount={reloadTable}
        columns={columns}
        columnsSortableNames={columnSortables}
        generateSortParams={generateSortParams}
        generateParams={generateParams}
        getList={getList}
      />
    </React.Fragment>
  );
};

export default SceneObjectList;
