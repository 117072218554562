import useBaseDataApi from "Services/base-data-api";
import { IResultBase } from "Services/interfaces";
import {
  IAdvertGetDetailsResult,
  IAdvertGetListResult,
} from "./interfaces-result";
import { IAdvertData, IAdvertApiInterface } from "./interfaces";
import { IAdvertGetListModel } from "./interfaces-model";

export const useAdvertApi: () => IAdvertApiInterface = (): IAdvertApiInterface => {
  const {
    baseDeleteById,
    baseGetDetails,
    baseGetList,
    baseSave,
  } = useBaseDataApi({ apiControllerName: "Advert" });

  const deleteById: (id: number) => Promise<IResultBase> = async (
    id: number
  ): Promise<IResultBase> => {
    return await baseDeleteById<IResultBase>(id);
  };

  const save: (model: IAdvertData) => Promise<IResultBase> = async (
    model: IAdvertData
  ): Promise<IResultBase> => {
    return await baseSave<IAdvertData, IResultBase>(model);
  };

  const getDetails: (id: number) => Promise<IAdvertGetDetailsResult> = async (
    id: number
  ): Promise<IAdvertGetDetailsResult> => {
    return await baseGetDetails<IAdvertGetDetailsResult>(id);
  };

  const getList: (
    model: IAdvertGetListModel
  ) => Promise<IAdvertGetListResult> = async (
    model: IAdvertGetListModel
  ): Promise<IAdvertGetListResult> => {
    return await baseGetList<IAdvertGetListModel, IAdvertGetListResult>(model);
  };

  return { deleteById, save, getDetails, getList };
};
