import * as React from "react";
import * as Yup from "yup";
import * as _ from "lodash";
import { ISceneEditorAdminProps } from "../interfaces";
import { useSceneApi } from "Services/SceneApi/scene-api";
import { ISceneData } from "Services/SceneApi/interfaces";
import { ISceneGetDetailsResult } from "Services/SceneApi/interfaces-result";
import {
  ApiResultStatus,
  FileType,
  SBSSceneStatus,
  SceneGroupType,
} from "Utils/enums";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Formik, Form, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { IBaseListItem } from "Services/interfaces";
import { ColorResult, SketchPicker } from "react-color";
import { IFileData } from "Services/FileApi/interfaces";
import { IFileUploadResult } from "Services/FileApi/interfaces-results";
import { IFileUploadModel } from "Services/FileApi/interfaces-models";
import { hexToRgb } from "Utils/helper-color";
import { Close } from "@material-ui/icons";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonSecondary from "Components/Buttons/button-secondary";
import ButtonPrimary from "Components/Buttons/button-primary";
import useModalSuccess from "Hooks/use-modal-success";
import FileUploader from "Components/File/file-uploader";
import useFileApi from "Services/FileApi/file-api";
import TextInfo from "Components/Texts/text-info";
import { ISBSSceneData } from "Services/SBSSceneApi/interfaces";
import { useSBSSceneApi } from "Services/SBSSceneApi/sbs-scene-api";
import { ISBSSceneGetListResult } from "Services/SBSSceneApi/interfaces-result";

const SceneEditorAdmin: (props: ISceneEditorAdminProps) => JSX.Element = (
  props: ISceneEditorAdminProps
): JSX.Element => {
  const availableLogoFileFormats: string[] = [".png"];
  const availableThemeMusicFileFormats: string[] = [".mp3"];
  const availableRectangleFileFormats: string[] = [".jpg"];
  const [isOpen, setIsOpen] = React.useState(false);
  const [toSave, setToSave] = React.useState(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = React.useState(false);
  const [logoFile, setLogoFile] = React.useState<File | null>();
  const [valueLogoFile, setValueLogoFile] = React.useState<IFileData | null>(
    null
  );
  const [themeMusicFile, setThemeMusicFile] = React.useState<File | null>();
  const [valueThemeMusicFile, setValueThemeMusicFile] =
    React.useState<IFileData | null>(null);
  const [rectangleFile, setRectangleFile] = React.useState<File | null>();
  const [valueRectangleFile, setValueRectangleFile] =
    React.useState<IFileData | null>(null);
  const [sbsSceneList, setSbsSceneList] = React.useState<
    (ISBSSceneData | { objectsCount?: number })[]
  >([]);
  const { save, getDetails } = useSceneApi();
  const { getList } = useSBSSceneApi();
  const { upload } = useFileApi();

  const showModalSuccess: (successResult: string) => void =
    useModalSuccess(undefined);
  const { t } = useTranslation(["commonResources", "validationResources"]);
  const [values, setValues] = React.useState<ISceneData>({
    name: "",
    demoVersionTimeInSeconds: 60,
    description: "",
    groundColor: null,
    groundColorAlpha: 100,
    groupType: SceneGroupType.Group1,
    isAvailableInTableSceneUsingMode: false,
    isAvailableInPlaygroundSceneUsingMode: false,
    objectScale: 1,
    rLengthInMeters: null,
    playgroundObjectScale: 1,
    playgroundSceneObjectTimelineScale: 1,
    rectangleFirstSideLengthInMeters: 0.001,
    rectangleSecondSideLengthInMeters: 0.001,
  });

  const setFile: (file: File | undefined, fileType: FileType) => void = (
    file: File | undefined,
    fileType: FileType
  ): void => {
    const newValueFile: IFileData | null = file
      ? {
          originalFileName: file.name,
        }
      : null;

    switch (fileType) {
      case FileType.SceneLogo:
        setValueLogoFile(newValueFile);
        setLogoFile(file);
        break;
      case FileType.SceneThemeMusic:
        setValueThemeMusicFile(newValueFile);
        setThemeMusicFile(file);
        break;
      case FileType.SceneRectangle:
        setValueRectangleFile(newValueFile);
        setRectangleFile(file);
        break;
    }
  };

  const uploadFile: (
    file: IFileUploadModel
  ) => Promise<IFileUploadResult> = async (
    file: IFileUploadModel
  ): Promise<IFileUploadResult> => {
    return await upload(file);
  };

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    if (props.sceneId) {
      return await getDetails(props.sceneId).then(
        (response: ISceneGetDetailsResult) => {
          if (response && response.data) {
            setValues(response.data);
            setValueLogoFile(response.data.logoFile ?? null);
            setValueThemeMusicFile(response.data.themeMusicFile ?? null);
            setValueRectangleFile(response.data.rectangleFile ?? null);
          }
        }
      );
    }
  };

  const handleColorChange: (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<ISceneData>
  ) => void = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<ISceneData>
  ): void => {
    let newColor: string = color.hex;
    let newValues: ISceneData = { ...formik.values };
    newValues.groundColor = newColor;
    newValues.groundColorAlpha = (color.rgb.a ?? 0) * 100;
    formik.setValues(newValues);
  };

  const handleColorClear: (formik: FormikProps<ISceneData>) => void = (
    formik: FormikProps<ISceneData>
  ): void => {
    let newValues: ISceneData = { ...formik.values };
    newValues.groundColor = undefined;
    newValues.groundColorAlpha = null;
    formik.setValues(newValues);
  };

  const handleClose: () => void = (): void => {
    setValues({
      name: "",
      demoVersionTimeInSeconds: 60,
      description: "",
      groundColor: null,
      groundColorAlpha: 100,
      groupType: SceneGroupType.Group1,
      isAvailableInTableSceneUsingMode: false,
      isAvailableInPlaygroundSceneUsingMode: false,
      objectScale: 1,
      rLengthInMeters: null,
      playgroundObjectScale: 1,
      playgroundSceneObjectTimelineScale: 1,
      rectangleFirstSideLengthInMeters: 0.001,
      rectangleSecondSideLengthInMeters: 0.001,
      gssbsSceneId: null,
    });
    setValueLogoFile(null);
    setValueThemeMusicFile(null);
    setValueRectangleFile(null);
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    handleLoadData();
    setIsOpen(true);
  };

  const handleColorPickerClose: () => void = (): void => {
    setIsColorPickerOpen(false);
  };

  const handleColorPickerOpen: () => void = (): void => {
    setIsColorPickerOpen(true);
  };

  const handleSave: () => void = (): void => {
    values.gsPlaceId = props.placeId;

    save(values).then((response) => {
      if (response && response.status === ApiResultStatus.Ok) {
        showModalSuccess(
          values.id ? t("saveChangesSuccess") : t("sceneForm.addSuccess")
        );
        if (props.handleSaveSuccess) {
          props.handleSaveSuccess();
        }
        handleClose();
        setToSave(false);
      }
    });
  };

  const handleFilesSave: (scene: ISceneData) => Promise<void> = async (
    scene: ISceneData
  ): Promise<void> => {
    let fileToUpload: IFileUploadModel;
    if (logoFile) {
      fileToUpload = {
        type: FileType.SceneLogo,
        file: logoFile,
      };
      await uploadFile(fileToUpload).then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          scene.logoFile = response.data;
        }
      });
    } else {
      scene.logoFile = valueLogoFile;
    }
    if (themeMusicFile) {
      fileToUpload = {
        type: FileType.SceneThemeMusic,
        file: themeMusicFile,
      };
      await uploadFile(fileToUpload).then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          scene.themeMusicFile = response.data;
        }
      });
    } else {
      scene.themeMusicFile = valueThemeMusicFile;
    }
    if (rectangleFile) {
      fileToUpload = {
        type: FileType.SceneRectangle,
        file: rectangleFile,
      };
      await uploadFile(fileToUpload).then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          scene.rectangleFile = response.data;
        }
      });
    } else {
      scene.rectangleFile = valueRectangleFile;
    }
    setValues(scene);
    setToSave(true);
  };

  const handleLoadSbsSceneList: () => Promise<void> =
    async (): Promise<void> => {
      await getList({ status: SBSSceneStatus.Finished }).then(
        (response: ISBSSceneGetListResult) => {
          if (response && response.status === ApiResultStatus.Ok) {
            setSbsSceneList(response?.data?.gridData ?? []);
          }
        }
      );
    };

  React.useEffect(() => {
    if (toSave) {
      handleSave();
    }
  }, [toSave]);

  React.useEffect(() => {
    if (!props.sceneId) {
      handleLoadSbsSceneList();
    }
  }, []);

  return (
    <React.Fragment>
      {props.sceneId && (
        <ButtonGridIcon
          icon={"fa-edit"}
          onClick={handleOpen}
          color="secondary"
        />
      )}
      {!props.sceneId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-map"} color="secondary" />
          {t("sceneForm.add")}
        </ButtonPrimary>
      )}

      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          {props.sceneId ? t("sceneForm.edit") : t("sceneForm.addInfo")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<ISceneData>({
              name: Yup.string()
                .max(100, t("validationResources:nameMaxLength"))
                .required(t("validationResources:required")),
              description: Yup.string()
                .max(1000, t("validationResources:descriptionMaxLength"))
                .nullable(),
              demoVersionTimeInSeconds: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(1, t("validationResources:numericMinValue", { value: 1 }))
                .max(100000, t("validationResources:numericMax1000000"))
                .required(t("validationResources:required")),
              isAvailableInDemoVersion: Yup.boolean(),
              groupType: Yup.number().required(
                t("validationResources:required")
              ),
              isAvailableInTableSceneUsingMode: Yup.boolean(),
              isAvailableInPlaygroundSceneUsingMode: Yup.boolean(),
              rectangleFirstSideLengthInMeters: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(
                  0.001,
                  t("validationResources:numericMinValue", { value: 0.001 })
                )
                .max(
                  10,
                  t("validationResources:numericMaxValue", { value: 10 })
                )
                .required("validationResources:required"),
              rectangleSecondSideLengthInMeters: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(
                  0.001,
                  t("validationResources:numericMinValue", { value: 0.001 })
                )
                .max(
                  10,
                  t("validationResources:numericMaxValue", { value: 10 })
                )
                .required("validationResources:required"),
              objectScale: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(
                  -10000,
                  t("validationResources:numericMinValue", { value: -10000 })
                )
                .max(
                  10000,
                  t("validationResources:numericMaxValue", { value: 10000 })
                )
                .required(t("validationResources:required")),
              rLengthInMeters: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(
                  0.01,
                  t("validationResources:numericMinValue", { value: 0.01 })
                )
                .max(
                  9999.99,
                  t("validationResources:numericMaxValue", { value: 9999.99 })
                )
                .required(t("validationResources:required")),
              playgroundObjectScale: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(
                  -10000,
                  t("validationResources:numericMinValue", { value: -10000 })
                )
                .max(
                  10000,
                  t("validationResources:numericMaxValue", { value: 10000 })
                )
                .required(t("validationResources:required")),
              playgroundSceneObjectTimelineScale: Yup.number()
                .typeError(t("validationResources:requiredNumeric"))
                .min(
                  -10000,
                  t("validationResources:numericMinValue", { value: -10000 })
                )
                .max(
                  10000,
                  t("validationResources:numericMaxValue", { value: 10000 })
                )
                .required(t("validationResources:required")),
            })}
            onSubmit={(values: ISceneData) => {
              handleFilesSave(values);
            }}
          >
            {(formik) => (
              <Form>
                <Grid
                  container
                  justify="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid container item md={6} sm={12} spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.name ?? ""}
                        onChange={formik.handleChange}
                        error={!!(formik.errors.name && formik.touched.name)}
                        helperText={
                          formik.errors.name &&
                          formik.touched.name &&
                          formik.errors.name
                        }
                        label={t("name")}
                        variant="outlined"
                        size="small"
                        name="name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.description ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.description &&
                            formik.touched.description
                          )
                        }
                        helperText={
                          formik.errors.description &&
                          formik.touched.description &&
                          formik.errors.description
                        }
                        label={t("description")}
                        variant="outlined"
                        size="small"
                        name="description"
                        multiline
                        rowsMax="5"
                        rows="5"
                      />
                    </Grid>
                    {!props.sceneId && (
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          size="small"
                          onChange={formik.handleChange}
                          variant="outlined"
                        >
                          <InputLabel id="sbsscene-select-id">
                            {t("sceneForm.sbsSceneToCopy")}
                          </InputLabel>
                          <Select
                            name="gssbsSceneId"
                            fullWidth
                            labelId="sbsscene-select-id"
                            value={formik.values.gssbsSceneId}
                            onChange={formik.handleChange}
                          >
                            {_.map(sbsSceneList, (sbsScene: ISBSSceneData) => {
                              return (
                                <MenuItem
                                  value={sbsScene.id}
                                  key={`sbs-scene-id-${sbsScene.id}`}
                                >
                                  {sbsScene.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                formik.values.isAvailableInDemoVersion ?? false
                              }
                              onChange={formik.handleChange}
                              value={
                                formik.values.isAvailableInDemoVersion ?? false
                              }
                              name="isAvailableInDemoVersion"
                              disabled={!props.placeIsAvailableInDemoVersion}
                            />
                          }
                          label={t("availableInDemoVersion")}
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                formik.values
                                  .isAvailableInTableSceneUsingMode ?? false
                              }
                              onChange={formik.handleChange}
                              value={
                                formik.values
                                  .isAvailableInTableSceneUsingMode ?? false
                              }
                              name="isAvailableInTableSceneUsingMode"
                            />
                          }
                          label={t("isAvailableInTableSceneUsingMode")}
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                formik.values
                                  .isAvailableInPlaygroundSceneUsingMode ??
                                false
                              }
                              onChange={formik.handleChange}
                              value={
                                formik.values
                                  .isAvailableInPlaygroundSceneUsingMode ??
                                false
                              }
                              name="isAvailableInPlaygroundSceneUsingMode"
                            />
                          }
                          label={t("isAvailableInPlaygroundSceneUsingMode")}
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container item md={6} sm={12} spacing={1}>
                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        size="small"
                        onChange={formik.handleChange}
                        variant="outlined"
                      >
                        <InputLabel id="group-select-id">
                          {t("group")}
                        </InputLabel>
                        <Select
                          name="groupType"
                          fullWidth
                          labelId="group-select-id"
                          value={
                            formik.values.groupType ?? SceneGroupType.Group1
                          }
                          onChange={formik.handleChange}
                        >
                          {_.map(props.groupList, (group: IBaseListItem) => {
                            return (
                              <MenuItem
                                value={group.value}
                                key={`group-id-${group.value}`}
                              >
                                {group.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                      <FormControl variant="outlined" size={"small"}>
                        <InputLabel htmlFor="outlined-adornment-groud-color">
                          {t("sceneForm.groundColour")}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-groud-color"
                          name="groundColor"
                          type={"text"}
                          value={formik.values.groundColor ?? ""}
                          onChange={formik.handleChange}
                          disabled={true}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  handleColorClear(formik);
                                }}
                                onMouseDown={() => {
                                  handleColorClear(formik);
                                }}
                                edge="end"
                                disabled={
                                  formik.values.groupType ===
                                  SceneGroupType.Group3
                                }
                              >
                                <Close />
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <ButtonGridIcon
                        icon={"fa-palette"}
                        onClick={handleColorPickerOpen}
                        iconSize={"20px"}
                        style={{
                          color: formik.values.groundColor ?? "#f7e13e",
                          paddingTop: "10px",
                        }}
                      />
                    </Grid>
                    <Grid container item spacing={1} xs={12}>
                      <Grid item xs={6}>
                        <TextInfo>{t("sceneForm.logoFile")}</TextInfo>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInfo>{t("sceneForm.themeMusicFile")}</TextInfo>
                      </Grid>
                      <Grid item xs={6}>
                        <FileUploader
                          fileName={
                            valueLogoFile?.originalFileName ?? undefined
                          }
                          discFileName={valueLogoFile?.discFileName}
                          file={logoFile}
                          setFile={(file: File | undefined) => {
                            setFile(file, FileType.SceneLogo);
                          }}
                          acceptedFormats={availableLogoFileFormats}
                          addFilePreview={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FileUploader
                          fileName={
                            valueThemeMusicFile?.originalFileName ?? undefined
                          }
                          discFileName={valueThemeMusicFile?.discFileName}
                          file={themeMusicFile}
                          setFile={(file: File | undefined) => {
                            setFile(file, FileType.SceneThemeMusic);
                          }}
                          acceptedFormats={availableThemeMusicFileFormats}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12}>
                      <Grid item xs={6}>
                        <TextInfo>{t("sceneForm.rectangleFile")}</TextInfo>
                      </Grid>
                      <Grid item xs={6}></Grid>
                      <Grid item xs={6}>
                        <FileUploader
                          fileName={
                            valueRectangleFile?.originalFileName ?? undefined
                          }
                          discFileName={valueRectangleFile?.discFileName}
                          file={rectangleFile}
                          setFile={(file: File | undefined) => {
                            setFile(file, FileType.SceneRectangle);
                          }}
                          acceptedFormats={availableRectangleFileFormats}
                          addFilePreview={true}
                          showDeleteButton={true}
                        />
                      </Grid>
                      <Grid item xs={6}></Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          value={
                            formik.values.rectangleFirstSideLengthInMeters ??
                            0.001
                          }
                          onChange={formik.handleChange}
                          error={
                            !!(
                              formik.errors.rectangleFirstSideLengthInMeters &&
                              formik.touched.rectangleFirstSideLengthInMeters
                            )
                          }
                          helperText={
                            formik.errors.rectangleFirstSideLengthInMeters &&
                            formik.touched.rectangleFirstSideLengthInMeters &&
                            formik.errors.rectangleFirstSideLengthInMeters
                          }
                          label={t("rectangleFirstSideLengthInMeters")}
                          variant="outlined"
                          size="small"
                          name="rectangleFirstSideLengthInMeters"
                          disabled={
                            formik.values.groupType === SceneGroupType.Group3
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          value={
                            formik.values.rectangleSecondSideLengthInMeters ??
                            0.001
                          }
                          onChange={formik.handleChange}
                          error={
                            !!(
                              formik.errors.rectangleSecondSideLengthInMeters &&
                              formik.touched.rectangleSecondSideLengthInMeters
                            )
                          }
                          helperText={
                            formik.errors.rectangleSecondSideLengthInMeters &&
                            formik.touched.rectangleSecondSideLengthInMeters &&
                            formik.errors.rectangleSecondSideLengthInMeters
                          }
                          label={t("rectangleSecondSideLengthInMeters")}
                          variant="outlined"
                          size="small"
                          name="rectangleSecondSideLengthInMeters"
                          disabled={
                            formik.values.groupType === SceneGroupType.Group3
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          value={formik.values.objectScale ?? 1}
                          onChange={formik.handleChange}
                          error={
                            !!(
                              formik.errors.objectScale &&
                              formik.touched.objectScale
                            )
                          }
                          helperText={
                            formik.errors.objectScale &&
                            formik.touched.objectScale &&
                            formik.errors.objectScale
                          }
                          label={t("objectScale")}
                          variant="outlined"
                          size="small"
                          name="objectScale"
                          disabled={
                            formik.values.groupType === SceneGroupType.Group3
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          value={formik.values.rLengthInMeters ?? ""}
                          onChange={formik.handleChange}
                          error={
                            !!(
                              formik.errors.rLengthInMeters &&
                              formik.touched.rLengthInMeters
                            )
                          }
                          helperText={
                            formik.errors.rLengthInMeters &&
                            formik.touched.rLengthInMeters &&
                            formik.errors.rLengthInMeters
                          }
                          label={t("rLength")}
                          variant="outlined"
                          size="small"
                          name="rLengthInMeters"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          value={formik.values.playgroundObjectScale ?? 1}
                          onChange={formik.handleChange}
                          error={
                            !!(
                              formik.errors.playgroundObjectScale &&
                              formik.touched.playgroundObjectScale
                            )
                          }
                          helperText={
                            formik.errors.playgroundObjectScale &&
                            formik.touched.playgroundObjectScale &&
                            formik.errors.playgroundObjectScale
                          }
                          label={t("playgroundObjectScale")}
                          variant="outlined"
                          size="small"
                          name="playgroundObjectScale"
                          disabled={
                            formik.values.groupType === SceneGroupType.Group3
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          value={
                            formik.values.playgroundSceneObjectTimelineScale ??
                            1
                          }
                          onChange={formik.handleChange}
                          error={
                            !!(
                              formik.errors
                                .playgroundSceneObjectTimelineScale &&
                              formik.touched.playgroundSceneObjectTimelineScale
                            )
                          }
                          helperText={
                            formik.errors.playgroundSceneObjectTimelineScale &&
                            formik.touched.playgroundSceneObjectTimelineScale &&
                            formik.errors.playgroundSceneObjectTimelineScale
                          }
                          label={t("playgroundSceneObjectTimelineScale")}
                          variant="outlined"
                          size="small"
                          name="playgroundSceneObjectTimelineScale"
                          disabled={
                            formik.values.groupType === SceneGroupType.Group3
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          value={formik.values.demoVersionTimeInSeconds ?? ""}
                          onChange={formik.handleChange}
                          error={
                            !!(
                              formik.errors.demoVersionTimeInSeconds &&
                              formik.touched.demoVersionTimeInSeconds
                            )
                          }
                          helperText={
                            formik.errors.demoVersionTimeInSeconds &&
                            formik.touched.demoVersionTimeInSeconds &&
                            formik.errors.demoVersionTimeInSeconds
                          }
                          label={t("durationInDemo")}
                          variant="outlined"
                          size="small"
                          name="demoVersionTimeInSeconds"
                          disabled={
                            !(
                              props.placeIsAvailableInDemoVersion &&
                              formik.values.isAvailableInDemoVersion
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Dialog
                  open={isColorPickerOpen}
                  onClose={handleColorPickerClose}
                  scroll={"paper"}
                  maxWidth={"sm"}
                >
                  <DialogContent>
                    <SketchPicker
                      color={
                        formik.values.groundColor
                          ? hexToRgb(
                              formik.values.groundColor,
                              (formik.values.groundColorAlpha ?? 100) / 100
                            )
                          : ""
                      }
                      presetColors={[]}
                      onChange={(
                        color: ColorResult,
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleColorChange(color, event, formik);
                      }}
                    />
                  </DialogContent>
                </Dialog>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SceneEditorAdmin;
