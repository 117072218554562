import { IResultBase } from "Services/interfaces";
import { ISceneApiInterface, ISceneData } from "./interfaces";
import { ISceneGetDetailsResult, ISceneGetListResult } from "./interfaces-result";
import { ISceneGetListModel } from "./interfaces-model";
import useBaseDataApi from "Services/base-data-api";

export const useSceneApi: () => ISceneApiInterface
    = (): ISceneApiInterface => {
        const { baseDeleteById, baseGetDetails, baseGetList, baseSave } = useBaseDataApi({ apiControllerName: "Scene"});

        const deleteById: (id: number) => Promise<IResultBase>
        = async (id: number): Promise<IResultBase> => {
            return await baseDeleteById<IResultBase>(id);
        };

        const save: (model: ISceneData) => Promise<IResultBase>
            = async (model: ISceneData): Promise<IResultBase> => {
                return await baseSave<ISceneData, IResultBase>(model);
            };

        const getDetails: (id: number) => Promise<ISceneGetDetailsResult>
            = async (id: number): Promise<ISceneGetDetailsResult> => {
                return await baseGetDetails<ISceneGetDetailsResult>(id);
            };

        const getList: (model: ISceneGetListModel) => Promise<ISceneGetListResult>
            = async (model: ISceneGetListModel): Promise<ISceneGetListResult> => {
                return await baseGetList<ISceneGetListModel, ISceneGetListResult>(model);
            };

        return { deleteById, save, getDetails, getList };
    };