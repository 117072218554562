import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const stylesSceneObjectList: () => Record<string, string> = makeStyles(
    (theme: Theme) =>
      createStyles({
        imageContainer: {
          margin: "0",
          width: "auto",
          "& img": {
            objectFit: "scale-down",
          },
        },
      })
  );