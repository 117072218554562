import GridContainer from "Components/Grids/grid-container";
import GridTable from "Components/Grids/grid-table";
import { IGenerateSortParamsResult } from "Components/Grids/interfaces";
import moment from "moment";
import { MUIDataTableColumn } from "mui-datatables";
import React from "react";
import { useTranslation } from "react-i18next";
import { IGridModel } from "Services/interfaces";
import { INotificationGetListModel } from "Services/NotificationApi/interfaces-model";
import {
  INotificationGetListGridData,
  INotificationGetListResult,
} from "Services/NotificationApi/interfaces-result";
import { useNotificationApi } from "Services/NotificationApi/notification-api";
import { NotificationGridGetListColumn } from "Utils/enums";

const NotificationList: () => JSX.Element = (): JSX.Element => {
  const reloadTableReducer: (reloadTable: number) => number = (
    reloadTable: number
  ): number => {
    reloadTable += 1;
    return reloadTable;
  };
  const [reloadTable, dispatchReloadTable] = React.useReducer(
    reloadTableReducer,
    0
  );
  const { t } = useTranslation(["commonResources", "enumResources"]);
  const { getList } = useNotificationApi();
  const columnSortables: string[] = ["insertDate"];

  const columns: MUIDataTableColumn[] = [
    {
      name: "insertDate",
      label: t("insertDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let dateText: string = value
            ? moment(value).format("DD.MM.YYYY HH:mm")
            : "";
          return dateText;
        },
      },
    },
    {
      name: "sbsScene",
      label: t("scene"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <p
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100px",
              }}
              title={value.name}
            >
              {value.name}
            </p>
          );
        },
        sort: false,
      },
    },
    {
      name: "insertUser",
      label: t("email"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.email ?? "";
        },
        sort: false,
      },
    },
    {
      name: "sbsScene",
      label: t("content"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let content = t("notificationTextByType.SBSSceneFinished", {
            scene: tableMeta.rowData[1].name,
            email: tableMeta.rowData[2].email,
          });
          return (
            <p
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "300px",
              }}
              title={content}
            >
              {content}
            </p>
          );
        },
        sort: false,
      },
    },
  ];

  const generateSortParams: (
    changedColumn: string
  ) => IGenerateSortParamsResult = (
    changedColumn: string
  ): IGenerateSortParamsResult => {
    let newSortParams: IGenerateSortParamsResult = {
      columnIndex: 0,
      orderColumn: 1,
    };

    switch (changedColumn) {
      case "insertDate":
        newSortParams.columnIndex = 0;
        newSortParams.orderColumn = NotificationGridGetListColumn.InsertDate;
        break;
    }

    return newSortParams;
  };

  const generateParams: (
    tableState: IGridModel
  ) => INotificationGetListModel = (
    tableState: IGridModel
  ): INotificationGetListModel => {
    let params: INotificationGetListModel = { ...tableState };
    params.isOnlyNew = false;
    return params;
  };

  return (
    <React.Fragment>
      <GridContainer title={t("notifications")}>
        <GridTable<
          INotificationGetListModel,
          INotificationGetListGridData,
          INotificationGetListResult
        >
          columns={columns}
          columnsSortableNames={columnSortables}
          generateParams={generateParams}
          generateSortParams={generateSortParams}
          getList={getList}
          reloadCount={reloadTable}
        />
      </GridContainer>
    </React.Fragment>
  );
};

export default NotificationList;
