import { RGBColor } from "react-color";

export const hexToRgb: (hex: string, alpha?: number | null) => RGBColor | undefined =
(hex: string, alpha?: number | null): RGBColor | undefined => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: alpha ?? undefined,
    };
  }
  return undefined;
};