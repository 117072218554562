import * as React from "react";
import { IRouteSimple } from "./interfaces";
import { Route } from "react-router-dom";
import PaymentSuccess from "Views/Payment/payment-success";
import Register from "Views/Register/register";
import RegisterConfirm from "Views/Register/register-confirm";
import ResendUserConfirmation from "Views/ResendUserConfirmation/resend-user-confirmation";
import ResetPasswordRequest from "Views/ResetPassword/reset-password-request";
import ResetPasswordConfirm from "Views/ResetPassword/reset-password-confirm";

const routes: IRouteSimple[] = [
    {
        path: "/register",
        component: Register
    },
    {
        path: "/register-confirm/:token",
        component: RegisterConfirm
    },
    {
        path: "/resend-user-confirmation",
        component: ResendUserConfirmation
    },
    {
        path: "/reset-password-reqest",
        component: ResetPasswordRequest
    },
    {
        path: "/reset-password-confirm/:token",
        component: ResetPasswordConfirm
    },
    {
        path: "/payment-success",
        component: PaymentSuccess
    }
];

const GenerateRoute: (route: IRouteSimple) => JSX.Element
= (route: IRouteSimple): JSX.Element => {
    return (
        <Route
            path={route.path}
            render={props => (
                <route.component {...props} />
            )}
        />
    );
};

const RouteSimpleConfig: () => JSX.Element
= (): JSX.Element => {
    return (
        <React.Fragment>
            {routes.map((route, i) => (
                <GenerateRoute key={i} {...route} />
            ))}
        </React.Fragment>
    );
};

export default RouteSimpleConfig;