import { IProductApiInterface, IProductData } from "./interfaces";
import { IResultBase } from "Services/interfaces";
import { IProductGetListModel, IProductCopyModel } from "./interfaces-model";
import {
  IProductGetListResult,
  IProductGetDetailsResult,
} from "./interfaces-result";
import useBaseDataApi from "Services/base-data-api";
import { useBaseApi } from "Services/base-api";

export const useProductApi: () => IProductApiInterface =
  (): IProductApiInterface => {
    const { baseDeleteById, baseGetDetails, baseGetList, baseSave } =
      useBaseDataApi({ apiControllerName: "Product" });
    const { basePostRequest } = useBaseApi();

    const copy: (model: IProductCopyModel) => Promise<IResultBase> = async (
      model: IProductCopyModel
    ): Promise<IResultBase> => {
      let url: string = "/Product/Copy";
      return await basePostRequest<IProductCopyModel, IResultBase>(url, model);
    };

    const deleteById: (id: number) => Promise<IResultBase> = async (
      id: number
    ): Promise<IResultBase> => {
      return await baseDeleteById<IResultBase>(id);
    };

    const save: (model: IProductData) => Promise<IResultBase> = async (
      model: IProductData
    ): Promise<IResultBase> => {
      return await baseSave<IProductData, IResultBase>(model);
    };

    const getDetails: (
      id: number
    ) => Promise<IProductGetDetailsResult> = async (
      id: number
    ): Promise<IProductGetDetailsResult> => {
      return await baseGetDetails<IProductGetDetailsResult>(id);
    };

    const getList: (
      model: IProductGetListModel
    ) => Promise<IProductGetListResult> = async (
      model: IProductGetListModel
    ): Promise<IProductGetListResult> => {
      return await baseGetList<IProductGetListModel, IProductGetListResult>(
        model
      );
    };

    return { copy, deleteById, save, getDetails, getList };
  };
