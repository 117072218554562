import { useState } from "react";
import { DEFAULT_USER_AUTH_TOKEN } from "../Utils/consts";
import { IContextAuthInterface } from "Contexts/intefaces";
import { IUserLoginData } from "Services/UserApi/interfaces";

const useAuthorization: (initialState: string) => IContextAuthInterface
= (initialState: string): IContextAuthInterface => {
    const [authToken, setAuthToken] = useState(initialState);

    const setUser: (user: IUserLoginData) => void = (user: IUserLoginData): void => {
        window.sessionStorage.setItem("UserId", user.id.toString());
        window.sessionStorage.setItem("UserEmail", user.email);
        window.sessionStorage.setItem("UserRole", user.role.toString());
        window.sessionStorage.setItem("UserVirtualWalletBalance", user.virtualWalletBalance.toString());
    };

    const setAuthorize: (authToken: string) => void = (authToken: string): void => {
        window.sessionStorage.setItem("UserAuthToken", authToken);
        setAuthToken(authToken);
    };

    const setUnauthorize: () => void = (): void => {
        window.sessionStorage.clear();
        setAuthToken(DEFAULT_USER_AUTH_TOKEN);
    };

    return { authToken, setUser, setAuthorize, setUnauthorize };
};

export default useAuthorization;