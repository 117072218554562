import * as React from "react";
import * as Yup from "yup";
import { useAdvertApi } from "Services/AdvertApi/advert-api";
import { IAdvertEditorAdminProps } from "../interfaces";
import { IAdvertData } from "Services/AdvertApi/interfaces";
import useModalSuccess from "Hooks/use-modal-success";
import { IAdvertGetDetailsResult } from "Services/AdvertApi/interfaces-result";
import { AdvertType, ApiResultStatus, FileType } from "Utils/enums";
import ButtonGridIcon from "Components/Buttons/button-grid-icon";
import ButtonPrimary from "Components/Buttons/button-primary";
import {
  DialogTitle,
  DialogContent,
  Divider,
  Dialog,
  DialogActions,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Formik, Form, getIn } from "formik";
import ButtonSecondary from "Components/Buttons/button-secondary";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";
import useFileApi from "Services/FileApi/file-api";
import { IFileData } from "Services/FileApi/interfaces";
import { IFileUploadModel } from "Services/FileApi/interfaces-models";
import { IFileUploadResult } from "Services/FileApi/interfaces-results";
import FileUploader from "Components/File/file-uploader";
import TextInfo from "Components/Texts/text-info";
import cogoToast from "cogo-toast";
import { useTranslation } from "react-i18next";

const AdvertEditorAdmin: (props: IAdvertEditorAdminProps) => JSX.Element = (
  props: IAdvertEditorAdminProps
): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [advertFile, setAdvertFile] = React.useState<File>();
  const { upload } = useFileApi();
  const { save, getDetails } = useAdvertApi();
  const { t } = useTranslation([
    "commonResources",
    "enumResources",
    "validationResources",
  ]);
  const showModalSuccess: (successResult: string) => void = useModalSuccess(
    undefined
  );
  const [values, setValues] = React.useState<IAdvertData>({
    description: "",
    displayEndTime: null,
    displayStartTime: null,
    durationInSeconds: null,
    endDate: null,
    isAvailableOnStartupScreen: false,
    name: "",
    radiusInMeters: null,
    startDate: null,
    type: AdvertType.Standard,
    localizationEPSG4326: {
      altitude: 0,
      longitude: null,
      latitude: null,
    },
  });
  const [valuesFile, setValuesFile] = React.useState<IFileData>({});

  const fileFormats: string[] = [".png", ".jpg"];
  const advertId: number = props.advertId ?? 0;

  const setFile: (file: File | undefined) => void = (
    file: File | undefined
  ): void => {
    if (file) {
      let newValuesFile: IFileData = { ...valuesFile };
      newValuesFile = {
        originalFileName: file.name,
      };
      setValuesFile(newValuesFile);
    }
    setAdvertFile(file);
  };

  const uploadFile: (
    file: IFileUploadModel
  ) => Promise<IFileUploadResult> = async (
    file: IFileUploadModel
  ): Promise<IFileUploadResult> => {
    return await upload(file);
  };

  const handleLoadData: () => Promise<void> = async (): Promise<void> => {
    return await getDetails(advertId).then(
      (response: IAdvertGetDetailsResult) => {
        if (response && response.data) {
          response.data.displayStartTime = moment(response.data.displayStartTime as string, 'HH:mm:ss');
          response.data.displayEndTime = moment(response.data.displayEndTime as string, 'HH:mm:ss');

          setValues(response.data);
          setValuesFile(response.data.file ?? {});
        }
      }
    );
  };

  const handleClose: () => void = (): void => {
    setIsOpen(false);
  };

  const handleOpen: () => void = (): void => {
    if (advertId) {
      handleLoadData();
    }
    setIsOpen(true);
  };

  const handleSave: (advert: IAdvertData) => Promise<void> = async (
    advert: IAdvertData
  ): Promise<void> => {
    if (advert.localizationEPSG4326) {
      advert.localizationEPSG4326.altitude = 0;
    }
    if (advert.startDate) {
      advert.startDate = moment(advert.startDate).format("YYYY-MM-DD");
    }
    if (advert.endDate) {
      advert.endDate = moment(advert.endDate).format("YYYY-MM-DD");
    }
    if (advert.displayStartTime && moment(advert.displayStartTime).isValid()) {
      advert.displayStartTime = moment(advert.displayStartTime).format("HH:mm");
    }
    if (advert.displayEndTime && moment(advert.displayEndTime).isValid()) {
      advert.displayEndTime = moment(advert.displayEndTime).format("HH:mm");
    }
    if (advertFile) {
      let fileToUpload: IFileUploadModel = {
        type: FileType.AdvertImage,
        file: advertFile,
      };
      await uploadFile(fileToUpload).then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          advert.file = response.data;
          save(advert).then((response) => {
            if (response && response.status === ApiResultStatus.Ok) {
              showModalSuccess(
                advert.id ? t("saveChangesSuccess") : t("advertForm.addSuccess")
              );
              if (props.handleSaveSuccess) {
                props.handleSaveSuccess();
              }
            }
          });
          handleClose();
        }
      });
      setValuesFile({});
    } else if (valuesFile) {
      advert.file = valuesFile;
      save(advert).then((response) => {
        if (response && response.status === ApiResultStatus.Ok) {
          showModalSuccess(
            advert.id ? t("saveChangesSuccess") : t("advertForm.addSuccess")
          );
          if (props.handleSaveSuccess) {
            props.handleSaveSuccess();
          }
          handleClose();
        }
      });
    }
  };

  return (
    <React.Fragment>
      {!!advertId && (
        <ButtonGridIcon
          icon={"fa-edit"}
          onClick={handleOpen}
          color="secondary"
        />
      )}
      {!advertId && (
        <ButtonPrimary size="sm" onClick={handleOpen}>
          <ButtonGridIcon icon={"fa-ad"} color="secondary" />
          {t("advertForm.add")}
        </ButtonPrimary>
      )}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          {advertId ? t("advertForm.edit") : t("advertForm.addInfo")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Formik
            initialValues={values}
            enableReinitialize={true}
            validationSchema={Yup.object<IAdvertData>({
              name: Yup.string()
                .max(100, t("validationResources:nameMaxLength"))
                .required(t("validationResources:required")),
              description: Yup.string()
                .max(1000, t("validationResources:descriptionMaxLength"))
                .nullable(),
              startDate: Yup.string()
                .nullable()
                .required(t("validationResources:required")),
              endDate: Yup.string()
                .nullable()
                .required(t("validationResources:required")),
              displayStartTime: Yup.string()
                .nullable()
                .required(t("validationResources:required")),
              displayEndTime: Yup.string()
                .nullable()
                .required(t("validationResources:required")),
              radiusInMeters: Yup.number()
                .max(1000000, t("validationResources:numericMax1000000"))
                .typeError(t("validationResources:requiredNumeric"))
                .required(t("validationResources:required")),
              durationInSeconds: Yup.number()
                .max(1000000, t("validationResources:numericMax1000000"))
                .typeError(t("validationResources:requiredNumeric"))
                .required(t("validationResources:required")),
              localizationEPSG4326: Yup.object().shape({
                latitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .required(t("validationResources:required")),
                longitude: Yup.number()
                  .typeError(t("validationResources:requiredNumeric"))
                  .test(
                    "len",
                    t("validationResources:numericBeforeComa"),
                    (val) => Math.floor(Math.abs(val)).toString().length <= 6
                  )
                  .required(t("validationResources:required")),
              }),
            })}
            onSubmit={(values: IAdvertData) => {
              if (valuesFile.originalFileName) {
                handleSave(values);
              } else {
                cogoToast.error(t("fileForm.noChoose"));
              }
            }}
          >
            {(formik) => (
              <Form>
                <Grid container item spacing={2}>
                  <Grid container item spacing={2} xs={6}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.name ?? ""}
                        onChange={formik.handleChange}
                        error={!!(formik.errors.name && formik.touched.name)}
                        helperText={
                          formik.errors.name &&
                          formik.touched.name &&
                          formik.errors.name
                        }
                        label={t("name")}
                        variant="outlined"
                        size="small"
                        name="name"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.description ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.description &&
                            formik.touched.description
                          )
                        }
                        helperText={
                          formik.errors.description &&
                          formik.touched.description &&
                          formik.errors.description
                        }
                        label={t("description")}
                        variant="outlined"
                        size="small"
                        name="description"
                        multiline
                        rowsMax="5"
                        rows="5"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                formik.values.isAvailableOnStartupScreen ??
                                false
                              }
                              onChange={formik.handleChange}
                              value={
                                formik.values.isAvailableOnStartupScreen ??
                                false
                              }
                              name="isAvailableOnStartupScreen"
                            />
                          }
                          label={t("showOnStartSide")}
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        size="small"
                        onChange={formik.handleChange}
                        variant="outlined"
                      >
                        <InputLabel id="advert-type-select-id">
                          {t("advertForm.type")}
                        </InputLabel>
                        <Select
                          name="type"
                          fullWidth
                          labelId="advert-type-select-id"
                          value={formik.values.type ?? AdvertType.Standard}
                          onChange={formik.handleChange}
                        >
                          <MenuItem value={AdvertType.Alert}>
                            {t("enumResources:advertType.alarm")}
                          </MenuItem>
                          <MenuItem value={AdvertType.Standard}>
                            {t("enumResources:advertType.standard")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2} xs={6}>
                    <Grid item xs={12}>
                      <FileUploader
                        fileName={valuesFile?.originalFileName ?? undefined}
                        discFileName={valuesFile?.discFileName}
                        file={advertFile}
                        setFile={setFile}
                        acceptedFormats={fileFormats}
                        addFilePreview={true}
                        size="1920x1080"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={plLocale}
                      >
                        <DatePicker
                          fullWidth
                          autoOk={true}
                          format={"dd.MM.yyy"}
                          name="startDate"
                          value={formik.values.startDate}
                          onChange={(ed) =>
                            formik.setFieldValue(
                              "startDate",
                              ed ? moment(ed).toString() : null
                            )
                          }
                          label={t("startDate")}
                          size="small"
                          inputVariant="outlined"
                          clearable={true}
                          error={
                            !!(
                              formik.errors.startDate &&
                              formik.touched.startDate
                            )
                          }
                          helperText={
                            formik.errors.startDate &&
                            formik.touched.startDate &&
                            formik.errors.startDate
                          }
                          cancelLabel={t("cancel")}
                          clearLabel={t("clear")}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={plLocale}
                      >
                        <TimePicker
                          fullWidth
                          autoOk={true}
                          ampm={false}
                          name="displayStartTime"
                          value={formik.values.displayStartTime}
                          onChange={(ed) =>
                            formik.setFieldValue(
                              "displayStartTime",
                              ed ? moment(ed).toString() : null
                            )
                          }
                          label={t("startTime")}
                          size="small"
                          inputVariant="outlined"
                          clearable={true}
                          error={
                            !!(
                              formik.errors.displayStartTime &&
                              formik.touched.displayStartTime
                            )
                          }
                          helperText={
                            formik.errors.displayStartTime &&
                            formik.touched.displayStartTime &&
                            formik.errors.displayStartTime
                          }
                          cancelLabel={t("cancel")}
                          clearLabel={t("clear")}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={plLocale}
                      >
                        <DatePicker
                          fullWidth
                          autoOk={true}
                          format={"dd.MM.yyy"}
                          name="endDate"
                          value={formik.values.endDate}
                          onChange={(ed) =>
                            formik.setFieldValue(
                              "endDate",
                              ed ? moment(ed).toString() : null
                            )
                          }
                          label={t("endDate")}
                          size="small"
                          inputVariant="outlined"
                          clearable={true}
                          error={
                            !!(formik.errors.endDate && formik.touched.endDate)
                          }
                          helperText={
                            formik.errors.endDate &&
                            formik.touched.endDate &&
                            formik.errors.endDate
                          }
                          cancelLabel={t("cancel")}
                          clearLabel={t("clear")}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={plLocale}
                      >
                        <TimePicker
                          fullWidth
                          autoOk={true}
                          ampm={false}
                          name="displayEndTime"
                          value={formik.values.displayEndTime}
                          onChange={(ed) =>
                            formik.setFieldValue(
                              "displayEndTime",
                              ed ? moment(ed).toString() : null
                            )
                          }
                          label={t("endTime")}
                          size="small"
                          inputVariant="outlined"
                          clearable={true}
                          error={
                            !!(
                              formik.errors.displayEndTime &&
                              formik.touched.displayEndTime
                            )
                          }
                          helperText={
                            formik.errors.displayEndTime &&
                            formik.touched.displayEndTime &&
                            formik.errors.displayEndTime
                          }
                          cancelLabel={t("cancel")}
                          clearLabel={t("clear")}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid container item xs={12}>
                      <TextField
                        fullWidth
                        value={formik.values.durationInSeconds ?? ""}
                        onChange={formik.handleChange}
                        error={
                          !!(
                            formik.errors.durationInSeconds &&
                            formik.touched.durationInSeconds
                          )
                        }
                        helperText={
                          formik.errors.durationInSeconds &&
                          formik.touched.durationInSeconds &&
                          formik.errors.durationInSeconds
                        }
                        label={t("durationInSeconds")}
                        variant="outlined"
                        size="small"
                        name="durationInSeconds"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Divider />
                    <TextInfo color={"secondary"}>
                      {t("possitionInEpsg4326")}
                    </TextInfo>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      value={
                        formik.values.localizationEPSG4326?.longitude ?? ""
                      }
                      onChange={formik.handleChange}
                      error={
                        !!(
                          getIn(
                            formik.errors,
                            "localizationEPSG4326.longitude"
                          ) &&
                          getIn(
                            formik.touched,
                            "localizationEPSG4326.longitude"
                          )
                        )
                      }
                      helperText={
                        getIn(
                          formik.errors,
                          "localizationEPSG4326.longitude"
                        ) &&
                        getIn(
                          formik.touched,
                          "localizationEPSG4326.longitude"
                        ) &&
                        getIn(formik.errors, "localizationEPSG4326.longitude")
                      }
                      label="L"
                      variant="outlined"
                      size="small"
                      name="localizationEPSG4326.longitude"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      value={formik.values.localizationEPSG4326?.latitude ?? ""}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          getIn(
                            formik.errors,
                            "localizationEPSG4326.latitude"
                          ) &&
                          getIn(formik.touched, "localizationEPSG4326.latitude")
                        )
                      }
                      helperText={
                        getIn(formik.errors, "localizationEPSG4326.latitude") &&
                        getIn(
                          formik.touched,
                          "localizationEPSG4326.latitude"
                        ) &&
                        getIn(formik.errors, "localizationEPSG4326.latitude")
                      }
                      label="B"
                      variant="outlined"
                      size="small"
                      name="localizationEPSG4326.latitude"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      value={formik.values.radiusInMeters ?? ""}
                      onChange={formik.handleChange}
                      error={
                        !!(
                          formik.errors.radiusInMeters &&
                          formik.touched.radiusInMeters
                        )
                      }
                      helperText={
                        formik.errors.radiusInMeters &&
                        formik.touched.radiusInMeters &&
                        formik.errors.radiusInMeters
                      }
                      label={t("radiusInMeters")}
                      variant="outlined"
                      size="small"
                      name="radiusInMeters"
                    />
                  </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                  <ButtonSecondary onClick={handleClose}>
                    {t("cancel")}
                  </ButtonSecondary>
                  <ButtonPrimary type="submit" onClick={formik.handleSubmit}>
                    {t("save")}
                  </ButtonPrimary>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default AdvertEditorAdmin;
