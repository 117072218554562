import * as React from "react";
import { stylesContainer } from "./styles";
import { Container } from "@material-ui/core";
import { IPanelContainerProps } from "./interfaces";

const PanelContainer: (props: IPanelContainerProps) => JSX.Element = (
  props: IPanelContainerProps
): JSX.Element => {
  const customStyles: Record<string, string> = stylesContainer(props);
  const { children, centerElements, ...other } = props;

  return (
    <Container
      className={customStyles.root}
      maxWidth={false}
    >
      {children}
    </Container>
  );
};

export default PanelContainer;
