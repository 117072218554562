import { makeStyles, Theme, createStyles, useTheme, useMediaQuery } from "@material-ui/core";

export const stylesGridContainer: () => Record<string, string> = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            padding: 0
        },
        paper: {
            backgroundColor: "transparent",
            textAlign: "center",
            [theme.breakpoints.up("lg")]: {
                paddingTop: "10px",
                padding: "30px"
            },
            [theme.breakpoints.down("md")]: {
                padding: "15px"
            },
            boxShadow: "none",
            border: "none"
        }
    }));
