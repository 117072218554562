import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const stylesFileUploader: () => Record<string, string> = makeStyles(
  (theme: Theme) =>
    createStyles({
      centerItem: {
        width: "100%",
        height: "100px",
        display: "table",
      },
      fileInfo: {
        textAlign: "center",
        verticalAlign: "middle",
        display: "table-cell",
      },
      container: {
        border: `1px dashed ${theme.palette.text.secondary}`,
        borderRadius: "5px",
        width: "100%",
        height: "100px",
        outline: "none",
        textAlign: "center",
        marginBottom: "18px",
      },
      imageContainer: {
        margin: "0 5px",
        width: "auto",
        "& img": {
          objectFit: "scale-down",
        },
      },
    })
);
