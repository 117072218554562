import * as React from "react";
import { Grid } from "@material-ui/core";
import UserDetailsInvoice from "./user-details-invoice";
import UserDetailsPassword from "./user-details-password";
import { getStoredUserRole } from "Utils/helpers";
import { UserRole } from "Utils/enums";
import GridContainer from "Components/Grids/grid-container";
import { useTranslation } from "react-i18next";

const UserDetails: () => JSX.Element = (): JSX.Element => {
  const { t } = useTranslation(["commonResources"]);

  return (
    <GridContainer title={t("yourAccount")}>
      <Grid container alignItems="flex-start" spacing={2}>
        {getStoredUserRole() === UserRole.Standard && (
          <Grid item xs={12} md={6}>
            <UserDetailsInvoice />
          </Grid>
        )}
        <Grid item xs={12} md={6} style={{ textAlign: "left" }}>
          <UserDetailsPassword />
        </Grid>
      </Grid>
    </GridContainer>
  );
};

export default UserDetails;
